import React from "react";
import NoUser from "../Images/Recipt.png";

function RecipientCart({
  list,
  setEditIndex,
  formik,
  deleteItemById,
  setShowCart,
  roundedTotalProductBalance,
  setShowEmailSmsTemp,
  setSendIndividual,
  handleEditClick
}) {
  return (
    <>
      <div className="col-12 col-lg-12 mb-4 ">
        <div className="card">
          <div className="card-body" style={{ padding: "0.6rem" }}>
            {list?.length !== 0 ? (
              <>
                <div className="table-responsive text-nowrap">
                  {/* <div style={{ fontSize: "22px", fontWeight: "700" }}>
                    Added Recipients
                  </div>
                  <div className="fs-12 mb-4">
                    Enable the convenience of adding one or multiple recipients
                    to effortlessly send vouchers.
                  </div> */}
                  <table className="table " >
                    <thead
                      className=" text-white"
                      style={{
                        backgroundColor: "#D5D2FF",
                        width: "fit-content",
                      }}
                    >
                      <tr>
                        <th
                          className="text-center"
                          style={{ borderRight: "1.5px solid gray" }}
                        >
                          Details
                        </th>

                        <th
                          className="text-center"
                          style={{ borderRight: "1.5px solid gray" }}
                        >
                          Brand
                        </th>

                        <th
                          className="text-center"
                          style={{ borderRight: "1.5px solid gray" }}
                        >
                          Amount
                        </th>
                        <th
                          className="text-center"
                          style={{ borderRight: "1.5px solid gray" }}
                        >
                          Quantity
                        </th>
                        <th
                          className="text-center"
                          style={{ borderRight: "1.5px solid gray" }}
                        >
                          Discount
                        </th>
                        <th
                          className="text-center"
                          style={{ borderRight: "1.5px solid gray" }}
                        >
                          Total Payable
                        </th>

                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {list?.map((item, index) => {
                        return (
                          <tr className="" key={index}>
                            <td
                              className="fs-12 "
                              style={{
                                paddingLeft: "0px",
                                backgroundColor: "#FAFAFF",
                              }}
                            >
                              {item?.email}
                              <br />
                              <span className="text-left">
                                {item?.phoneNumber}
                              </span>
                            </td>
                            <td className="fs-12 text-center ">
                              {item?.voucher?.data?.product_name}
                            </td>

                            <td className="fs-12 text-center ">
                              &#8377;{" "}
                              {item?.voucherValue?.value
                                ? item?.voucherValue?.value
                                : item?.voucherValue}
                            </td>
                            <td className="fs-12 text-center ">
                              {item?.numberOfVouchers}
                            </td>
                            <td className="fs-12 text-center ">
                              {" "}
                              {item?.voucher?.data?.discount}%
                            </td>

                            <td>
                              <span>
                                <div className="fs-12 text-center ">
                                  {/* Total:{" "}
                                  {item?.voucherValue?.value
                                    ? item?.voucherValue?.value
                                    : item?.voucherValue}
                                  {" , "} */}
                                  &#8377;{" "}
                                  {(item?.voucherValue?.value
                                    ? item?.voucherValue?.value
                                    : item?.voucherValue -
                                      ((item?.voucherValue?.value
                                        ? item?.voucherValue?.value
                                        : item?.voucherValue) *
                                        item?.voucher?.data?.discount) /
                                        100) * item?.numberOfVouchers}
                                </div>
                              </span>
                            </td>

                            <td>
                              <div className="d-flex fs-12 justify-content-center gap-2">
                                {" "}
                                <span
                                  onClick={() => {
                                    setShowCart(false);
                                    setEditIndex(index);
                                    formik.setValues({
                                      email: item?.email,
                                      name: item?.name,
                                      phoneNumber: item?.phoneNumber,
                                      voucher: item?.voucher,
                                      voucherValue: item?.voucherValue,
                                      numberOfVouchers: item?.numberOfVouchers,
                                      showAdditional: item?.showAdditional,
                                      personalMessage: item?.personalMessage,
                                      poNumber: item?.poNumber,
                                    });
                                    handleEditClick()
                                  }}
                                >
                                  <i className="bx bx-edit-alt me-2 p-1 rounded cursor-pointer text-white bg-primary" />
                                </span>
                                <span onClick={() => deleteItemById(index)}>
                                  <i className="bx bx-trash me-2 p-1 rounded cursor-pointer text-white bg-primary" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="container text-center">
                  <img
                    src={NoUser}
                    className="img-fluid mx-auto"
                    alt="Centered Image"
                    style={{ width: "20%" }}
                  />
                  <div className="mt-3">
                    <p className="fw-bold">No Recipients!</p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className=" d-flex justify-content-between col px-5  mt-5">
            <div className="fw-bold" style={{ fontSize: "18px" }}>
              Grand Total:{" "}
              <span
                className="fw-bold"
                style={{ color: "#594DF1", fontSize: "18px" }}
              >
                &#8377;{roundedTotalProductBalance?.toFixed(2)}
              </span>
            </div>
            <div>
              {" "}
              {/* <button
                type="button"
                className="btn mb-4 me-2 "
                style={{ color: "#594DF1", border: "1px solid #594DF1" }}
                onClick={() => setShowCart(false)}
              >
                Add More
              </button> */}
              <button
                type="button"
                className="btn btn-primary mb-4 me-2 "
                onClick={() => {
                  setShowEmailSmsTemp(true);
                  setSendIndividual(false);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecipientCart;
