import React from "react";
import EmailPreview from "../EmailPreview";

const EmailAndSms = ({
  setShowExplore,
  setTemplateId,
  templateId,
  setTempImg,
  tempImg,
  emailSub,
  setEmailSub,
  setShowEmailSmsTemp,
  setSendIndividual,
  handleGenrate,
  setCustomTemp,
  customTemp,
  list,
}) => {

  return (
    <div className="row m-auto bg-light p-3 container-xxl">
      <p className="card-text mt-2 flex-wrap d-flex justify-content-between">
        <h5 className="card-title text-primary fw-semibold" style={{lineHeight:"34px"}}>
          Customise Email 
      
        </h5>
        <div className="d-flex  justify-content-end  gap-3">
        <div
          type="button"
          className="btn btn-primary "
          onClick={() => {
            setShowEmailSmsTemp(false);
            setSendIndividual(true);
          }}
        >
          Back
        </div>
        <div
          type="button"
          className="btn btn-primary "
          style={{ width: "5rem" }}
          onClick={() => {
            handleGenrate();
          }}
        >
          Send
        </div>
      </div>
      </p>
      <ul className="nav nav-pills nav-fill   " style={{ width: "10rem" }}>
        {/* <li
          className="nav-item "
          style={{
            borderRadius: "100px", 
            width:"5.3rem"
          }}
        >
          <a className="nav-link " aria-current="page" href="#">
            Email
          </a>
        </li> */}
        {/* <li
          className="nav-item bg-secondary"
          style={{
            borderTopRightRadius: "100px",
            borderBottomRightRadius: "100px",
          }}
        >
          <a className="nav-link" href="#">
            Sms
          </a>
        </li> */}
      </ul>
     
      <EmailPreview
        setShowExplore={setShowExplore}
        setTemplateId={setTemplateId}
        templateId={templateId}
        setTempImg={setTempImg}
        tempImg={tempImg}
        emailSub={emailSub}
        list={list}
        setEmailSub={setEmailSub}
        customTemp={customTemp}
        setCustomTemp={setCustomTemp}
      />
    </div>
  );
};

export default EmailAndSms;
