import React, { useEffect, useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import {
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Delete";

import { useNavigate } from "react-router-dom";
import {
  companyMemberList,
  deleteCompanyMember,
} from "../services/Apiservices";
import { useSelector } from "react-redux";
import showNotification from "../services/NotificationService";

function ManageAdmins() {
  const [list, setList] = useState([]);
  const data = useSelector((state) => state?.profile?.data?.data);
  const permissions = data?.permissions?.map((item) => item?.id);

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.company_added) {
      getPermission();
    }
  }, []);

  const getPermission = async () => {
    try {
      const response = await companyMemberList();
      // Assuming API returns an array of user objects

      if (response?.status == 200) {
        setList(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
    }
  };

  const handleNavigate = () => {
    if (data?.company_verified) {
      navigate("/admin/add-admins");
    } else {
      showNotification("danger", "Please verify your company");
    }
  };
  const deleteMember = async (id) => {
    let confirm = window.confirm(
      "Are you sure you want to perform this action?"
    );
    if (confirm) {
      try {
        const response = await deleteCompanyMember(id);
        // Assuming API returns an array of user objects

        if (response?.status == 200) {
          getPermission();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
      }
    }
  };
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row d-flex justify-content-end">
                <div className="col-md-8 col-sm-12">
                  <div className="d-flex flex-column">
                    <div>
                      <h4 className="fw-semibold text-primary">
                        <span className="text-muted fw-light"></span>Admins
                      </h4>
                      <p>Add, modify, or delete the admins of your account.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="d-flex justify-content-end">
                    {data?.company_verified && (
                      <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={() => navigate("/admin/invitations")}
                      >
                        Invitations
                      </button>
                    )}

                    {data?.company_added && permissions?.includes(2) && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleNavigate()}
                      >
                        Add Admin
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {list?.length !== 0 ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead
                        style={{
                          backgroundColor: "#B2AEFF",
                          color: "#111827",
                          fontWeight: "700",
                        }}
                        aria-label="simple table"
                      >
                        <TableRow>
                          <TableCell className="fw-bold" align="center">Id</TableCell>

                          <TableCell align="center" className="fw-bold">
                            User
                          </TableCell>
                          <TableCell align="center" className="fw-bold">
                            Email
                          </TableCell>
                          <TableCell align="center" className="fw-bold">
                            Mobile no
                          </TableCell>
                          <TableCell align="center" className="fw-bold">
                            Role
                          </TableCell>
                          <TableCell align="center" className="fw-bold">
                            Status
                          </TableCell>

                          <TableCell align="center" className="fw-bold">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {list?.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row?.id}
                            </TableCell>
                            <TableCell align="center">
                              {row?.full_name}
                            </TableCell>
                            <TableCell align="center">{row?.email}</TableCell>
                            <TableCell align="center">
                              {row?.mobile_no}
                            </TableCell>

                            <TableCell align="center">
                              {row?.role == 1
                                ? "Super Admin"
                                : row?.role == 2
                                  ? "Admin"
                                  : "Viewer"}
                            </TableCell>
                            <TableCell align="center">
                              {row?.user_approved ? "Accepted" : "Invited"}
                            </TableCell>

                            <TableCell align="center">
                              {" "}
                              <>
                                {row?.role != 1 || row?.creator ? (
                                  <span title="Can Not Edit">
                                    {" "}
                                    <IconButton color="primary" disabled>
                                      <Avatar
                                        style={{
                                          backgroundColor: "gray",
                                          color: "white",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        <CheckIcon
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      </Avatar>
                                    </IconButton>
                                  </span>
                                ) : (
                                  <IconButton
                                    color="primary"

                                    onClick={() =>
                                      navigate(`/admin/edit-admin/${row?.id}`, {
                                        state: "manage",
                                      })
                                    }
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: "blue",
                                        color: "white",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      <CheckIcon
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </Avatar>
                                  </IconButton>
                                )}

                                {permissions?.includes(4) &&
                                  (row?.role != 1 || row?.creator ? (
                                    <span title="Can not delete">
                                      {" "}
                                      <IconButton color="secondary" disabled>
                                        <Avatar
                                          style={{
                                            backgroundColor: "gray",
                                            color: "white",
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          <ClearIcon
                                            style={{
                                              width: "15px",
                                              height: "15px",
                                            }}
                                          />
                                        </Avatar>
                                      </IconButton>
                                    </span>
                                  ) : (
                                    <IconButton
                                      color="secondary"
                                      onClick={() => deleteMember(row?.id)}
                                    >
                                      <Avatar
                                        style={{
                                          backgroundColor: "blue",
                                          color: "white",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        <ClearIcon
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      </Avatar>
                                    </IconButton>
                                  ))}
                              </>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="h6" align="center">
                    {data?.company_added
                      ? " No Members found."
                      : "Please add company"}
                  </Typography>
                )}
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default ManageAdmins;
