import React from "react";


import { useNavigate, Link, useLocation } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  return (
    <span
                      className="fw-semibold text-primary cursor-pointer"
                      onClick={()=>navigate(-1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M21 11H6.414l5.293-5.293l-1.414-1.414L2.586 12l7.707 7.707l1.414-1.414L6.414 13H21z"
                        />
                      </svg>{" "}
                      Back
                    </span>
  );
}

export default BackButton;
