import React from "react";
import { Modal, Button } from "react-bootstrap";
const PaymentModal = ({ show, setShowModal,amount ,handleReset}) => {
  return (
    <Modal show={show}  centered size="sm">
      <div className="d-flex flex-column align-items-center gap-3 py-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 145 144"
          fill="none"
        >
          <circle
            cx="72.4999"
            cy="72.0153"
            r="71.7201"
            fill="#23A26D"
            fill-opacity="0.12"
          />
          <path
            d="M71.2193 37.863C52.4013 37.863 37.0669 53.1975 37.0669 72.0155C37.0669 90.8334 52.4013 106.168 71.2193 106.168C90.0373 106.168 105.372 90.8334 105.372 72.0155C105.372 53.1975 90.0373 37.863 71.2193 37.863ZM87.5442 64.1604L68.1797 83.5248C67.7016 84.003 67.0527 84.2762 66.3697 84.2762C65.6866 84.2762 65.0377 84.003 64.5596 83.5248L54.8945 73.8597C53.904 72.8693 53.904 71.2299 54.8945 70.2395C55.8849 69.2491 57.5242 69.2491 58.5146 70.2395L66.3697 78.0946L83.924 60.5402C84.9144 59.5498 86.5538 59.5498 87.5442 60.5402C88.5346 61.5307 88.5346 63.1358 87.5442 64.1604Z"
            fill="#23A26D"
          />
        </svg>
        <div
          style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}
        >
          ₹{amount}
        </div>
      </div>
      <div>
        <h5 className="text-center py-2 text-gray">added successfully!</h5>
      </div>
      <div className="d-flex justify-content-center pb-4 ">
        <Button variant="primary" className="px-4" onClick={()=>{setShowModal(false);handleReset()}}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentModal;
