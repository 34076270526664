import React from 'react'
import comImg from "./comgif.gif"

function ComingSoon() {
  return (
    
<div>
<img src={comImg} style={{display:'block',margin:'auto'}}/>

</div>
    
    
    
    
    
  )
}

export default ComingSoon