import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import profileDummy from "../dummy.png";
import { getProfile } from "../redux/actions/profileAction";
import { editProfile } from "../services/Apiservices";
import showNotification from "../services/NotificationService";
import Navbar from "./CommonComponents/Navbar";
import SIdeBar from "./CommonComponents/SIdeBar";
import { restrictNumeric } from "../constant";

function Profile() {
  const [showForm, setShowForm] = useState(false);
  const data = useSelector((state) => state?.profile?.data?.data);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Full name is required")
      .min(3, "Full name must be at least 3 characters"),
    profilePhoto: Yup.mixed()
      .nullable()
      .test(
        "fileSize",
        "Profile photo is too large, maximum size is 5MB",
        (value) => {
          if (!value) return true; // No file, no size check
          return value.size <= 5 * 1024 * 1024; // 5MB
        }
      )
      .test(
        "fileType",
        "Unsupported file type, only JPEG, PNG, or GIF are allowed",
        (value) => {
          if (!value) return true; // No file, no type check
          return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
        }
      ),
  });

  const initialValues = {
    fullName: data?.full_name,
    email: data?.email,
    mobileNo: data?.mobile_no,
    profilePhoto: null,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      handleSave(values);
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("profilePhoto", file);

    // Display image preview
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  const handleSave = async (values) => {
    setisLoading(true);
    let body = {
      full_name: values?.fullName,
      dob: "",
      gender: "",
      profile_pic: values?.profilePhoto,
    };
    const formData = new FormData();

    Object.entries(body).forEach(([key, value]) => formData.append(key, value));
    try {
      let response = await editProfile(formData);
      if (response?.status == 200) {
        showNotification("success", "Profile Updated Successfully");
        setisLoading(false);
        dispatch(getProfile());
        // dispatch(setUser({
        //   full_name:response?.data?.full_name,
        //   profile_picture:response?.data?.profile_picture,
        //   mobile_no:response?.data?.mobile_no,
        //   email:response?.data?.email

        // }));
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}
              <div className="container-xxl flex-grow-1 container-p-y">
                {/* <div className="alert alert-warning mt-2" role="alert">
                  Appropriate currency conversion value will be applied, if the
                  voucher you send are in different currencies than your base
                  currency.
                </div> */}
                {data?.email_verified ? (
                  <></>
                ) : (
                  <div className="alert alert-warning mt-2" role="alert">
                    Please verify your email !
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <h5 className="card-header text-primary mt-3">
                        Profile Details
                      </h5>
                      <hr />
                      <div className="d-flex flex-column flex-md-row">
                        <div className="card-body">
                          <div className="d-flex flex-column  align-items-center gap-4">
                            {imagePreview ? (
                              <img
                                src={imagePreview}
                                alt="user-avatar"
                                className="d-block rounded"
                                height={200}
                                width={200}
                                id="uploadedAvatar"
                              />
                            ) : (
                              <img
                                src={
                                  data?.profile_photo
                                    ? data?.profile_photo
                                    : profileDummy
                                }
                                alt="user-avatar"
                                className="d-block rounded"
                                height={200}
                                width={200}
                                id="uploadedAvatar"
                              />
                            )}

                            <div className="button-wrapper">
                              <label
                                htmlFor="upload"
                                className="btn btn-primary me-2 mb-4"
                                tabIndex={0}
                              >
                                <span className="d-none d-sm-block">
                                  Upload new photo
                                </span>
                                <i className="bx bx-upload d-block d-sm-none" />
                                <input
                                  type="file"
                                  name="profilePhoto"
                                  onChange={handleImageChange}
                                  onBlur={formik.handleBlur}
                                  id="upload"
                                  className="account-file-input"
                                  hidden="true"
                                  accept="image/png, image/jpeg"
                                />
                              </label>
                              {formik.errors.profilePhoto ? (
                                <div className="text-danger">
                                  {formik.errors.profilePhoto}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <hr className="my-0" />
                        <div className="card-body">
                          <form id="formAccountSettings">
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  htmlFor="firstName"
                                  className="form-label"
                                >
                                  Full Name
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="fullName"
                                  name="fullName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.fullName}
                                  onKeyPress={restrictNumeric}
                                />
                                {formik.touched.fullName &&
                                formik.errors.fullName ? (
                                  <div className="text-danger">
                                    {formik.errors.fullName}
                                  </div>
                                ) : null}
                              </div>

                              <div className="mb-3 col-md-6">
                                <label htmlFor="email" className="form-label">
                                  E-mail
                                </label>
                                <div className="input-group input-group-merge">
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="email"
                                    name="email"
                                    defaultValue={formik.values.email}
                                    readOnly
                                  />
                                  {data?.email_verified ? (
                                    <span className="input-group-text cursor-pointer">
                                      <i
                                        className="bx bx-check-circle"
                                        style={{ color: "green" }}
                                      />
                                    </span>
                                  ) : (
                                    <span
                                      className="input-group-text cursor-pointer"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                      }}
                                    >
                                      Verify
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="phoneNumber"
                                >
                                  Phone Number
                                </label>
                                <div className="input-group input-group-merge">
                                  <span className="input-group-text">
                                    IN (+91)
                                  </span>
                                  <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    className="form-control"
                                    defaultValue={formik.values.mobileNo}
                                    readOnly
                                  />

                                  <span className="input-group-text cursor-pointer">
                                    <i
                                      className="bx bx-check-circle"
                                      style={{ color: "green" }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="address" className="form-label">
                                  Role
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  name="address"
                                  value={data?.role}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-md-end justify-content-sm-center align-items-end mt-5">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={() => formik?.handleSubmit()}
                              >
                                Save changes
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* Account */}

                      {/* /Account */}
                    </div>
                  </div>
                </div>
              </div>
              {/* / Content */}
              {/* Footer */}

              {/* / Footer */}
              <div className="content-backdrop fade" />
            </div>

            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}

export default Profile;
