import React, { useState } from "react";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/reducers/authSlice";
import { BASE_URL } from "../../constant";
import dummyImg from "../../dummy.png";

function Navbar() {
  const [showDrop, setShowDrop] = useState(false);
  const data = useSelector((state) => state?.profile?.data?.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAddClass = () => {
    document.documentElement.classList.add("layout-menu-expanded");
  };
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDrop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const handleLogout = () => {
    navigate("/");

    dispatch(logout());
    localStorage.clear();
    sessionStorage?.clear();
  };
  return (
    <>
      {" "}
      <nav
        className="layout-navbar ] navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        style={{ width: "100%" }}
        id="layout-navbar"
      >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <a
            className="nav-item nav-link px-0 me-xl-4"
            href="#"
            onClick={handleAddClass}
          >
            <i className="bx bx-menu bx-sm" />
          </a>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          {/* Search */}
          <div className="navbar-nav align-items-center">
            <div className="nav-item d-flex align-items-center"></div>
          </div>
          {/* /Search */}
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {/* Place this tag where you want the button to render. */}
            <li className="nav-item  me-3 mt-1">
              <span className="fw-bold fs-12" style={{ color: "black" }}>
                {data?.company_name && data?.company_name}
              </span>
            </li>
            {/* User */}
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <a
                className={
                  showDrop
                    ? "nav-link dropdown-toggle hide-arrow show"
                    : "nav-link dropdown-toggle hide-arrow"
                }
                href="#"
                data-bs-toggle="dropdown"
                onClick={() => setShowDrop(!showDrop)}
              >
                <div className="avatar ">
                  <img
                    src={data?.profile_photo ? data?.profile_photo : dummyImg}
                    alt=""
                    className="rounded-circle"
                  />
                </div>
              </a>
              <ul
                className={
                  showDrop
                    ? "dropdown-menu dropdown-menu-end  show"
                    : "dropdown-menu dropdown-menu-end "
                }
                data-bs-popper="none"
                ref={ref}
                style={{ zIndex: 500 }}
              >
                <li className="" style={{ color: "black" }}>
                  <a className="dropdown-item" href="#">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar ">
                          <img
                            src={
                              data?.profile_photo
                                ? data?.profile_photo
                                : dummyImg
                            }
                            alt=""
                            className="w-px-40 h-auto rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span
                          className="fw-semibold d-block"
                          style={{ color: "black" }}
                        >
                          {data?.full_name}
                        </span>
                        <small
                          className="text-muted"
                          style={{ color: "black" }}
                        >
                          {data?.role}
                        </small>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <div className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="/admin/profile">
                    <i className="bx bx-user me-2" />
                    <span
                      className="align-middle fw-semibold"
                      style={{ color: "black" }}
                    >
                      My Profile
                    </span>
                  </Link>
                </li>

                <li>
                  <div className="dropdown-divider" />
                </li>
                <li className="select">
                  <a className="dropdown-item" onClick={() => handleLogout()}>
                    <i className="bx bx-power-off me-2" />
                    <span
                      className="align-middle fw-semibold"
                      style={{ color: "black" }}
                    >
                      Log Out
                    </span>
                  </a>
                </li>
              </ul>
            </li>
            {/*/ User */}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
