import React from 'react'
import access from "../../Images/access.png"
import { useNavigate } from 'react-router-dom'



function NotAuthorized() {
    const navigate=useNavigate()
   
  return (
    <>
    
    <div className="container">
  <div className="row">
    <div className="col-md-12 center-screen">
      <div className="text-center">
        <img
          src={access}
          className="img-fluid"
          alt="Not Authorized Image"
        />
        <h1 className="mt-3 fw-bold">Not Accessible</h1>
        <p className="lead mt-1">You do not have permission to access this page.</p>
        <button  type ="button" className="btn btn-primary" onClick={()=>navigate(-1)}>
                Back to home
              </button>
      </div>
    </div>
  </div>
</div>

  
  
  </>
  
  )
}

export default NotAuthorized