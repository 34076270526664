import React, { useState } from 'react';
import SIdeBar from './CommonComponents/SIdeBar';
import Navbar from './CommonComponents/Navbar';

function PaymentSelectPage() {
  const [selectedTab, setSelectedTab] = useState('netbanking');

  // Define state variables for payment form fields
  const [netBankingFields, setNetBankingFields] = useState({
    bankName: '',
    accountNumber: '',
    otp: '',
  });

  const [creditCardFields, setCreditCardFields] = useState({
    cardNumber: '',
    cardHolderName: '',
    expirationDate: '',
    cvv: '',
  });

  const [debitCardFields, setDebitCardFields] = useState({
    cardNumber: '',
    cardHolderName: '',
    expirationDate: '',
    cvv: '',
  });

  const [walletFields, setWalletFields] = useState({
    walletId: '',
    walletPassword: '',
  });

  // Define a function to handle payment submission
  const handlePaymentSubmit = () => {
    // Handle payment submission logic here
  };

  return (
   
       <div className="layout-wrapper layout-content-navbar">
       <div className="layout-container">
         {/* Menu */}
        <SIdeBar/>
         {/* / Menu */}
         {/* Layout container */}
         <div className="layout-page">
           {/* Navbar */}
        <Navbar/>
           {/* / Navbar */}
           {/* Content wrapper */}
           <div className="content-wrapper">
             {/* Content */}
             <div className="container-xxl flex-grow-1 container-p-y">
             <h4 className="fw-semibold py-3 mb-4"><span className="text-muted fw-light"></span>Complete Payment</h4>
             <div className="row">
        <div className="col-md-8">
          
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${selectedTab === 'netbanking' ? 'active' : ''}`}
                onClick={() => setSelectedTab('netbanking')}
              >
                Net Banking
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${selectedTab === 'creditcard' ? 'active' : ''}`}
                onClick={() => setSelectedTab('creditcard')}
              >
                Credit Card
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${selectedTab === 'debitcard' ? 'active' : ''}`}
                onClick={() => setSelectedTab('debitcard')}
              >
                Debit Card
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${selectedTab === 'wallet' ? 'active' : ''}`}
                onClick={() => setSelectedTab('wallet')}
              >
                Wallet
              </button>
            </li>
          </ul>

          {/* Payment Form based on selected tab */}
          {selectedTab === 'netbanking' && (
            <form>
              {/* Net Banking Fields */}
              <div className="mb-3 mt-2 col-md-6">
                <label htmlFor="bankName">Bank Name</label>
                <select
                
                  className="form-select"
                  id="bankName"
                  name="bankName"
                  value={netBankingFields.bankName}
                  onChange={(e) =>
                    setNetBankingFields({ ...netBankingFields, bankName: e.target.value })
                  }
                >
                  <option>State Bank of India</option>
                  <option> Bank of Baroda</option>

                </select>
              </div>
              
             
            </form>
          )}
             {/* Payment Form based on selected tab */}
             {selectedTab === 'creditcard' && (
            <form>
              <div className='row'>
              <div className="mb-3 mt-2 col-md-6">
                <label htmlFor="bankName">Card Holder Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="bankName"
                  name="bankName"
                  value={netBankingFields.bankName}
                  onChange={(e) =>
                    setNetBankingFields({ ...netBankingFields, bankName: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 mt-2 col-md-6">
                <label htmlFor="accountNumber">Card Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNumber"
                  name="accountNumber"
                  value={netBankingFields.accountNumber}
                  onChange={(e) =>
                    setNetBankingFields({ ...netBankingFields, accountNumber: e.target.value })
                  }
                />
              </div>
              </div>
             
              <div className='row'>
              <div className="mb-3 mt-2 col-md-6">
                <label htmlFor="bankName">Expiration Date</label>
                <input
                  type="text"
                  className="form-control"
                  id="bankName"
                  name="bankName"
                  value={netBankingFields.bankName}
                  onChange={(e) =>
                    setNetBankingFields({ ...netBankingFields, bankName: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 mt-2 col-md-6">
                <label htmlFor="accountNumber">CVV</label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNumber"
                  name="accountNumber"
                  value={netBankingFields.accountNumber}
                  onChange={(e) =>
                    setNetBankingFields({ ...netBankingFields, accountNumber: e.target.value })
                  }
                />
              </div>
              </div>
            </form>
          )}
             {/* Payment Form based on selected tab */}
             {selectedTab === 'debitcard' && (
          <form>
          <div className='row'>
          <div className="mb-3 mt-2 col-md-6">
            <label htmlFor="bankName">Card Holder Name</label>
            <input
              type="text"
              className="form-control"
              id="bankName"
              name="bankName"
              value={netBankingFields.bankName}
              onChange={(e) =>
                setNetBankingFields({ ...netBankingFields, bankName: e.target.value })
              }
            />
          </div>
          <div className="mb-3 mt-2 col-md-6">
            <label htmlFor="accountNumber">Card Number</label>
            <input
              type="text"
              className="form-control"
              id="accountNumber"
              name="accountNumber"
              value={netBankingFields.accountNumber}
              onChange={(e) =>
                setNetBankingFields({ ...netBankingFields, accountNumber: e.target.value })
              }
            />
          </div>
          </div>
         
          <div className='row'>
          <div className="mb-3 mt-2 col-md-6">
            <label htmlFor="bankName">Expiration Date</label>
            <input
              type="text"
              className="form-control"
              id="bankName"
              name="bankName"
              value={netBankingFields.bankName}
              onChange={(e) =>
                setNetBankingFields({ ...netBankingFields, bankName: e.target.value })
              }
            />
          </div>
          <div className="mb-3 mt-2 col-md-6">
            <label htmlFor="accountNumber">CVV</label>
            <input
              type="text"
              className="form-control"
              id="accountNumber"
              name="accountNumber"
              value={netBankingFields.accountNumber}
              onChange={(e) =>
                setNetBankingFields({ ...netBankingFields, accountNumber: e.target.value })
              }
            />
          </div>
          </div>
        </form>
          )}
             {/* Payment Form based on selected tab */}
             {selectedTab === 'wallet' && (
         <></>
          )}

          {/* Similar forms for Credit Card, Debit Card, and Wallet */}
          {/* ... (Repeat the form structure for other payment methods) */}

          {/* Payment Button */}
          <button className="btn btn-primary" onClick={handlePaymentSubmit}>
            Paynow
          </button>
        </div>

        {/* Payment Summary Card */}
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">Payment Summary</div>
            <div className="card-body">
            <p className="card-text">
        Net Amount: $XXX.XX
      </p>
      <p className="card-text">
        Total: $XXX.XX
      </p>
      <p className="card-text">
        Charges: $XXX.XX
      </p>
              {/* Display payment summary information here */}
            </div>
          </div>
        </div>
      </div>
     
           </div>
           {/* Content wrapper */}
         </div>
         {/* / Layout page */}
       </div>
       {/* Overlay */}
       </div>
       <div className="layout-overlay layout-menu-toggle" />
     </div>
  );
}

export default PaymentSelectPage;
