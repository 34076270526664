import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import { Link } from "react-router-dom";
import sendSelfImg from "../../Images/helpImg/sendSelf.png";
import dataImg from "../../Images/helpImg/selfData.png";
import showAdd from "../../Images/helpImg/showAdditional.png";
import BackButton from "./BackButton";

function SendSelfStep() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Send Rewards - Self{" "}
                    </h5>
                    <p>
                      Effortlessly send vouchers to yourself which can be used for rewarding participants in Ad-hoc Campaigns, Events,Seminars.
                    </p>
                    <hr className="mt-4" />

                    <p>Send Rewards -  Self Steps:</p>
                    <ol>
                      <li>Click on the Send Self tab</li>

                      <li>
                        Select voucher and input the voucher value and quantity.
                      </li>
                      <li>
                        Click on Next button to Review the Voucher Details.

                      </li>

                      <img
                        className="mt-4  mb-2"
                        width={"80%"}
                        src={sendSelfImg}
                        alt=""
                      />


                      <li>Add vouchers by simply clicking the “Add more” button located at the bottom of the page.
                      </li>
                     
                      <img
                        className="mt-4  mb-2"
                        width={"80%"}
                        src={dataImg}
                        alt=""
                      />
                    </ol>
                    <blockquote className="callout callout_info" theme="📘">
                      <h5 className="callout-heading ">
                        <p className="fw-bold">Show Additional Settings:</p>
                      </h5>
                      <img
                        className="mt-2 mb-2"
                        width={"80%"}
                        src={showAdd}
                        alt=""
                      />
                      <p>With additional settings you can;</p>
                      <ul>
                        <li>
                        Include a personalized message, Campaign/Event/Seminar Name in the email.
                        </li>
                        <li>
                        Mention internal reference no. or PO Number for your own records to track later
                        </li>
                      </ul>
                    </blockquote>
                    <hr />
                    <div className="mt-4">
                     <BackButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default SendSelfStep;
