export const BASE_URL = "https://host.credencerewards.com";


export function restrictAlpha(event) {
  const regex = /[a-zA-Z]+$/; // Regular expression to allow only alphabets

  if (regex.test(event.key)) {
    event.preventDefault(); // Prevent the keypress if it's not an alphabet
  }
}
export function restrictNumeric(event) {
  const regex =/[a-zA-Z ]+$/; // Regular expression to allow only alphabets

  if (!regex.test(event.key)) {
    event.preventDefault(); // Prevent the keypress if it's not an alphabet
  }
}

export const showStatus = (key) => {
  switch (key) {
    case 1:
      return "Not Started";
    case 2:
      return "Processing";
    case 3:
      return "Completed";
    case 4:
      return "Failed";
    case 5:
      return "InCompleted";
    case 6:
      return "Refunded";

    default:
      break;
  }
};

export const walletIcon = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1574_539)">
      <path
        d="M5.1738 4.55545L13.1468 2.37914L12.7246 1.52569C12.4484 0.970943 11.7746 0.741858 11.2198 1.01811L4.08228 4.55545H5.1738Z"
        fill="#594DF1"
      />
      <path
        d="M15.6218 2.44189C15.523 2.44189 15.4242 2.45537 15.3254 2.48232L13.4523 2.99439L7.73187 4.55531H14.2249H17.0547L16.7044 3.27064C16.5696 2.7698 16.1159 2.44189 15.6218 2.44189Z"
        fill="#594DF1"
      />
      <path
        d="M18.2249 5.34131H17.9688H17.6207H17.2726H14.6157H4.85261H3.57243H2.49439H2.2945H1.62521C1.27035 5.34131 0.953678 5.50526 0.747052 5.76354C0.652723 5.88258 0.580853 6.01958 0.540427 6.17006C0.515722 6.26439 0.5 6.36321 0.5 6.46427V6.59903V7.87921V18.9763C0.5 19.5962 1.00309 20.0993 1.62296 20.0993H18.2226C18.8425 20.0993 19.3456 19.5962 19.3456 18.9763V15.8433H12.6819C11.6286 15.8433 10.7729 14.9876 10.7729 13.9342V12.9056V12.5575V12.2094V11.4368C10.7729 10.9202 10.9795 10.4508 11.3141 10.1072C11.6106 9.80172 12.0081 9.5951 12.4528 9.54344C12.527 9.53448 12.6033 9.52997 12.6797 9.52997H18.4113H18.7594H19.1075H19.3456V6.46427C19.3478 5.8444 18.8448 5.34131 18.2249 5.34131Z"
        fill="#594DF1"
      />
      <path
        d="M20.1339 10.6038C20.0216 10.5005 19.8891 10.4218 19.7409 10.3702C19.6263 10.332 19.5051 10.3096 19.377 10.3096H19.3478H19.3254H18.9773H17.7218H12.6819C12.062 10.3096 11.559 10.8126 11.559 11.4325V11.9917V12.3399V12.688V13.9322C11.559 14.5521 12.062 15.0552 12.6819 15.0552H19.3478H19.377C19.5051 15.0552 19.6263 15.0327 19.7409 14.9946C19.8891 14.9451 20.0216 14.8643 20.1339 14.761C20.3585 14.5566 20.5 14.2601 20.5 13.9323V11.4325C20.5 11.1046 20.3585 10.8081 20.1339 10.6038ZM15.0267 12.9058C15.0267 13.2158 14.7751 13.4673 14.4652 13.4673H14.0924C13.7824 13.4673 13.5309 13.2158 13.5309 12.9058V12.533C13.5309 12.3533 13.614 12.1939 13.7465 12.0928C13.8431 12.0187 13.9621 11.9715 14.0924 11.9715H14.1867H14.4652C14.7751 11.9715 15.0267 12.2231 15.0267 12.533V12.9058Z"
        fill="#594DF1"
      />
    </g>
    <defs>
      <clipPath id="clip0_1574_539">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const arrowRight = (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.29175 11.5H18.7084"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 4.7915L18.7083 11.4998L12 18.2082"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const compass = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.24 7.75977L14.12 14.1198L7.76001 16.2398L9.88001 9.87977L16.24 7.75977Z"
      fill="#B2AEFF"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const copyIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1705_8118)">
      <path
        d="M9.71875 16H3.75C2.37146 16 1.25 14.8785 1.25 13.5V5.03125C1.25 3.65271 2.37146 2.53125 3.75 2.53125H9.71875C11.0973 2.53125 12.2188 3.65271 12.2188 5.03125V13.5C12.2188 14.8785 11.0973 16 9.71875 16ZM3.75 3.78125C3.06079 3.78125 2.5 4.34204 2.5 5.03125V13.5C2.5 14.1892 3.06079 14.75 3.75 14.75H9.71875C10.408 14.75 10.9688 14.1892 10.9688 13.5V5.03125C10.9688 4.34204 10.408 3.78125 9.71875 3.78125H3.75ZM14.7188 11.9375V2.5C14.7188 1.12146 13.5973 0 12.2188 0H5.28125C4.93604 0 4.65625 0.279785 4.65625 0.625C4.65625 0.970215 4.93604 1.25 5.28125 1.25H12.2188C12.908 1.25 13.4688 1.81079 13.4688 2.5V11.9375C13.4688 12.2827 13.7485 12.5625 14.0938 12.5625C14.439 12.5625 14.7188 12.2827 14.7188 11.9375Z"
        fill="#0000EE"
      />
    </g>
    <defs>
      <clipPath id="clip0_1705_8118">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const pencilIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.82034 5.20762L9.81331 5.20059L6.78988 2.1748C6.78988 2.1748 3.1055 5.85918 1.32894 7.67324C1.10863 7.89824 0.939876 8.2123 0.841438 8.51699C0.550813 9.40762 0.309407 10.317 0.0398759 11.2146C-0.0327804 11.4561 -0.0187179 11.6693 0.171126 11.8475C0.349251 12.0162 0.550813 12.0256 0.780501 11.9553C1.63831 11.6975 2.50081 11.4537 3.36097 11.2076C3.81331 11.0787 4.193 10.8396 4.52581 10.5045C6.218 8.80527 9.82034 5.20762 9.82034 5.20762Z"
      fill="#5E6366"
    />
    <path
      d="M11.5853 1.44844L10.5517 0.414844C9.99858 -0.138281 9.10562 -0.138281 8.55249 0.414844L7.38062 1.58672L10.4134 4.61953L11.5853 3.44766C12.1384 2.89688 12.1384 2.00156 11.5853 1.44844Z"
      fill="#5E6366"
    />
    <path
      d="M5.40469 10.7295H11.3648C11.7164 10.7295 12 11.0131 12 11.3646C12 11.7162 11.7164 11.9998 11.3648 11.9998H5.40469C5.05313 11.9998 4.76953 11.7162 4.76953 11.3646C4.77188 11.0131 5.05547 10.7295 5.40469 10.7295Z"
      fill="#5E6366"
    />
  </svg>
);
export const homeIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0859 13.7951C22.5891 14.1373 22.0547 14.2217 21.4547 14.1935C21.45 16.6638 21.4734 19.0826 21.4406 21.5435C21.4172 22.856 20.2969 23.9576 18.9844 23.981C17.6719 24.0045 16.3547 23.9904 15.0375 23.9857C14.6156 23.9857 14.3344 23.681 14.3344 23.231C14.3297 22.1201 14.3344 21.0092 14.3297 19.8982C14.3297 19.167 14.3297 18.431 14.3297 17.6998C14.325 16.9451 13.8516 16.4623 13.0875 16.4529C12.3703 16.4435 11.6531 16.4435 10.9359 16.4529C10.1906 16.4623 9.7125 16.9498 9.7125 17.6951C9.70781 19.5092 9.70781 21.3185 9.70781 23.1326C9.70781 23.7373 9.45469 23.9904 8.84531 23.9904C7.65469 23.9904 6.46406 23.9904 5.27344 23.9904C3.70312 23.9857 2.5875 22.8888 2.57813 21.3138C2.56875 18.942 2.57344 16.5654 2.57344 14.1935C0.398438 14.3998 -0.867187 11.9529 0.689063 10.3732C3.90469 7.15759 7.12031 3.94197 10.3406 0.731031C10.5422 0.520093 10.7766 0.346656 11.0344 0.206031C11.7844 -0.168969 12.9516 -0.0517817 13.6641 0.679468C15.4688 2.53103 17.3109 4.34509 19.1391 6.17322C20.5406 7.57947 21.9328 8.99509 23.3578 10.3826C24.2953 11.2967 24.2063 13.0498 23.0859 13.7951Z"
      fill="black"
    />
  </svg>
);

export const homeIconRed = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0859 13.7951C22.5891 14.1373 22.0547 14.2217 21.4547 14.1935C21.45 16.6638 21.4734 19.0826 21.4406 21.5435C21.4172 22.856 20.2969 23.9576 18.9844 23.981C17.6719 24.0045 16.3547 23.9904 15.0375 23.9857C14.6156 23.9857 14.3344 23.681 14.3344 23.231C14.3297 22.1201 14.3344 21.0092 14.3297 19.8982C14.3297 19.167 14.3297 18.431 14.3297 17.6998C14.325 16.9451 13.8516 16.4623 13.0875 16.4529C12.3703 16.4435 11.6531 16.4435 10.9359 16.4529C10.1906 16.4623 9.7125 16.9498 9.7125 17.6951C9.70781 19.5092 9.70781 21.3185 9.70781 23.1326C9.70781 23.7373 9.45469 23.9904 8.84531 23.9904C7.65469 23.9904 6.46406 23.9904 5.27344 23.9904C3.70312 23.9857 2.5875 22.8888 2.57813 21.3138C2.56875 18.942 2.57344 16.5654 2.57344 14.1935C0.398438 14.3998 -0.867187 11.9529 0.689063 10.3732C3.90469 7.15759 7.12031 3.94197 10.3406 0.731031C10.5422 0.520093 10.7766 0.346656 11.0344 0.206031C11.7844 -0.168969 12.9516 -0.0517817 13.6641 0.679468C15.4688 2.53103 17.3109 4.34509 19.1391 6.17322C20.5406 7.57947 21.9328 8.99509 23.3578 10.3826C24.2953 11.2967 24.2063 13.0498 23.0859 13.7951Z"
      fill="#594DF1"
    />
  </svg>
);

export const columnIcon=<svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 2.25H14.25C14.6478 2.25 15.0294 2.40804 15.3107 2.68934C15.592 2.97064 15.75 3.35218 15.75 3.75V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H9M9 2.25H3.75C3.35218 2.25 2.97064 2.40804 2.68934 2.68934C2.40804 2.97064 2.25 3.35218 2.25 3.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H9M9 2.25V15.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
export const filterIcon=<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6663 4H3.33301L7.06634 8.20444V11.1111L8.93301 12V8.20444L12.6663 4Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export const exportIcon=<svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 2.5V9.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 6L6 9.5L2.5 6" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export const crossIcon=<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ui-svg-inline"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg>
const jsonData = {
  assets: [
    {
      id: "E9MKG4M-ZojQcU7Inf197",
      layers: [],
    },
    {
      h: 445,
      id: "qFbdY7i5vL33_Au4BoUBx",
      p: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAaoAAAGhCAYAAAAwSPA1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAMd0SURBVHgB7J0HfFPV28d/N7t7D6Bl7w2CbMWBgigOVNwbFSeI+ncr6OtGcQ8U9wAEVFBwo+y99ywtLd0rbXbue55zM9sUChRokvP1c23uyE1omvM7zzjPI0EgEAiCGFmW1eyHgW06tmnZJrFNBUFjw8k2B9vsbLOwzSxJklyfJ0oQCASCIIQJFAlTDBSBEgQnJrZVMsFyHOkiIVQCgSCocFlQ8RACFUpUQRGsgBaWECqBQBA0MJGKhGJFeVx7lp8Xxlb99HdX2/7sq2CxdJEd9uaQEQtB40JChaTTbZH0hq36rh1nxr351NYaV5BVVRzIuhJCJRAIggImUiRQ0b7HCi66aaKzpGysEKbgQ9Jotmg7tn0u8dNXl/kcJpEqYWJl97sWAoFA0MipKVIVr76TWT33r9lwOjMgCGpUsbHT4u68YYr+quEVrkO1LCshVAKBoFHDRIoy+ZLd+8WPTOpiW7x2trCiQgeyriKuOP+22In35LgOkUgVumNWIoVTIBA0dhLcD4RIhSay3d7VNOfP6ZZZC92fKyXMxLjPC6ESCASNFpfLjwYt7u6zLVn/mRCp0ITEqvzjryf6HIpyZXgKoRIIBI2aCPcD08JlE0VMKrRxVlSMLR77vwE+h7hVJYRKIBA0SthsmkTKY03JVVVXQxDy2Hfu9bWq9OzvQBJCJRAIGisG9wNuTQnCAtliHehjVZFGGYRQCQSCxoqn8oRsMg2EIGxwHMrz/bx1QqgEAkGjw5WSzscnqjwhYlNhhrGqr8+eVgiVQCBojHjWeFJ5JAjCCtnmbOKzqxZCJRAIGiNq9wPZbI6BIKyQnQ5foVIJoRIIBI0ae7UpHoKwRgiVQCAQCBo1QqgEAoFA0KjR4BhxlbSgtFE1BI0VKpFvr1kqXyAQCIKRegmVq+UzLb6jleLCCgsS2OfmZD8sbKtmomWFQCAQBCFHFB2yntiWxB7SFgUhUsEGfV40uUiiz9Fd4FEgEDQetK2awzBsCAR1U6dFxQY1EiZqVOYnTpavZ8VWfPHNQFtlWSasNlHFuBGiTUrZqu/TfUvsS5NzfA6TVZzKPlcjs64qIRAITiuqqEjEPnYP9AN6w2mshm3DNjgKi/k5ddNUOHILIFAIKFRsMCMBivI9VnD5dQNt27Y8JJutopRJI8dSXAzLrh2onDV3i6ZZk0/S/v19ps/paPb50ude5m5KJhAITj2Rlw+H4YKz+GN1TDSix90I04J/EH3zlVClJKLk7ifhLC1TLpYkGpgRrtRy5bFBjNYseEQq/+Y7uxzq0P0H67p1PwiRCi5km62r7cDBqYdad1lZcMU1vqXzKd5IrkDR4VkQNmgym0I/qA91k8XJQBUfh4jhZ3NLqT7oz+7vtx8x8lwkvvs8dH17QNMyEzH338KXukZcfB6SPn0NqsQTX05mOGcAE8EkBBt+QuVqUubp/5Lbf+hY26L//hACFdzIDkemde2G2bl9BvlWoKZaanEQCMKEqFuuQtxzEyHFRuNkQC68uEkTmdsu7ajXSpEGaDq2PuI1FLdKnfcZ4p5+ENou7RF9+xhuWaliohB1w+VQJR2bcKmSExH37AREjBiKYMMztXBl9nk+wdzegyY6i4tFaf0QwllYPJGJFZquWTrFdSiCfe425gKsgkAQBpAHDSfLj+Au9FOP+xvOGQRJpzviNZJOCyk5wbMfMeoCfvOIC8+GxMSKLKzKtz/j57TtW8NxuBDOirrDz5JKCtqqeb7v2iPPxSNGXShEKjThYjVw6FifQ",
    },
  ],
};
export const base64Image = jsonData.assets[1].p;
export const defaultOptions = {
  reverse:        false,  // reverse the tilt direction
  max:            35,     // max tilt rotation (degrees)
  perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
  scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
  speed:          1000,   // Speed of the enter/exit transition
  transition:     true,   // Set a transition on enter/exit.
  axis:           null,   // What axis should be disabled. Can be X or Y.
  reset:          true,    // If the tilt effect has to be reset on exit.
  easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}