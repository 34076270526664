import React from "react";
import { Modal, Button, Table } from "react-bootstrap";

const VoucherColModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Rewards CSV Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table>
          <thead>
            <tr>
              <th>Column</th>
              <th>Description</th>
              <th>Conditions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                Recipient Name
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  Name of the recipient.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    Ex: John{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Mandatory.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Max characters: 50
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                Recipient  Email
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  Valid email id of the recipient.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    Ex: john@gmail.com{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Either email or mobile number is needed for reward
                      delivery.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
        
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                  Mobile number
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  Valid mobile number of the recipient.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    Ex: 9876543210{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Either email or mobile number is needed for reward
                      delivery.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Between 7 to 16 digits
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                  Product ID
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  ID of the product that will be rewarded. Product ID can be
                  found in the same CSV template.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    EX: 12345{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Mandatory.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Max characters: 12
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                  Product Name
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  Name of the product that will be rewarded.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    EX: Amazon{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Mandatory.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Max characters: 15
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                  Denomination
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  Value of the product that will be rewarded.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    Ex: 1000{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Mandatory.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Cannot be 0.
                    </p>
                  </li>
               
                </ul>
              </td>
            </tr>
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                  Quantity
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  No. of vouchers that needs to be sent.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    Ex: 10{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Mandatory.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Cannot be 0.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Max value: 500
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
         
       
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                  Personal Message in Email
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  Add a personal message for the recipient. This will be visible
                  only in the email and not supported for mobile SMS.{" "}
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    Ex: Congratulations on winning this reward. You deserve it.{" "}
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Optional.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Max characters: 600
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr class="sc-dKOIaw hteciu">
              <td class="sc-gFflvC egVHGA px-12 py-20">
                <div color="dark" class="sc-kFCsca dmtljK mb-0">
                  PO Number
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <p class="sc-dmyDGi kBIgrJ mb-0" color="dark">
                  Add a PO Number
                </p>
                <div>
                  <p class="sc-dmyDGi iAmaqE mb-0" color="dark">
                    Ex: ABC123
                  </p>
                </div>
              </td>
              <td class="sc-jtCpqd fcrEws px-12 py-20">
                <ul class="px-12">
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Optional.
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Alfanumeric
                    </p>
                  </li>
                  <li>
                    <p color="dark" class="sc-dmyDGi kBIgrJ mb-0">
                      Max characters: 10
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default VoucherColModal;
