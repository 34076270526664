import React, { useState, useEffect } from "react";
import { createSelfOrder, getAllVoucher } from "../../services/Apiservices";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { restrictAlpha } from "../../constant";
import NoUser from "../../Images/Recipt.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import showNotification from "../../services/NotificationService";
import fund from "../../Images/nofunds.png";
import OrderSuccess from "../OrderSuccess";

const SendSelf = ({ options, balance }) => {
  const [selflist, setSelfList] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [emailSub, setEmailSub] = useState(
    "Congratulations! You have received a reward."
  );
  const [showOrderModal, setShowOrderModal] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      voucher: "",
      voucherValue: "",
      numberOfVouchers: "",
      showAdditional: false,
      personalMessage: "Congratulations",
      poNumber: "",
    },

    validationSchema: Yup.object({
      voucher: Yup.object().required("Required"),
      voucherValue: Yup.mixed().required("Required"),
      numberOfVouchers: Yup.number().required("Required"),
      personalMessage: Yup.string().when("showAdditional", {
        is: true,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      if (validateQuant() && validateValue() && !isDuplicate) {
        if (editIndex !== null) {
          const updatedList = [...selflist];

          // Update the specific item at the desired index
          updatedList[editIndex] = values;

          // Set the updated list back to the state
          setSelfList(updatedList);
          resetForm();
          setEditIndex(null);
          setShowCart(true);
        } else {
          setShowCart(true);
          setSelfList([...selflist, values]);
          resetForm();
        }
      }
      // Handle form submission here
    },
  });
  const isDuplicate = selflist?.length !== 0 && selflist?.some(item =>

    item.email == formik?.values.email &&
    item.voucher?.value == formik?.values?.voucher?.value 
    &&
    item.numberOfVouchers == formik?.values?.numberOfVouchers 
    &&
    (item.voucherValue?.value ? item?.voucherValue?.value : item?.voucherValue) == (formik?.values.voucherValue?.value ? formik?.values?.voucherValue?.value : formik?.values?.voucherValue)
  )
  function validateValue() {
    if (formik?.values?.voucher?.data?.type == "Slab") {
      return true;
    } else {
      if (
        formik?.values?.voucherValue >=
          formik?.values?.voucher?.data?.checkout?.min &&
        formik?.values?.voucherValue <=
          formik?.values?.voucher?.data?.checkout?.max
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  function validateQuant() {
    if (
      formik?.values?.numberOfVouchers <=
      formik?.values?.voucher?.data?.checkout?.quantity
    ) {
      return true;
    } else {
      return false;
    }
  }
  const discount = () => {
    const voucherValue =
      formik?.values?.voucherValue?.value || formik?.values?.voucherValue; // Get the voucher value
    const numberOfVouchers = formik?.values?.numberOfVouchers; // Get the number of vouchers
    const discountPercentage = formik?.values?.voucher?.data?.discount || 0; // Get the discount percentage (default to 0 if not provided)

    // Calculate the total amount per voucher after applying the discount
    const discountedVoucherValue =
      voucherValue - (voucherValue * discountPercentage) / 100;

    // Calculate the total amount for all vouchers before applying the discount

    // Calculate the total amount for all vouchers after applying the discount
    const totalAfterDiscount = discountedVoucherValue * numberOfVouchers;
    return totalAfterDiscount?.toFixed(2);
  };
  const deleteItemById = (index) => {
    // Clone the original list to avoid mutating the state directly
    const updatedList = selflist.filter((item, ind) => ind !== index);

    // Set the updated list back to the state
    setSelfList(updatedList);
  };
  let grandTotal = selflist?.reduce((acc, current) => {
    return (
      acc +
      (current?.voucherValue?.value
        ? current?.voucherValue?.value
        : current?.voucherValue -
          (current?.voucherValue?.value
            ? current?.voucherValue?.value
            : current?.voucherValue * current?.voucher?.data?.discount) /
            100) *
        current?.numberOfVouchers
    );
  }, 0);

  const roundedAccountBalance = parseFloat(balance);
  const roundedTotalProductBalance = parseFloat(grandTotal);
  const handleSendRewards = async () => {
    let body = {
      total_recipients: selflist?.length,
      email_subject: emailSub,
      template_id: 51,
      recipients: selflist?.map((recipient) => {
        return {
          country_code: "+91",
          message: recipient?.personalMessage
            ? recipient?.personalMessage
            : emailSub,
          value_of_voucher: recipient?.voucherValue?.value
            ? recipient?.voucherValue?.value
            : recipient?.voucherValue,
          quantity: recipient?.numberOfVouchers,
          provider_id: recipient?.voucher?.value,
        };
      }),
    };
    try {
      let response = await createSelfOrder(body);
      if (response?.status == 200) {
       setShowOrderModal(true)
        setSelfList([]);
      } else {
      }
    } catch (error) {}
  };
  const handleGenrate = () => {
    if (selflist?.length !== 0) {
      if (roundedAccountBalance >= roundedTotalProductBalance) {
        handleSendRewards();
      } else {
        Swal.fire({
          title: "Insufficient funds!",
          text: "Please add funds",
          imageUrl: fund,
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonText: "Add Funds",
          cancelButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            // User clicked "Add Funds", navigate to the add funds screen
            navigate("/admin/add-funds");
          } else {
            // User clicked "Close" or outside the modal
            // You can handle this case or leave it empty
          }
        });
      }
    } else {
      showNotification("danger", "Please add recipients");
    }
  };
  return (
    <>
  <div className="">
      {" "}
      {showCart ? (
        <>
          <div className="col-12 col-lg-12 bg-light  mb-4">
            <div className="">
              <div className="card-body">
                {selflist?.length !== 0 ? (
                  <>
                    <div className="table-responsive text-nowrap">
                      <div style={{ fontSize: "22px", fontWeight: "700" }}>
                        Added Recipients
                      </div>
                      <div className="fs-12 mb-4">
                        Enable the convenience of adding one or multiple
                        recipients to effortlessly send vouchers.
                      </div>
                      <table className="table">
                        <thead
                          className=" text-white"
                          style={{
                            backgroundColor: "#D5D2FF",
                            width: "fit-content",
                          }}
                        >
                          <tr>
                            <th
                              className="text-center"
                              style={{ borderRight: "1.5px solid gray" }}
                            >
                              Brand
                            </th>

                            <th
                              className="text-center"
                              style={{ borderRight: "1.5px solid gray" }}
                            >
                              Amount
                            </th>
                            <th
                              className="text-center"
                              style={{ borderRight: "1.5px solid gray" }}
                            >
                              Quantity
                            </th>
                            <th
                              className="text-center"
                              style={{ borderRight: "1.5px solid gray" }}
                            >
                              Discount
                            </th>

                            <th
                              className="text-center"
                              style={{ borderRight: "1.5px solid gray" }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0 p-0">
                          {selflist?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  className="fs-12 fw-semibold"
                                  style={{
                                    backgroundColor: "#FAFAFF",
                                  }}
                                >
                                  {item?.voucher?.data?.product_name}
                                </td>

                                <td className="fs-12 text-center">
                                  {item?.voucherValue?.value
                                    ? item?.voucherValue?.value
                                    : item?.voucherValue}
                                </td>
                                <td className="fs-12 text-center">
                                  {item?.numberOfVouchers}
                                </td>
                                <td>
                                  <span>
                                    <div className="fs-12 text-center">
                                      {/* Total:{" "}
                                      {item?.voucherValue?.value
                                        ? item?.voucherValue?.value
                                        : item?.voucherValue}
                                      {" , "}
                                      Discount: */}
                                      {item?.voucher?.data?.discount}%
                                      {/* Payable:{" "}
                                      {(item?.voucherValue?.value
                                        ? item?.voucherValue?.value
                                        : item?.voucherValue -
                                          ((item?.voucherValue?.value
                                            ? item?.voucherValue?.value
                                            : item?.voucherValue) *
                                            item?.voucher?.data?.discount) /
                                            100) * item?.numberOfVouchers} */}
                                    </div>
                                  </span>
                                </td>

                                <td>
                                  <div className="d-flex fs-12 justify-content-center gap-3">
                                    {" "}
                                    <span
                                      onClick={() => {
                                        setShowCart(false);
                                        setEditIndex(index);
                                        formik.setValues({
                                          email: item?.email,
                                          name: item?.name,
                                          phoneNumber: item?.phoneNumber,
                                          voucher: item?.voucher,
                                          voucherValue: item?.voucherValue,
                                          numberOfVouchers:
                                            item?.numberOfVouchers,
                                          showAdditional: item?.showAdditional,
                                          personalMessage:
                                            item?.personalMessage,
                                          poNumber: item?.poNumber,
                                        });
                                      }}
                                    >
                                      <i className="bx bx-edit-alt me-2 p-1 rounded cursor-pointer text-white bg-primary" />
                                    </span>
                                    <span onClick={() => deleteItemById(index)}>
                                      <i className="bx bx-trash me-2   p-1 rounded cursor-pointer text-white bg-primary" />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="container text-center">
                      <img
                        src={NoUser}
                        className="img-fluid mx-auto"
                        alt="Centered Image"
                        style={{ width: "20%" }}
                      />
                      <div className="mt-3">
                        <p className="fw-bold">No Recipients!</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className=" d-flex justify-content-between fw-semibold fs-12 px-5 col ">
                {selflist?.length !== 0 && (
                  <div>
                    Grand Total:{" "}
                    <span
                      className="  fw-bold"
                      style={{ color: "#594DF1", fontSize: "18px" }}
                    >
                      &#8377; {roundedTotalProductBalance}
                    </span>
                  </div>
                )}
                <div>
                  {" "}
                  {/* <button
                    type="button"
                    className="btn btn-text-primary mb-4 me-2 border-primary border-2 "
                    onClick={() => setShowCart(false)}
                  >
                    + Add More
                  </button> */}
                  {selflist?.length !== 0 && (
                    <button
                      type="button"
                      className="btn btn-primary mb-4"
                      onClick={handleGenrate}
                    >
                      Send
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* <form id="formAccountSettings">
            <div className="row">
              <div className="mb-3 col-md-3">
                <label htmlFor="firstName" className="form-label ">
                  Select Voucher
                </label>
                <Select
                  options={options}
                  id="voucher"
                  name="voucher"
                  onChange={(selectedOption) => {
                    formik.setFieldValue("voucher", selectedOption);
                    formik.setFieldValue("voucherValue", "");
                    formik.setFieldValue("numberOfVouchers", 1);
                  }}
                  onBlur={() => formik.setFieldTouched("voucher", true)}
                  value={formik.values.voucher}
                  isSearchable
                  isClearable
                />
                {formik.errors.voucher && formik?.touched?.voucher ? (
                  <div className="text-danger">{formik.errors.voucher}</div>
                ) : null}
              </div>

              <div className="mb-3 col-md-3">
                <label htmlFor="lastName" className="form-label">
                  Value Of Voucher
                </label>
                {formik?.values?.voucher?.data?.type == "Range" ? (
                  <input
                    disabled={formik.values.voucher !== "" ? false : true}
                    className="form-control"
                    type="text"
                    name="voucherValue"
                    id="voucherValue"
                    placeholder={`range.${formik?.values?.voucher?.data?.checkout?.min}-${formik?.values?.voucher?.data?.checkout?.max}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.voucherValue}
                    maxLength={5}
                    onKeyPress={restrictAlpha}
                  />
                ) : (
                  <>
                    <Select
                      isDisabled={formik.values.voucher !== "" ? false : true}
                      options={formik.values?.voucher?.data?.checkout?.denominations?.map(
                        (price) => {
                          return {
                            label: "₹ " + price,
                            value: +price,
                          };
                        }
                      )}
                      id="voucher"
                      name="voucher"
                      onChange={(selectedOption) =>
                        formik.setFieldValue("voucherValue", selectedOption)
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.voucherValue}
                      isSearchable
                      isClearable
                    />
                  </>
                )}
                {formik.touched.voucherValue && formik.errors.voucherValue ? (
                  <div className="text-danger">
                    {formik.errors.voucherValue}
                  </div>
                ) : null}

                {formik?.values?.voucher?.data?.type == "Range" ? (
                  !validateValue() && !formik.errors.voucherValue ? (
                    <div className="text-danger">
                      Enter a value between{" "}
                      {`${formik?.values?.voucher?.data?.checkout?.min}-${formik?.values?.voucher?.data?.checkout?.max}`}
                    </div>
                  ) : null
                ) : (
                  <></>
                )}
              </div>

              <div className="mb-3 col-md-3">
                <label htmlFor="lastName" className="form-label">
                  No. Of Voucher
                </label>
                <input
                  disabled={formik.values.voucher !== "" ? false : true}
                  className="form-control"
                  type="text"
                  name="numberOfVouchers"
                  id="numberOfVouchers"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.numberOfVouchers}
                  maxLength={4}
                  onKeyPress={restrictAlpha}
                />

                {formik.touched.numberOfVouchers &&
                formik.errors.numberOfVouchers ? (
                  <div className="text-danger">
                    {formik.errors.numberOfVouchers}
                  </div>
                ) : null}

                {!validateQuant() &&
                formik.touched.numberOfVouchers &&
                !formik.errors.numberOfVouchers ? (
                  <div className="text-danger">
                    Enter no of voucher less than or equal to{" "}
                    {`${formik?.values?.voucher?.data?.checkout?.quantity}`}
                  </div>
                ) : null}
              </div>
              <div className="mb-4 col-md-3 mt-1">
                <div className="d-flex justify-content-between">
                  <p className="mb-2 fw-bold">Total Amt</p>
                  <p className="mb-2">
                    <i className="bx bx-rupee" />
                    {discount()}{" "}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-2 fw-bold">Discount</p>
                  <p className="mb-2">
                    {formik?.values?.voucher?.data?.discount || 0}%
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="showAdditional"
                  name="showAdditional"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.showAdditional}
                />
                <label className="form-check-label" for="defaultCheck1">
                  {" "}
                  Show additional settings{" "}
                </label>
              </div>
              {!formik.values.showAdditional && (
                <div className=" d-flex justify-content-end">
                  {editIndex !== null && (
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      Update
                    </button>
                  )}
                  {editIndex == null && (
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              )}
            </div>
          </form>
          {formik.values.showAdditional && (
            <form id="formAccountSettings">
              <div className="row">
                <div className="mb-3 col-md-9">
                  <label htmlFor="lastName" className="form-label">
                    Personal Message in Email
                  </label>
                  <textarea
                    className="form-control select-show"
                    type="text"
                    name="personalMessage"
                    id="personalMessage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.personalMessage}
                  />
                  This message will appear as part of the reward email body.
                </div>
                {formik.touched.personalMessage &&
                formik.errors.personalMessage ? (
                  <div className="text-danger">
                    {formik.errors.personalMessage}
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="mb-3 col-md-3">
                  <label htmlFor="lastName" className="form-label">
                    PO Number
                  </label>
                  <input
                    className="form-control select-show"
                    type="text"
                    name="poNumber"
                    id="poNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.poNumber}
                  />
                  {formik.touched.poNumber && formik.errors.poNumber ? (
                    <div className="text-danger">{formik.errors.poNumber}</div>
                  ) : null}
                </div>
              </div>

              <div className="mt-2 d-flex justify-content-end">
                {editIndex ? (
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
          )} */}
        </>
      )}
      <div className="col-12 col-lg-12 bg-light p-3  mb-3">
        <form id="formAccountSettings">
          <div className="row">
            <div className="mb-3 col-md-3">
              <label htmlFor="firstName" className="form-label ">
                Select Voucher<span>*</span>
              </label>
              <Select
                options={options}
                id="voucher"
                name="voucher"
                onChange={(selectedOption) => {
                  formik.setFieldValue("voucher", selectedOption);
                  formik.setFieldValue("voucherValue", "");
                  formik.setFieldValue("numberOfVouchers", 1);
                }}
                onBlur={() => formik.setFieldTouched("voucher", true)}
                isOptionDisabled={(option) => option.isdisabled}
                value={formik.values.voucher}
                isSearchable
                isClearable
              />
              {formik.errors.voucher && formik?.touched?.voucher ? (
                <div className="text-danger">{formik.errors.voucher}</div>
              ) : null}
            </div>

            <div className="mb-3 col-md-3">
              <label htmlFor="lastName" className="form-label">
                Value Of Voucher<span>*</span>
              </label>
              {formik?.values?.voucher?.data?.type == "Range" ? (
                <input
                  disabled={formik.values.voucher !== "" ? false : true}
                  className="form-control"
                  type="text"
                  name="voucherValue"
                  id="voucherValue"
                  placeholder={`range.${formik?.values?.voucher?.data?.checkout?.min}-${formik?.values?.voucher?.data?.checkout?.max}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.voucherValue}
                  maxLength={5}
                  onKeyPress={restrictAlpha}
                />
              ) : (
                <>
                  <Select
                    isDisabled={formik.values.voucher !== "" ? false : true}
                    options={formik.values?.voucher?.data?.checkout?.denominations?.map(
                      (price) => {
                        return {
                          label: "₹ " + price,
                          value: +price,
                        };
                      }
                    )}
                    id="voucher"
                    name="voucher"
                    onChange={(selectedOption) =>
                      formik.setFieldValue("voucherValue", selectedOption)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.voucherValue}
                    isSearchable
                    isClearable
                  />
                </>
              )}
              {formik.touched.voucherValue && formik.errors.voucherValue ? (
                <div className="text-danger">{formik.errors.voucherValue}</div>
              ) : null}

              {formik?.values?.voucher?.data?.type == "Range" ? (
                !validateValue() && !formik.errors.voucherValue ? (
                  <div className="text-danger">
                    Enter a value between{" "}
                    {`${formik?.values?.voucher?.data?.checkout?.min}-${formik?.values?.voucher?.data?.checkout?.max}`}
                  </div>
                ) : null
              ) : (
                <></>
              )}
            </div>

            <div className="mb-3 col-md-3">
              <label htmlFor="lastName" className="form-label">
                No. Of Voucher<span>*</span>
              </label>
              <input
                disabled={formik.values.voucher !== "" ? false : true}
                className="form-control"
                type="text"
                name="numberOfVouchers"
                id="numberOfVouchers"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.numberOfVouchers}
                maxLength={4}
                onKeyPress={restrictAlpha}
              />

              {formik.touched.numberOfVouchers &&
              formik.errors.numberOfVouchers ? (
                <div className="text-danger">
                  {formik.errors.numberOfVouchers}
                </div>
              ) : null}

              {!validateQuant() &&
              formik.touched.numberOfVouchers &&
              !formik.errors.numberOfVouchers ? (
                <div className="text-danger">
                  Enter no of voucher less than or equal to{" "}
                  {`${formik?.values?.voucher?.data?.checkout?.quantity}`}
                </div>
              ) : null}
            </div>
            <div className="mb-4 col-md-3 mt-1">
              <div className="d-flex justify-content-between">
                <p className="mb-2 fw-bold">Total Amt</p>
                <p className="mb-2">
                  <i className="bx bx-rupee" />
                  {discount()}{" "}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-2 fw-bold">Discount</p>
                <p className="mb-2">
                  {formik?.values?.voucher?.data?.discount || 0}%
                </p>
              </div>
            </div>
          </div>
          {isDuplicate && <div className="text-danger mb-1">
                                           *Brand with Same denomination cannot be added again. Please add new denomination or update quantity
                                          </div>}
          <div className="row justify-content-between">
            <div className="form-check px-5">
              <input
                className="form-check-input "
                type="checkbox"
                id="showAdditional"
                name="showAdditional"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.showAdditional}
              />
              <label className="form-check-label" for="defaultCheck1">
                {" "}
                Show additional settings{" "}
              </label>
            </div>
            {!formik.values.showAdditional && (
              <div className=" d-flex justify-content-end">
                {editIndex !== null && (
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                {editIndex == null && (
                   selflist?.length!==0 ?   <button
                    type="button"
                    className="btn  mx-2 border-primary border-2 "
                   
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                   + Add More
                  </button>:  <button
                  type="button"
                  className="btn btn btn-primary mx-2 "
                 
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                 Next
                </button>
                
                )}
              </div>
            )}
          </div>
        </form>
        {formik.values.showAdditional && (
          <form id="formAccountSettings">
            <div className="row">
              <div className="mb-3 col-md-9">
                <label htmlFor="lastName" className="form-label">
                  Personal Message in Email
                </label>
                <textarea
                  className="form-control select-show"
                  type="text"
                  name="personalMessage"
                  id="personalMessage"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personalMessage}
                />
                This message will appear as part of the reward email body.
              </div>
              {formik.touched.personalMessage &&
              formik.errors.personalMessage ? (
                <div className="text-danger">
                  {formik.errors.personalMessage}
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="mb-3 col-md-3">
                <label htmlFor="lastName" className="form-label">
                  PO Number
                </label>
                <input
                  className="form-control select-show"
                  type="text"
                  name="poNumber"
                  id="poNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.poNumber}
                />
                {formik.touched.poNumber && formik.errors.poNumber ? (
                  <div className="text-danger">{formik.errors.poNumber}</div>
                ) : null}
              </div>
            </div>

            <div className="mt-2 d-flex justify-content-end">
              {editIndex ? (
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Update
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn  border-primary mx-2 border-2 "
                    style={{
                      color: "#594DF1",
                    }}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Next
                  </button>
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </div>

      <OrderSuccess show={showOrderModal} setShow={setShowOrderModal}/>
    
    </>
  
  );
};

export default SendSelf;
