import React, { useEffect, useRef } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useState } from "react";
import EmailPreview from "./EmailPreview";
import SMSPreview from "./SMSPreview";

import AllTemplate from "./AllTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createBulkOrder,
  createOrder,
  getAdminEmail,
  getAllVoucher,
  getBalanceForRewards,
  getBulkOrder,
} from "../services/Apiservices";
import { useDispatch, useSelector } from "react-redux";
import { getTemplate } from "../redux/actions/templateActions";
import axios from "axios";
import { restrictAlpha, restrictNumeric, walletIcon } from "../constant";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import showNotification from "../services/NotificationService";
import Swal from "sweetalert2";
import fund from "../Images/nofunds.png";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import VoucherColModal from "./CommonComponents/VoucherColModal";
import RecipientCart from "./RecipientCart";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SendSelf from "./SendRewardsComponent/SendSelf";
import EmailAndSms from "./SendRewardsComponent/EmailAndSms";
import self from "../Images/self.png";
import OrderSuccess from "./OrderSuccess";

const CredenceCode = () => {
  const [showSetting, setShowSetting] = useState(false);
  const [showAccordian, setShowAccordian] = useState(false);
  const [activeTab, setAciveTab] = useState("email");
  const [show, setShow] = useState(false);
  const [SendIndividual, setSendIndividual] = useState(false);
  const [SendSelfVoucher, setSendSelfVoucher] = useState(false);
  const [ShowBulk, setShowBulk] = useState(true);
  const [showEmailSmsTemp, setShowEmailSmsTemp] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showList, setShowList] = useState(false);
  const [showExplore, setShowExplore] = useState(false);
  const [options, setOptions] = useState([]);
  const [list, setList] = useState([]);
  const [selflist, setSelfList] = useState([]);

  const [editIndex, setEditIndex] = useState(null);
  const [templateId, setTemplateId] = useState("");
  const [emailSub, setEmailSub] = useState(
    "Congratulations! You have received a reward."
  );
  const [tempImg, setTempImg] = useState("");
  const [country, setCountry] = useState({ label: "India", value: 101 });
  const [balance, setBalance] = useState(0.0);
  const userData = useSelector((state) => state?.profile?.data?.data);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(true);
  const [file, setFile] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [bulkData, setBulkData] = useState(null);

  const [adminEmail, setAdminEmail] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [customTemp, setCustomTemp] = useState("");

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const formRef = useRef(null);
  const myRef = useRef(null);

  useEffect(() => {
    getVouhcers();
    if (userData?.company_added) {
      getBalance();
    }
  }, []);
  // handle scroll

  const getVouhcers = async () => {
    try {
      let response = await getAllVoucher();
      if (response?.status == 200) {
        setOptions(
          response?.data?.data?.map((item) => {
            return userData?.company_verified
              ? {
                  label: item?.product_name,
                  value: item?.id,
                  data: { ...item },
                }
              : {
                  label: item?.product_name,
                  value: item?.id,
                  data: { ...item },
                  isdisabled:
                    item?.id === "61400014" || item?.id === "61400015"
                      ? true
                      : false,
                };
          })
        );
      } else {
      }
    } catch (error) {}
  };

  const getBalance = async () => {
    try {
      let response = await getBalanceForRewards();
      if (response?.status == 200) {
        setBalance(response?.data?.balance);
      } else {
      }
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      voucher: "",
      voucherValue: "",
      numberOfVouchers: "",
      showAdditional: false,
      personalMessage: "",
      poNumber: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )
        .required("Required"),
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, "Mobile number must contain only digits")
        .min(10, "Mobile number must be at least 10 digits")
        .required("Required"),
      voucher: Yup.object().required("Required"),
      voucherValue: Yup.mixed().required("Required"),
      numberOfVouchers: Yup.number().required("Required"),
      personalMessage: Yup.string().when("showAdditional", {
        is: true,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      if (validateQuant() && validateValue() && !isDuplicate) {
        if (editIndex !== null) {
          const updatedList = [...list];

          // Update the specific item at the desired index
          updatedList[editIndex] = values;

          // Set the updated list back to the state
          setList(updatedList);
          resetForm();
          setEditIndex(null);
          setShowCart(true);
        } else {
          setShowCart(true);
          setList([...list, values]);
          resetForm();
        }
      }
      // Handle form submission here
    },
  });
  const isDuplicate =
    list?.length !== 0 &&
    list?.some(
      (item) =>
        item.email == formik?.values.email &&
        item.voucher?.value == formik?.values?.voucher?.value &&
        item.numberOfVouchers == formik?.values?.numberOfVouchers &&
        (item.voucherValue?.value
          ? item?.voucherValue?.value
          : item?.voucherValue) ==
          (formik?.values.voucherValue?.value
            ? formik?.values?.voucherValue?.value
            : formik?.values?.voucherValue)
    );
  function validateValue() {
    if (formik?.values?.voucher?.data?.type == "Slab") {
      return true;
    } else {
      if (
        formik?.values?.voucherValue >=
          formik?.values?.voucher?.data?.checkout?.min &&
        formik?.values?.voucherValue <=
          formik?.values?.voucher?.data?.checkout?.max
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  function validateQuant() {
    if (
      formik?.values?.numberOfVouchers <=
      formik?.values?.voucher?.data?.checkout?.quantity
    ) {
      return true;
    } else {
      return false;
    }
  }

  const deleteItemById = (index) => {
    // Clone the original list to avoid mutating the state directly
    const updatedList = list.filter((item, ind) => ind !== index);

    // Set the updated list back to the state
    setList(updatedList);
  };

  const handleSendRewards = async () => {
    let body = {
      total_recipients: list?.length,
      email_subject: emailSub,
      template_id: templateId,
      image: customTemp,
      recipients: list?.map((recipient) => {
        return {
          email: recipient?.email,
          name: recipient?.name,
          mobile_no: recipient?.phoneNumber,
          country_code: "+91",
          message: recipient?.personalMessage
            ? recipient?.personalMessage
            : emailSub,
          value_of_voucher: recipient?.voucherValue?.value
            ? recipient?.voucherValue?.value
            : recipient?.voucherValue,
          quantity: recipient?.numberOfVouchers,
          provider_id: recipient?.voucher?.value,
        };
      }),
    };
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (key === "recipients") {
        // Convert recipients array to JSON string before appending
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    try {
      let response = await createOrder(formData);
      if (response?.status == 200) {
        setShowEmailSmsTemp(false);
        setSendIndividual(true);
        setShowOrderModal(true);
        setList([]);
      } else {
      }
    } catch (error) {}
  };

  let grandTotal = list?.reduce((acc, current) => {
    return (
      acc +
      (current?.voucherValue?.value
        ? current?.voucherValue?.value
        : current?.voucherValue -
          (current?.voucherValue?.value
            ? current?.voucherValue?.value
            : current?.voucherValue * current?.voucher?.data?.discount) /
            100) *
        current?.numberOfVouchers
    );
  }, 0);

  const roundedAccountBalance = parseFloat(balance);
  const roundedTotalProductBalance = parseFloat(grandTotal);
  const handleGenrate = () => {
    if (list?.length !== 0) {
      if (roundedAccountBalance >= roundedTotalProductBalance) {
        handleSendRewards();
      } else {
        Swal.fire({
          title: "Insufficient funds!",
          text: "Please add funds",
          imageUrl: fund,
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonText: "Add Funds",
          cancelButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            // User clicked "Add Funds", navigate to the add funds screen
            navigate("/admin/add-funds");
          } else {
            // User clicked "Close" or outside the modal
            // You can handle this case or leave it empty
          }
        });
      }
    } else {
      showNotification("danger", "Please add recipients");
    }
  };
  const handleBulkGenrate = async () => {
    if (roundedAccountBalance >= bulkData?.total_amount) {
      try {
        let response = await getBulkOrder(bulkData?.data_token);
        if (response?.status == 200) {
          setShowOrderModal(true);
        } else {
        }
      } catch (error) {
        setBulkData(null);
      }
    } else {
      Swal.fire({
        title: "Insufficient funds!",
        text: "Please add funds",
        imageUrl: fund,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Add Funds",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked "Add Funds", navigate to the add funds screen
          navigate("/admin/add-funds");
        } else {
          // User clicked "Close" or outside the modal
          // You can handle this case or leave it empty
        }
      });
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      // Check if the selected file is either CSV or XLSX
      if (
        selectedFile?.type === "text/csv" ||
        selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please upload a valid CSV or XLSX file.");
        // Clear the file input
        event.target.value = null;
      }
    }
  };

  const handleUpload = async () => {
    // Handle file upload logic here
    if (file) {
      let formData = new FormData();
      formData?.append("excel_file", file);

      // Perform the necessary actions with the file, for example, send it to the server
      try {
        let response = await createBulkOrder(formData);
        if (response?.status == 200) {
          setFile(null);
          Swal.fire(response?.data?.message, "", "success").then((result) => {
            if (result.isConfirmed) {
              // User clicked "Add Funds", navigate to the add funds screen
              setBulkData(response?.data);
            } else {
              // User clicked "Close" or outside the modal
              // You can handle this case or leave it empty
            }
          });
        } else {
          setFile(null);
        }
      } catch (error) {}
    } else {
      alert("Please select a file before uploading.");
    }
  };

  const handleDownload = () => {
    // Assume sample-file.csv is in the src folder
    const filePath =
      "https://host.credencerewards.com/media/SquidUploadSampleSpreadsheet.xlsx";

    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = filePath;
    anchor.download = "sample-file.xlsx";

    // Trigger a click event on the anchor to start the download
    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
  };

  const discount = () => {
    const voucherValue =
      formik?.values?.voucherValue?.value || formik?.values?.voucherValue; // Get the voucher value
    const numberOfVouchers = formik?.values?.numberOfVouchers; // Get the number of vouchers
    const discountPercentage = formik?.values?.voucher?.data?.discount || 0; // Get the discount percentage (default to 0 if not provided)

    // Calculate the total amount per voucher after applying the discount
    const discountedVoucherValue =
      voucherValue - (voucherValue * discountPercentage) / 100;

    // Calculate the total amount for all vouchers before applying the discount

    // Calculate the total amount for all vouchers after applying the discount
    const totalAfterDiscount = discountedVoucherValue * numberOfVouchers;
    return totalAfterDiscount?.toFixed(2);
  };
  const hasErrors =
    Object.keys(formik.errors)?.length > 0 &&
    Object.keys(formik.touched)?.length > 0;
  const handleNext = async () => {
    if (list?.length !== 0) {
      setShowEmailSmsTemp(true);
      setSendIndividual(false);
    } else {
      const errors = await formik.validateForm();

      if (
        formik?.values?.email === "" ||
        formik?.values?.name === "" ||
        formik?.values?.phoneNumber === "" ||
        formik?.values?.voucher === "" ||
        formik?.values?.voucherValue === "" ||
        Object.keys(errors).length !== 0
      ) {
        formik?.handleSubmit();
      } else {
        if (validateValue()) {
          formik?.handleSubmit();
          setShowEmailSmsTemp(true);
          setSendIndividual(false);
        }
      }
    }
  };
  const handleEditClick = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollonAddMore = () => {
    setTimeout(() => {
      if (myRef.current) {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };
  return (
    <>
      {showExplore ? (
        <AllTemplate
          setShowExplore={setShowExplore}
          setTemplateId={setTemplateId}
          templateId={templateId}
          setTempImg={setTempImg}
          tempImg={tempImg}
        />
      ) : (
        <>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              {/* Menu */}
              <SIdeBar />
              {/* / Menu */}
              {/* Layout container */}
              <div className="layout-page">
                {/* Navbar */}
                <Navbar />
                {/* / Navbar */}
                {/* Content wrapper */}
                <div className="content-wrapper">
                  {/* Content */}
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="d-flex px-2 justify-content-between align-items-center bg-light p-1 py-3 rounded shadow">
                      <div className="col-md-8 px-2">
                        <h4 className="fw-semibold text-primary ">
                          <span className="text-muted fw-light "></span>
                          Send Credence Code
                        </h4>
                        <p className="fs-12">
                          Generate and Send Credence Code to anyone, anytime
                          with ease.
                        </p>
                        <p>
                          {" "}
                          <Link
                            to="/admin/rewards-catalogue"
                            className=""
                            style={{
                              textDecoration: "underline",
                              color: "#594DF1",
                            }}
                          >
                            View entire voucher catalogue
                          </Link>
                        </p>
                      </div>
                      <div
                        className=" text-right mb-3 me-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span className="fw-normal">
                            {" "}
                            {walletIcon}Balance
                          </span>
                          <div className="fw-bold">
                            <i className="bx bx-rupee" />

                            {balance}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-auto">
                      <div>
                        <div className="main mt-4 ">
                          {showEmailSmsTemp ? (
                            <EmailAndSms
                              setShowExplore={setShowExplore}
                              setTemplateId={setTemplateId}
                              templateId={templateId}
                              setTempImg={setTempImg}
                              tempImg={tempImg}
                              emailSub={emailSub}
                              setEmailSub={setEmailSub}
                              setSendIndividual={setSendIndividual}
                              setShowEmailSmsTemp={setShowEmailSmsTemp}
                              handleGenrate={handleGenrate}
                              setCustomTemp={setCustomTemp}
                              customTemp={customTemp}
                              list={list}
                            />
                          ) : (
                            <>
                              {" "}
                              <ul className="nav nav-pills nav-justified ">
                                <li
                                  className="nav-item   bg-dark-subtle"
                                  style={{
                                    backgroundColor: ShowBulk
                                      ? "#B2AEFF"
                                      : "rgba(178, 174, 255, 0.55)",
                                    borderTopLeftRadius: "10px",
                                    borderRight: "1.5px solid",
                                  }}
                                  onClick={() => {
                                    setSendIndividual(false);
                                    setSendSelfVoucher(false);
                                    setShowBulk(true);
                                  }}
                                >
                                  <a
                                    className="nav-link text-black"
                                    aria-current="page"
                                    href="#"
                                  >
                                    <span
                                      className="d-flex align-items-center justify-content-center gap-2 "
                                      style={{ color: "black" }}
                                    >
                                      <GroupsIcon /> Send Bulk
                                    </span>
                                  </a>
                                </li>
                                <li
                                  className="nav-item   bg-dark-subtle"
                                  style={{
                                    backgroundColor: SendIndividual
                                      ? "#B2AEFF"
                                      : "rgba(178, 174, 255, 0.55) ",
                                    borderRight: "1.5px solid",
                                  }}
                                  onClick={() => {
                                    setSendIndividual(true);
                                    setSendSelfVoucher(false);
                                    setShowBulk(false);
                                  }}
                                >
                                  <a
                                    className="nav-link text-black"
                                    href="#"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      className="d-flex align-items-center justify-content-center gap-2"
                                      style={{ color: "black" }}
                                    >
                                      <PersonIcon /> Send individual
                                    </span>
                                  </a>
                                </li>
                                <li
                                  className="nav-item   "
                                  style={{
                                    backgroundColor: SendSelfVoucher
                                      ? "#B2AEFF"
                                      : "rgba(178, 174, 255, 0.55)",
                                    borderTopRightRadius: "10px",
                                  }}
                                  onClick={() => {
                                    setSendSelfVoucher(true);
                                    setSendIndividual(false);
                                    setShowBulk(false);
                                  }}
                                >
                                  <a className="nav-link text-black" href="#">
                                    <span
                                      className="d-flex align-items-center justify-content-center gap-2"
                                      style={{ color: "black" }}
                                    >
                                      <img width={"25px"} src={self} alt="" />{" "}
                                      Send Self
                                    </span>
                                  </a>
                                </li>
                              </ul>
                              {ShowBulk && (
                                <div className="card">
                                  <div className="card-body">
                                    {bulkData &&
                                    bulkData?.order_details?.length !== 0 ? (
                                      <>
                                        <div
                                          style={{
                                            fontSize: "22px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Added Recipients
                                        </div>
                                        <div className="fs-12 mb-4">
                                          Enable the convenience of adding one
                                          or multiple recipients to effortlessly
                                          send vouchers.
                                        </div>
                                        <table className="table">
                                          <thead
                                            className=" text-white"
                                            style={{
                                              backgroundColor: "#D5D2FF",
                                              width: "fit-content",
                                            }}
                                          >
                                            <tr>
                                              <th
                                                className="text-center"
                                                style={{
                                                  borderRight:
                                                    "1.5px solid gray",
                                                }}
                                              >
                                                Details
                                              </th>

                                              <th
                                                className="text-center"
                                                style={{
                                                  borderRight:
                                                    "1.5px solid gray",
                                                }}
                                              >
                                                Brand
                                              </th>

                                              <th
                                                className="text-center"
                                                style={{
                                                  borderRight:
                                                    "1.5px solid gray",
                                                }}
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="text-center"
                                                style={{
                                                  borderRight:
                                                    "1.5px solid gray",
                                                }}
                                              >
                                                Quantity
                                              </th>
                                              <th
                                                className="text-center"
                                                style={{
                                                  borderRight:
                                                    "1.5px solid gray",
                                                }}
                                              >
                                                Discount
                                              </th>
                                              <th className="text-center">
                                                Total Payable
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-border-bottom-0">
                                            {bulkData?.order_details?.map(
                                              (item, index) => {
                                                return (
                                                  <tr className="" key={index}>
                                                    <td
                                                      className="fs-12 fw-semibold"
                                                      style={{
                                                        paddingLeft: "50px",
                                                        backgroundColor:
                                                          "#FAFAFF",
                                                      }}
                                                    >
                                                      {item?.email}
                                                      <br />
                                                      <span className="text-left">
                                                        {item?.mobile_no}
                                                      </span>
                                                    </td>
                                                    <td className="fs-12 text-center fw-semibold">
                                                      {item?.provider_name}
                                                    </td>

                                                    <td className="fs-12 text-center fw-semibold">
                                                      &#8377;{" "}
                                                      {item?.value_of_voucher}
                                                    </td>
                                                    <td className="fs-12 text-center fw-semibold">
                                                      {item?.quantity}
                                                    </td>
                                                    <td className="fs-12 text-center fw-semibold">
                                                      {" "}
                                                      {item?.discount}%
                                                    </td>

                                                    <td>
                                                      <span>
                                                        <div className="fs-12 text-center fw-semibold">
                                                          {/* Total:{" "}
                                    {item?.voucherValue?.value
                                      ? item?.voucherValue?.value
                                      : item?.voucherValue}
                                    {" , "} */}
                                                          &#8377;{" "}
                                                          {(item?.value_of_voucher -
                                                            (item?.value_of_voucher *
                                                              item?.discount) /
                                                              100) *
                                                            item?.quantity}
                                                        </div>
                                                      </span>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                        <div className=" d-flex justify-content-between align-items-center col px-5  mt-4 ">
                                          <div
                                            className="fw-bold"
                                            style={{ fontSize: "18px" }}
                                          >
                                            Grand Total:{" "}
                                            <span
                                              className="  fw-bold"
                                              style={{
                                                color: "#594DF1",
                                                fontSize: "18px",
                                              }}
                                            >
                                              &#8377;
                                              {bulkData?.total_amount?.toFixed(
                                                3
                                              )}
                                            </span>
                                          </div>
                                          <div>
                                            {" "}
                                            <button
                                              type="button"
                                              className="btn btn-primary mb-4 me-2 "
                                              onClick={() => {
                                                handleBulkGenrate();
                                              }}
                                            >
                                              Send
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="file-upload-container text-center dashed-border py-5s">
                                        {file ? (
                                          <>
                                            {" "}
                                            <h6 className="fw-semibold">
                                              {" "}
                                              File Uploaded!
                                            </h6>
                                            <p>
                                              {" "}
                                              We will verify this file before
                                              sending the reward
                                            </p>
                                            <div className="mt-3 p-3 bg-light d-flex align-items-center">
                                              <div className="file-details d-flex flex-grow ">
                                                <div className="file-name mx-5">
                                                  <i className="bx bx-file"></i>{" "}
                                                  {file?.name}{" "}
                                                </div>
                                                <div className="mr-2">
                                                  <Link
                                                    onClick={() =>
                                                      setFile(null)
                                                    }
                                                  >
                                                    Remove
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                            <button
                                              type="button"
                                              className="btn btn-primary "
                                              onClick={() => handleUpload()}
                                            >
                                              Verify
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <div className="custom-file mt-3 py-3 ">
                                              <input
                                                type="file"
                                                className="custom-file-input"
                                                id="fileInput"
                                                aria-describedby="fileHelp"
                                                onChange={handleFileChange}
                                              />
                                              <label
                                                className="custom-file-label "
                                                htmlFor="fileInput"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="107"
                                                  height="93"
                                                  viewBox="0 0 107 93"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M55.0912 21.2135C54.8881 21.8943 55.2759 22.6098 55.9564 22.8128L56.049 22.8404L56.0518 22.8373C56.7051 22.9797 57.3639 22.5946 57.5558 21.9465C59.3852 15.7997 65.1479 11.5062 71.5688 11.5062C72.2789 11.5062 72.8548 10.9303 72.8548 10.2202C72.8548 9.51011 72.2789 8.9342 71.5688 8.9342C63.7603 8.9342 57.1982 14.1331 55.0912 21.2135ZM55.0912 21.2135L55.2349 21.2564M55.0912 21.2135C55.0912 21.2135 55.0912 21.2136 55.0911 21.2136L55.2349 21.2564M55.2349 21.2564C55.0556 21.8576 55.398 22.4897 55.9993 22.6691L55.2349 21.2564Z"
                                                    fill="#483EA8"
                                                    stroke="#F9FFF9"
                                                    stroke-width="0.3"
                                                  />
                                                  <path
                                                    d="M87.4716 65.8841H80.6558C80.0285 65.8841 79.5198 65.3753 79.5198 64.7481C79.5198 64.1209 80.0285 63.6121 80.6558 63.6121H87.4716C96.8669 63.6121 104.511 55.9677 104.511 46.5723C104.511 37.177 96.8669 29.5326 87.4716 29.5326H87.3077C86.9782 29.5326 86.665 29.3898 86.4492 29.1407C86.2334 28.8916 86.136 28.5613 86.1831 28.2351C86.2845 27.5276 86.3356 26.8168 86.3356 26.1246C86.3356 17.9821 79.7103 11.3568 71.5677 11.3568C68.3999 11.3568 65.379 12.3467 62.8311 14.2202C62.2712 14.6316 61.476 14.449 61.1523 13.8332C53.9364 0.0926809 35.0892 -1.75253 25.3179 10.2005C21.2017 15.2361 19.5843 21.7867 20.8803 28.1711C21.0231 28.8762 20.4835 29.5334 19.767 29.5334H19.3118C9.91651 29.5334 2.27208 37.1779 2.27208 46.5732C2.27208 55.9685 9.91651 63.613 19.3118 63.613H26.1277C26.7549 63.613 27.2637 64.1217 27.2637 64.7489C27.2637 65.3762 26.7549 65.8849 26.1277 65.8849H19.3118C8.66352 65.8849 0 57.2214 0 46.5731C0 36.2235 8.18389 27.749 18.4201 27.2816C17.4585 20.658 19.2972 13.9767 23.5587 8.76268C34.0203 -4.03578 54.0691 -2.60124 62.5443 11.67C65.248 9.97491 68.3418 9.08569 71.5673 9.08569C81.4325 9.08569 89.2498 17.4823 88.5658 27.2922C98.7077 27.861 106.783 36.2908 106.783 46.5723C106.783 57.2214 98.1194 65.8841 87.4711 65.8841L87.4716 65.8841Z"
                                                    fill="#483EA8"
                                                  />
                                                  <path
                                                    d="M24.6896 64.1067C24.6896 79.8494 37.4971 92.6567 53.2395 92.6567C68.9822 92.6567 81.7895 79.8492 81.7895 64.1067C81.7895 48.364 68.9822 35.5568 53.2395 35.5568C37.4968 35.5568 24.6896 48.3642 24.6896 64.1067ZM27.2621 64.1067C27.2621 49.7831 38.9157 38.1293 53.2395 38.1293C67.5631 38.1293 79.217 49.7829 79.217 64.1067C79.217 78.4303 67.5631 90.0842 53.2395 90.0842C38.9159 90.0842 27.2621 78.4305 27.2621 64.1067Z"
                                                    fill="#483EA8"
                                                    stroke="#F9FFF9"
                                                    stroke-width="0.3"
                                                  />
                                                  <path
                                                    d="M52.777 75.5392C52.777 76.0813 53.2166 76.5209 53.7587 76.5209C54.3007 76.5209 54.7403 76.0819 54.7403 75.5392V53.9156C54.7403 53.3736 54.3007 52.934 53.7587 52.934C53.2166 52.934 52.777 53.3736 52.777 53.9156V75.5392Z"
                                                    fill="#483EA8"
                                                    stroke="#483EA8"
                                                    stroke-width="0.3"
                                                  />
                                                  <path
                                                    d="M53.7586 55.3064L47.7995 61.2655C47.4165 61.6492 46.7945 61.6491 46.4111 61.2656C46.0276 60.8821 46.0276 60.2608 46.4111 59.8772L53.0643 53.224C53.0643 53.224 53.0643 53.224 53.0644 53.2239C53.4474 52.8403 54.0693 52.8405 54.4527 53.2239L61.106 59.8772C61.4895 60.2607 61.4895 60.8819 61.1062 61.2654C60.9145 61.4578 60.6625 61.5531 60.4119 61.5531L53.7586 55.3064ZM53.7586 55.3064L59.7177 61.2656C59.9092 61.457 60.1611 61.5531 60.4119 61.5531L53.7586 55.3064Z"
                                                    fill="#483EA8"
                                                    stroke="#483EA8"
                                                    stroke-width="0.3"
                                                  />
                                                </svg>
                                                <br />
                                                Click here to upload
                                              </label>
                                            </div>
                                            <small
                                              id="fileHelp"
                                              className="form-text text-muted"
                                            >
                                              Supported file formats: CSV, XLSX
                                            </small>
                                            <div className="fs-7 mt-4">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="31"
                                                viewBox="0 0 30 31"
                                                fill="none"
                                              >
                                                <g clip-path="url(#clip0_1850_2641)">
                                                  <path
                                                    d="M24.375 30.6089H5.625C2.51813 30.6089 0 28.0908 0 24.9839V6.23389C0 3.12701 2.51813 0.608887 5.625 0.608887H24.375C27.4819 0.608887 30 3.12701 30 6.23389V24.9839C30 28.0908 27.4819 30.6089 24.375 30.6089Z"
                                                    fill="rgb(89, 77, 241)"
                                                    fill-opacity="0.17"
                                                  />
                                                  <path
                                                    d="M15 10.2689C14.7413 10.2689 14.5312 10.0589 14.5312 9.80014V8.57764C14.5312 8.31889 14.7413 8.10889 15 8.10889C15.2588 8.10889 15.4688 8.31889 15.4688 8.57764V9.80014C15.4688 10.0589 15.2588 10.2689 15 10.2689Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M19.1072 11.9704C18.9872 11.9704 18.8672 11.9245 18.7753 11.8336C18.5925 11.6508 18.5925 11.3536 18.7753 11.1708L19.6406 10.3054C19.8234 10.1226 20.1206 10.1226 20.3034 10.3054C20.4862 10.4883 20.4862 10.7854 20.3034 10.9683L19.4381 11.8336C19.3472 11.9245 19.2272 11.9704 19.1072 11.9704Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M22.0313 16.0776H20.8088C20.5501 16.0776 20.3401 15.8676 20.3401 15.6089C20.3401 15.3501 20.5501 15.1401 20.8088 15.1401H22.0313C22.2901 15.1401 22.5001 15.3501 22.5001 15.6089C22.5001 15.8676 22.2901 16.0776 22.0313 16.0776Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M19.9715 21.0492C19.8515 21.0492 19.7315 21.0033 19.6396 20.9123L18.7743 20.047C18.5915 19.8642 18.5915 19.567 18.7743 19.3842C18.9571 19.2014 19.2543 19.2014 19.4371 19.3842L20.3024 20.2495C20.4853 20.4323 20.4853 20.7295 20.3024 20.9123C20.2115 21.0042 20.0915 21.0492 19.9715 21.0492Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M10.0286 21.0492C9.90855 21.0492 9.78855 21.0033 9.69668 20.9123C9.51387 20.7295 9.51387 20.4323 9.69668 20.2495L10.562 19.3842C10.7448 19.2014 11.042 19.2014 11.2248 19.3842C11.4076 19.567 11.4076 19.8642 11.2248 20.047L10.3595 20.9123C10.2676 21.0042 10.1486 21.0492 10.0286 21.0492Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M9.19125 16.0776H7.96875C7.71 16.0776 7.5 15.8676 7.5 15.6089C7.5 15.3501 7.71 15.1401 7.96875 15.1401H9.19125C9.45 15.1401 9.66 15.3501 9.66 15.6089C9.66 15.8676 9.45 16.0776 9.19125 16.0776Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M10.8929 11.9704C10.7729 11.9704 10.6529 11.9245 10.561 11.8336L9.6957 10.9683C9.51289 10.7854 9.51289 10.4883 9.6957 10.3054C9.87852 10.1226 10.1757 10.1226 10.3585 10.3054L11.2238 11.1708C11.4066 11.3536 11.4066 11.6508 11.2238 11.8336C11.1329 11.9245 11.0129 11.9704 10.8929 11.9704Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M16.875 21.2339V22.0148C16.875 22.6148 16.3809 23.1089 15.7809 23.1089H14.2181C13.6941 23.1089 13.125 22.7086 13.125 21.8339V21.2339H16.875Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M17.7563 12.2086C16.7316 11.377 15.3816 11.0526 14.0625 11.3339C12.4059 11.678 11.0625 13.028 10.7184 14.6836C10.3687 16.3833 11.0062 18.0708 12.3684 19.102C12.7369 19.3767 12.9938 19.8023 13.0809 20.2955V20.302C13.0941 20.2964 13.1128 20.2964 13.125 20.2964H16.875C16.8872 20.2964 16.8938 20.2964 16.9059 20.303V20.2964C16.9931 19.8211 17.2744 19.3842 17.7056 19.0467C18.7622 18.2095 19.3744 16.9589 19.3744 15.6089C19.3753 14.2842 18.7875 13.0467 17.7563 12.2086ZM17.3438 15.9211C17.0878 15.9211 16.875 15.7083 16.875 15.4523C16.875 14.5026 16.1062 13.7339 15.1566 13.7339C14.9006 13.7339 14.6878 13.5211 14.6878 13.2651C14.6878 13.0092 14.9006 12.7964 15.1566 12.7964C16.6191 12.7964 17.8125 13.9898 17.8125 15.4523C17.8125 15.7092 17.5997 15.9211 17.3438 15.9211Z"
                                                    fill="rgb(89, 77, 241)"
                                                  />
                                                  <path
                                                    d="M13.0811 20.2964H13.1251C13.1129 20.2964 13.0942 20.2964 13.0811 20.3029V20.2964Z"
                                                    fill="black"
                                                  />
                                                  <path
                                                    d="M16.9059 20.2964V20.3029C16.8938 20.2964 16.8872 20.2964 16.875 20.2964H16.9059Z"
                                                    fill="black"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1850_2641">
                                                    <rect
                                                      width="30"
                                                      height="30"
                                                      fill="white"
                                                      transform="translate(0 0.608887)"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>{" "}
                                              View
                                              <Link
                                                onClick={handleShowModal}
                                                className="link-bt"
                                              >
                                                {" "}
                                                Instruction
                                              </Link>{" "}
                                              or download a{" "}
                                              <Link
                                                onClick={handleDownload}
                                                className="link-bt"
                                              >
                                                sample file
                                              </Link>{" "}
                                              to view the template.
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {SendIndividual && (
                                <div className="row ">
                                  {/* {showCart ? ( */}
                                  <>
                                    {/* <RecipientCart
                                          list={list}
                                          setShowCart={setShowCart}
                                          setEditIndex={setEditIndex}
                                          formik={formik}
                                          deleteItemById={deleteItemById}
                                          roundedTotalProductBalance={
                                            roundedTotalProductBalance
                                          }
                                          setShowEmailSmsTemp={
                                            setShowEmailSmsTemp
                                          }
                                          setSendIndividual={setSendIndividual}
                                        /> */}
                                  </>
                                  {/* ) : ( */}
                                  <div className="col-12 col-lg-12 ">
                                    {list?.length !== 0 && (
                                      <RecipientCart
                                        list={list}
                                        setShowCart={setShowCart}
                                        setEditIndex={setEditIndex}
                                        formik={formik}
                                        deleteItemById={deleteItemById}
                                        roundedTotalProductBalance={
                                          roundedTotalProductBalance
                                        }
                                        setShowEmailSmsTemp={
                                          setShowEmailSmsTemp
                                        }
                                        setSendIndividual={setSendIndividual}
                                        handleEditClick={handleEditClick}
                                      />
                                    )}
                                    <div className="card h-80">
                                      <div className="card-body">
                                        <h6 className="card-title text-primary fs-4 fw-semibold">
                                          Add Recipients
                                        </h6>
                                        <span className="card-text fs-12">
                                          Adding one or multiple recipients to
                                          send rewards.
                                        </span>
                                        <div>
                                          <div className="mt-3">
                                            <h6 className="card-title text-primary fw-semibold">
                                              Recipient Details
                                            </h6>
                                          </div>
                                        </div>
                                        <form
                                          id="formAccountSettings"
                                          ref={formRef}
                                        >
                                          <div className="row">
                                            <div className="mb-2 col-md-4">
                                              <label
                                                htmlFor="firstName"
                                                className="form-label"
                                              >
                                                Email<span>*</span>
                                              </label>
                                              <input
                                                className="form-control "
                                                type="text"
                                                id="email"
                                                name="email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                maxLength={50}
                                              />
                                              {/* <CreatableSelect
                                                  options={adminEmail?.map(
                                                    (item) => {
                                                      return {
                                                        label: item?.user__email,
                                                        value: item?.user__email,
                                                        name: item?.user__full_name,
                                                        phoneNumber:
                                                          item?.user__mobile_no,
                                                      };
                                                    }
                                                  )}
                                                  value={formik.values.email}
                                                  onBlur={() =>
                                                    formik.setFieldTouched(
                                                      "email",
                                                      true
                                                    )
                                                  }
                                                  onChange={(selectedOption) => {
                                                    formik.setFieldValue(
                                                      "email",
                                                      selectedOption
                                                    );
                                                    formik.setFieldValue(
                                                      "name",
                                                      selectedOption?.name
                                                    );
                                                    formik.setFieldValue(
                                                      "phoneNumber",
                                                      selectedOption?.phoneNumber
                                                    );
                                                  }}
                                                  isClearable
                                                  components={{
                                                    ClearIndicator: () => (
                                                      <span
                                                        className="p-2"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          formik.setFieldValue(
                                                            "email",
                                                            ""
                                                          );
  
                                                          formik.setFieldValue(
                                                            "name",
                                                            ""
                                                          );
                                                          formik.setFieldValue(
                                                            "phoneNumber",
                                                            ""
                                                          );
                                                        }}
                                                      >
                                                        &times;
                                                      </span>
                                                    ),
                                                  }}
                                                /> */}

                                              {formik.touched.email &&
                                              formik.errors.email ? (
                                                <div className="text-danger">
                                                  {formik.errors.email}
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="mb-2 col-md-4">
                                              <label
                                                htmlFor="firstName"
                                                className="form-label"
                                              >
                                                Name<span>*</span>
                                              </label>
                                              <input
                                                className="form-control "
                                                type="text"
                                                id="name"
                                                name="name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                maxLength={25}
                                                onKeyPress={restrictNumeric}
                                              />
                                              {formik.touched.name &&
                                              formik.errors.name ? (
                                                <div className="text-danger">
                                                  {formik.errors.name}
                                                </div>
                                              ) : null}
                                            </div>

                                            <div className="mb-2 col-md-4">
                                              <label
                                                htmlFor="lastName"
                                                className="form-label"
                                              >
                                                Phone Number<span>*</span>
                                              </label>
                                              <input
                                                className="form-control"
                                                type="text"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                  formik.values.phoneNumber
                                                }
                                                maxLength={10}
                                                onKeyPress={restrictAlpha}
                                              />
                                              {formik.touched.phoneNumber &&
                                              formik.errors.phoneNumber ? (
                                                <div className="text-danger">
                                                  {formik.errors.phoneNumber}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </form>
                                        <div>
                                          <div className="mt-2">
                                            <h6 className="card-title text-primary fw-semibold">
                                              Credence Code Details
                                            </h6>
                                          </div>
                                        </div>
                                        <form id="formAccountSettings">
                                          <div className="row">
                                            <div className="mb-3 col-md-3">
                                              <label
                                                htmlFor="firstName"
                                                className="form-label "
                                              >
                                                Select Brands
                                                <span>*</span>
                                              </label>
                                              <Select
                                                options={options}
                                                id="voucher"
                                                name="voucher"
                                                onChange={(selectedOption) => {
                                                  formik.setFieldValue(
                                                    "voucher",
                                                    selectedOption
                                                  );
                                                  formik.setFieldValue(
                                                    "voucherValue",
                                                    ""
                                                  );
                                                  formik.setFieldValue(
                                                    "numberOfVouchers",
                                                    1
                                                  );
                                                }}
                                                onBlur={() =>
                                                  formik.setFieldTouched(
                                                    "voucher",
                                                    true
                                                  )
                                                }
                                                value={formik.values.voucher}
                                                isOptionDisabled={(option) =>
                                                  option.isdisabled
                                                }
                                                isSearchable
                                                isClearable
                                              />
                                              {formik.errors.voucher &&
                                              formik?.touched?.voucher ? (
                                                <div className="text-danger">
                                                  {formik.errors.voucher}
                                                </div>
                                              ) : null}
                                            </div>

                                            <div
                                              className="mb-3 col-md-3"
                                              ref={myRef}
                                            >
                                              <label
                                                htmlFor="lastName"
                                                className="form-label"
                                              >
                                                Select Denominations<span>*</span>
                                              </label>
                                              {formik?.values?.voucher?.data
                                                ?.type == "Range" ? (
                                                <input
                                                  disabled={
                                                    formik.values.voucher !== ""
                                                      ? false
                                                      : true
                                                  }
                                                  className="form-control"
                                                  type="text"
                                                  name="voucherValue"
                                                  id="voucherValue"
                                                  placeholder={`range.${formik?.values?.voucher?.data?.checkout?.min}-${formik?.values?.voucher?.data?.checkout?.max}`}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={
                                                    formik.values.voucherValue
                                                  }
                                                  maxLength={5}
                                                  onKeyPress={restrictAlpha}
                                                />
                                              ) : (
                                                <>
                                                  <Select
                                                    isDisabled={
                                                      formik.values.voucher !==
                                                      ""
                                                        ? false
                                                        : true
                                                    }
                                                    options={formik.values?.voucher?.data?.checkout?.denominations?.map(
                                                      (price) => {
                                                        return {
                                                          label: "₹ " + price,
                                                          value: +price,
                                                        };
                                                      }
                                                    )}
                                                    id="voucher"
                                                    name="voucher"
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      formik.setFieldValue(
                                                        "voucherValue",
                                                        selectedOption
                                                      )
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                      formik.values.voucherValue
                                                    }
                                                    isSearchable
                                                    isClearable
                                                  />
                                                </>
                                              )}
                                              {formik.touched.voucherValue &&
                                              formik.errors.voucherValue ? (
                                                <div className="text-danger">
                                                  {formik.errors.voucherValue}
                                                </div>
                                              ) : null}

                                              {formik?.values?.voucher?.data
                                                ?.type == "Range" ? (
                                                !validateValue() &&
                                                !formik.errors.voucherValue ? (
                                                  <div className="text-danger">
                                                    Enter a value between{" "}
                                                    {`${formik?.values?.voucher?.data?.checkout?.min}-${formik?.values?.voucher?.data?.checkout?.max}`}
                                                  </div>
                                                ) : null
                                              ) : (
                                                <></>
                                              )}
                                            </div>

                                            <div className="mb-3 col-md-3">
                                              <label
                                                htmlFor="lastName"
                                                className="form-label"
                                              >
                                                No. Of Codes<span>*</span>
                                              </label>
                                              <input
                                                disabled={
                                                  formik.values.voucher !== ""
                                                    ? false
                                                    : true
                                                }
                                                className="form-control"
                                                type="text"
                                                name="numberOfVouchers"
                                                id="numberOfVouchers"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                  formik.values.numberOfVouchers
                                                }
                                                maxLength={4}
                                                onKeyPress={restrictAlpha}
                                              />

                                              {formik.touched
                                                .numberOfVouchers &&
                                              formik.errors.numberOfVouchers ? (
                                                <div className="text-danger">
                                                  {
                                                    formik.errors
                                                      .numberOfVouchers
                                                  }
                                                </div>
                                              ) : null}

                                              {!validateQuant() &&
                                              formik.touched.numberOfVouchers &&
                                              !formik.errors
                                                .numberOfVouchers ? (
                                                <div className="text-danger">
                                                  Enter no of voucher less than
                                                  or equal to{" "}
                                                  {`${formik?.values?.voucher?.data?.checkout?.quantity}`}
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="mb-4 col-md-3 mt-1">
                                              <div className="d-flex justify-content-between">
                                                <p className="mb-2 fw-bold">
                                                  Total Amt
                                                </p>
                                                <p className="mb-2">
                                                  <i className="bx bx-rupee" />
                                                  {discount()}{" "}
                                                </p>
                                              </div>
                                              <div className="d-flex justify-content-between">
                                                <p className="mb-2 fw-bold">
                                                  Discount
                                                </p>
                                                <p className="mb-2">
                                                  {formik?.values?.voucher?.data
                                                    ?.discount || 0}
                                                  %
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {isDuplicate && (
                                            <div className="text-danger mb-1">
                                              *Brand with Same denomination
                                              cannot be added again. Please add
                                              new denomination or update
                                              quantity
                                            </div>
                                          )}
                                          <div className="row justify-content-between">
                                            <div className="form-check ">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="showAdditional"
                                                name="showAdditional"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={
                                                  formik.values.showAdditional
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="defaultCheck1"
                                              >
                                                {" "}
                                                Show additional settings{" "}
                                              </label>
                                            </div>
                                            {!formik.values.showAdditional && (
                                              <div className=" d-flex justify-content-end">
                                                {editIndex !== null && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary "
                                                    onClick={() => {
                                                      formik.handleSubmit();
                                                    }}
                                                  >
                                                    Update
                                                  </button>
                                                )}
                                                {editIndex == null && (
                                                  <>
                                                    <button
                                                      type="button"
                                                      className="btn  border-primary mx-2 border-2 "
                                                      style={{
                                                        color: "#594DF1",
                                                      }}
                                                      onClick={() => {
                                                        formik.handleSubmit();
                                                        handleScrollonAddMore();
                                                      }}
                                                    >
                                                      + Add More
                                                    </button>
                                                    {list?.length == 0 && (
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary mx-2"
                                                        onClick={() => {
                                                          handleNext();
                                                        }}
                                                      >
                                                        Next
                                                      </button>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </form>
                                        {formik.values.showAdditional && (
                                          <form id="formAccountSettings">
                                            <div className="row">
                                              <div className="mb-3 col-md-9">
                                                <label
                                                  htmlFor="lastName"
                                                  className="form-label"
                                                >
                                                  Personal Message in Email
                                                </label>
                                                <textarea
                                                  className="form-control select-show"
                                                  type="text"
                                                  name="personalMessage"
                                                  id="personalMessage"
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={
                                                    formik.values
                                                      .personalMessage
                                                  }
                                                />
                                                This message will appear as part
                                                of the reward email body.
                                              </div>
                                              {formik.touched.personalMessage &&
                                              formik.errors.personalMessage ? (
                                                <div className="text-danger">
                                                  {
                                                    formik.errors
                                                      .personalMessage
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="row">
                                              <div className="mb-3 col-md-3">
                                                <label
                                                  htmlFor="lastName"
                                                  className="form-label"
                                                >
                                                  PO Number
                                                </label>
                                                <input
                                                  className="form-control select-show"
                                                  type="text"
                                                  name="poNumber"
                                                  id="poNumber"
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={formik.values.poNumber}
                                                />
                                                {formik.touched.poNumber &&
                                                formik.errors.poNumber ? (
                                                  <div className="text-danger">
                                                    {formik.errors.poNumber}
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>

                                            <div className="mt-2 d-flex justify-content-end">
                                              {editIndex ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-primary "
                                                  onClick={() => {
                                                    formik.handleSubmit();
                                                  }}
                                                >
                                                  Update
                                                </button>
                                              ) : (
                                                <>
                                                  <>
                                                    <button
                                                      type="button"
                                                      className="btn  mx-2 border-primary border-2"
                                                      onClick={() => {
                                                        formik.handleSubmit();
                                                        handleScrollonAddMore();
                                                      }}
                                                    >
                                                      + Add More
                                                    </button>
                                                    {list?.length == 0 && (
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary mx-2"
                                                        onClick={() => {
                                                          handleNext();
                                                        }}
                                                      >
                                                        Next
                                                      </button>
                                                    )}
                                                  </>
                                                </>
                                              )}
                                            </div>
                                          </form>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {/* )} */}
                                </div>
                              )}
                              {SendSelfVoucher && (
                                <SendSelf balance={balance} options={options} />
                              )}
                            </>
                          )}
                        </div>
                        {/* Content wrapper */}
                      </div>
                      {/* / Layout page */}
                    </div>
                    {/* Overlay */}
                  </div>
                  <div className="layout-overlay layout-menu-toggle" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <VoucherColModal show={showModal} onHide={handleHideModal} />
      <OrderSuccess show={showOrderModal} setShow={setShowOrderModal} />
    </>
  );
};

export default CredenceCode;
