import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import { Link } from "react-router-dom";
import sendBulkImg from "../../Images/helpImg/individual.png";
import dataImg from "../../Images/helpImg/indiData.png";
import showAdd from "../../Images/helpImg/showAdditional.png";
import BackButton from "./BackButton";

function SendIndividualStep() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Send Rewards - Individual{" "}
                    </h5>
                    <p>
                      Reward Individuals based on campaigns, events, promotions, birthdays, festival giftings.
                    </p>
                    <hr className="mt-4" />

                    <p>Send Rewards - Individual Steps:</p>
                    <ol>
                      <li>Click on the Send Individual tab</li>
                      <li>
                        Enter recipient details such as Email address, Name and Phone number.
                      </li>
                      <li>
                        Select the Reward Voucher and provide voucher value and quantity.
                      </li>
                      <li>
                        Add recipients by clicking the “Add more” button.
                      </li>

                      <img
                        className="mt-4  mb-2"
                        width={"80%"}
                        src={sendBulkImg}
                        alt=""
                      />

                      <li>
                        Click on the Next button to review and customize Recipient Email based on the campaign or event.
                      </li>
                      <img
                        className="mt-4  mb-2"
                        width={"80%"}
                        src={dataImg}
                        alt=""
                      />
                      <li>
                        Click the Send button to send Reward Vocher to the recipient(s).
                      </li>
                    </ol>
                    <blockquote className="callout callout_info" theme="📘">
                      <h5 className="callout-heading ">
                        <p className="fw-bold">Pro Tip</p>
                      </h5>

                      <p>To add more recipients, click the 'Add More' button.
                        You can include up to 15 recipients in a single transaction.
                      </p>

                    </blockquote>
                    <blockquote className="callout callout_info" theme="📘">
                      <h5 className="callout-heading ">
                        <p className="fw-bold">Show Additional Settings</p>
                      </h5>
                      <img
                        className="mt-2 mb-2"
                        width={"80%"}
                        src={showAdd}
                        alt=""
                      />
                      <p>With additional settings you can;</p>
                      <ul>
                        <li>
                        Include a personalized message, Campaign/Event/Seminar Name in the email.
                        </li>
                        <li>
                        Mention internal reference no. or PO Number for your own records to track later
                        </li>
                      </ul>
                    </blockquote>
                    <hr />
                    <div className="mt-4">
                    <BackButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default SendIndividualStep;
