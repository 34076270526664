import axios from "axios";

import showNotification from "./services/NotificationService";
import { getToken } from "./session";

// create a instance of axios with a custom config
export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor

http.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor

http.interceptors.response.use(
  function (response) {
    // If the response is successful, simply return it
    return response;
  },
  function (error) {
    // Check if there's a response in the error

    if (error.response) {
       
      if (
        window?.location?.pathname != "/"  && window?.location?.pathname != "/signup" 
      ) {
        showNotification("danger", error?.response?.data?.message);
      }
      // Check for specific error statuses
      if (error.response.status === 403) {
        // Clear localStorage and sessionStorage on 403 Forbidden error
        localStorage.clear();
        sessionStorage.clear();
      } else if (error.response.status === 401) {
        sessionStorage.clear();
        localStorage.clear();
        // Redirect to the login page for 401 Unauthorized error
        window.location.href = "/";
      } else {
        // For other error statuses, reject the promise
        return Promise.reject(error);
      }
    } else {
      // Handle any other errors that are not related to a response
      // For example, network errors
      // You might want to show a generic error message or log the error
      console.error("Network Error:", error.message);
      // Optionally, you can reject the promise here as well
      return Promise.reject(error);
    }
  }
);
