import React from "react";
import { Modal, Button } from "react-bootstrap";
const UploadDocModal = ({ show, setShow,formik }) => {
  return (
    <Modal
      show={show}
      
      centered
      size="md"
      style={{ padding: "5px" }}
    >
      <h4 className="p-3">Upload Documents</h4>

      <div className="p-2 d-flex gap-1 align-items-center justify-content-between">
        <div className="d-flex gap-1 align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5001 18.9582H7.50008C2.97508 18.9582 1.04175 17.0248 1.04175 12.4998V7.49984C1.04175 2.97484 2.97508 1.0415 7.50008 1.0415H11.6667C12.0084 1.0415 12.2917 1.32484 12.2917 1.6665C12.2917 2.00817 12.0084 2.2915 11.6667 2.2915H7.50008C3.65841 2.2915 2.29175 3.65817 2.29175 7.49984V12.4998C2.29175 16.3415 3.65841 17.7082 7.50008 17.7082H12.5001C16.3417 17.7082 17.7084 16.3415 17.7084 12.4998V8.33317C17.7084 7.9915 17.9917 7.70817 18.3334 7.70817C18.6751 7.70817 18.9584 7.9915 18.9584 8.33317V12.4998C18.9584 17.0248 17.0251 18.9582 12.5001 18.9582Z"
              fill="#353535"
            />
            <path
              d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z"
              fill="#353535"
            />
            <path
              d="M10.8333 11.4585H5.83325C5.49159 11.4585 5.20825 11.1752 5.20825 10.8335C5.20825 10.4918 5.49159 10.2085 5.83325 10.2085H10.8333C11.1749 10.2085 11.4583 10.4918 11.4583 10.8335C11.4583 11.1752 11.1749 11.4585 10.8333 11.4585Z"
              fill="#353535"
            />
            <path
              d="M9.16659 14.7915H5.83325C5.49159 14.7915 5.20825 14.5082 5.20825 14.1665C5.20825 13.8248 5.49159 13.5415 5.83325 13.5415H9.16659C9.50825 13.5415 9.79159 13.8248 9.79159 14.1665C9.79159 14.5082 9.50825 14.7915 9.16659 14.7915Z"
              fill="#353535"
            />
          </svg>
          <div>GST</div>
        </div>
        <div>
          <label htmlFor="uplaod" className="" tabIndex={0}>
            <button
              className="d-flex p-1 align-items-center gap-2"
              style={{
                color: "#594DF1",
                border: "2px solid #594DF1",
                borderRadius: "10px",
                cursor:"pointer"
              }}
            >
              <div>
                <label htmlFor="upload" tabIndex={0}>
                  <span className="d-none d-sm-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.3334 5.33333L8.00008 2L4.66675 5.33333"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 2V10"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    {formik?.values?.gst_certificate ? "Update":"Upload"}   

                  </span>
                  <i className="bx bx-upload d-block d-sm-none" />
                  <input
                    type="file"
                    name="gst_certificate"
                    id="upload"
                    className="account-file-input"
                    hidden="true"
                    accept="application/pdf"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "gst_certificate",
                        e.target.files[0]
                      )
                    }
                  />
                </label>
              </div>
            </button>
          </label>
        </div>
      </div>
      {formik.errors.gst_certificate ? (
                                <div className="text-danger">
                                  {formik.errors.gst_certificate}
                                </div>
                              ) : null}
      {/* <div className="p-2 d-flex gap-1 align-items-center justify-content-between">
        <div className="d-flex gap-1 align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5001 18.9582H7.50008C2.97508 18.9582 1.04175 17.0248 1.04175 12.4998V7.49984C1.04175 2.97484 2.97508 1.0415 7.50008 1.0415H11.6667C12.0084 1.0415 12.2917 1.32484 12.2917 1.6665C12.2917 2.00817 12.0084 2.2915 11.6667 2.2915H7.50008C3.65841 2.2915 2.29175 3.65817 2.29175 7.49984V12.4998C2.29175 16.3415 3.65841 17.7082 7.50008 17.7082H12.5001C16.3417 17.7082 17.7084 16.3415 17.7084 12.4998V8.33317C17.7084 7.9915 17.9917 7.70817 18.3334 7.70817C18.6751 7.70817 18.9584 7.9915 18.9584 8.33317V12.4998C18.9584 17.0248 17.0251 18.9582 12.5001 18.9582Z"
              fill="#353535"
            />
            <path
              d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z"
              fill="#353535"
            />
            <path
              d="M10.8333 11.4585H5.83325C5.49159 11.4585 5.20825 11.1752 5.20825 10.8335C5.20825 10.4918 5.49159 10.2085 5.83325 10.2085H10.8333C11.1749 10.2085 11.4583 10.4918 11.4583 10.8335C11.4583 11.1752 11.1749 11.4585 10.8333 11.4585Z"
              fill="#353535"
            />
            <path
              d="M9.16659 14.7915H5.83325C5.49159 14.7915 5.20825 14.5082 5.20825 14.1665C5.20825 13.8248 5.49159 13.5415 5.83325 13.5415H9.16659C9.50825 13.5415 9.79159 13.8248 9.79159 14.1665C9.79159 14.5082 9.50825 14.7915 9.16659 14.7915Z"
              fill="#353535"
            />
          </svg>
          <div>CIN</div>
        </div>
        <div>
          <label htmlFor="uplaod" className="" tabIndex={0}>
            <button
              className="d-flex p-1 align-items-center gap-2"
              style={{
                color: "#594DF1",
                border: "2px solid #594DF1",
                borderRadius: "10px",
                cursor:"pointer"
              }}
            >
              <div>
                <label htmlFor="upload2" tabIndex={0}>
                  <span className="d-none d-sm-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.3334 5.33333L8.00008 2L4.66675 5.33333"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 2V10"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    {formik?.values?.cin_certificate ? "Update":"Upload"}   
                  </span>
                  <i className="bx bx-upload d-block d-sm-none" />
                  <input
                    type="file"
                    name="cin_certificate"
                    id="upload2"
                    className="account-file-input"
                    hidden="true"
                    accept="application/pdf"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "cin_certificate",
                        e.target.files[0]
                      )
                    }
                  />
                </label>
              </div>
            </button>
          </label>
        </div>
      </div>
      <div className="p-2 d-flex gap-1 align-items-center justify-content-between">
        <div className="d-flex gap-1 align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5001 18.9582H7.50008C2.97508 18.9582 1.04175 17.0248 1.04175 12.4998V7.49984C1.04175 2.97484 2.97508 1.0415 7.50008 1.0415H11.6667C12.0084 1.0415 12.2917 1.32484 12.2917 1.6665C12.2917 2.00817 12.0084 2.2915 11.6667 2.2915H7.50008C3.65841 2.2915 2.29175 3.65817 2.29175 7.49984V12.4998C2.29175 16.3415 3.65841 17.7082 7.50008 17.7082H12.5001C16.3417 17.7082 17.7084 16.3415 17.7084 12.4998V8.33317C17.7084 7.9915 17.9917 7.70817 18.3334 7.70817C18.6751 7.70817 18.9584 7.9915 18.9584 8.33317V12.4998C18.9584 17.0248 17.0251 18.9582 12.5001 18.9582Z"
              fill="#353535"
            />
            <path
              d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z"
              fill="#353535"
            />
            <path
              d="M10.8333 11.4585H5.83325C5.49159 11.4585 5.20825 11.1752 5.20825 10.8335C5.20825 10.4918 5.49159 10.2085 5.83325 10.2085H10.8333C11.1749 10.2085 11.4583 10.4918 11.4583 10.8335C11.4583 11.1752 11.1749 11.4585 10.8333 11.4585Z"
              fill="#353535"
            />
            <path
              d="M9.16659 14.7915H5.83325C5.49159 14.7915 5.20825 14.5082 5.20825 14.1665C5.20825 13.8248 5.49159 13.5415 5.83325 13.5415H9.16659C9.50825 13.5415 9.79159 13.8248 9.79159 14.1665C9.79159 14.5082 9.50825 14.7915 9.16659 14.7915Z"
              fill="#353535"
            />
          </svg>
          <div>PAN</div>
        </div>
        <div>
          <label htmlFor="uplaod3" className="" tabIndex={0}>
            <button
              className="d-flex p-1 align-items-center gap-2"
              style={{
                color: "#594DF1",
                border: "2px solid #594DF1",
                borderRadius: "10px",
                cursor:"pointer"
              }}
            >
              <div>
                <label htmlFor="upload3" tabIndex={0}>
                  <span className="d-none d-sm-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.3334 5.33333L8.00008 2L4.66675 5.33333"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 2V10"
                        stroke="#594DF1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                 {formik?.values?.pan_card ? "Update":"Upload"}   
                  </span>
                  <i className="bx bx-upload d-block d-sm-none" />
                  <input
                    type="file"
                    name="pan_card"
                    id="upload3"
                    className="account-file-input"
                    hidden="true"
                    accept="application/pdf"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "pan_card",
                        e.target.files[0]
                      )
                    }
                  />
                </label>
              </div>
            </button>
          </label>
        </div>
      </div> */}
      <div
        className="d-flex justify-content-between align-items-center p-2 mt-2"
        style={{ backgroundColor: "#F8F8FF" }}
      >
        <p style={{ color: "lightgrey" }}>Note: File must be in PDF Format, max size 5 MB</p>
        <button className="btn btn-primary" onClick={()=>setShow(false)} disabled={formik?.errors?.gst_certificate}>Save</button>
      </div>
    </Modal>
  );
};

export default UploadDocModal;
