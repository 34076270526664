import React, { useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import support1 from "../Images/email.png";
import support2 from "../Images/phone.png";
import banner from "../Images/supportBanner.png";
import support3 from "../Images/wp.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import supportBG from "../Images/imggg.png";
import { sendQueryToSupport } from "../services/Apiservices";

function Support() {
  const [Other, setOther] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const SUPPORTED_FORMATS = [
    "image/png",
    "image/jpeg",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for .xlsx files
  ];

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    files: Yup.mixed()
      .test("fileType", "Unsupported File Format", (value) => {
        if (!value) return true; // If no file is uploaded
        for (let i = 0; i < value.length; i++) {
          if (!SUPPORTED_FORMATS.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "File size is too large, it should be below 5MB",
        (value) => {
          const maxSize = 5 * 1024 * 1024; // 5MB size limit per file
          if (!value) return true; // If no file is uploaded
          for (let i = 0; i < value.length; i++) {
            console.log(value[i]?.size);
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      topic: "Wallet Balance",
      otherTopic: "",
      title: "",
      description: "",
      files: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      handleSendMessage(values);
    },
  });

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setOther(value === "Other");
    formik.setFieldValue("topic", value);
    if (value !== "Other") {
      formik.setFieldValue("otherTopic", "");
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    formik.setFieldValue("files", files);
  };
  const removeFile = (index) => {
    const updatedFiles = formik.values.files.filter((_, i) => i !== index);
    formik.setFieldValue("files", updatedFiles);
  };

  const handleSendMessage = async (values) => {
    setLoading(true);
    let body = {
      support_type:
        values?.otherTopic !== "" ? values?.otherTopic : values?.topic,
      subject: values?.title,
      msg: values?.description,
    };
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => formData.append(key, value));

    Object.entries(formik?.values?.files).forEach(([_, value]) =>
      formData.append("attachment", value)
    );
    try {
      let response = await sendQueryToSupport(formData);
      if (response?.status == 200) {
        setLoading(false);
        formik?.handleReset();
        setOther(false);
        setShowMsg(response?.data);
      } else {
        setShowMsg("");
        setLoading(false);
      }
    } catch (error) {
      setShowMsg("");
      setLoading(false);
    }
  };

  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;
    if (sizeInKB > 1000) {
      return (sizeInKB / 1024).toFixed(2) + " MB";
    } else {
      return sizeInKB.toFixed(2) + " KB";
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div
            className="d-flex justify-content-between mt-3 bg-white shadow mx-auto"
            style={{
              width: "90%",
              height: "42.5rem",
              borderRadius: "20px",
            }}
          >
            <div
              className="desktop support-bg"
              style={{
                position: "relative ",
              }}
            >
              <img
                src={supportBG}
                alt="Support Background"
                style={{
                  height: "100%",
                  objectFit: "cover",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "5rem",
                  left: "1rem",
                  right: "1rem",
                  zIndex: "20",
                }}
              >
                <h2
                  className="fw-bold"
                  style={{ lineHeight: "35px", color: "#170F49" }}
                >
                  Submit your Query
                </h2>
                <p style={{ lineHeight: "25px" }}>
                  Raise a Ticket and We'll Get Back to You Within 24 Hours!
                </p>
              </div>
            </div>
            <div
              className="bg-white px-5 pt-3"
              style={{
                width: " 100%",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              {showMsg && (
                <blockquote
                  className="callout callout_info align-center"
                  theme="➡️"
                >
                  <p className="text-center">
                    Ticket Id - {showMsg?.ticket_id}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: showMsg?.message }}
                  ></p>
                </blockquote>
              )}

              <form
                className="pt-5"
                id="formAuthentication"
                onSubmit={formik?.handleSubmit}
              >
                <div className="py-2">
                  <label
                    className="py-2"
                    htmlFor=""
                    style={{
                      color: "#594DF1",
                      fontSize: "19px",
                      fontWeight: "600",
                    }}
                  >
                    Topic
                  </label>
                  <div
                    style={{
                      width: "100%",
                      border: "2px solid lightgray",
                      borderRadius: "30px",
                      padding: "0px",
                    }}
                  >
                    <select
                      onChange={handleSelectChange}
                      className=" mx-auto "
                      style={{
                        width: "98%",
                        border: "0px solid lightgray",
                        borderRadius: "30px",
                        padding: "13px",
                        outline: "none",
                      }}
                      value={formik.values.topic}
                    >
                      <option
                        style={{ padding: "10px", borderRadius: "10px" }}
                        className="p-3 rounded"
                        value="Wallet Balance"
                      >
                        <span
                          style={{ padding: "10px", borderRadius: "10px" }}
                          className="p-3 rounded"
                        >
                          Wallet Balance
                        </span>
                      </option>
                      <option
                        style={{ padding: "10px", borderRadius: "10px" }}
                        className="p-3 rounded"
                        value="Unable to Redeem"
                      >
                        Unable to Redeem
                      </option>
                      <option
                        style={{ padding: "10px", borderRadius: "10px" }}
                        className="p-3 rounded"
                        value="Vouchers not Issued"
                      >
                        Vouchers not Issued
                      </option>
                      <option
                        style={{ padding: "10px", borderRadius: "10px" }}
                        className="p-3 rounded"
                        value="Other"
                        selected={Other}
                      >
                        Other
                      </option>
                    </select>
                  </div>
                  <div className="py-2">
                    <label
                      className="py-2"
                      htmlFor=""
                      style={{
                        color: "#594DF1",
                        fontSize: "19px",
                        fontWeight: "600",
                      }}
                    >
                      Title
                    </label>
                    <input
                      id="title"
                      name="title"
                      className=""
                      style={{
                        width: "100%",
                        border: "2px solid lightgray",
                        borderRadius: "30px",
                        padding: "13px",
                      }}
                      type="text"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                    <div className="text-danger">
                      {formik.errors.title && formik?.touched?.title
                        ? formik.errors.title
                        : null}
                    </div>
                  </div>
                  <div className="mt-2">
                    <label
                      className="py-2"
                      htmlFor=""
                      style={{
                        color: "#594DF1",
                        fontSize: "19px",
                        fontWeight: "600",
                      }}
                    >
                      Description
                    </label>
                    <div>
                      <textarea
                        id="description"
                        name="description"
                        className=""
                        style={{
                          width: "100%",
                          height: "9rem",
                          border: "2px solid lightgray",
                          borderRadius: "10px",
                          padding: "13px",
                        }}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                      ></textarea>
                      <div className="text-danger">
                        {formik.errors.description &&
                        formik?.touched?.description
                          ? formik.errors.description
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-danger">
                  {formik.errors.files && formik?.touched?.files
                    ? formik.errors.files
                    : null}
                </div>
                {formik?.values?.files?.length !== 0 && (
                  <div className="d-flex justify-content-end">
                    {formik?.values?.files?.map((data, index) => {
                      return (
                        <div
                          className="rounded-lg border  my-2  d-flex justify-content-between gap-2"
                          style={{
                            width: "fit-content",
                            borderRadius: "10px",
                            padding: "15px",
                          }}
                          key={index}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M12.5003 18.9584H7.50033C2.97533 18.9584 1.04199 17.0251 1.04199 12.5001V7.50008C1.04199 2.97508 2.97533 1.04175 7.50033 1.04175H11.667C12.0087 1.04175 12.292 1.32508 12.292 1.66675C12.292 2.00841 12.0087 2.29175 11.667 2.29175H7.50033C3.65866 2.29175 2.29199 3.65841 2.29199 7.50008V12.5001C2.29199 16.3417 3.65866 17.7084 7.50033 17.7084H12.5003C16.342 17.7084 17.7087 16.3417 17.7087 12.5001V8.33342C17.7087 7.99175 17.992 7.70842 18.3337 7.70842C18.6753 7.70842 18.9587 7.99175 18.9587 8.33342V12.5001C18.9587 17.0251 17.0253 18.9584 12.5003 18.9584Z"
                                fill="#353535"
                              />
                              <path
                                d="M18.3337 8.95841H15.0003C12.1503 8.95841 11.042 7.85007 11.042 5.00007V1.66674C11.042 1.41674 11.192 1.18341 11.4253 1.09174C11.6587 0.991739 11.9253 1.05007 12.1087 1.22507L18.7753 7.89174C18.9503 8.06674 19.0087 8.34174 18.9087 8.57507C18.8087 8.8084 18.5837 8.95841 18.3337 8.95841ZM12.292 3.17507V5.00007C12.292 7.15007 12.8503 7.70841 15.0003 7.70841H16.8253L12.292 3.17507Z"
                                fill="#353535"
                              />
                              <path
                                d="M10.833 11.4583H5.83301C5.49134 11.4583 5.20801 11.1749 5.20801 10.8333C5.20801 10.4916 5.49134 10.2083 5.83301 10.2083H10.833C11.1747 10.2083 11.458 10.4916 11.458 10.8333C11.458 11.1749 11.1747 11.4583 10.833 11.4583Z"
                                fill="#353535"
                              />
                              <path
                                d="M9.16634 14.7917H5.83301C5.49134 14.7917 5.20801 14.5084 5.20801 14.1667C5.20801 13.8251 5.49134 13.5417 5.83301 13.5417H9.16634C9.50801 13.5417 9.79134 13.8251 9.79134 14.1667C9.79134 14.5084 9.50801 14.7917 9.16634 14.7917Z"
                                fill="#353535"
                              />
                            </svg>{" "}
                            <span>{data?.name} </span>
                            <div>{formatFileSize(data.size)}</div>
                            {/* <div>{data?.size}</div> */}
                          </span>
                          <div onClick={() => removeFile(index)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M17.5001 5.60839C17.4834 5.60839 17.4584 5.60839 17.4334 5.60839C13.0251 5.16673 8.62505 5.00006 4.26672 5.44173L2.56672 5.60839C2.21672 5.64173 1.90839 5.39173 1.87505 5.04173C1.84172 4.69173 2.09172 4.39173 2.43339 4.35839L4.13339 4.19173C8.56672 3.74173 13.0584 3.91673 17.5584 4.35839C17.9001 4.39173 18.1501 4.70006 18.1167 5.04173C18.0917 5.36673 17.8167 5.60839 17.5001 5.60839Z"
                                fill="#353535"
                              />
                              <path
                                d="M7.08363 4.76675C7.05029 4.76675 7.01696 4.76675 6.97529 4.75842C6.64196 4.70008 6.40863 4.37508 6.46696 4.04175L6.65029 2.95008C6.78363 2.15008 6.96696 1.04175 8.90863 1.04175H11.092C13.042 1.04175 13.2253 2.19175 13.3503 2.95841L13.5336 4.04175C13.592 4.38341 13.3586 4.70842 13.0253 4.75842C12.6836 4.81675 12.3586 4.58342 12.3086 4.25008L12.1253 3.16675C12.0086 2.44175 11.9836 2.30008 11.1003 2.30008H8.91696C8.03363 2.30008 8.01696 2.41675 7.89196 3.15841L7.70029 4.24175C7.65029 4.55008 7.38363 4.76675 7.08363 4.76675Z"
                                fill="#353535"
                              />
                              <path
                                d="M12.675 18.9584H7.325C4.41666 18.9584 4.3 17.3501 4.20833 16.0501L3.66666 7.6584C3.64166 7.31673 3.90833 7.01673 4.25 6.99173C4.6 6.97506 4.89166 7.2334 4.91666 7.57506L5.45833 15.9667C5.55 17.2334 5.58333 17.7084 7.325 17.7084H12.675C14.425 17.7084 14.4583 17.2334 14.5417 15.9667L15.0833 7.57506C15.1083 7.2334 15.4083 6.97506 15.75 6.99173C16.0917 7.01673 16.3583 7.3084 16.3333 7.6584L15.7917 16.0501C15.7 17.3501 15.5833 18.9584 12.675 18.9584Z"
                                fill="#353535"
                              />
                              <path
                                d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z"
                                fill="#353535"
                              />
                              <path
                                d="M12.0837 11.0417H7.91699C7.57533 11.0417 7.29199 10.7584 7.29199 10.4167C7.29199 10.0751 7.57533 9.79175 7.91699 9.79175H12.0837C12.4253 9.79175 12.7087 10.0751 12.7087 10.4167C12.7087 10.7584 12.4253 11.0417 12.0837 11.0417Z"
                                fill="#353535"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="d-flex justify-content-end  align-items-center gap-2 mt-3">
                  {" "}
                  <input
                    id="file-upload"
                    name="files"
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    multiple
                    style={{ display: "none" }}
                  />
                  <label
                    className="d-flex justify-content-center gap-2 align-items-center px-2"
                    for="file-upload"
                    style={{
                      border: "2px solid lightgray",
                      borderRadius: "20px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      className=""
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M4.57467 11.9998C4.57467 10.4323 5.84884 9.15817 7.41634 9.15817H11.083V7.4165H7.41634C4.88634 7.4165 2.83301 9.46984 2.83301 11.9998C2.83301 14.5298 4.88634 16.5832 7.41634 16.5832H11.083V14.8415H7.41634C5.84884 14.8415 4.57467 13.5673 4.57467 11.9998ZM8.33301 12.9165H15.6663V11.0832H8.33301V12.9165ZM16.583 7.4165H12.9163V9.15817H16.583C18.1505 9.15817 19.4247 10.4323 19.4247 11.9998C19.4247 13.5673 18.1505 14.8415 16.583 14.8415H12.9163V16.5832H16.583C19.113 16.5832 21.1663 14.5298 21.1663 11.9998C21.1663 9.46984 19.113 7.4165 16.583 7.4165Z"
                        fill="black"
                        fill-opacity="0.54"
                      />
                    </svg>
                    <div>Attach files </div>
                  </label>
                  <span title="Upload file max 5MB in JPEG or PNG ,PDF ,CSV format.">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18px"
                      height="18px"
                    >
                      {" "}
                      <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                    </svg>
                  </span>
                  <button
                    className="btn p-2 px-4 btn-primary mx-2"
                    type="submit"
                    style={{ borderRadius: "25px", fontSize: "20px" }}
                    disabled={loading}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* Content wrapper */}
        </div>
        {/* / Layout page */}
      </div>
      {/* / Layout container */}
    </div>
  );
}

export default Support;
