import React from 'react'
import img from "../../Images/Notverified.png"

function EmailFailed() {
  return (
    <>
    <>
      <div
        className="container text-center mt-5"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // This will make the div take the full height of the viewport
        }}
      >
        <img
          src={img} // Replace with the URL of your image
          alt="Centered Image"
          style={{height:"50%"}} // Adjust the dimensions as needed
        />
        <div className="mt-3">
          <h5 style={{ lineHeight: 3 }}>
            Email verification failed.{" "}
          </h5>
          <p>We are sorry,something has gone wrong.Please try later</p>
        </div>
      </div>
    </>
    </>
  )
}

export default EmailFailed