import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";

function EmailSidebar({tempCat,setshowActive,showActive}) {
  const handleRemoveClass = () => {
    document.documentElement.classList.remove("layout-menu-expanded");
  };
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleRemoveClass();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
        ref={ref}
      >
        <div className="app-brand demo">
          <a className="app-brand-link">
            <span className="app-brand-text demo menu-text fw-bolder ms-2">
              Categories
            </span>
          </a>
          <a
            href="#"
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
            onClick={handleRemoveClass}
          >
            <i className="bx bx-chevron-left bx-sm align-middle" />
          </a>
        </div>
        <div className="menu-inner-shadow" />
        <ul className="menu-inner py-1">
          <li className={showActive=="all"?"menu-item active" :"menu-item" }onClick={()=>{setshowActive("all")}}>
            <Link className="menu-link" onClick={()=>handleRemoveClass()}>
              <div data-i18n="Analytics">All Templates</div>
            </Link>
          </li>
          {/* Layouts */}

          {
            tempCat?.length!==0 && tempCat?.map((item,index)=>{
              return(
                <li className={showActive==item?.id?"menu-item active" :"menu-item" }onClick={()=>setshowActive(item?.id)}>
          
                <Link className="menu-link menu-toggle" onClick={()=>handleRemoveClass()} >
                  <div data-i18n="Layouts">{item?.title}</div>
                </Link>  
              </li>
              )
            })
          }
       
         
         
        </ul>
      </div>
    </>
  );
}

export default EmailSidebar;
