import React, { useEffect, useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import { Link, useNavigate } from "react-router-dom";
import PaymentMsgModal from "./PaymentMsgModal";
import {
  getWalletBalance,
  addFunds,
  checkRefrence,
  getFundInvoice,
  addFundsOffline,
  getPerformaHistory,
  getPerformaList,
  getPerformaById,
} from "../services/Apiservices";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  compass,
  copyIcon,
  pencilIcon,
  restrictAlpha,
  walletIcon,
} from "../constant";
import Pagination from "./Pagination/Pagination";

let PageSize = 5;

function PerfomaInvoice() {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [balance, setBalance] = useState(0.0);
  const [details, setDetails] = useState(0.0);

  const [isValidRefNo, setIsValidRefNo] = useState(true);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.profile?.data?.data);
  const [history, setHistory] = useState([]);
  const [filterHistory, setFilterHistory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    applyPagination(history, currentPage, PageSize);
  }, [currentPage, PageSize, history]);
  const formik = useFormik({
    initialValues: {
      amount: "",
      refNo: "",
      email: userData?.email,
      mode: "Online",
      utr: "",
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(/^[0-9]+$/, "amount must contain only digits")
        .test(
          "min-value",
          "Please enter an amount greater than or equal to 10",
          function (value) {
            return parseInt(value, 10) >= 10;
          }
        )
        .required("Amount is required"),
      // refNo: Yup.string()
      //   .min(6, "Must be at least 15 characters")
      //   .required("RefNo is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )

        .required("Email is Required"),
    }),
    validateOnChange: true,

    onSubmit: (values) => {
      // You can handle the form submission here
      handlePaymentClick(values);
      // navigate("/admin/dashboard")
    },
  });

  useEffect(() => {
    if (userData?.company_added) {
      getHistory();
    }
  }, []);

  const getHistory = async () => {
    try {
      let response = await getPerformaHistory();
      let res = await getPerformaList();
      if (res?.status == 200) {
        setDetails(res?.data);
        setHistory(res?.data?.results || []);
      }

      if (response?.status == 200) {
        setBalance(response?.data?.balance);
      } else {
      }
    } catch (error) {}
  };

  const handlePaymentClick = async (values) => {
    setLoading(true);

    try {
      let response = await getFundInvoice(
        values?.amount,
        values?.refNo,
        values?.email
      );
      if (response?.status == 200) {
        formik.handleReset();
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to initiate download
        const link = document.createElement("a");
        link.href = url;
        link.download = "proforma_invoice.pdf";
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setLoading(false);
        getHistory();
      } else {
        setLoading(false);
      }
    } catch (error) {}
  };
  const handleDownload = async (id) => {
    try {
      let response = await getPerformaById(id);
      if (response?.status == 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to initiate download
        const link = document.createElement("a");
        link.href = url;
        link.download = `proforma_invoice_${id}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
      }
    } catch (error) {}
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  function copycode(text) {
    try {
      navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  }
  const applyPagination = (data, page, size) => {
    const startIndex = (page - 1) * size;
    const endIndex = startIndex + size;
    const paginatedData = data.slice(startIndex, endIndex);
    setFilterHistory(paginatedData);
  };

  const handleNavigate = () => {
    navigate("/admin/report", { state: "PI" });
  };

  const hasErrors =
    Object.keys(formik.errors).length > 0 &&
    Object.keys(formik.touched).length > 0;
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row  ">
                  <div className="col-12 col-lg-12 col-sm-12 col-md-12 ">
                    <div className="card h-80">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mt-3">
                          <h5 className="fw-semibold text-primary">
                            <span
                              className="text-muted fw-light "
                              style={{ lineHeight: "30px" }}
                            ></span>
                            Proforma Invoice{" "}
                          </h5>
                          <div>
                            <span className="fw-normal">
                              {" "}
                              {walletIcon}Balance
                            </span>
                            <div className="fw-bold">
                              <i className="bx bx-rupee" />

                              {balance}
                            </div>
                          </div>
                        </div>
                        <hr className="my-24" />

                        <form id="formAccountSettings">
                          <div className="row">
                            <div className="mb-3 col-md-4">
                              <label htmlFor="firstName" className="form-label">
                                Amount<span>*</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">₹</span>
                                </div>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="amount"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.amount}
                                  name="amount"
                                  autoFocus=""
                                  onKeyPress={restrictAlpha}
                                />
                              </div>

                              {formik.touched.amount && formik.errors.amount ? (
                                <div className="text-danger">
                                  {formik.errors.amount}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-4">
                              <label htmlFor="lastName" className="form-label">
                                PO Number (Optional)
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="refNo"
                                id="refNo"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.refNo}
                                maxLength={15}
                              />
                              {formik.touched.refNo && formik.errors.refNo ? (
                                <div className="text-danger">
                                  {formik.errors.refNo}
                                </div>
                              ) : null}
                              {!isValidRefNo && !formik.errors.refNo && (
                                <div className="text-danger">Not Available</div>
                              )}
                            </div>
                            <div className="mb-3 col-md-4">
                              <label htmlFor="emailId" className="form-label">
                                Email Id<span>*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control withoutborder"
                                  type="text"
                                  name="email"
                                  id="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                />
                                <span className="input-group-text noleft ">
                                  {pencilIcon}
                                </span>
                              </div>

                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div></div>

                          <div className="mt-4 d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-primary  "
                              disabled={loading || hasErrors}
                              onClick={() => {
                                formik?.handleSubmit();
                              }}
                            >
                              <i className="menu-icon tf-icons bx bx-receipt" />
                              Generate PI
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-12 col-sm-12 col-md-12 mt-4">
                    <div className="card h-100 ">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h5 className="card-title text-primary fw-semibold" style={{lineHeight:"34px"}}>
                            {compass} Proforma Invoice{" "}
                          </h5>
                          <span
                            onClick={handleNavigate}
                            style={{ color: "#696cff", cursor: "pointer" }}
                          >
                            View More
                          </span>
                        </div>
                        <div className="row" style={{ overflowX: "auto" }}>
                          <div className="col-12">
                            {filterHistory && filterHistory?.length !== 0 ? (
                              <table className="table" >
                                <thead
                                  style={{
                                    backgroundColor: "#B2AEFF",
                                    color: "#111827",
                                      
                                    alignItems: "center !important",
                                  }}
                                >
                                  <tr>
                                    <th scope="col">PI No.</th>

                                    <th scope="col">Date</th>
                                    <th scope="col">Amount</th>

                                    <th scope="col">PO Number</th>
                                    <th scope="col">Email Id</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filterHistory?.map((data, index) => {
                                    return (
                                      <tr key={data?.id}>
                                        <td>{data?.pis_id}</td>

                                        <th scope="row" className="fw-normal">
                                          {" "}
                                          {moment(data?.created_at)?.format(
                                            "ll"
                                          )}
                                        </th>

                                        <td>₹ {data?.amount}</td>
                                        <td>
                                          {data?.po_number ? (
                                            <>
                                              {data?.po_number}{" "}
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  copycode(data?.po_number)
                                                }
                                              >
                                                {copyIcon}
                                              </span>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </td>

                                        <td>
                                          {data?.email ? data?.email : "-"}
                                        </td>
                                        <td>
                                          <span
                                            onClick={() =>
                                              handleDownload(data?.id)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <svg
                                              width="30"
                                              height="29"
                                              viewBox="0 0 30 29"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                x="0.166504"
                                                width="29"
                                                height="29"
                                                rx="14.5"
                                                fill="#FFF5F6"
                                              />
                                              <g clip-path="url(#clip0_2215_17207)">
                                                <path
                                                  d="M18.3577 14.3287C18.2906 14.1804 18.1431 14.086 17.9807 14.086H16.3236V8.70024C16.3236 8.47156 16.138 8.28596 15.9093 8.28596H14.2521C14.0234 8.28596 13.8378 8.47156 13.8378 8.70024V14.086H12.1807C12.0183 14.086 11.8708 14.1812 11.8037 14.3287C11.7358 14.477 11.7623 14.6502 11.8692 14.7728L14.7692 18.0871C14.8479 18.1774 14.9614 18.2288 15.0807 18.2288C15.2 18.2288 15.3135 18.1766 15.3922 18.0871L18.2922 14.7728C18.4 14.651 18.4248 14.477 18.3577 14.3287Z"
                                                  fill="#594DF1"
                                                />
                                                <path
                                                  d="M19.6379 17.4002V19.886H10.5236V17.4002H8.86646V20.7145C8.86646 21.1727 9.23766 21.5431 9.69503 21.5431H20.4665C20.9247 21.5431 21.295 21.1727 21.295 20.7145V17.4002H19.6379Z"
                                                  fill="#594DF1"
                                                />
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_2215_17207">
                                                  <rect
                                                    width="13.2571"
                                                    height="13.2571"
                                                    fill="white"
                                                    transform="translate(8.45215 8.28596)"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <>
                                <li className="d-flex justify-content-center align-items-center mt-4">
                                  <strong>No Recent Activity Found!</strong>
                                </li>
                              </>
                            )}
                            {/* <div className="d-flex justify-content-center mt-4">
                              <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={history?.length}
                                pageSize={PageSize}
                                onPageChange={(page) => {
                                  setCurrentPage(page);
                                }}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Content wrapper */}
            </div>
            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
      <PaymentMsgModal show={showModal} onHide={handleCloseModal} />
    </>
  );
}

export default PerfomaInvoice;
