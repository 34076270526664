import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAllTemplates } from "../../services/Apiservices"




export const getTemplate = createAsyncThunk(
    'template/allTemp',
    async (arg, { rejectWithValue }) => {
      try {
        const response = await getAllTemplates()
        return response?.data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )