import * as api from "../request";

export const register = async (body) => {
  return await api.PostReq(`/api/auth/register`, body);
};
export const loginWithOTP = async (body) => {
  return await api.PostReq(`/api/auth/login-with-otp`, body);
};
export const loginWithPass = async (body) => {
  return await api.PostReq(`/api/auth/login-with-password`, body);
};
export const loginWithEmail = async (body) => {
  return await api.PostReq(`/api/auth/login-with-email`, body);
};
export const verifyOTP = async (body) => {
  return await api.PostReq(`/api/auth/verify-signup-otp`, body);
};
export const resendOTP = async (body) => {
  return await api.PostReq(`/api/auth/signup-resend-otp`, body);
};
export const resendLoginOTP = async (body) => {
  return await api.PostReq(`/api/auth/login-resend-otp`, body);
};
export const editProfile = async (body) => {
  return await api.PostReq(`/api/auth/user-profile`, body);
};
export const getUserProfile = async () => {
  return await api.GetReq(`/api/auth/user-profile`);
};
export const forgotPassowrd = async (body) => {
  return await api.PostReq(`/api/auth/forgot-password`, body);
};
export const resetPassowrd = async (body) => {
  return await api.PostReq(`/api/auth/reset-password`, body);
};
export const changePassword = async (body) => {
  return await api.PostReq(`/api/auth/change-password`, body);
};
export const addCompanyDetails = async (body) => {
  return await api.PostReq(`/api/company/`, body);
};
export const getCompanyDetails = async (body) => {
  return await api.GetReq(`/api/company/`, body);
};
export const verifyEmail = async () => {
  return await api.GetReq(`/api/auth/verify-email`);
};
export const uploadDocuments = async (body) => {
  return await api.PostReq(`/api/company/upload-docs`, body);
};

export const searchUser = async (search) => {
  return await api.GetReq(`/api/company/search-user/${search}`);
};
export const companyPermission = async (search) => {
  return await api.GetReq(`/api/company/all-perms`);
};
export const addAdmin = async (body) => {
  return await api.PostReq(`/api/company/member`, body);
};
export const companyMemberList = async () => {
  return await api.GetReq(`/api/company/member`);
};
export const invitationList = async () => {
  return await api.GetReq(`/api/company/invitation`);
};
export const deleteInvitation = async (id) => {
  return await api.delReq(`/api/company/invitation/${id}`);
};
export const companyVerification = async () => {
  return await api.GetReq(`/api/company/submit-verfication`);
};
export const getEmailOTP = async () => {
  return await api.GetReq(`/api/company/send-email-otp`);
};
export const verifyEmailOTP = async (body) => {
  return await api.PostReq(`/api/company/verify-email-otp`, body);
};
export const getMobileOTP = async () => {
  return await api.GetReq(`/api/company/send-mobile-otp`);
};
export const verifyMobileOTP = async (body) => {
  return await api.PostReq(`/api/company/verify-mobile-otp`, body);
};
export const updateCompany = async (body) => {
  return await api.PatchReq(`/api/company/`, body);
};
export const getSingleMember = async (id) => {
  return await api.GetReq(`/api/company/member/${id}`);
};
export const updateCompanyMember = async (body, id) => {
  return await api.PatchReq(`/api/company/member/${id}`, body);
};
export const updateInviteMember = async (body, id) => {
  return await api.PatchReq(`/api/company/invitation/${id}`, body);
};
export const deleteCompanyMember = async (id) => {
  return await api.delReq(`/api/company/member/${id}`);
};
export const getWalletBalance = async () => {
  return await api.GetReq(`/api/wallet/wallet-details`);
};
export const getPerformaHistory = async () => {
  return await api.GetReq(`/api/wallet/offline-fund-history`);
};
export const getPerformaList = async (limit,offset,filters) => {
  return await api.GetReq(`/api/wallet/proforma-invoice-list?limit=${limit?limit:""}&offset=${offset?offset:''}&pi_id=${filters?.pi_id?filters?.pi_id:""}&po_number=${filters?.po_number?filters?.po_number:""}&email=${filters?.email?filters?.email:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`);
};
export const exportPIReport = async (filters) => {
  return await api.getReq(`/api/wallet/download-proforma-invoice-list?pi_id=${filters?.pi_id?filters?.pi_id:""}&po_number=${filters?.po_number?filters?.po_number:""}&email=${filters?.email?filters?.email:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`);
};
export const exportInvoiceReport = async (filters) => {
  return await api.getReq(`/api/wallet/download-all-invoices?inv_id=${filters?.inv_id?filters?.inv_id:""}&po_number=${filters?.po_number?filters?.po_number:""}&email=${filters?.email?filters?.email:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`);
};
export const getPerformaById = async (id) => {
  return await api.getReq(`/api/wallet/download-previous-pi?inv_id=${id}`);
};
export const addFunds = async (body) => {
  return await api.PostReq(`api/wallet/add-funds`, body);
};
export const checkRefrence = async (value) => {
  return await api.GetReq(`/api/wallet/check-ref-no/${value}`);
};
export const getAllVoucher = async () => {
  return await api.GetReq(`/api/brands/products`);
};
export const getAllVoucherCat = async (pageSize, offset, search,catID) => {
  return await api.GetReq(
    `/api/brands/catlog?limit=${search ? "" : pageSize}&offset=${
      search ? "" : offset
    }&query=${search}&category_id=${catID}`
  );
};
export const getCategories = async () => {
  return await api.GetReq(`/api/brands/categories`);
};
export const getAllTemplates = async () => {
  return await api.GetReq(`/api/rewards/image-templates`);
};
export const createOrder = async (body) => {
  return await api.PostReq(`/api/rewards/create-order`, body);
};
export const createSelfOrder = async (body) => {
  return await api.PostReq(`/api/rewards/create-self-order`, body);
};
export const getSimpleReport = async (limit, offset, filters) => {
  return await api.GetReq(
    `/api/rewards/transaction-report/?limit=${limit}&offset=${offset}&txn_id=${filters?.txn_id?filters?.txn_id:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`
  );
};
export const exportTansactionReport = async (filters) => {
  return await api.getReq(`/api/rewards/download-transactions?txn_id=${filters?.txn_id?filters?.txn_id:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`);
};
export const getInvoiceReport = async (limit,offset,filters) => {
  return await api.GetReq(`/api/wallet/all-invoice-api?limit=${limit}&offset=${offset}&inv_id=${filters?.inv_id?filters?.inv_id:""}&po_number=${filters?.po_number?filters?.po_number:""}&email=${filters?.email?filters?.email:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`);
};
export const getBalanceForRewards = async () => {
  return await api.GetReq(`/api/wallet/wallet-balance`);
};
export const getDashboardData = async () => {
  return await api.GetReq(`/api/rewards/dashboard`);
};
export const getRewardsReport = async (limit, offset,filters) => {
  return await api.GetReq(`/api/rewards/order-report/?limit=${limit}&offset=${offset}&txn_id=${filters?.txn_id?filters?.txn_id:""}&order_id=${filters?.order_id?filters?.order_id:""}&name=${filters?.name?filters?.name:""}&email=${filters?.email?filters?.email:""}&mobile_no=${filters?.mobile_no?filters?.mobile_no:""}&brand_id=${filters?.brand_id?filters?.brand_id:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`);
};
export const exportRewardReport = async (filters) => {
  return await api.getReq(`/api/rewards/download-order-report?txn_id=${filters?.txn_id?filters?.txn_id:""}&order_id=${filters?.order_id?filters?.order_id:""}&name=${filters?.name?filters?.name:""}&email=${filters?.email?filters?.email:""}&mobile_no=${filters?.mobile_no?filters?.mobile_no:""}&brand_id=${filters?.brand_id?filters?.brand_id:""}&status=${filters?.status?filters?.status:""}&created_from=${filters?.created_from?filters?.created_from:""}&created_to=${filters?.created_to?filters?.created_to:""}`);
};
export const getTemplateCategorires = async () => {
  return await api.GetReq(`/api/rewards/categories`);
};
export const createBulkOrder = async (body) => {
  return await api.PostReq(`/api/rewards/create-bulk-order`, body);
};
export const getBulkOrder = async (body) => {
  return await api.PostReq(`/api/rewards/verify-bulk-order`,body);
};
export const getFundInvoice = async (amount, refNo, email) => {
  return await api.getReq(
    `/api/wallet/create-pro-forma-invoice?amount=${amount}&ref_no=${refNo}&email=${email}`
  );
};
export const addFundsOffline = async (body) => {
  return await api.PostReq(`/api/wallet/create-pro-forma-invoice`, body);
};
export const getAdminEmail = async () => {
  return await api.GetReq(`/api/rewards/admin-email-list`);
};
export const getInvoice = async (id) => {
  return await api.getReq(`/api/wallet/download-invoice/${id}`);
};
export const getFundReport = async (id) => {
  return await api.getReq(`/api/wallet/download-fund-report/${id}`);
};

export const sendQueryToSupport = async (body) => {
  return await api.PostReq(`/api/support/create`, body);
};
export const sendEmailForRewards = async (id) => {
  return await api.GetReq(`/api/rewards/resend-order-email?recipient_id=${id}
  `);
};
export const getCompanyIndustry = async () => {
  return await api.GetReq(`/api/company/industry-type`);
};
export const getInvitationOtp = async (body) => {
  return await api.PostReq(`api/company/verify-mobile`, body);
};
export const getInvitationConfirmOtp = async (body) => {
  return await api.PostReq(`api/company/confirm-otp`, body);
};