import React, { useEffect, useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import { addAdmin, companyPermission, getSingleMember, searchUser, updateCompanyMember, updateInviteMember } from "../services/Apiservices";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select from "react-select";

import showNotification from "../services/NotificationService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { restrictNumeric } from "../constant";

function AddAdmin() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("")
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userDetail, setUserDetail] = useState("")

  const [permission, setPermission] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate()
  const { id } = useParams();
  const location=useLocation()

  const initialValues = {
    role_id: '',
    name: '',
    email: '',
    department:"",
    permissions: [], // Initially an empty array
  };

  // Define the validation schema using Yup
  const validationSchema = Yup.object({
    role_id: Yup.string()
      .required('Role ID is required'),
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters long')
      .max(50, 'Name can be at most 50 characters long'),
    email: Yup.string()
      .email()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ).required('Email is required'),
      department: Yup.string()
      .required('Department is required'),
    permissions: Yup.array()
      .min(1, 'At least one permission is required')
      .of(Yup.string().required('Permission is required')),
  });

  useEffect(() => {
    getPermission();
    if (id) {
      getMemberDetail()
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      // Handle form submission
      handleSubmit(values)
    },
  });
  // Event handler for radio button selection
  const handleSelectOption = (option) => {
    if (formik?.values?.permissions?.includes(option)) {
      // If the option is already selected, remove it
      const updatedPermissions = formik?.values?.permissions?.filter((item) => item !== option);
      formik.setFieldValue('permissions', updatedPermissions);
    } else {
      // If the option is not selected, add it
      const updatedPermissions = [...formik?.values?.permissions, option];
      formik.setFieldValue('permissions', updatedPermissions);
    }
  };
  console.log(formik?.values)



  const getPermission = async () => {
    setIsLoading(true);
    try {
      const response = await companyPermission();
      // Assuming API returns an array of user objects

      if (response?.status == 200) {
        setPermission(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleSubmit = async (values) => {
    // Perform form submission only if the validation passes
    if (id) {
      setIsLoading(true);

      let body = {
        role_id: values?.role_id,
        permissions: values?.permissions,
        department:values?.department
      }
      try {
        const response = location?.state=="invite"?await updateInviteMember(body, id):await updateCompanyMember(body, id);
        // Assuming API returns an array of user objects

        if (response?.status == 200) {
          // showNotification("success", response?.data?.message)
          if(location?.state=="invite"){
            navigate('/admin/invitations')

          }else{
          navigate('/admin/manage-admins')

          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);

      let body = {
        user_id: values?.email,
        email: values?.email,
        name: values?.name,
        role_id: values?.role_id,
        permissions: values?.permissions,
        department:values?.department

      }
      try {
        const response = await addAdmin(body);
        // Assuming API returns an array of user objects

        if (response?.status == 200) {
          showNotification("success", response?.data?.message)
          navigate('/admin/invitations')
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getMemberDetail = async () => {
    setIsLoading(true);
    try {
      const response = await getSingleMember(id);
      // Assuming API returns an array of user objects

      if (response?.status == 200) {
        setUserDetail(response?.data?.data)
        formik?.setFieldValue("permissions", response?.data?.data?.permissions?.map((item) => item?.id))
        formik?.setFieldValue("email", response?.data?.data?.email)
        formik?.setFieldValue("role_id", response?.data?.data?.role)
        formik?.setFieldValue("name", response?.data?.data?.name)
        formik?.setFieldValue("department", response?.data?.data?.department)



        // setSelectedUser({
        //   label: response?.data?.data?.full_name,
        //   value: response?.data?.data?.full_name
        // })
        // setSelectedRole(response?.data?.data?.role)
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRoleIdChange = (e) => {
    const { value } = e.target; // Extract the new value of role_id
    formik.setFieldValue('role_id', value); // Set the new role_id value
    // Update permissions based on the new role_id value
    const newPermissions = value === '1' ? [1,2,3,4,5] : [];
    formik.setFieldValue('permissions', newPermissions);
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}
              <div className="container-xxl flex-grow-1 container-p-y">

                <div className="row mt-2">
                  <div className="col-12 col-lg-12 ">
                    <div className="card h-80">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mt-3">
                          <h5 className="fw-semibold text-primary">
                            <span className="text-muted fw-light "></span>
                            Add Admin{" "}
                          </h5>
                          <div>
                          </div>
                        </div>
                        <hr className="my-24" />

                        <form id="formAccountSettings">
                          <div className="row">
                            <div className="mb-3 col-md-4">
                              <label htmlFor="firstName" className="form-label">
                                Name<span>*</span>
                              </label>
                              <div className="input-group">

                                <input
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  type="text"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.name}
                                  disabled={id?true:false}
                                  onKeyPress={restrictNumeric}
                                />
                              </div>

                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-4">
                              <label htmlFor="lastName" className="form-label">
                                Email<span>*</span>
                              </label>
                              <input
                                className="form-control"
                                id="email"
                                name="email"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                disabled={id?true:false}

                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">
                                  {formik.errors.email}
                                </div>
                              ) : <></>}
                            </div>
                            <div className="mb-3 col-md-4">
                              <div>
                                <label
                                  className="form-label"
                                  htmlFor="Role"
                                >
                                  Role<span>*</span>
                                </label>
                              </div>
                              <select
                                className="form-select"
                                id="role_id"
                                name="role_id"

                                onChange={handleRoleIdChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.role_id}
                              // onChange={formik?.handleChange}
                              >
                                <option value="">
                                  Select Role
                                </option>
                                <option value="1">
                                  Super Admin
                                </option>
                                <option value="2">
                                  Admin
                                </option>
                              </select>
                              {formik.touched.role_id &&
                                formik.errors.role_id ? (
                                <div className="text-danger">
                                  {formik.errors.role_id}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-4">
                              <div>
                                <label
                                  className="form-label"
                                  htmlFor="Department"
                                >
                                  Department<span>*</span>
                                </label>
                              </div>
                              <select
                                className="form-select"
                                id="department"
                                name="department"

                                onChange={formik?.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.department}
                            
                              >
                                <option value="">
                                  Select Department
                                </option>
                                <option value="1">
                                  Admin
                                </option>
                                <option value="2">
                                  HR
                                </option>
                                <option value="3">
                                  Operation
                                </option>
                                <option value="4">
                                  Finance
                                </option>
                                <option value="5">
                                  Other
                                </option>
                              </select>
                              {formik.touched.department &&
                                formik.errors.department ? (
                                <div className="text-danger">
                                  {formik.errors.department}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div></div>



                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-lg-12 ">
                    <div className="card h-80">
                      <div className="card-body">
                        <div className="d-flex w-100">
                          <div className="w-50">
                            <h4 className="text-primary">Select Access Level.</h4>
                            <p>
                              Select the level of access you want to grant this
                              admin.
                            </p>
                          </div>
                          <div className="w-50">
                            {permission?.length !== 0 ? (
                              <>
                                {permission?.map((data, index) => {
                                  return (
                                    <div
                                      className="form-check mt-3"
                                      key={index}
                                    >
                                      <input
                                        name="default-radio-1"
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        id={`defaultRadio${data?.id}`}
                                        value={data?.id}
                                        disabled={formik?.values?.role_id==1 || (formik?.values?.role_id!=="" &&[2,4].includes(data?.id)) }
                                        checked={formik?.values?.permissions?.includes(data?.id)}
                                        onChange={() => handleSelectOption(data?.id)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="defaultRadio1"
                                      >
                                        {" "}
                                        {data?.permission}{" "}
                                      </label>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {formik.touched.permissions && formik.errors.permissions ? (
                              <div className="text-danger mt-2">
                                {formik.errors.permissions}
                              </div>
                            ) : null}
                            <div className="mt-4 d-flex justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary me-2"
                                onClick={formik?.handleSubmit}
                              >
                                {id ? "Update" : "Submit"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Content wrapper */}
            </div>
            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}

export default AddAdmin;
