import React, { useEffect, useState } from "react";
import EmailSidebar from "./CommonComponents/EmailSidebar";
import EmailNavbar from "./CommonComponents/EmailNavbar";
import { useDispatch, useSelector } from "react-redux";
import { getTemplate } from "../redux/actions/templateActions";
import { BASE_URL } from "../constant";
import multi from "../Images/multi.png";
import logo from "../CredLogo.png";
import { getTemplateCategorires } from "../services/Apiservices";

function AllTemplate(props) {
  const { setShowExplore, setTemplateId, templateId, setTempImg, tempImg } =
    props;
  const [previewMobile, setPreviewMobile] = useState(false);
  const [tempCat, setTempCat] = useState([]);
  const [showActive, setshowActive] = useState("all");

  const dispatch = useDispatch();
  const templateList = useSelector((state) => state?.template?.data?.data);
  const filterTemplate =
    showActive === "all"
      ? templateList
      : templateList?.filter((item) => item?.category?.id == showActive);
  const firstElement = templateList?.filter((_, index) => index === 0)?.shift();

  const handleScroll = (isEnabled) => {
    if (isEnabled) {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      // Disable scrolling
      // Add padding-top equivalent to the scrollbar width to prevent page from shifting
    }
  };

  // Run this effect when the component mounts
  useEffect(() => {
    // Disable scroll when the component mounts
    handleScroll(false);

    // Re-enable scroll when the component unmounts
    return () => {
      handleScroll(true);
    };
  }, []);

  useEffect(() => {
    dispatch(getTemplate());
    getTempCategory();
    if (templateId == "") {
      setTemplateId(firstElement?.id);
    }
    if (tempImg == "") {
      setTempImg(firstElement?.image);
    }
  }, []);

  const getTempCategory = async () => {
    try {
      let response = await getTemplateCategorires();
      if (response?.status == 200) {
        setTempCat(response?.data?.data);
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar ">
        <div className="layout-container">
          {/* Menu */}
          <EmailSidebar
            tempCat={tempCat}
            setshowActive={setshowActive}
            showActive={showActive}
          />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <EmailNavbar setShowExplore={setShowExplore} />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper d-flex">
              {/* Content */}
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-md-3 allsection ">
                    {filterTemplate?.length !== 0 ? (
                      <>
                        {" "}
                        {filterTemplate?.map((item, index) => {
                          return (
                            <div
                              className="sc-hbGONZ bVvKaH  mb-16 false"
                              key={index}
                            >
                              <div>
                                <div
                                  className="d-flex flex-column voucher-card-campaign banner-card emailTemplate amazonIndProduct
    
    "
                                  style={{
                                    color: "rgb(0, 0, 0)",
                                    position: "relative",
                                  }}
                                >
                                  <div className="banner-card-img">
                                    <div
                                      className="sc-CWzKr kAjLlE"
                                      style={{
                                        height: 120,
                                        backgroundImage: `url(${
                                          BASE_URL + item?.image
                                        })`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                        borderRadius: 8,
                                      }}
                                      onClick={() => {
                                        setTemplateId(item?.id);
                                        setTempImg(item?.image);
                                      }}
                                    />
                                    <span
                                      style={{
                                        top: 12,
                                        right: 12,
                                        position: "absolute",
                                      }}
                                    >
                                      {templateId == item?.id && (
                                        <span className="sc-beySbM jRCUXG ml-auto">
                                          <svg
                                            width={23}
                                            height={23}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="2.50049"
                                              y="2.50049"
                                              width={19}
                                              height={19}
                                              rx="9.5"
                                              fill="#565ADD"
                                              stroke="white"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M16.6161 8.68728C16.8606 8.93178 16.8606 9.3282 16.6161 9.5727L10.877 15.3118C10.6325 15.5563 10.236 15.5563 9.99153 15.3118L7.38284 12.7031C7.13834 12.4586 7.13834 12.0622 7.38284 11.8177C7.62734 11.5732 8.02376 11.5732 8.26826 11.8177L10.4342 13.9837L15.7307 8.68728C15.9752 8.44278 16.3716 8.44278 16.6161 8.68728Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="mt-4">
                                    <p
                                      color="dark"
                                      className="sc-dmyDGi jpYxZF"
                                    >
                                      {item?.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div className="text-center">No Template Found!</div>
                      </>
                    )}
                  </div>
                  <div className="col-md-9 allsection">
                    <div className="tab-content abc">
                      <div className="tab-pane active">
                        <div className="row email-preview  justify-content-center">
                          <div className="col-12 ">
                            <div className="emailTempBckground">
                              <div className="previewMode"></div>
                              <div
                                className={
                                  previewMobile
                                    ? "emailTemplateSection border1 p-0 my-0 mx-auto bg-white mobile"
                                    : "emailTemplateSection border1 p-0 my-0 mx-auto bg-white"
                                }
                              >
                                <div className="justify-content-center row">
                                  <div className="col-12">
                                    <div className="logoSection position-relative">
                                      <div className="logoPreview">
                                        <div className="">
                                          <img
                                            className="img logo-template-image"
                                            src={logo}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="justify-content-center row">
                                  <div className="col-12">
                                    <div className="position-relative mailBannerImage">
                                      <div className="showMask h-100">
                                        <div className="editBtn d-flex align-items-center justify-content-end pr-4">
                                          <span className="cursor-pointer">
                                            <svg
                                              width={20}
                                              height={20}
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M16.5325 16.2791H8.85382L15.1332 9.99969L15.1336 9.99936L15.1339 9.99899L17.1425 7.9904C17.2558 7.87711 17.3457 7.74259 17.4071 7.59455C17.4684 7.4465 17.5 7.28783 17.5 7.12758C17.5 6.96733 17.4684 6.80865 17.4071 6.6606C17.3458 6.51255 17.2559 6.37803 17.1426 6.26472L13.7346 2.85669C13.5056 2.62826 13.1953 2.49999 12.8718 2.5C12.5483 2.50001 12.238 2.62832 12.009 2.85677L2.85734 12.0084C2.73885 12.1269 2.6461 12.2686 2.58492 12.4246C2.57865 12.4398 2.57336 12.4553 2.56837 12.4709C2.52336 12.5996 2.50025 12.7349 2.5 12.8712V16.2791C2.50037 16.6027 2.62905 16.9128 2.8578 17.1416C3.08656 17.3703 3.39671 17.499 3.72022 17.4994H16.5325C16.6943 17.4994 16.8495 17.4351 16.9639 17.3207C17.0783 17.2062 17.1426 17.0511 17.1426 16.8893C17.1426 16.7274 17.0783 16.5723 16.9639 16.4578C16.8495 16.3434 16.6943 16.2791 16.5325 16.2791ZM12.8718 3.71957L16.2797 7.12752L14.7022 8.70508L11.2942 5.2972L12.8718 3.71957Z"
                                                fill="#28A138"
                                              />
                                            </svg>
                                            <span
                                              className="ml-2 fw-500 text-white"
                                              onClick={() =>
                                                setShowExplore(true)
                                              }
                                            >
                                              Edit Banner
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="voucher-image-preview text-center">
                                        <div className="">
                                          <img
                                            className="img img-fluid voucher-template-image"
                                            src={
                                              tempImg != ""
                                                ? BASE_URL + tempImg
                                                : BASE_URL + firstElement?.image
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-12">
                                    <div className="position-relative  ">
                                      <div
                                        style={{
                                          marginBottom: 12,
                                          textAlign: "center",
                                        }}
                                      >
                                        <span
                                          className="inkNormal fw-bold"
                                          style={{ color: "#5A4EF1" }}
                                        >
                                          Congratulations 🥳
                                        </span>
                                      </div>
                                      <div className="inkNormal emailBody">
                                        <div className="plumProDistributionEmailBody text-center">
                                          <div>Recipent Name</div>

                                          <div>
                                            You've got a E-gift Card Voucher.
                                          </div>

                                          <div
                                            className="overlayBanner align-items-center justify-content-center px-3"
                                            style={{ top: 40 }}
                                          >
                                            <div className="mr-2">
                                              <svg
                                                width={20}
                                                height={20}
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10 2C8.41775 2 6.87103 2.46919 5.55544 3.34824C4.23985 4.22729 3.21447 5.47672 2.60897 6.93853C2.00347 8.40034 1.84504 10.0089 2.15372 11.5607C2.4624 13.1126 3.22433 14.538 4.34315 15.6569C5.46197 16.7757 6.88743 17.5376 8.43928 17.8463C9.99113 18.155 11.5997 17.9965 13.0615 17.391C14.5233 16.7855 15.7727 15.7602 16.6518 14.4446C17.5308 13.129 18 11.5822 18 10C17.9975 7.87902 17.1539 5.84561 15.6541 4.34585C14.1544 2.84609 12.121 2.00245 10 2ZM9.99992 5.69231C10.1825 5.69231 10.361 5.74644 10.5128 5.84787C10.6646 5.9493 10.7829 6.09347 10.8527 6.26214C10.9226 6.43081 10.9409 6.61641 10.9053 6.79547C10.8696 6.97453 10.7817 7.139 10.6526 7.2681C10.5235 7.39719 10.3591 7.48511 10.18 7.52072C10.0009 7.55634 9.81535 7.53806 9.64668 7.46819C9.47801 7.39833 9.33384 7.28002 9.23241 7.12822C9.13099 6.97642 9.07685 6.79795 9.07685 6.61538C9.07685 6.49416 9.10072 6.37413 9.14711 6.26214C9.1935 6.15014 9.2615 6.04838 9.34721 5.96267C9.43293 5.87695 9.53469 5.80896 9.64668 5.76257C9.75867 5.71618 9.87871 5.69231 9.99993 5.69231H9.99992ZM10.6154 14.3077H10C9.91918 14.3077 9.83913 14.2918 9.76445 14.2609C9.68977 14.23 9.62191 14.1847 9.56476 14.1276C9.5076 14.0704 9.46228 14.0025 9.43137 13.9279C9.40046 13.8532 9.38457 13.7731 9.38462 13.6923V10C9.22141 10 9.06488 9.93516 8.94947 9.81976C8.83407 9.70435 8.76923 9.54782 8.76923 9.38461C8.76923 9.2214 8.83407 9.06488 8.94947 8.94947C9.06488 8.83406 9.22141 8.76923 9.38462 8.76923H10C10.0808 8.76918 10.1609 8.78507 10.2356 8.81598C10.3102 8.84689 10.3781 8.89221 10.4352 8.94937C10.4924 9.00652 10.5377 9.07438 10.5686 9.14906C10.5995 9.22374 10.6154 9.30379 10.6154 9.38461V13.0769C10.7786 13.0769 10.9351 13.1418 11.0505 13.2572C11.1659 13.3726 11.2308 13.5291 11.2308 13.6923C11.2308 13.8555 11.1659 14.012 11.0505 14.1274C10.9351 14.2429 10.7786 14.3077 10.6154 14.3077Z"
                                                  fill="white"
                                                />
                                              </svg>
                                            </div>
                                            <div>
                                              Message to the recipient will
                                              overwrite the default message
                                              here.
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="justify-content-center row mt-2">
                                  {/* Order Details: */}
                                  <div className="col-12">
                                    <div className=" m-1 text-center inkNormal">
                                      <div className="rewardData mt-3 ">
                                        <div className="fw-bold">
                                          Order Details
                                        </div>
                                        <hr />

                                        <div className="d-flex align-items-center justify-content-around ">
                                          <div className="my-2">
                                            <img width={120} src={multi} />
                                          </div>
                                          <div
                                            className="d-flex justify-content-between gap-5"
                                            style={{ border: "none" }}
                                          >
                                            <div>
                                              <h6>Qty</h6>
                                              <p>2222</p>
                                            </div>
                                            <div>
                                              <h6>Value</h6>
                                              <p> 3333</p>
                                            </div>
                                            <div>
                                              <h6>Validity</h6>
                                              <p>******</p>
                                            </div>
                                            {/* <table
                                    className="table "
                                    style={{ border: "none" }}
                                  >
                                    <thead
                                      className=""
                                      style={{ border: "none" }}
                                    >
                                      <tr style={{ border: "none" }}>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Validity</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ border: "none" }}>
                                      <tr style={{ border: "none" }}>
                                        <td>{item?.numberOfVouchers}</td>
                                        <td>
                                          {" "}
                                          {item?.voucherValue?.value
                                            ? item?.voucherValue?.value
                                            : item?.voucherValue}
                                        </td>
                                        <td style={{ fontSize: "11px" }}>
                                          {" "}
                                          *******
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div className="text-center">
                                  Transaction ID:*********
                                </div>
                                <div className="rewardBtnSection  row">
                                  <div className="text-center mt-2 mb-3 col-12">
                                    <div
                                      className="d-flex align-items-center justify-content-center "
                                      style={{ columnGap: 50 }}
                                    >
                                      <span>
                                        {" "}
                                        <a className="kVZSMi">
                                          How to use?
                                        </a>{" "}
                                        &nbsp;{" "}
                                        <a className="kVZSMi">
                                          Terms &amp; Conditions
                                        </a>
                                      </span>

                                      <div className="kVZSMi mt-2">
                                        Connect with us
                                        <span>
                                          {" "}
                                          <svg
                                            width="15"
                                            height="15"
                                            viewBox="0 0 16 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.25234 0.503906H14.7438C15.4304 0.503906 15.9961 1.07734 15.9961 1.76406V2.13867L8.28281 6.31719C8.28086 6.31719 8.27891 6.31836 8.27695 6.31953C8.2751 6.32031 8.27314 6.32109 8.27119 6.32109C8.10732 6.41875 7.90049 6.41875 7.73272 6.32109C7.72998 6.32109 7.72529 6.31914 7.72139 6.31797L7.71719 6.31719L0 2.13867V1.76406C0 1.07734 0.565723 0.503906 1.25234 0.503906Z"
                                              fill="#B4B4B6"
                                            ></path>
                                            <path
                                              d="M0 3.02422L7.33867 7.00391C7.74443 7.23398 8.25166 7.23398 8.65742 7.00391L16 3.02422V11.5723C16 12.259 15.4343 12.8324 14.7477 12.8324H1.25234C0.565723 12.8324 0 12.259 0 11.5723V3.02422Z"
                                              fill="#B4B4B6"
                                            ></path>
                                          </svg>{" "}
                                          <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M8 0.667969C3.582 0.667969 0 4.24997 0 8.66797C0 10.1688 0.421561 11.568 1.14062 12.7682L0.0716146 16.668L4.05469 15.6224C5.21957 16.2847 6.56433 16.668 8 16.668C12.418 16.668 16 13.086 16 8.66797C16 4.24997 12.418 0.667969 8 0.667969ZM5.26172 4.9362C5.39172 4.9362 5.52529 4.93541 5.64063 4.94141C5.78329 4.94474 5.93857 4.95519 6.08724 5.28385C6.26391 5.67452 6.64858 6.65457 6.69792 6.75391C6.74725 6.85324 6.78221 6.97026 6.71354 7.10026C6.64821 7.23359 6.61423 7.31429 6.51823 7.43229C6.4189 7.54696 6.31005 7.68938 6.22005 7.77604C6.12072 7.87538 6.01815 7.98429 6.13281 8.18229C6.24748 8.38029 6.64571 9.02939 7.23437 9.55339C7.99104 10.2294 8.62946 10.4371 8.82812 10.5365C9.02679 10.6358 9.14184 10.6203 9.25651 10.487C9.37451 10.357 9.75208 9.91091 9.88542 9.71224C10.0154 9.51357 10.1481 9.54795 10.3281 9.61328C10.5108 9.67861 11.4849 10.1585 11.6836 10.2578C11.8823 10.3571 12.0125 10.4063 12.0625 10.487C12.1138 10.5703 12.1139 10.967 11.9492 11.4297C11.7846 11.8917 10.9759 12.3385 10.6133 12.3698C10.2473 12.4038 9.90571 12.5343 8.23438 11.8763C6.21838 11.0823 4.94699 9.01745 4.84766 8.88412C4.74832 8.75412 4.04036 7.81057 4.04036 6.83724C4.04036 5.86057 4.55244 5.38226 4.73177 5.18359C4.91444 4.98493 5.12839 4.9362 5.26172 4.9362Z"
                                              fill="#B4B4B6"
                                            ></path>
                                          </svg>
                                        </span>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="rewardEmailFooter text-center ">
                <div className="content">
                  To raise any concern, email us at{" "}
                  <span
                    style={{
                      textDecoration: "underline"
                    }}
                  >
                    cs@xoxoday.com
                  </span>{" "}
                  <br /> or Whatsapp at{" "}
                  <span
                    style={{
                      textDecoration: "underline"
                    }}
                  >
                    (+91) 8061915050.
                  </span>
                </div>
              </div> */}
                              </div>
                              {/* <div
              className="text-center fw-500 emailTemplateSection"
              style={{ margin: "42px auto 0px" }}
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.75 2C9.82164 2 7.93657 2.57183 6.33319 3.64317C4.72982 4.71451 3.48013 6.23726 2.74218 8.01884C2.00422 9.80042 1.81114 11.7608 2.18735 13.6521C2.56355 15.5434 3.49215 17.2807 4.85571 18.6443C6.21928 20.0079 7.95656 20.9365 9.84787 21.3127C11.7392 21.6889 13.6996 21.4958 15.4812 20.7578C17.2627 20.0199 18.7855 18.7702 19.8568 17.1668C20.9282 15.5634 21.5 13.6784 21.5 11.75C21.497 9.16506 20.4688 6.68684 18.641 4.85901C16.8132 3.03118 14.3349 2.00299 11.75 2ZM11.7499 6.5C11.9724 6.5 12.1899 6.56598 12.3749 6.6896C12.5599 6.81321 12.7041 6.98891 12.7893 7.19448C12.8744 7.40005 12.8967 7.62625 12.8533 7.84448C12.8099 8.06271 12.7027 8.26316 12.5454 8.4205C12.3881 8.57783 12.1876 8.68498 11.9694 8.72838C11.7512 8.77179 11.525 8.74951 11.3194 8.66436C11.1138 8.57922 10.9381 8.43502 10.8145 8.25002C10.6909 8.06501 10.6249 7.8475 10.6249 7.625C10.6249 7.47726 10.654 7.33097 10.7105 7.19448C10.7671 7.05799 10.85 6.93397 10.9544 6.8295C11.0589 6.72504 11.1829 6.64217 11.3194 6.58563C11.4559 6.5291 11.6022 6.5 11.7499 6.5H11.7499ZM12.5 17H11.75C11.6515 17.0001 11.5539 16.9807 11.4629 16.943C11.3719 16.9054 11.2892 16.8501 11.2195 16.7805C11.1499 16.7108 11.0947 16.6281 11.057 16.5371C11.0193 16.4461 10.9999 16.3485 11 16.25V11.75C10.8011 11.75 10.6103 11.671 10.4697 11.5303C10.329 11.3897 10.25 11.1989 10.25 11C10.25 10.8011 10.329 10.6103 10.4697 10.4697C10.6103 10.329 10.8011 10.25 11 10.25H11.75C11.8485 10.2499 11.9461 10.2693 12.0371 10.307C12.1281 10.3446 12.2108 10.3999 12.2805 10.4695C12.3501 10.5392 12.4054 10.6219 12.443 10.7129C12.4807 10.8039 12.5001 10.9015 12.5 11V15.5C12.6989 15.5 12.8897 15.579 13.0303 15.7197C13.171 15.8603 13.25 16.0511 13.25 16.25C13.25 16.4489 13.171 16.6397 13.0303 16.7803C12.8897 16.921 12.6989 17 12.5 17Z"
                  fill="#0172CB"
                />
              </svg>
              <span className="ml-2">
                Codes are sent as email attachment if
                'quantity' is more than 5
              </span>
            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Content wrapper */}
            </div>
            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}

export default AllTemplate;
