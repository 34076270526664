import { createSlice } from "@reduxjs/toolkit";
import { getTemplate } from "../actions/templateActions";



const initialState = {
  data: "", // for template
};

const  templateSlice= createSlice({
  name: "template",
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTemplate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getTemplate.rejected, (state, { payload }) => {
        state.loading = false;
        // handle rejection if needed
      });
  },
});

export default templateSlice.reducer;
