import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import { Link } from "react-router-dom";
import sendBulkImg from "../../Images/helpImg/sendBulk.png";
import verifyImg from "../../Images/helpImg/verifyCSV.png";
import dataImg from "../../Images/helpImg/bulkData.png";
import BackButton from "./BackButton";



function SendRewardHome() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Send Rewards
                    </h5>
                    <p>
                      Squid simplifies the process of rewarding others, effortlessly meeting all your needs! Whether you're looking to appreciate an employee, a customer, or a partner, Squid empowers you to reward anyone, anywhere, in a personalized manner
                    </p>
                    <hr className="mt-4" />
                 
                    <ol>
                         
                          <li>
                            {" "}
                            <Link
                              className="fw-semibold text-primary"
                              to="/admin/send-reward-individual"
                            >
                              Send Rewards to Individuals
                            </Link>
                          </li>

                          <li>
                            {" "}
                            <Link
                              className="fw-semibold text-primary"
                              to="/admin/send-reward-self"
                            >
                              Send Rewards to Self
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              className="fw-semibold text-primary"
                              to="/admin/send-reward-bulk"
                            >
                             Send Rewards to Many
                            </Link>
                          </li>
                        </ol>

                    <hr />
                    <div className="mt-4">
                   <BackButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default SendRewardHome;
