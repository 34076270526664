import React from "react";

const Terms = () => {
  return (
    <div className=" p-5">
      <h1 className="text-center">Terms & Conditions</h1>
      <div className="mt-5">
        <h3>Introduction</h3>
        <p className="text-justify" style={{ textAlign: "justify" }}>
          Squid is a web application of Credence Rewards Private Limited, a
          company incorporated under theCompanies Act 1956 and registered under
          the laws of India. For Squid, registered users, thewebsite,
          andapplication programming interface (the “API”) all together referred
          as Service, also provides aplatformandtools for using the Service and
          is considered a part of the Service.
        </p>
      </div>
      <div className="mt-5">
        <h3>1. Acceptance of terms & conditions of service</h3>
        <p style={{ textAlign: "justify" }}>
          Squid provides the website and the Service, including the website, the
          API and any functionalityservicesoffered on or through the website and
          the Service, to you subject to these Terms and Conditionsof
          Service(this “TOS”). This TOS includes Squid Privacy Policy, which
          explains how Squid collects, usesandprotects data from visitors to the
          website and users of the Service. For the purpose of this Agreement,
          theterm, “Company Information” shall mean and include, without
          limitation, details such as Name, PAN, GST,CIN, Industry, Business
          Category, Address, Website link and Personal Information shall
          includeName, Email ID, Phone Number, Passwords. Also, details of bank
          account, credit card, or debit cardor otherdetails of payment
          instrument (collectively, “Financial Information”) as may be obtained
          fromyoufor facilitating online payments during your usage of the
          website will be stored for audit purpose. If your organization has
          entered into a commercial agreement with Credence Rewards Pvt. Ltd,
          includinganyagreement or addendum related to the protection of
          personal data (a “Customer Agreement”), then(i)theterms of the
          Customer Agreement govern the relationship between your organization
          and CredenceRewards Pvt. Ltd , and this TOS governs your individual
          use of the Site and the Service, and(ii) if thereisany conflict
          between this TOS and the Customer Agreement, the Customer Agreement
          will takeprecedence over this TOS.
        </p>
      </div>
      <div className="mt-5">
        <h3>2. KYB – Know your Business</h3>
        <p style={{ textAlign: "justify" }}>
          Know Your Business “KYB”, can be defined as the process of verifying
          the identification informationprovided by a business. We conduct an
          investigation to verify the identity of entities we
          plantodobusinesswith. This helps us build a risk-free relationship
          with other companies.
        </p>
        <p style={{ textAlign: "justify" }}>
          In cases where we cannot identify the business, we document and
          communicate the reasons tosuchBusiness for our failure to identify
          them. And we do not engage with them till they are identified.
        </p>
        <p style={{ textAlign: "justify" }}>
          Using this approach, we ensure that we do not knowingly conduct
          business with the following:
          <ol>
            <li>Terrorist & Terrorist Organizations</li>
            <li>
              Persons involved in narcotics, money laundering or human
              trafficking;
            </li>
            <li>Anonymous Organizations; and</li>
            <li>Shell Companies</li>
          </ol>
        </p>
        <p style={{ textAlign: "justify" }}>
          In addition, we also confirm the beneficial owners of the
          organizations. Beneficial owners areanyindividuals(s) that own or
          control 25% or more of the organization, either directly or
          indirectly.
        </p>
      </div>
      <div className="mt-5">
        <h3>3. Account</h3>
        <p style={{ textAlign: "justify" }}>
          Your organization will provide your personal information to create the
          account and will have access to modify on need basis. Upon signing up
          to your account, you will be considered as user of the account and
          will have access to the website as per the role assigned to you by
          your organization. You are entirely responsible for maintaining the
          confidentiality of your password and account. Furthermore, you are
          entirely responsible for any and all activities that occur under your
          account. You agree to notify Squid immediately of any unauthorized use
          of your account or any other breach of security. Credence will not be
          liable for any loss that you may incur as a result of someone else
          using your password or account, either with or without your knowledge
          so long as the security or data breach is not attributable to any
          fraud, negligence, security breach, or misconduct on part of Squid.
          However, you could be held liable for losses incurred by Credence or
          another party due to unauthorized use of your account.
        </p>
      </div>
      <div className="mt-5">
        <h3>4. Site access</h3>
        <p style={{ textAlign: "justify" }}>
          Squid makes available to its customers and their users from time to
          time application programming interfaces, browser or other application
          plugins and related documentation and other related materials, which
          may include sample code (collectively, the “API”). If you are a
          registered user, or an administrator or developer on behalf of an
          organization that is a Squid customer, then you may use the API for
          your or such customer’s internal business purposes to develop or
          enable web or other software services or applications(“Integrations”)
          that will communicate and interoperate with the Service.
          Notwithstanding anything in this TOS or other agreement between you or
          your organization and Squid to the contrary, the API is provided“as
          is,” and Squid makes no representation or warranty of any kind in
          connection with the API, including without limitation any
          representation or warranty that the API, Integrations, or any products
          or results of the use of the API or Integrations will meet your or
          your organization’s requirements, achieve any intended result, be
          compatible or work with any of your or your organization’s or any
          third party’s software, systems or other services.
        </p>
        <p style={{ textAlign: "justify" }}>
          If you are an administrator on behalf of an organization that is a
          Squid customer, then you may designate authorized users of your
          organization’s account, and by using the Site and the Service, you
          confirm that you are authorized by your organization to do so. Those
          authorized users will have the ability to access your organization’s
          account and to take any actions that users are authorized to take
          under thisTOS and any applicable Customer Agreement. If you establish
          or are given a user account as an employee or contractor of an
          organization that has a Customer Agreement with Squid , you represent
          that you are authorized by your organization to do so, and you agree
          to use the Site and the Service solely as authorized by your
          organization.
        </p>
        <p style={{ textAlign: "justify" }}>
          To access the certain areas of the website and the Service, you may be
          asked or required to provide certain registration details or other
          information. It is a condition of your use of the Site and the Service
          that all such details and information that you provide be correct,
          current and complete. You expressly agree that all information you
          provide to register with Squid is subject to the terms of Squid ’s
          Privacy Policy, and you consent to all actions we take with respect to
          your information consistent with Squid ’s PrivacyPolicy.If you choose
          or are provided with a user name, password or any other information as
          part of the security features of the Site or the Service, you agree to
          treat such information as confidential, and you agree not to disclose
          it to any other person or entity. You user account, if applicable, is
          personal to you, and you agree not to provide any other person with
          access to the Site or the Service through your user account. You agree
          to notify Squid immediately if you become aware of any unauthorized
          access or use of your user name, password or other security
          information. Squid reserves the right to bar access to the Site and
          the Service by any unauthorized user, or any user who Squid has reason
          to believe is in breach of this TOS.
        </p>
      </div>
      <div className="mt-5">
        <h3>5. Payments</h3>
        <p style={{ textAlign: "justify" }}>
          Customer acknowledges that the receipt of an authorisation for a
          Payment Transaction indicates that, as of the date of the
          authorisation, the underlying Funding Instrument has sufficient credit
          with the card issuer or Operator for the amount of the Purchase
          Amount. Customer further acknowledges that upon receipt of
          anauthorisation for a Payment Transaction, Customer payment obligation
          to Squid is completed and discharged (except in the event of charge
          back or other reversal). Customer acknowledges that the authorisation
          is not a confirmation of the Customer's identity; nor is an
          authorisation or guarantee b yCredence that the transaction will not
          be subject to a charge back or other reversal.
        </p>
      </div>
      <div className="mt-5">
        <h3>6. Refund and Cancellation Policy</h3>
        <p style={{ textAlign: "justify" }}>
          For Vouchers purchased on Squid Marketplace
        </p>
        <p style={{ textAlign: "justify" }}>
          A voucher is cancelled, and a refund is issued in the following cases;
          <ol>
            <li>
              The Voucher(s) issued to the user is different from the Voucher(s)
              ordered by the customer; and/or,
            </li>
            <li>
              The Brand issuing the voucher(s) does not oblige to the terms and
              conditions of the voucher.
            </li>
            <li>
              If the order is still in pending status and if the same order is
              getting canceled due to non-availability of voucher(s) then the
              refund will be processed post verification of the issue within 5-7
              working days.
            </li>
          </ol>
        </p>
      </div>
      <div className="mt-5">
        <h3>7. Security of confidential information</h3>
        <p style={{ textAlign: "justify" }}>
          Personal Information submitted by you or collected from your
          organization, whether on or through this website or otherwise and
          other information collected from you for the purpose of providing
          access to website shall be securely stored on servers hosted by a
          reputed Internet Service Provider (“ISP”) for a period of active
          contract meeting the prescribed industry security standards. Your
          Personal Information shall not be disclosed in any circumstances
          except pursuant to a valid and subsisting order of a court or other
          judicial, quasi judicial or government body under applicable laws
        </p>
        <p style={{ textAlign: "justify" }}>
          Squid may use certain technical information, including, inter alia,
          log data, analytics code, etc. that you do not visibly enter and which
          are automatically collected during your access or use of the website,
          such as,IPaddress, version and identification number of the device,
          browser type, browser language, date and time of your requests to
          obtain vital statistics regarding your usages of the website, enhance
          your experience and interface, increase the efficiency of the website,
          perform software verification and administration. Squid may use
          certain technical information, inter alia, log data, analytics code,
          that you do not visibly enter and which are automatically collected
          during your access or use of the Web site, such as, IPaddress, version
          and identification number of the device, browser type, browser
          language, date and time of your requests,your Squid profile, links in
          this application visited by you, search terms used in Squid
          application, that is not associated with or linked to your personal
          data and which, by itself, does not permit the identification of an
          individual user (collectively, “Anonymous Data”) or share such
          Anonymous Data with its affiliated entities or other third parties on
          need to know basis to obtain vital statistics regarding your usages of
          theWebSite,enhance your experience and interface, increase the
          efficiency of the Web site, conduct surveys, perform software
          verification and administration. You can refuse to the use of cookies
          on the Website and app permissions on mobile; however please note that
          in such case the full functionality of this Website/ mobile app may
          not be used.
        </p>
        <p style={{ textAlign: "justify" }}>
          Squid is committed to protect the security of your Personal
          Information and uses reasonable efforts and security measures,
          including, a variety of security technologies and procedures to help
          protect such information from any unauthorized access, use or
          disclosure. However, the website shall not be liable for retaining
          your Personal Information beyond a period of 1 day from the date of
          deactivation of the contract.
        </p>
      </div>
      <div className="mt-5">
        <h3>8. Intellectual property rights & trademark</h3>
        <p style={{ textAlign: "justify" }}>
          Except as otherwise expressly stated in this TOS, the website and the
          Service, and all the information, images, logos, trade names, domain
          names, service marks and other materials on this website,
          including,inter alia, object code, source code, design, layout, user
          interface, and look and feel of the website(collectively,
          “Intellectual Property”) are the exclusive intellectual property of
          Squid or respective third parties and are subject to protection by
          intellectual property laws of India and other jurisdictions to which
          it may extend. No right, title or interest in or to the Site or any
          part of the Service is transferred to you, and all rights not
          expressly granted to you in this TOS are reserved by Squid . Squid ’s
          Intellectual Property shall not be used in connection with any purpose
          other than performing activities on website and matters incidental
          thereto. Unless otherwise specified, the website is for your official
          and non-commercial usage only. You will not modify, copy, distribute,
          transmit, display, perform, reproduce, publish, license, create
          derivative works from, transfer, or sell any Intellectual Property
          obtained from the website
        </p>
        <p style={{ textAlign: "justify" }}>
          Without limiting the foregoing paragraph, the name Squid , the Squid
          logo and all other trademarks, logos,product and service names,
          designs and slogans ("Marks") displayed on the website or through the
          Service are trademarks of Squid or its licensors. You must not use any
          Marks for any purpose without prior, written permission from Squid or
          its applicable licensor.
        </p>
      </div>
      <div className="mt-5">
        <h3>9.International transfer of data</h3>
        <p style={{ textAlign: "justify" }}>
          In the process of providing access to you, website and the Service may
          transfer Personal Information that it collects from you/your
          organization to affiliated entities across borders on need to know
          basis with an obligation to maintain Your Personal Information
          confidential for the limited purpose of up keeping and maintaining the
          website. By using this website, you hereby consent to transfer of your
          Personal Information across countries or jurisdictions which shall
          abide by the rules governing such international data transfers.You
          consent to Squid sharing Personal Data to such affiliated entities or
          other third parties across countries or jurisdictions approved by your
          Organization.
        </p>
        <p style={{ textAlign: "justify" }}>
          Squid has engaged third party vendors to provide services to clients
          in certain jurisdictions where Squid does not operate. Your use of the
          platform to send Rewards to recipients in those jurisdictions may be
          subject to additional or different terms. If you wish to send Rewards
          to recipients in the UnitedStates, for example, we have contracted
          with a Partner, and your use of the Services is subject to our
          Partner'sCorporate Terms of Service and your designated recipient's
          acceptance of the Reward is subject to our Partner's Recipient Terms
          of Service.
        </p>
      </div>
      <div className="mt-5">
        <h3>10. Prohibited uses</h3>
        <p style={{ textAlign: "justify" }}>
          You agree and undertake to use the Website and the Service only to
          access information regarding Squid including the products and services
          provided by us. By way of example, and not as a limitation, you agree
          and undertake that when using the Website and the Service, you will
          not: Modify, alter or otherwise make any derivative uses of our
          content, trademarks, technology platform or its underlying software,
          or any portion thereof;
        </p>
        <p style={{ textAlign: "justify" }}>
          Reverse engineer, modify, copy, distribute, transmit, display,
          perform, reproduce, publish, license, create derivative works from,
          transfer, or sell any information or software obtained from the
          Website or MobileApp.Infringe upon or violate the rights of Squid, its
          users or third parties;
        </p>
        <p style={{ textAlign: "justify" }}>
          Download, copy or imitate any portion of the Squid technology
          platform, its underlying software and content,or its user interface,
          or the Website, except as expressly permitted by us; Aggregate or
          scrape any content, data or other information from the Website to be
          aggregated or shown with material from other sites or on a secondary
          site without our express written permission; Hyperlink to the Website
          or deep-link to any portion of our Website without our written
          consent; Interfere with our Website’s attempt to access or steal any
          data and information on or related to the Website;
        </p>
        <p style={{ textAlign: "justify" }}>
          Seek to transact any fraudulent or illegal activity, through any means
          or corrupt practices, including but not limited to any of the
          following: hacking, password mining, deceptive impersonation of
          another person, misrepresentation of your affiliation with a person or
          entity, hiding or attempting to hide your true identity or location
          (including via proxy server or otherwise) or providing false,
          inaccurate or altered documentation,information or identification;
        </p>
        <p style={{ textAlign: "justify" }}>
          Circumvent or seek to circumvent any security measures or other
          features meant to protect the security of the Website and the users’
          security;
        </p>
        <p style={{ textAlign: "justify" }}>
          Use the Website other than for its intended purposes (including for
          any malicious, threatening, unethical,harassing, fraudulent or illegal
          activity) or otherwise in violation of these Terms of Use.
        </p>
        <p style={{ textAlign: "justify" }}>
          Upload files that contain software or other material protected by
          intellectual property laws unless you own or control the rights
          thereto or have received all necessary consents;
        </p>
        <p style={{ textAlign: "justify" }}>
          Upload or distribute files that contain viruses, corrupted files, or
          any other similar software or programs that may damage the operation
          of the Website or another's computer;
        </p>
        <p style={{ textAlign: "justify" }}>
          Conduct or forward surveys, contests, pyramid schemes or chain
          letters;
        </p>
        <p style={{ textAlign: "justify" }}>
          Falsify or delete any author attributions, legal or other proper
          notices or proprietary designations or labels of the origin or source
          of software or other material contained in a file that is uploaded;
        </p>
        <p style={{ textAlign: "justify" }}>
          Violate any code of conduct or other guidelines, which may be
          applicable for or to any particular Service;Violate any applicable
          laws or regulations for the time being in force in or outside India;
        </p>
        <p style={{ textAlign: "justify" }}>
          Violate any provisions of these TOS. Under no circumstance shall Squid
          be liable for any consequential,indirect or remote loss that you or
          your friends and family may suffer.
        </p>
      </div>
      <div className="mt-5">
        <h3>11. Links to third party sites</h3>
        <p style={{ textAlign: "justify" }}>
          Squid will contain links to other websites ("Linked Sites"). The
          Linked Sites are not under the control of Squid, and Squid is not
          responsible for the contents of any Linked Site, including without
          limitation any link contained in a Linked Site, or any changes or
          updates to a Linked Site. Squid is not responsible for any form of
          transmission, whatsoever, received by you from any Linked Site. Squid
          provides links of online advertisements to you only as a convenience
          and the inclusion of any link does not imply endorsement by or
          affiliation with Squid of the Linked Sites nor does it represent the
          advice, views, opinions or beliefs of Squid. The users are requested
          to verify the accuracy of all information on their own before
          undertaking any reliance on such information. In the event that by
          accessing the Website or following links to third-party websites you
          are exposed to content that you consider offensive or inappropriate,
          your only recourse will be to stop using the Website.
        </p>
      </div>
      <div className="mt-5">
        <h3>12. Disclaimer of warranties & limitation of liability</h3>
        <p style={{ textAlign: "justify" }}>
          Squid has endeavored to ensure that all the information on the Website
          and the Services is correct, butSquid neither warrants nor makes any
          representations regarding the quality, accuracy or completeness of any
          data, information, product or service. In no event shall Squid be
          liable for any direct, indirect, punitive,incidental, special,
          consequential damages or any other damages. Neither shall Squid be
          responsible for the delay or inability to use the Website and the
          Service or related Functionalities, the provision of or failure to
          provide Functionalities, or for any information, software, products,
          Functionalities and related graphics obtained through the Website, or
          otherwise arising out of the use of the website and the Service,
          whether based on contract, tort, negligence, strict liability or
          otherwise. Further, Squid shall not be held responsible for
          non-availability of the Website and the Service during periodic
          maintenance operations or any unplanned suspension of access to the
          website that may occur due to technical reasons or for any reason
          beyond Squid 's control. Squid will use commercially reasonable
          efforts consistent with prevailing industry standards to maintain the
          site and the services in a manner which seeks to minimize errors and
          interruptions to the services. The user understands and agrees that
          any material and/or data downloaded or otherwise obtained through the
          Website is done entirely at their own discretion and risk and they
          will be solely responsible for any damage to their computer systems or
          loss of data that results from the download of such material and/or
          data. In case of any charge back dispute related to the payment not
          being made as per the provisions of the Payment Gateway Merchant,
          Cards issuing company or as per the relevant laws,Squid will neither
          be held responsible nor will be a contractual party in the dispute,
          and the user will beheld responsible for any such claims arising out
          of that incorrect payment.
        </p>
      </div>
      <div className="mt-5">
        <h3>13. Indemnity</h3>
        <p style={{ textAlign: "justify" }}>
          You agree to defend, indemnify and hold harmless Squid (and its
          officers, directors, employees, agents,affiliates, contractors,
          licensors, suppliers, successors and assigns) from and against any
          claims, demands,liabilities, damages, judgments, awards, losses, costs
          (including reasonable attorneys’ fees), arising out of or relating to
          your breach of this TOS, including, but not limited to, your User
          Content, your violation of any applicable law or the rights of a third
          party, or your use of the Site or the Service other than as expressly
          authorized by Squid .
        </p>
      </div>
      <div className="mt-5">
        <h3>14. Jurisdiction</h3>
        <p style={{ textAlign: "justify" }}>
          The terms and use of the website shall be exclusively governed by the
          laws of India and the courts in Mumbai,Maharashtra shall have the
          exclusive jurisdiction over such matters. Credence here by expressly
          disclaims any implied warranties or liabilities imputed by the laws of
          any other jurisdiction.
        </p>
      </div>
      <div className="mt-5">
        <h3>15. Dispute resolution</h3>
        <p style={{ textAlign: "justify" }}>
          Any dispute arising out of or in connection with this Agreement,
          including, inter alia, any question regarding its existence, validity
          or termination, shall be referred to and finally resolved by
          arbitration under theArbitration and Conciliation Act, 1996 and the
          rules made thereunder. The decision of the majority of the arbitrators
          shall be final and binding on the parties. The seat and venue of the
          arbitration shall be Mumbai,India.
        </p>
      </div>
      <div className="mt-5">
        <h3>16. Severability</h3>
        <p style={{ textAlign: "justify" }}>
          If any of the above conditions in this Agreement is deemed invalid,
          void, or for any reason unenforceable,that condition will be deemed
          severable and will not affect the validity and enforceability of the
          remaining Agreement
        </p>
      </div>
      <div className="mt-5">
        <h3>17. Messaging policy</h3>
        <p style={{ textAlign: "justify" }}>
          Squid sends messages to users regarding order details. To users who
          reply with HELPor INFO keywords to the long code or toll-free number,
          carrier charges rates may apply.
        </p>
      </div>
      <p style={{ textAlign: "justify", fontWeight: "bold" }}>
        *Note: The Above mentioned T& C is applicable while procuring the
        vouchers from Squid usage&redemption terms and condition varies from
        brand to brand.
      </p>
    </div>
  );
};

export default Terms;
