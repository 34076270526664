import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function PaymentMsgModal({ show, onHide }) {
  const [paymentStatus, setPaymentStatus] = useState(null);


  setTimeout(() => {
    setPaymentStatus('success'); // Change to 'error' for failed payment
  }, 2000);
  useEffect(()=>{
if(show){
    setPaymentStatus(null)
}
  },[show])

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{paymentStatus !== 'success'?"Payment Processing":"Transaction successful"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {paymentStatus === null ? (
          <p>Processing your payment...</p>
        ) : paymentStatus === 'success' ? (
          <div>
            <p>Payment successful!</p>
            <p>Thank you for your purchase.</p>
          </div>
        ) : (
          <div>
            <p>Payment failed.</p>
            <p>Please try again later.</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {paymentStatus === null ? (
        <></>
        ) : (
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default PaymentMsgModal;
