import React, { useEffect, useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import homeImg from "../Images/dashimage.png";
import rupeeIcon from "../Images/icon.png";
import totalIcon from "../Images/icon-1.svg";
import bell from "../Images/bell.png";

import {
  getBalanceForRewards,
  getDashboardData,
  verifyEmail,
} from "../services/Apiservices";
import DashboardSlider from "./DashboardSlider";
import showNotification from "../services/NotificationService";

import { Tilt } from "react-tilt";

import { defaultOptions } from "../constant";

function Dashboard() {
  const data = useSelector((state) => state?.profile?.data?.data);
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0.0);
  const [graphData, setGraphData] = useState([]);
  // 'Orders' or 'Vouchers'

  useEffect(() => {
    getBalance();
    getGraphData();
  }, []);

  const getBalance = async () => {
    try {
      let response = await getBalanceForRewards();
      if (response?.status == 200) {
        setBalance(response?.data?.balance);
      } else {
      }
    } catch (error) {}
  };
  const getGraphData = async () => {
    try {
      let response = await getDashboardData();
      if (response?.status == 200) {
        setGraphData(response?.data);
      } else {
      }
    } catch (error) {}
  };

  function formatAmount(amount) {
    switch (true) {
      case amount >= 10000000: // If amount is 10 million or more
        return (amount / 10000000)?.toFixed(2) + " Cr";
      case amount >= 100000: // If amount is 100,000 or more
        return (amount / 100000)?.toFixed(2) + "Lakh";
      default:
        return amount?.toString(); // Return the amount as it is if it's less than 100,000
    }
  }
  const handleEmailVerify = async () => {
    try {
      let response = await verifyEmail();

      if (response?.status == 200) {
        showNotification("success", response?.data?.message);
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}
              <div className="container-xxl flex-grow-1">
                {/* {!data?.email_verified && (
                  <div
                    class="alert alert-success text-center fw-semisolid"
                    role="alert"
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      border: "0px solid #d9dee3",
                    }}
                  >
                    <div>
                      {" "}
                      Please verify your email by the pressing button{"  "}
                      <button
                        className="send-rewards-text me-4"
                        style={{
                          display: "inline-block",
                          height: "30px",
                          color: "white",
                        }}
                        onClick={handleEmailVerify}
                      >
                        Verify Email
                      </button>
                    </div>
                  </div>
                )} */}
                {/* {!data?.company_added && (
                  <div
                    class="alert alert-success text-center fw-semisolid"
                    role="alert"
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      border: "0px solid #d9dee3",
                    }}
                  >
                    <div>
                      {" "}
                      Please add your company{"  "}
                      <button
                        className="send-rewards-text me-4"
                        style={{
                          display: "inline-block",
                          height: "30px",
                          color: "white",
                        }}
                        onClick={handleEmailVerify}
                      >
                        Add 
                      </button>
                    </div>
                  </div>
                )} */}
                {data && !data?.company_verified && (
                  <div
                    style={{
                      backgroundColor: "#FFF5D9",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderRadius: "50px",
                      margin: "auto",
                      color: "red",
                      fontWeight: "font-semibold",
                      width: "fit-content",
                    }}
                  >
                    <span>
                      <img src={bell} width={"24px"} alt="" />
                    </span>
                    Your account is under verification with limited access to
                    our voucher catalogue. You can still add funds and generate
                    vouchers
                  </div>
                )}

                <div className="row mt-3">
                  <div className="col-md-6 col-sm-12 col-lg-4   mb-2">
                    <div className="card rounded-4">
                      {/* Card Content */}
                      <div className="card-body">
                        <p className="card-title">Total Voucher Shared</p>

                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="card-text fw-normal5 fs-45 mt-2">
                            {graphData?.total_vouchers
                              ? graphData?.total_vouchers
                              : 0}
                          </h5>
                          <div className="d-flex align-items-center ">
                            <img
                              className="icon"
                              loading="eager"
                              alt=""
                              src={totalIcon}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12 col-lg-4  mb-2">
                    <div className="card rounded-4">
                      {/* Card Content */}
                      <div className="card-body">
                        <p className="card-title">Total Vochers Amount</p>

                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="card-text fw-normal5 fs-45 mt-2">
                            {formatAmount(
                              graphData?.total_amount
                                ? graphData?.total_amount
                                : 0.0
                            )}
                          </h5>
                          <div className="d-flex align-items-center ">
                            <img
                              className="icon"
                              loading="eager"
                              alt=""
                              src={rupeeIcon}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12  mb-2">
                    <div className="card rounded-4">
                      {/* Card Content */}
                      <div className="card-body">
                        <button
                          className="btn  btn-sm float-end rounded-4 add-fund-icon-group mt-1"
                          style={{ color: "white" }}
                          onClick={() => navigate("/admin/add-funds")}
                        >
                          + Add Funds
                        </button>
                        <p className="card-title">Squid Fund Balance</p>

                        <div className="d-flex justify-content-between">
                          <h5 className="card-text fw-normal5 fs-45 mt-2">
                            <img
                              className="icon"
                              loading="eager"
                              alt=""
                              src={rupeeIcon}
                            />{" "}
                            {formatAmount(balance)}
                          </h5>
                          <div className="d-flex align-items-center "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-12">
                    <DashboardSlider />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card rounded-4">
                      <div className="row">
                        {/* Left Column for Text Content */}
                        <div className="col-md-8">
                          <div className="card-body">
                            <h5
                              className="card-title mt-3 fw-semibold fs-22"
                              style={{ lineHeight: "1.25rem" }}
                            >
                              Send Rewards with ease and Spread Joy!
                            </h5>
                            <p className="card-text our-reliable-and">
                              Our reliable and efficient reward delivery system
                              ensures prompt and hassle-free distribution,
                              allowing you to experience the excitement of your
                              team’s accomplishment without delay
                            </p>
                            <button
                              className="send-rewards-text"
                              onClick={() => navigate("/admin/send-rewards")}
                            >
                              <div className="send-rewards1">Send Rewards</div>
                            </button>
                          </div>
                        </div>

                        {/* Right Column for Image */}
                        <div className="col-md-4">
                          {/* Add your image here */}
                          <Tilt options={defaultOptions}>
                            <img
                              src={homeImg}
                              alt="Reward Image"
                              className="img-fluid"
                              style={{ marginTop: "20px" }}
                            />
                          </Tilt>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
    </>
  );
}

export default Dashboard;
