import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  loginWithOTP,
  loginWithPass,
  resendLoginOTP,
  resendOTP,
} from "../../services/Apiservices";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/reducers/authSlice";
import { getToken, setSession } from "../../session";
import { setUser } from "../../redux/reducers/userSlice";
import showNotification from "../../services/NotificationService";
import { getProfile } from "../../redux/actions/profileAction";
import logo from "../../CredLogo.png";
import { restrictAlpha } from "../../constant";

import Slider from "./Slider";
import OTPInput from "./OTPInput";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setisLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const profile = useSelector((state) => state?.profile?.data?.data);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginOTP, setloginOTP] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  useEffect(() => {
    // Disable scroll when the component mounts
    document.body.style.overflow = "hidden";
    document.body.style.paddingTop = "0"; // Reset padding-top
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    if (getToken()) {
      navigate("/admin/dashboard");
    }

    // Re-enable scroll when the component unmounts
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      mobile: "",
      otp: "",
    },
    validationSchema: loginOTP
      ? Yup.object({
          mobile: Yup.string()
            .test(
              "is-email-or-mobile",
              "Invalid email or mobile number",
              function (value) {
                if (!value) return false; // if the field is empty, it's invalid
                if (!isNaN(value)) return /^\d{10}$/.test(value); // valid mobile number if the value is a number
                return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                  value
                );
              }
            )
            .required("Email or mobile is required"),
          otp: showOTP
            ? Yup.string()
                .test(
                  "len",
                  "OTP must be exactly 6 digits",
                  (val) => val && val.length === 6
                )
                .required("OTP is required")
            : Yup.string().notRequired(),
        })
      : Yup.object({
          mobile: Yup.string()
            .test(
              "is-email-or-mobile",
              "Invalid email or mobile number",
              function (value) {
                if (!value) return false; // if the field is empty, it's invalid
                if (/\S+@\S+\.\S+/.test(value)) return true; // valid email
                if (/^\d{10}$/.test(value)) return true; // valid mobile number
                return false; // if it's neither email nor mobile number, it's invalid
              }
            )
            .required("Email or mobile is required"),
          password: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("Password is required"),
        }),

    onSubmit: (values) => {
      // You can handle the form submission here
      handleLogin(values);
      // navigate("/admin/dashboard")
    },
  });
  const handleLogin = async (values) => {
    try {
      setisLoading(true);
      let body = {
        password: values?.password,
        mobile_no: values?.mobile,
      };
      let response = await loginWithPass(body);
      if (response?.status == 200) {
        // showNotification("success", "Login Successfully");
        setisLoading(false);
        setSession(response?.data?.access);
        dispatch(login());

        dispatch(
          setUser({
            full_name: response?.data?.full_name,
            profile_picture: response?.data?.profile_picture,
            mobile_no: response?.data?.mobile_no,
            email: response?.data?.email,
          })
        );
        localStorage.setItem(
          "userDetails",
          JSON.stringify(response?.data?.details)
        );
        dispatch(getProfile())
          .then((resultAction) => {
            if (
              resultAction?.payload?.data?.company_added &&
              resultAction?.payload?.data?.company_verified
            ) {
              navigate("/admin/dashboard");
            } else {
              if (resultAction?.payload?.data?.company_added) {
                navigate("/admin/accounts");
              } else {
                navigate("/admin/company-details");
              }
            }
          })
          .catch((error) => {
            // Handle any error if required
            console.error("Error updating experience:", error);
          });
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const handleLoginOTP = async (OTP) => {
    if (OTP.length == 6) {
      try {
        setisLoading(true);
        let body = {
          otp: OTP,
          mobile_no: formik?.values?.mobile,
        };

        let response = await loginWithOTP(body);
        if (response?.status == 200) {
          // showNotification("success", "Login Successfully");
          setisLoading(false);
          setSession(response?.data?.access);
          dispatch(login());
          dispatch(getProfile());
          dispatch(
            setUser({
              full_name: response?.data?.full_name,
              profile_picture: response?.data?.profile_picture,
              mobile_no: response?.data?.mobile_no,
              email: response?.data?.email,
            })
          );
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response?.data?.details)
          );
          if (profile?.is_first_login) {
            navigate("/admin/accounts");
          } else {
            navigate("/admin/dashboard");
          }
        } else {
          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    }
  };

  const getOTP = async (values) => {
    if (formik?.values?.mobile) {
      let body = {
        mobile_no: values?.mobile,
      };
      try {
        let response = await resendLoginOTP(body);
        if (response?.status == 200) {
          showNotification("success", response?.data?.message);
          setloginOTP(true);
          setShowOTP(true);
        } else {
          setShowOTP(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    } else {
      formik?.handleSubmit();
    }
  };

  const handleResend = async () => {
    let body = {
      mobile_no: formik?.values?.mobile,
    };
    try {
      let response = await resendOTP(body);
      if (response?.status == 200) {
        showNotification("sucesss", response?.data?.message);
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="container-xxl px-0 mx-0 ">
        <div className="row d-flex flex-sm-wrap">
          {/* Left part with form UI */}
          <div className="col-md-6 d-none d-md-block">
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              {/* Logo at the top with margin */}
              <img
                src={logo} // Replace with your logo URL
                alt="Logo"
                className="img-fluid" // Bootstrap class for responsive images
                style={{
                  width: "208px",
                  height: "70px",
                  marginBottom: "30px", // Adjust the margin to create space between logo and image
                }}
              />

              <Slider />

              {/* Image centered below the logo */}
            </div>
          </div>

          {/* Right part with image */}
          <div className="col-md-6">
            <div className="authentication-wrapper authentication-basic container-p-y">
              <div className="card p-5 rounded-4 authentication-inner">
                {/* Register */}

                <div>
                  {/* Logo */}

                  {/* /Logo */}
                  <div className="text-center">
                    <h4 className="mb-2 fw-semibold">Welcome to Credence!</h4>
                    <p className="mb-4 fw-normal">
                      Please sign-in to your account and start the adventure
                    </p>
                  </div>
                  <form
                    id="formAuthentication"
                    className="mb-3"
                    onSubmit={formik?.handleSubmit}
                  >
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email/Mobile No
                      </label>
                      <input
                        type="text"
                        className="form-control input-bottom-line"
                        id="mobile"
                        name="mobile"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}
                        placeholder={"Enter your email or mobile no "}
                        maxLength={50}
                        readOnly={loginOTP}
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className="text-danger fs-7">
                          {formik.errors.mobile}
                        </div>
                      ) : null}
                    </div>
                    {showOTP && (
                      <>
                        <OTPInput
                          autoFocus
                          length={6}
                          className="otpContainer"
                          inputClassName="otpInput"
                          onChangeOTP={(otp) => {
                            formik?.setFieldValue("otp", otp);
                            handleLoginOTP(otp);
                          }}
                        />
                        {formik.touched.otp && formik.errors.otp ? (
                          <div className="text-danger fs-7">
                            {formik.errors.otp}
                          </div>
                        ) : null}
                      </>
                    )}
                    {!loginOTP && (
                      <div className="mb-3 form-password-toggle">
                        <div className="d-flex justify-content-between">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                        </div>
                        <div className="input-group input-group-merge">
                          <input
                            type={showPass ? "text" : "password"}
                            id="password"
                            className="form-control input-bottom-line"
                            placeholder="Enter your password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            aria-describedby="password"
                            maxLength={20}
                          />
                          {showPass ? (
                            <span
                              className="input-group-text cursor-pointer input-bottom-line"
                              onClick={() => setShowPass(false)}
                            >
                              <i className="bx bx-hide" />
                            </span>
                          ) : (
                            <span
                              className="input-group-text cursor-pointer input-bottom-line"
                              onClick={() => setShowPass(true)}
                            >
                              <i className="bx bx-show" />
                            </span>
                          )}
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-danger fs-7">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    )}

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <div className="form-check">
                          <input
                            className="form-check-input fs-7 mt-2"
                            type="checkbox"
                            id="remember-me"
                            value={rememberMe}
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                          />
                          <label
                            className="form-check-label fs-7"
                            htmlFor="remember-me"
                          >
                            {" "}
                            Remember Me{" "}
                          </label>
                        </div>
                        {!loginOTP && (
                          <Link to="/forget-password">
                            <small
                              className="fs-7"
                              style={{ color: "#4D4D4D" }}
                            >
                              Forgot Password?
                            </small>
                          </Link>
                        )}
                      
                      </div>
                    </div>
                    <div className="mt-3 mb-3 d-flex justify-content-center">
                      {/* Login Through OTP button on the left */}
                      {loginOTP ? (
                        <button
                          className="btn btn-otp"
                          type="button"
                          onClick={() => {
                            setloginOTP(false);
                            setShowOTP(false);
                          }}
                        >
                          Login With Password
                        </button>
                      ) : (
                        <button
                          className="btn btn-otp"
                          type="button"
                          onClick={() => getOTP(formik?.values)}
                        >
                          Login With OTP
                        </button>
                      )}

                      {/* Sign in button on the right */}
                      {showOTP ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          {loading ? (
                            <button
                              className="btn new-bt"
                              type="submit"
                              disabled
                            >
                              Processing...
                            </button>
                          ) : (
                            <button className="btn  new-bt" type="submit">
                              {loginOTP && !showOTP ? "Get OTP" : "Login"}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </form>
                  <p className="text-center fw-normal fs-6">
                    <span>If you don't have an account register</span> <br />
                    <span>
                      You can{" "}
                      <Link to="/signup">
                        <span className="link-bt">Register here!</span>
                      </Link>
                    </span>
                  </p>
                </div>

                {/* /Register */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
