import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import CompanyImg from "../../Images/helpImg/companyAdd.png";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";

function VerificationDetails() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Submitting Verification Details{" "}
                    </h5>
                    <p>
                      This article provides all the essential information to
                      complete the KYC verification step and begin your
                      rewarding journey with Squid.
                    </p>
                    <hr className="mt-4" />
                    <blockquote className="callout callout_info" theme="📘">
                      <h5 className="callout-heading ">
                        <p className="fw-bold">
                          {" "}
                          What is the Squid account verification process?
                        </p>
                      </h5>
                      <p className="fs-12">
                        We verify your businesses and gift card usage to adhere
                        to regulatory standards. Squid verification ensures
                        tailored assistance by understanding your business and
                        its purpose.
                      </p>
                    </blockquote>
                    <div className="mt-4">
                      <h5>Requirements</h5>
                      <p className="fs-12">
                        You can complete the KYB submission in just minutes.
                        Simply log in to Squid and be ready with below item
                        details.
                      </p>
                    </div>
                    <div className="mt-4">
                      <h5>Company Details</h5>
                      <p className="fs-12">
                        We need some basic company details as follows:
                      </p>
                      <ol>
                        <li>Registered Business Name</li>
                        <li>Company Address</li>
                        <li>Website (Optional)</li>
                        <li>Industry</li>
                        <li>Business Type</li>
                        <li>Country, State and City of Registration</li>
                        <li>
                          Registered Identification Number (e.g., GST, CIN NO)
                        </li>
                        <li>Upload GST Document</li>
                      </ol>
                    </div>
                    <img className="" width={"80%"} src={CompanyImg} alt="" />

                    <blockquote
                      className="callout callout_info mt-2"
                      theme="📘"
                    >
                      <h5 className="callout-heading ">
                        <p className="fw-bold">Next Steps</p>
                      </h5>
                      <p className="fs-12">
                        Expect an acknowledgment email within 2 hours. Our
                        compliance team will verify your details for account
                        approval, any additional information needed will be
                        requested via email.
                      </p>
                      <p>Feel free to reach out to <Link>sales@credencerewards.com</Link> anytime.</p>
                    </blockquote>
                  </div>
                  <hr />
                  <div className="mt-4">
                 <BackButton/>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default VerificationDetails;
