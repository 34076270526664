import React from "react";

const Policy = () => {
  return (
    <div className=" p-5 " style={{ textAlign: "justify" }}>
      <h1 className="text-center">Privacy & Policy</h1>
      <div className="mt-5">
        <h3>Introduction</h3>
        <h5>Your privacy is important to us !!</h5>
        <p style={{ textAlign: "justify" }}>
          Squid is committed to respect your privacy while using our website and
          products. This SquidPrivacyPolicy (“Policy”) defines the requirements
          to ensure compliance with the applicable data privacy laws and
          regulations applicable to Squid collection, use, and transmission of
          Personal Data and SensitivePersonalData for information collected by
          us about you.
        </p>
        <p style={{ textAlign: "justify" }}>
          This website is operated by Credence Rewards Private Limited, a
          Private Limited Company also referred as Squid (“we”,”us” or “our”).
          This privacy policy (“Policy”) explains how we collect, use and
          disclose information about our users , our Web site (the “Site”) and
          other online products and services that link to this Policy
          (collectively, the “Service”). We refer throughout this Policy to our
          users as “User,” “you,”or“your,” and we also refer to users as,
          “Potential Customers” to denote those visiting or site or requesting
          information regarding our Services, “Customer Company” to denote our
          organizational customer, and“Employee User” to denote individual
          employees of Customer Company who are users of theSite, and the
          Service through their employer.
        </p>
        <p style={{ textAlign: "justify" }}>
          By using the Service, you consent to our collection, use and
          disclosure of your personal information as described in this Policy.
          Protecting the privacy rights of data subjects and safeguarding their
          Personal Data is now being treated as a basic right of an individual
          and a legal requirement in many parts of world,being a global
          organization, respects the privacy of data subjects and is committed
          to complying with the applicable data privacy laws and legislation
          related to Information Technology Act 2000 read along with the
          Information Technology (Reasonable Security Practices and Procedures
          and SensitivePersonal Dataor Information) Rules, 2011 and other
          applicable privacy laws to the extent that they apply to Squid data
          processing and business operations) (the “Data Privacy Laws”)
        </p>
        <p style={{ textAlign: "justify" }}>
          We take your privacy seriously. If you have any questions about this
          Policy or about privacy at Squid,please contact us at
          support@credencerewards.com.
        </p>
      </div>
      <div className="mt-5">
        <h3>This privacy policy describes:</h3>
        <ol>
          <li>The information We collect,</li>
          <li>how we do so and the purposes of our collection</li>
          <li>How We use and with whom We share such information</li>
          <li>How you can access and update such information</li>
          <li>The choices you can make about how We collect</li>
          <li>use and share your information</li>
          <li>How We protect the information we store about you</li>
          <li>
            Aggregate or scrape any content, data, or other information from the
            Website to be aggregated or shown with material from other sites or
            on a secondary site without our express written permission
          </li>
          <li>
            If you access our Services from a third-party site, you may be
            required to also read and accept the third party’s terms of service
            and privacy policy.
          </li>
        </ol>
      </div>
      <div className="mt-5">
        <h3>Definitions</h3>
        <p>
          The meaning of some of the terms in use in the Policy are explained
          below:
        </p>
        <h5>Personal Data :</h5>
        <p>
          Any information of “Data Subject” which can reasonably associate or
          link to an identifiable natural person or could include anyone who can
          be identified, directly or indirectly, in particular by reference to
          an identifier such as a name, an identification number, location data,
          an online identifier or to one or more factors specific to the
          physical, physiological, economic, cultural or social identity of that
          natural person
        </p>
        <h5>Sensitive Personal Data :</h5>
        <p>
          Defined as any information revealing an identified or identifiable
          natural person’s racial or ethnic origin, political opinions,
          religious or philosophical beliefs, trade-union membership, and the
          processing of genetic information, biometric information for the
          purpose of uniquely identifying a natural person, data concerning
          health, or information concerning an individual’s sex life or sexual
          orientation, and data relating to offenses, or criminal convictions.
        </p>
        <h5>Process, Processes, Processed or Processing :</h5>
        <p>
          Means any operation or set of operations which is performed on
          Personal Data or Personal Information or Sensitive Personal Data or on
          sets of Personal Data or Personal Information or Sensitive Personal
          Data, whether or not by automated means, such as collection,
          recording, organization, structuring, storage, adaptation or
          alteration, retrieval, consultation, use, disclosure byt transmission,
          dissemination or otherwise making available, alignment or combination,
          restriction,erasure or destruction.
        </p>
        <h5>Consent :</h5>
        <p>
          Any freely given, specific, informed, and unambiguous indication of
          the Data Subject’s wishes by which the Processing of their Personal
          Data, Personal Information and/or Sensitive Personal Data via a
          statement or by a clear affirmative action, signifies agreement to the
          processing of their Personal Data, Personal Information and/or
          Sensitive Personal Data.
        </p>
        <h5>Data Subject :</h5>
        <p>
          Relates to a particular natural person (i.e., an identified or
          identifiable natural person to whom the Personal Data relates. In case
          of a minor/ individual with mental disabilities, the data subject
          would be represented by a legal representative (parent/ guardian).
        </p>
        <p>
          For the purpose of clarity of this Policy, “Data Subject” means Squid
          current and previous employees, prospective candidates, current,
          prospective and previous customer personnel, current and previous
          partner/vendor personnel, website visitors, sub-contractors and
          visitors.
        </p>
        <p>
          Squid does not collect Personal Data/ Personal Information and
          Sensitive Personal Information from Data Subjects that are under the
          age of 18.
        </p>
      </div>
      <div className="mt-5">
        <h5>Data Controller :</h5>
        <p>
          Means a person or organization who (either alone, or jointly, or in
          common) determines the purposes for which and the manner in which any
          Personal Data are, or are to be, Processed. For the purposes of this
          Policy, references to Data Controller shall mean references to Squid
          and its affiliates, where relevant.
        </p>
      </div>
      <div className="mt-5">
        <h5>Data Processor</h5>
        <p>
          Is a person or organization who Processes the Personal Data on behalf
          of and under the instruction of the Data Controller.
        </p>
      </div>
      <div className="mt-5">
        <h5>Third Party :</h5>
        <p>
          In relation to Personal Data or Personal Information or Sensitive
          Personal Data means any person other than the Data Subject, the Data
          Controller, or any Data Processor or other person authorized to
          process data for the Data Controller.
        </p>
      </div>
      <div className="mt-5">
        <h3>Personal Information we collect and process and howwe use it</h3>
        <h5>Information we collect from customer company</h5>
        <p>
          When a Company indicates interest in our Service, we collect the
          following information via our sign-up form: Company Name,Address, CIN,
          GSTIN, PAN, Industry Type, Business Category, website link(if
          available) and user details such as Name, Email Address, Phone Number.
          We collect this information through a landing page which an interested
          Company might access through forms on various directory services
          detailed in our Third-Party Provider.
        </p>
      </div>
      <div className="mt-5">
        <h3>Payment information</h3>
        <p>
          If a third party is not paying for the service on your behalf, We will
          collect the billing and financial information necessary to process
          your charges for Squid services which require payment, which may
          include your postal and e-mail addresses. Squid may also receive the
          billing and payment information that you provide when your purchase is
          processed by another party, such as payment viaQRcode, Credit card,
          Debit card, Corporate Credit card, UPI etc. Our Terms of Service
          explain our policies and terms relevant to our charges and billing
          practices.
        </p>
        <p>
          Squid does not save or collected any information such as card number,
          name, CVV or other information made via card payments.
        </p>
      </div>
      <div className="mt-5">
        <h5>Technical and usage information</h5>
        <p>When you access our websites or use our Services, we collect -</p>
        <p>
          1. Certain technical information about your mobile device or computer
          system, including IPAddress and mobile device ID; and
        </p>

        <p>
          2. Usage statistics about your interactions with the Service. This
          information is typically collected using server log files or web log
          files (“Log Files”), mobile device software development kits and
          tracking technologies like browser cookies to collect and analyze
          certain types of technical information. Some of the cookies the
          Service places on your computer are linked to your user ID number(s).
        </p>
      </div>
      <div className="mt-5">
        <h5>Cookies and automated information collection</h5>
        <p>
          When you access the Service, we collect certain technical information
          in order to -
        </p>
        <ol>
          <li>Analyze the usage of our sites and services;</li>
          <li>Provide a more personalized experience; and</li>
          <li>Manage testimonials</li>
          <li>
            You can set your web browser to warn you about attempts to place
            cookies on your computer or limit the type of cookies you allow.
          </li>
        </ol>
      </div>
      <div className="mt-5">
        <h3>Other sources</h3>
        <p>
          We may collect or receive information from other sources including
          third party information providers. This information will be used to
          supplement your profile - primarily to help you and your friends
          connect. It will be combined with other information We collect.
        </p>
      </div>
      <div className="mt-5">
        <h3>How we use the information we collect</h3>
        <p>
          In general, we collect, store, and use your information to provide you
          with a safe, smooth, efficient, and customized experience. For
          example, we may use information collected from you in any one or more
          of the following ways:
        </p>
        <ul>
          <li>Provide, maintain, and improve our Service.</li>
          <li>
            Provide and deliver the Service Customer Company requests and
            configures, process transactions and send you related information,
            including confirmations
          </li>
          <li>
            Investigate system issues that impact our ability to provide the
            Service to Users
          </li>
          <li>
            Send you technical notices, updates, confirmations, security alerts
            and support and administrative messages
          </li>
          <li>
            Respond to your comments, questions and requests and provide
            customer service.
          </li>
          <li>
            Communicate to Customer Companies with you about products, services,
            offers, promotions, rewards, and events offered by Squid and others,
            and provide news and information we think will be of interest to
            you.
          </li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with
            our Service and improve and personalize the Service.
          </li>
          <li>
            Personalize and improve the Service, content or features that match
            user profiles or interests.
          </li>
        </ul>
        <p>
          We will not sell, rent, or share Personal Data with third parties
          outside of our company without your consent, except in the following
          ways:
        </p>
      </div>
      <div className="mt-5">
        <h3>Law enforcement and internal operations</h3>
        <p>
          Personal Data may be provided where we are required to do so by law,
          or if we believe ingoodfaiththatit is reasonably necessary.
        </p>
        <ol>
          <li>
            To respond to claims asserted against Squid or to comply with the
            legal process (for example, discovery requests, subpoenas or
            warrants);
          </li>
          <li>
            To enforce or administer our policies and agreements with users.
          </li>
          <li>
            For fraud prevention, risk assessment, investigation, customer
            support, product development andde-bugging purposes; or
          </li>
          <li>
            We will use commercially reasonable efforts to notify users about
            law enforcement or court ordered requests for data unless otherwise
            prohibited by law.
          </li>
          <li>
            However, nothing in this Privacy Policy is intended to limit any
            legal defences or objections that you may have to any third-party
            request to compel disclosure of your information
          </li>
        </ol>
      </div>
      <div className="mt-5">
        <h3>
          Data recipients, transfer, and disclosure of Personal Information
        </h3>
        <h6>
          Squid does not share your Personal Information with third parties for
          their direct marketingpurposes.
        </h6>
        <p>
          We reserve the right to use or disclose your Personal Information if
          required by lawor if wereasonablybelieve that use or disclosure is
          necessary to protect our rights, protect your safety or thesafetyof
          others,investigate fraud, or comply with a law, court order, or legal
          process
        </p>
      </div>
      <div className="mt-5">
        <h3>Business transfer</h3>

        <p>
          Squid may sell, transfer, or otherwise share some or all of its
          assets, including your Personal Data, in connection with a merger,
          acquisition, reorganization or sale of assets or in the event of
          bankruptcy. Under such circumstances, Squid will use commercially
          reasonable efforts to notify its users if their personal information
          is to be disclosed or transferred and/or becomes subject to a
          different privacy policy.
        </p>
      </div>
      <div className="mt-5">
        <h3>Third – parties</h3>

        <p>
          We sometimes contract with other companies and individuals to perform
          functions or services on our behalf, such as software maintenance,
          data hosting, sending email messages, etc. We necessarily have to
          share your Personal Data with such third parties as may be required to
          perform their functions. We take necessary steps to ensure that these
          parties take protecting your privacy as seriously as we do, including
          entering into Data Processing Addendum(s), EU Model Clauses and/or
          ensuring these third-parties have EU-U.S. and Swiss-US Privacy Shield
          certification
        </p>
      </div>
      <div className="mt-5">
        <h3>How is my data protected?</h3>

        <p>
          We have implemented reasonable administrative, technical, and physical
          security measures to protect your personal information against
          unauthorized access, destruction, or alteration. For example:
        </p>
        <ul>
          <li>
            SSL encryption (https) where we deal with personal data. Personal
            Data is encrypted in transit using https/ssl/tls and encrypted at
            rest. Our database is encrypted, and data transferred via sftp is
            encrypted using PGP.
          </li>
          <li>Password protection on your account</li>
          <li>Rotating verification codes to access by some parties.</li>
          <li>Data is kept on secure, encrypted servers</li>
          <li>
            Restricting staff access to Personal Data, protected by password
            logs and two factor authentications
          </li>
          <li>Non-Disclosure Agreements with vendors</li>
          <li>Regular staff privacy and security training</li>
        </ul>
      </div>
      <div className="mt-5">
        <h3>Retention and Disposal of Personal Data or Personal Information</h3>
        <ol>
          <li>User Data: 7 Years from the date of termination of contract</li>
          <li>Employees data: 8 years as per Indian Companies Act</li>
          <li>Financial data: 8 years as per Indian Companies Act</li>
          <li>Audit logs: 1 Year</li>
          <li>Other records: 3 Years</li>
        </ol>
      </div>
      <div className="mt-5">
        <h3>Your rights in relation to your information</h3>
        <ul>
          <li>
            Access: You have the right to access information about the personal
            data we hold about you.
          </li>
          <li>
            Right to be informed about the data that we collect, process and
            store.
          </li>
          <li>
            Right to object to processing: You have the right to object to
            processing of your personal data.
          </li>
          <li>
            Rectification: You have the right to request rectification of
            inaccurate personal data held about you.
          </li>
          <li>
            Erasure: To the extent permitted by applicable data protection laws,
            you have the right to request erasure of personal data held about
            you.
          </li>
          <li>
            Request to restriction of processing: This enables you to request to
            restrict the processing of your personal data in certain
            circumstances
          </li>
          <li>
            Rights in relation to automated decision-making, including profiling
          </li>
          <li>
            Portability: You have the right to obtain your personal data to
            enable you to reuse it.
          </li>
        </ul>
      </div>
      <div>
        <h3>Access</h3>
        <p>
          You have the right to request certain information about our collection
          and use of your Personal Data over the past 12 months. We will provide
          you with the following information:
        </p>
        <ol>
          <li>
            The categories of Personal Data that we have collected about you.
          </li>
          <li>
            The categories of sources from which that Personal Data was
            collected.
          </li>
          <li>
            The business or commercial purpose for collecting or selling your
            Personal Data.
          </li>
          <li>
            The categories of third parties with whom we have shared your
            Personal Data; and
          </li>
          <li>
            The specific pieces of Personal Data that we have collected about
            you.
          </li>
        </ol>
        <p>
          If we have disclosed your Personal Data for a business purpose over
          the past 12 months, we will identify the categories of Personal Data
          shared with each category of third-party recipient as per CCPA/CPRA.
        </p>
      </div>
      <div className="mt-5">
        <h3>Exercising Your Rights</h3>
        <p>
          To exercise the rights described above, you must send us a request
          that
        </p>
        <p>
          (1) provides sufficient information to allow us to verify that you are
          the person about whom we have collected Personal Data (this will
          require you to send an email from the account in question or login
          credentials), and
        </p>
        <p>
          (2) describes your request in sufficient detail to allow us to
          understand, evaluate, and respond to it. Each request that meets both
          of these criteria will be considered a “Valid Request.” We may not
          respond to requests that do not meet these criteria. We will only use
          Personal Data provided in a ValidRequest to verify you and complete
          your request
        </p>
        <p>
          You may also authorize an agent (an “Authorized Agent”) to exercise
          your rights on your behalf. To do this, you must provide your
          Authorized Agent with written permission to do, and we may request a
          copy of this written permission from your Authorized Agent when they
          make a request to exercise your right son your behalf.
        </p>
      </div>
      <div className="mt-5">
        <h3>Complaints and Grievances</h3>
        <p>
          Any complaints or grievances received about our use of Personal Data,
          Personal Information or SensitivePersonal Data and any communications
          regarding enforcement of your privacy rights should be promptly
          directed to our Data Protection Officer Complaints.
        </p>
      </div>
      <div className="mt-5">
        <h5>Contact</h5>
        <p>Attn: Data Protection Officer</p>
        <p>
          Email ID -{" "}
          <a href="support@credencerewards.com">support@credencerewards.com</a>
        </p>
      </div>
      <div className="mt-5">
        <h3>Updates to Our Policy</h3>
        <p>
          We may amend or update our Privacy Policy. We will provide you notice
          of amendments to this Privacy Policy, as appropriate, and update the
          “Last modified” date at the top of this Privacy Policy. Please review
          our Privacy Policy from time to time.
        </p>
      </div>
    </div>
  );
};

export default Policy;
