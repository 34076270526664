import React, { useEffect } from "react";
import img from "../../email_verify.png";
import { useNavigate } from "react-router-dom";

function EmailVerified() {
  const navigate = useNavigate();
  useEffect(() => {
    // Redirect logic
    setTimeout(() => {
      navigate("/admin/dashboard"); // Redirects to the home page after 3 seconds
    }, 3000);
  }, []);

  return (
    <>
      <div
        className="container text-center mt-5"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // This will make the div take the full height of the viewport
        }}
      >
        <img
          src={img} // Replace with the URL of your image
          alt="Centered Image"
          style={{}} // Adjust the dimensions as needed
        />
        <div className="mt-3">
          <h5 style={{ lineHeight: 3 }}>
            Thank you for your email confirmation.Now you can login your account{" "}
          </h5>
        </div>
      </div>
    </>
  );
}

export default EmailVerified;
