import React, { useEffect, useState } from "react";
import { DataGrid, useGridApiRef, GridPagination } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  exportPIReport,
  getFundReport,
  getInvoice,
  getInvoiceReport,
  getPerformaById,
  getPerformaList,
  getSimpleReport,
} from "../services/Apiservices";
import moment from "moment";
import { useSelector } from "react-redux";
import showNotification from "../services/NotificationService";
import Tooltip from "@mui/material/Tooltip";
import MuiPagination from "@mui/material/Pagination";
import { columnIcon, crossIcon, exportIcon, filterIcon } from "../constant";
import "../App.css";
import {
  Box,
  Button,
  Modal,
  Toolbar,
  FormControlLabel,
  Grid,
  Checkbox,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
const buttonStyle = {
  // Change this to your desired color
  color: "black",

  borderRadius: "6px",
  border: "0.5px solid #A0A0A0",
  padding: "8px",
  margin: "0 5px",
  minWidth: "auto",
  height: "28px",
  fontSize: "12px"
};


function PITab() {
  const columns = [
    // Define your columns based on the API data structure
    {
      field: "pis_id",
      headerName: "PI No",
      flex: 1,
      maxWidth: 190,
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },

    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      maxWidth: 130,
      renderCell: (params) => {
        const date = params.value;

        return moment(date).format("ll");
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      maxWidth: 190,

      renderCell: (params) => {
        return (
          <div>
            {params?.row?.transaction_type == "credit" ? (
              <span>
                {/* <i class="bx bx-chevron-up bx-flip"></i> */} ₹{" "}
                {params?.row?.amount}
              </span>
            ) : (
              <span className="fw-normal">
                {/* <i class="bx bx-chevron-down bx-flip"></i> */} ₹{" "}
                {params?.row?.amount}
              </span>
            )}
          </div>
        );
      },
    },

    { field: "po_number", headerName: "PO Number", flex: 1, maxWidth: 300 },
    { field: "email", headerName: "Email", flex: 1 },

    // {
    //   field: "payment_status",
    //   headerName: "Email",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const status = params.value;
    //     let cellStyle = {};

    //     // Customize styles based on the status
    //     switch (status) {
    //       case "Success":
    //         cellStyle = {
    //           backgroundColor: "green",
    //           color: "white",
    //           borderRadius: "20px",
    //           fontSize: "11px",
    //         };
    //         break;
    //       case "rejected":
    //         cellStyle = {
    //           backgroundColor: "red",
    //           color: "white",
    //           borderRadius: "20px",
    //           fontSize: "11px",
    //         };
    //         break;
    //       case "pending":
    //         cellStyle = {
    //           backgroundColor: "orange",
    //           color: "white",
    //           borderRadius: "20px",
    //           fontSize: "11px",
    //         };
    //         break;
    //       default:
    //         break;
    //     }

    //     return (
    //       <div className="p-1 px-1 " style={cellStyle}>
    //         {status}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "payment_id",
      headerName: "Action",
      flex: 1,
      maxWidth: 100,
      renderCell: (params) => {
        const data = params;

        return (
          <>
            <span
              onClick={() => handleDownloadInvoice(data?.row?.id)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="30"
                height="29"
                viewBox="0 0 30 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.166504"
                  width="29"
                  height="29"
                  rx="14.5"
                  fill="#FFF5F6"
                />
                <g clip-path="url(#clip0_2215_17207)">
                  <path
                    d="M18.3577 14.3287C18.2906 14.1804 18.1431 14.086 17.9807 14.086H16.3236V8.70024C16.3236 8.47156 16.138 8.28596 15.9093 8.28596H14.2521C14.0234 8.28596 13.8378 8.47156 13.8378 8.70024V14.086H12.1807C12.0183 14.086 11.8708 14.1812 11.8037 14.3287C11.7358 14.477 11.7623 14.6502 11.8692 14.7728L14.7692 18.0871C14.8479 18.1774 14.9614 18.2288 15.0807 18.2288C15.2 18.2288 15.3135 18.1766 15.3922 18.0871L18.2922 14.7728C18.4 14.651 18.4248 14.477 18.3577 14.3287Z"
                    fill="#594DF1"
                  />
                  <path
                    d="M19.6379 17.4002V19.886H10.5236V17.4002H8.86646V20.7145C8.86646 21.1727 9.23766 21.5431 9.69503 21.5431H20.4665C20.9247 21.5431 21.295 21.1727 21.295 20.7145V17.4002H19.6379Z"
                    fill="#594DF1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2215_17207">
                    <rect
                      width="13.2571"
                      height="13.2571"
                      fill="white"
                      transform="translate(8.45215 8.28596)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </>
        );
      },
    },

    // Add more columns as needed
  ];
  const apiRef = useGridApiRef();
  const [loading, setisLoading] = useState(false);
  const userData = useSelector((state) => state?.profile?.data?.data);
  const [list, setList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const handleFilterOpen = () => setFilterOpen(true);
  const handleFilterClose = () => setFilterOpen(false);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    columns.reduce((acc, column) => {
      acc[column.field] = true;
      return acc;
    }, {})
  );

  const [filters, setFilters] = React.useState({
    pi_id: "",
    status: "",
    po_number: "",
    email: "",
    created_from: "",
    created_to: "",
  });

  const handleColumnVisibilityChange = (field) => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      [field]: !prevModel[field],
    }));
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (userData?.company_added) {
      getReports();
    }
  }, [page, pageSize]);

  const getReports = async (filterData) => {
    setisLoading(true);
    try {
      const offset = page * pageSize;
      const limit = pageSize;
      let response = await getPerformaList(limit, offset, filterData);
      if (response?.status == 200) {
        setList(response?.data?.results || []);
        setisLoading(false);
        setRowCount(response?.data?.total_count);
      } else {
        setFilters({
          pi_id: "",
    status: "",
    po_number: "",
    email: "",
    created_from: "",
    created_to: "",
        });
        setisLoading(false);
      }
    } catch (error) {
      setFilters({
        pi_id: "",
        status: "",
        po_number: "",
        email: "",
        created_from: "",
        created_to: "",
      });
      setisLoading(false);
    }
  };

  const handleDownloadInvoice = async (id) => {
    try {
      let response = await getPerformaById(id);
      if (response?.status == 200) {
        const blob = new Blob([response?.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to initiate download
        const link = document.createElement("a");
        link.href = url;
        link.download = `proforma_${id}_invoice.pdf`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        // showNotification("success",response?.data)
      } else {
      }
    } catch (error) { }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "80%",
    width: "450px",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 1,
  };
  const filterstyle = {
    position: "absolute",
    top: "45%",
    left: "65%",
    width: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 3,
  };
  const handlePaginationChange = (newModel) => {
    setPaginationModel(newModel);
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  };

  const applyFilters = () => {
    getReports(filters)
    handleFilterClose();
  };

  function Pagination({ page, onPageChange, className }) {
    const pagecount = Math.ceil(rowCount / pageSize);
    return (
      rowCount > pageSize && (
        <MuiPagination
          color="primary"
          className={className}
          count={pagecount}
          page={page + 1}
          onChange={(event, newPage) => {
            onPageChange(event, newPage - 1);
          }}
        />
      )
    );
  }

  function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }
  const handleExportReport = async () => {
    try {
      let response = await exportPIReport(filters);
      if (response?.status == 200) {
        // Assuming response.data contains the Excel file data
        const blob = new Blob([response?.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }); // Mime type for Excel (XLSX) files

        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to initiate download
        const link = document.createElement("a");
        link.href = url;
        link.download = `pi_report.xlsx`; // Change file extension to .xlsx
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        // showNotification("success",response?.data)
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      // Handle errors if any
    }
  };
  const handleCrossIconClick = (event) => {
    event.stopPropagation(); // Prevent the click from propagating to the Button
    setFilters({
      pi_id: "",
      status: "",
      po_number: "",
      email: "",
      created_from: "",
      created_to: "",
    });
    getReports();
  };
  const isFilter=Object.values(filters).some(value => value !== "");
  return (
    <div>
      {list?.length !== 0 ? (
        <>
          {" "}
          <div
            className="d-flex justify-content-end"
            style={{ padding: "5px", backgroundColor: "#f5f7fa" }}
          >
            <Button onClick={handleOpen} style={buttonStyle}>
              {columnIcon}{" "} Columns
            </Button>
            <Button style={buttonStyle} onClick={handleFilterOpen}>
              <div >
                {filterIcon} Filters
              </div>
              {
                isFilter &&    <div onClick={handleCrossIconClick}>
                {crossIcon}
              </div>
              }
           
            </Button>
            <Button style={buttonStyle} onClick={handleExportReport}>
              {exportIcon} Export
            </Button>
          </div>
          <Modal open={filterOpen} onClose={handleFilterClose}>
            <Box sx={filterstyle}>
              <Toolbar>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="PI Id"
                      variant="outlined"
                      value={filters.pi_id}
                      onChange={(e) =>
                        handleFilterChange("pi_id", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="PO Number"
                      variant="outlined"
                      value={filters.po_number}
                      onChange={(e) =>
                        handleFilterChange("po_number", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      value={filters.email}
                      onChange={(e) =>
                        handleFilterChange("email", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={filters.status}
                        onChange={(e) =>
                          handleFilterChange("status", e.target.value)
                        }
                        label="Status"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="failed">Failed</MenuItem>
                        {/* Add more statuses as needed */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Created From"
                      variant="outlined"
                      type="date"
                      value={filters.created_from}
                      onChange={(e) =>
                        handleFilterChange("created_from", e.target.value)
                      }
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Created To"
                      variant="outlined"
                      type="date"
                      value={filters.created_to}
                      onChange={(e) =>
                        handleFilterChange("created_to", e.target.value)
                      }
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="btn btn-secondary rounded me-2"
                  onClick={() => {
                    setFilters({
                      pi_id: "",
                      email: "",
                      po_number: "",
                      status: "",
                      created_from: "",
                      created_to: "",
                    });
                    getReports();
                  }}
                >
                  Reset
                </button>

                <button
                  className="btn btn-primary rounded"
                  onClick={applyFilters}
                >
                  Apply
                </button>
              </div>
            </Box>
          </Modal>
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Toolbar>
                <Grid container spacing={2}>
                  {columns.map((column) => (
                    <Grid item xs={12} sm={6} md={6} key={column.field}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={columnVisibilityModel[column.field]}
                            onChange={() =>
                              handleColumnVisibilityChange(column.field)
                            }
                          />
                        }
                        label={column.headerName}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Toolbar>
            </Box>
          </Modal>
          <DataGrid
            apiRef={apiRef}
            columns={columns}
            rows={list}
            slots={{
              pagination: CustomPagination,
            }}
            rowCount={rowCount}
            loading={loading}
            pageSizeOptions={[5, 10, 25, 50]}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            initialState={{
              ...list,
              pagination: { rowCount: -1 },
            }}
            disableRowSelectionOnClick={true}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        </>
      ) : (
        <>
          {loading ? <div
            className="d-flex justify-content-center bg-white align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>

          </div> : <div
            className="d-flex justify-content-center bg-white align-items-center"
            style={{ height: "70vh" }}
          >
            No PI Found!

          </div>}

        </>
      )}
    </div>
  );
}

export default PITab;
