import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import { Link } from "react-router-dom";
import sendSelfImg from "../../Images/helpImg/profiledrop.png";
import dataImg from "../../Images/helpImg/profileManage.png";
import BackButton from "./BackButton";


function ProfileManage() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Profile Management{" "}
                    </h5>
                    <p>Learn more about how to manage your profile.</p>
                    <hr className="mt-4" />

                    <p>Just follow these steps:</p>
                    <ol>
                      <li>Click the Profile Icon located at the top right corner to review and edit profile details</li>



                      <img
                        className="mt-4  mb-2"
                        width={"80%"}

                        src={sendSelfImg}
                        alt=""
                      />

                      <li>
                        Users can edit their name and profile picture. Save changes to update.

                      </li>
                      <img
                        className="mt-4  mb-2"
                        width={"80%"}
                        src={dataImg}
                        alt=""
                      />
                    </ol>

                    <hr />
                    <div className="mt-4">
                    <BackButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default ProfileManage;
