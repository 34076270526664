import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import showNotification from "../../services/NotificationService";
import { useDispatch } from "react-redux";
import { setMobileNumber } from "../../redux/reducers/mobileNumberSlice";
import { forgotPassowrd } from "../../services/Apiservices";
import logo from "../../CredLogo.png"
import LoginScreen from "../../forgot.png";



function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setisLoading] = useState(false);
  useEffect(() => {
    // Disable scroll when the component mounts
    document.body.style.overflow = "hidden";
    document.body.style.paddingTop = "0"; // Reset padding-top
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;


    // Re-enable scroll when the component unmounts
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      
    };
  }, []);


  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile number must contain only digits")
        .min(10, "Mobile number must be at least 10 digits")
        .required("Mobile number is required"),
    }),

    onSubmit: (values) => {
      // You can handle the form submission here
      handleLogin(values);
      // navigate("/admin/dashboard")
    },
  });
  const handleLogin = async (values) => {
    setisLoading(true);
    let body = {
      mobile_no: values?.mobile,
    };
    try {
      let response = await forgotPassowrd(body);
      if (response?.status == 200) {
        // showNotification("success", "Login Successfully");
        setisLoading(false);
        dispatch(setMobileNumber(values?.mobile));
        navigate("/reset-password");
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  return (
    <>
     <div className="container-xxl px-0 mx-0 ">
        <div className="row">
          {/* Left part with form UI */}
          <div className="col-md-6 d-none d-md-block">
            <div
              className="d-flex flex-column align-items-center justify-content-center h-100"
              style={{ position: "relative" }}
            >
              {/* Logo at the top with margin */}
              <img
                src={logo} // Replace with your logo URL
                alt="Logo"
                className="img-fluid " // Bootstrap class for responsive images
                style={{
                  width: "208px",
                  height: "70px",
                  marginBottom: "30px", // Adjust the margin to create space between logo and image
                }}
              />

              {/* Image centered below the logo */}
              <img
                src={LoginScreen} // Replace with your image URL
                alt="Image"
                className="img-fluid" // Bootstrap class for responsive images
                style={{
                  objectFit: "contain",
                  width: "100%", // Use 100% width for responsiveness
                  maxHeight: "437px", 
                  borderRadius:"32px"
                }}
              />
            </div>
          </div>
          {/* Right part with image */}
          <div className="col-md-6">
            <div className="authentication-wrapper authentication-basic container-p-y">
              <div className="card p-5 rounded-4 authentication-inner">
                {/* Register */}

                <div>
                  {/* Logo */}
                
                {/* /Logo */}
                <div className="text-center">
                <h4 className="mb-3 fw-semibold">Forgot Password? 🔒</h4>
                <p className="mb-4 fw-normal">
                  Enter your Mobile No and we'll send you otp for login
                </p>
                  </div>
               
                <form
                  id="formAuthentication"
                  className="mb-3"
                  onSubmit={formik?.handleSubmit}
                >
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Mobile No
                    </label>
                    <input
                      type="text"
                      className="form-control input-bottom-line"
                      id="mobile"
                      name="mobile"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile}
                      placeholder="Enter your mobile no"
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="text-danger fs-7">{formik.errors.mobile}</div>
                    ) : null}
                  </div>
                  <div className=" d-flex justify-content-between">
                  <button
                    className="btn new-bt "
                    type="submit"
                  >
                    Send OTP
                  </button>
                  <div className="text-center d-flex align-items-center justify-content-center" style={{position:'relative',zIndex:999}}>
                  <Link
                    to="/"
                  className="fs-7"
                  style={{color:"#4D4D4D"}}
                  >
                   {" "} <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm" />
                     Back to login
                  </Link>
                </div>
</div>
                
                </form>
             
                </div>

                {/* /Register */}
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}

export default ForgetPassword;
