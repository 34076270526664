import React, { useState, useRef, useEffect } from "react";
import logo from "../CredLogo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import * as Yup from "yup";
import { restrictAlpha } from "../constant";
import {
  getInvitationConfirmOtp,
  getInvitationOtp,
} from "../services/Apiservices";
import OTPInput from "./Auth/OTPInput";

const Invitation = () => {
  const [showOTP, setShowOTP] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5 * 60);
  const [errMsg, setErroMsg] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("user");
  const userId = queryParams.get("auth");
  const email = queryParams.get("email");

  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile number must contain only digits")
        .min(10, "Mobile number must be at least 10 digits")
        .required("Required"),
    }),

    onSubmit: (values) => {
      getOTP(values);
    },
  });
  useEffect(() => {
    let interval;

    if (otpSent) {
      interval = setInterval(() => {
        setTimeLeft((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setOTPSent(false);
            return 5 * 60; // Reset the timer to initial value when it reaches 0
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [otpSent]);

  const getOTP = async (values) => {
    let body = {
      email,
      user_id: userId,
      token,
      mobile_no: values?.mobile,
    };
    try {
      let response = await getInvitationOtp(body);

      if (response?.status == 200) {
        // showNotification("success", response?.data?.message);
        setOTPSent(true);
        setShowOTP(true);
      } else {
        setShowOTP(false);
      }
    } catch (error) {
      setShowOTP(false);
    }
  };

  const handleLoginOTP = async (OTP) => {
    if (OTP.length == 6) {
      try {
        let body = {
          otp: OTP,
          email,
          user_id: userId,
          token,
        };

        let response = await getInvitationConfirmOtp(body);
        if (response?.status == 200) {
          // showNotification("success", "Login Successfully");
          navigate("/verified");
          setOTPSent(false);
          setShowOTP(false);
        } else {
          setErroMsg(response?.response?.data?.message);
        }
      } catch (error) {}
    }
  };

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  return (
    <div>
      <div className="  " style={{ backgroundColor: "white", height: "100vh" }}>
        <div className="d-flex justify-content-center py-4 align-items-center">
          <img width={"11%"} src={logo} alt="" />
        </div>
        {!showOTP && (
          <>
            <h1
              className="fs-12 py-4 pt-12 fw-semibold text-center"
              style={{ fontSize: "34px" }}
            >
              Thanks! Your Email Has Been Verified
            </h1>
            <h3 className="fs-5 fw-normal  text-center mb-3">
              Please enter your Mobile number to verify and continue with Squid
              Admin Account.
            </h3>
          </>
        )}

        {!showOTP ? (
          <form className="d-flex flex-column align-items-center  mt-5">
            <input
              type="text"
              name="mobile"
              className="p-2  "
              style={{
                outline: "none",
                borderRadius: "5px",
                border: "1.75px solid ",
                width: "250px",
                backgroundColor: "rgba(211, 211, 211, 0.2)",
              }}
              placeholder="Enter Your Mobile Number"
              maxLength={10}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              onKeyPress={restrictAlpha}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="text-danger fs-7">{formik.errors.mobile}</div>
            ) : null}
            <button
              type="submit"
              className="mt-4 px-4 py-2 bg-purple-500 font-semibold text-white rounded-md"
              style={{
                outline: "none",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#594DF1",
              }}
              onClick={formik?.handleSubmit}
            >
              Submit
            </button>
          </form>
        ) : (
          <form className="mt-2">
            <div className="fs-5 fw-bold  text-center mb-3">Enter OTP</div>
            <div className="d-flex space-x-2 justify-content-center">
              <OTPInput
                autoFocus
                length={6}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={(OTP) => {
                  // setEmailOtp(OTP);
                  handleLoginOTP(OTP);
                }}
              />
              {/* {errMsg && <div className="text-danger fs-7">{errMsg}</div>} */}
            </div>

            <div className="text-center  mt-3">
              {" "}
              Didn't you receive any code?{" "}
              {otpSent ? (
                <span
                  className="text-primary fw-semibold cursor-pointer"
                  style={{ color: "black" }}
                >
                  Resend OTP {formatTime()}
                </span>
              ) : (
                <span
                  className="text-primary fw-semibold cursor-pointer"
                  style={{ color: "#594DF1" }}
                  onClick={() => getOTP(formik?.values)}
                >
                  Resend OTP
                </span>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Invitation;
