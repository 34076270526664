import React, { useEffect } from "react";

import { Route, Routes } from "react-router";
import Dashboard from "./Components/Dashboard";
import AddFunds from "./Components/AddFunds";
import SendRewards from "./Components/SendRewards";
import Reports from "./Components/Reports";
import Profile from "./Components/Profile";
import Accounts from "./Components/Accounts";
import ManageAdmins from "./Components/ManageAdmins";
import AddAdmin from "./Components/AddAdmin";
import AllTemplate from "./Components/AllTemplate";
import PaymentSelectPage from "./Components/PaymentSelectPage";
import CompanyDetails from "./Components/CompanyDetails";
import InvitationList from "./Components/InvitationList";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./redux/actions/profileAction";
import PageNotFound from "./Components/PageNotFound";
import { getToken } from "./session";
import Support from "./Components/Support";
import VoucherCatalog from "./Components/VoucherCatalog";

import NotAuthorized from "./Components/Auth/NotAuthorized";
import { useNavigate } from "react-router-dom";
import PerfomaInvoice from "./Components/PerfomaInvoice";
import OverView from "./Components/HelpCenter/OverView";
import VerificationDetails from "./Components/HelpCenter/VerificationDetails";
import RechargeAndWallet from "./Components/HelpCenter/RechargeAndWallet";
import AddAdminSteps from "./Components/HelpCenter/AddAdminSteps";
import SendBulkStep from "./Components/HelpCenter/SendBulkStep";
import SendIndividualStep from "./Components/HelpCenter/SendIndividualStep";
import SendSelfStep from "./Components/HelpCenter/SendSelfStep";
import ProfileManage from "./Components/HelpCenter/ProfileManage";
import HelpHome from "./Components/HelpCenter/HelpHome";
import ReportManage from "./Components/HelpCenter/ReportManage";
import SendRewardHome from "./Components/HelpCenter/SendRewardHome";
import CredenceCode from "./Components/CredenceCode";
function PlumAdminRoutes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.profile?.data?.data);
  const permissions = data?.permissions?.map((data) => data?.id);

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  useEffect(() => {
    // Check if the user is already logged in

    // Reset timer on user activity
    const resetTimer = () => {
      clearTimeout(logoutTimer);
      startLogoutTimer();
    };

    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keypress", resetTimer);

    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keypress", resetTimer);
    };
  }, []);
  let logoutTimer;

  const startLogoutTimer = () => {
    logoutTimer = setTimeout(() => {
      // Clear token and log out
      sessionStorage.clear();
      navigate("/");
      // Redirect to login page if needed
      // window.location.href = '/login';
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  };

  return (
    <>
      <Routes>
        {getToken() ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/add-funds"
              element={
                permissions?.includes(5) && data?.company_added ? (
                  <AddFunds />
                ) : (
                  <NotAuthorized />
                )
              }
            />
            <Route
              path="/proforma-invoice"
              element={
                permissions?.includes(5) && data?.company_added ? (
                  <PerfomaInvoice />
                ) : (
                  <NotAuthorized />
                )
              }
            />
            {/* <Route
              path="/send-rewards"
              element={
                permissions?.includes(1) && data?.company_verified ? <SendRewards /> : <NotAuthorized />
              }
            /> */}
            <Route path="/send-rewards" element={<SendRewards />} />
            <Route
              path="/report"
              element={
                permissions?.includes(3) ? <Reports /> : <NotAuthorized />
              }
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/manage-admins" element={<ManageAdmins />} />
            <Route path="/add-admins" element={<AddAdmin />} />
            <Route path="/edit-admin/:id" element={<AddAdmin />} />
            <Route path="/all-template" element={<AllTemplate />} />
            <Route path="/payment-select" element={<PaymentSelectPage />} />
            <Route path="/company-details" element={<CompanyDetails />} />
            <Route path="/invitations" element={<InvitationList />} />
            <Route path="/support" element={<Support />} />
            <Route path="/rewards-catalogue" element={<VoucherCatalog />} />
            <Route path="/credence-code" element={<CredenceCode />} />
            <Route path="/help-center" element={<OverView />} />
            <Route
              path="/submitting-verification-details"
              element={<VerificationDetails />}
            />
            <Route path="/wallet-management" element={<RechargeAndWallet />} />
            <Route path="/add-admin-user" element={<AddAdminSteps />} />
            <Route path="/send-reward" element={<SendRewardHome />} />

            <Route path="/send-reward-bulk" element={<SendBulkStep />} />
            <Route
              path="/send-reward-individual"
              element={<SendIndividualStep />}
            />
            <Route path="/send-reward-self" element={<SendSelfStep />} />
            <Route path="/profile-management" element={<ProfileManage />} />
            <Route path="/report-management" element={<ReportManage />} />

            <Route path="/help-center-home" element={<HelpHome />} />

            <Route path="*" element={<PageNotFound />} />
          </>
        ) : (
          // <Route path="*" element={<PageNotFound />} />
          <></>
        )}
      </Routes>
    </>
  );
}

export default PlumAdminRoutes;
