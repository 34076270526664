import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import AddFundImg from "../../Images/helpImg/addFund.png";
import AddFundImg2 from "../../Images/helpImg/proForma.png";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";

function RechargeAndWallet() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Wallet Management{" "}
                    </h5>
                    <p>
                      This article you'll find everything on managing your Squid
                      pre-funded wallet and adding Funds.
                    </p>
                    <hr className="mt-4" />
                    <blockquote className="callout callout_info" theme="📘">
                      <h5 className="callout-heading ">
                        <p className="fw-bold"> Suggestion</p>
                      </h5>
                      <p className="fs-12">
                        You can invite your colleagues to co-manage your account
                        by adding admins through the{" "}
                        <Link to="/admin/add-admin-user">Add Admin User</Link>{" "}
                        feature.
                      </p>
                    </blockquote>
                    <div className="mt-4">
                      <h5>Step-by-step Instructions for Adding Funds:</h5>

                      <ol>
                        <li>Log in to your Squid Admin Account.</li>
                        <li>
                          Click on the “Add Funds” section on the left sidebar.
                        </li>
                        <li>
                          Input the desired amount in INR and click on "Proceed
                          to Pay".
                        </li>
                        <li>
                          Payments methods such as UPI, Credit/Debit Cards,
                          Netbanking, Wallets through Razorpay payment gateway
                          can be utilised to complete the payment
                        </li>
                        <li>
                          Verify with an OTP sent before finalizing the
                          payments.
                        </li>
                      </ol>
                    </div>
                    <blockquote className="callout callout_info" theme="📘">
                      <h5 className="callout-heading ">
                        <p className="fw-bold">Pro Tip</p>
                      </h5>
                      <ol>
                        <li>
                          You can optionally enter a PO number at the time of
                          payments which will be reflected in the invoice.
                        </li>
                        <li>
                          Payment confirmation will be sent to the email
                          address, one can edit the email address on Add Funds.
                        </li>
                        <li>
                          You can view the status in the reports section under
                          the Transaction tab.
                        </li>
                        <li>
                          Invoice for the squid funds can be downloaded from the
                          reports section.
                        </li>
                      </ol>
                    </blockquote>
                    <img className="" width={"80%"} src={AddFundImg} alt="" />
                    <div className="mt-4">
                      <h5>Proforma invoice/Prepayment invoice</h5>
                      <p>
                        A Proforma invoice (or pre-payment invoice) is a great
                        way if you want to get your finance team involved to
                        make the payment. Here's how to do this:
                      </p>
                      <ol>
                        <li> Log in to your Squid Admin Account.</li>
                        <li>
                          Click on the "Proforma Invoice" section on the left
                          sidebar..
                        </li>
                        <li>
                          Input the desired amount in INR and click on "Generate
                          PI".
                        </li>
                        <li>Proforma Invoice will be downloaded.</li>
                      </ol>
                    </div>

                    <img className="" width={"80%"} src={AddFundImg2} alt="" />
                  </div>
                  <blockquote className="callout callout_info mt-2" theme="📘">
                    <h5 className="callout-heading ">
                      <p className="fw-bold">Pro Tip</p>
                    </h5>
                    <ol>
                      <li>
                        You can optionally enter a PO number at the time of
                        generating Proforma Invoice.
                      </li>
                      <li>
                        PI will be sent to the email address, one can edit the
                        email address before generating the PI.
                      </li>
                      <li>
                        Proforma Invoice for the squid funds can be downloaded
                        from the reports section.
                      </li>
                    </ol>
                  </blockquote>
                  <hr />
                  <div className="mt-4">
                <BackButton/>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default RechargeAndWallet;
