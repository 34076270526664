import React, { useEffect, useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import {
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Delete";

import { acceptRejectInvi, deleteInvitation, invitationList } from "../services/Apiservices";
import { useNavigate } from "react-router-dom";

function InvitationList() {
  const [invitations, setInvitations] = useState([
    // Add more invitations as needed
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    getInvitaions();
  }, []);

  const getInvitaions = async () => {
    try {
      const response = await invitationList();
      // Assuming API returns an array of user objects

      if (response?.status == 200) {
        setInvitations(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
    }
  };

  const handleAcceptReject = async (id) => {
    var result = window.confirm(
      "Are you sure you want to perform this action?"
    );
    if (result) {
   
      try {
        const response = await deleteInvitation(id);
        // Assuming API returns an array of user objects

        if (response?.status == 200) {
          getInvitaions()
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
      }
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}
              <div className="container-xxl flex-grow-1 container-p-y">
              
              <div className="row d-flex justify-content-end">
                <div className="col-md-8 col-sm-12">
                  <div className="d-flex flex-column">
                    <div>
                      <h4 className="fw-semibold text-primary">
                        <span className="text-muted fw-light"></span>Invitation
                      </h4>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="d-flex justify-content-end">
                   
                      <button
                        type="button"
                        className="btn btn-primary me-3 mb-2"
                        onClick={() => navigate("/admin/manage-admins")}
                      >
                        Admins
                      </button>
                    

                   
                  </div>
                </div>
              </div>
                <div>
                  {invitations?.length !== 0 ? (
                    <TableContainer component={Paper} >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow style={{ backgroundColor: "#B2AEFF" }}>
                            <TableCell align="center">Id</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Department</TableCell>
                            <TableCell align="center">Role</TableCell>

                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invitations?.map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row" align="center">
                                {row?.id}
                              </TableCell>
                              <TableCell align="center">
                                {row?.name}
                              </TableCell>
                              <TableCell align="center">
                                {row?.email}
                              </TableCell>
                              <TableCell align="center">
                                {row?.department}
                              </TableCell>

                              <TableCell align="center">
                                {row?.role == 1
                                  ? "Super Admin"
                                  : row?.role == 2
                                  ? "Admin"
                                  : "Viewer"}
                              </TableCell>

                              <TableCell align="center">
                                {" "}
                                {!row?.user_approved && (
                                  <>
                                       <IconButton
                                    color="primary"
                                    onClick={() =>
                                      navigate(`/admin/edit-admin/${row?.id}`,{state:"invite"})
                                    }
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: "rgb(178 174 255)",
                                        color: "white",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      <CheckIcon
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </Avatar>
                                  </IconButton>
                                    <IconButton
                                      color="secondary"
                                      onClick={() => handleAcceptReject(row?.id)}
                                    >
                                      <Avatar
                                        style={{
                                          backgroundColor: "rgb(178 174 255)",
                                          color: "white",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        <ClearIcon
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      </Avatar>
                                    </IconButton>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant="h6" align="center">
                      No invitations found.
                    </Typography>
                  )}
                </div>
              </div>
              {/* / Content */}
              {/* Footer */}

              {/* / Footer */}
              <div className="content-backdrop fade" />
            </div>

            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}

export default InvitationList;
