import React, { useEffect, useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import profileDummy from "../dummy.png";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import {
  addCompanyDetails,
  changePassword,
  companyVerification,
  editProfile,
  getCompanyIndustry,
  updateCompany,
  uploadDocuments,
} from "../services/Apiservices";
import showNotification from "../services/NotificationService";
import { BASE_URL, restrictAlpha } from "../constant";
import { getProfile } from "../redux/actions/profileAction";
import { logout } from "../redux/reducers/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import gstIcon from "../Images/gstFile.png";
import UploadDocModal from "./UploadDocModal";

function CompanyDetails() {
  const [imagePreview, setImagePreview] = useState(true);
  const [loading, setisLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [industry, setIndustry] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const SUPPORTED_FORMATS = ["application/pdf"];
  useEffect(() => {
    getIndustry();
  }, []);

  const getIndustry = async () => {
    try {
      let response = await getCompanyIndustry();

      if (response?.status == 200) {
        setIndustry(response?.data?.data);
      } else {
      }
    } catch (error) {}
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company name is required"),

    gstNumber: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid GST number format"
      )
      .required("GST number is required"),

    cinNumber: Yup.string()
      .matches(
        /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
        "Invalid CIN Nummber"
      )
      .nullable(),
    website: Yup.string(),
    industry_type: Yup.string().required("Industry is required"),
    business_category: Yup.string().required("Business category is required"),

    address: Yup.string().required("Address is required"),
    country: Yup.mixed().required("Country is required"),
    state: Yup.mixed().required("State is required"),
    city: Yup.mixed().required("City is required"),
    zipcode: Yup.string().required("Zipcode is required"),
    gst_certificate: Yup.mixed()
      .test("fileType", "Unsupported File Format", (value) => {
        if (!value) return true; // If no file is uploaded
        if (!SUPPORTED_FORMATS.includes(value.type)) {
          return false;
        }
        return true;
      })
      .test(
        "fileSize",
        "File size is too large, it should be below 5MB",
        (value) => {
          const maxSize = 5 * 1024 * 1024; // 5MB size limit per file
          if (!value) return true; // If no file is uploaded
          if (value.size > maxSize) {
            return false;
          }
          return true;
        }
      ),
    // cin_certificate: Yup.mixed()
    //   .nullable()
    //   .test(
    //     "fileSize",
    //     "Document is too large, maximum size is 2MB",
    //     (value) => {
    //       if (!value) return true; // No file, no size check
    //       return value.size <= 2 * 1024 * 1024; // 5MB
    //     }
    //   )
    //   .test(
    //     "fileType",
    //     "Unsupported file type, only JPEG, PNG, or PDF are allowed",
    //     (value) => {
    //       if (!value) return true; // No file, no type check
    //       return ["image/jpeg", "image/png", "application/pdf"].includes(
    //         value.type
    //       );
    //     }
    //   ),
    // pan_card: Yup.mixed()
    //   .nullable()
    //   .test(
    //     "fileSize",
    //     "Document is too large, maximum size is 2MB",
    //     (value) => {
    //       if (!value) return true; // No file, no size check
    //       return value.size <= 2 * 1024 * 1024; // 5MB
    //     }
    //   )
    //   .test(
    //     "fileType",
    //     "Unsupported file type, only JPEG, PNG, or PDF are allowed",
    //     (value) => {
    //       if (!value) return true; // No file, no type check
    //       return ["image/jpeg", "image/png", "application/pdf"].includes(
    //         value.type
    //       );
    //     }
    //   ),
  });

  const initialValues = {
    companyName: location?.state?.name,
    gstNumber: location?.state?.gst_no,
    cinNumber: location?.state?.cin_number,
    website: location?.state?.website,
    address: location?.state?.address,
    country: {
      name: location?.state?.country,
      isoCode: location?.state?.country_code,
    },
    state: { name: location?.state?.state },
    city: { name: location?.state?.city },
    zipcode: location?.state?.zipcode,
    gst_certificate: null,
    industry_type: "",
    business_category: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here

      handleSaveDetails(values);
    },
  });

  const Business = [
    { id: 2, name: "Reseller" },
    { id: 3, name: "Channel Partner" },
    { id: 4, name: "Rewards & Recognition" },
  ];
  const handleSaveDetails = async (values) => {
    setisLoading(true);
    let body = {
      name: values?.companyName,
      gst_no: values?.gstNumber,
      cin_number: values?.cinNumber,
      address: values?.address,
      website: values?.website,
      country: values?.country?.isoCode,
      state: values?.state?.name,
      city: values?.city?.name,
      zipcode: values?.zipcode,
      industry_type: values?.industry_type,
      business_category: values?.business_category,
    };
    const formData = new FormData();

    Object.entries(body).forEach(([key, value]) => formData.append(key, value));
    values?.gst_certificate !== null &&
      formData.append("gst_certificate", values?.gst_certificate);
    values?.pan_card && formData.append("pan_card", values?.pan_card);
    values?.cin_certificate &&
      formData.append("cin_certificate", values?.cin_certificate);
    if (location?.state) {
      try {
        let response = await updateCompany(formData);
        if (response?.status == 200) {
          navigate("/admin/accounts");
          setisLoading(false);
        } else {
          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    } else {
      try {
        let response = await addCompanyDetails(formData);
        if (response?.status == 200) {
          handleVerify();
          setisLoading(false);
        } else {
          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    }
  };

  const handleVerify = async () => {
    setisLoading(true);
    try {
      let response = await companyVerification();
      if (response?.status == 200) {
        dispatch(getProfile());
        navigate("/admin/dashboard");
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const handleDownload = () => {
    // Assume sample-file.csv is in the src folder

    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = location?.state?.gst_uploaded;
    anchor.download = "GSTCertificate.pdf";

    // Trigger a click event on the anchor to start the download
    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
  };
  const hasErrors =
    (Object.keys(formik.errors).length > 0 &&
      Object.keys(formik.touched).length > 0) ||
    !formik?.values?.gst_certificate;

  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;
    if (sizeInKB > 1000) {
      return (sizeInKB / 1024).toFixed(2) + " MB";
    } else {
      return sizeInKB.toFixed(2) + " KB";
    }
  };
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}
              <div
                className="container-xxl flex-grow-1 container-p-y"
                style={{ zIndex: 0 }}
              >
                <h4 className="fw-semibold py-3 mb-4">
                  <span className="text-muted fw-light"></span>Company Details
                </h4>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      {/* Account */}

                      <div className="card-body">
                        <form id="formAccountSettings">
                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label htmlFor="firstName" className="form-label">
                                Company Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                id="companyName"
                                name="companyName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.companyName}
                              />
                              {formik.touched.companyName &&
                              formik.errors.companyName ? (
                                <div className="text-danger">
                                  {formik.errors.companyName}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                Website
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  className="form-control select-show"
                                  type="text"
                                  id="website"
                                  name="website"
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "website",
                                      e.target.value.replace(
                                        /^(https?:)?(\/\/)?(www\.)?/i,
                                        ""
                                      )
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                  value={formik.values.website}
                                />
                              </div>
                              {formik.touched.website &&
                              formik.errors.website ? (
                                <div className="text-danger">
                                  {formik.errors.website}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                GST Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  className="form-control"
                                  type="text"
                                  id="gstNumber"
                                  name="gstNumber"
                                  onChange={(e) =>
                                    formik?.setFieldValue(
                                      "gstNumber",
                                      e.target.value.toUpperCase()
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                  value={formik.values.gstNumber}
                                  maxLength={15}
                                />
                              </div>
                              {formik.touched.gstNumber &&
                              formik.errors.gstNumber ? (
                                <div className="text-danger">
                                  {formik.errors.gstNumber}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                CIN Number{" "}
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  className="form-control"
                                  type="text"
                                  id="cinNumber"
                                  name="cinNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.cinNumber}
                                  maxLength={21}
                                />
                              </div>
                              {formik.touched.cinNumber &&
                              formik.errors.cinNumber ? (
                                <div className="text-danger">
                                  {formik.errors.cinNumber}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6 flex flex-column">
                              <div>
                                <label
                                  className="form-label"
                                  htmlFor="phoneNumber"
                                >
                                  Industry<span className="text-danger">*</span>
                                </label>
                              </div>
                              <select
                                className="form-select"
                                name="industry_type"
                                onChange={formik?.handleChange}
                              >
                                <option value="Select Industry">
                                  Select Industry
                                </option>
                                {industry.map((item) => (
                                  <option value={item?.id}>
                                    {item?.title}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.industry_type &&
                              formik.errors.industry_type ? (
                                <div className="text-danger">
                                  {formik.errors.industry_type}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6 flex flex-column">
                              <div>
                                <label
                                  className="form-label"
                                  htmlFor="phoneNumber"
                                >
                                  Business Category
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <select
                                className="form-select"
                                name="business_category"
                                onChange={formik?.handleChange}
                              >
                                <option value="Select Industry">
                                  Select Business Category
                                </option>
                                {Business.map((item) => (
                                  <option value={item?.id}>{item?.name}</option>
                                ))}
                              </select>
                              {formik.touched.business_category &&
                              formik.errors.business_category ? (
                                <div className="text-danger">
                                  {formik.errors.business_category}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                Address <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  className="form-control"
                                  type="text"
                                  id="address"
                                  name="address"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.address}
                                />
                              </div>
                              {formik.touched.address &&
                              formik.errors.address ? (
                                <div className="text-danger">
                                  {formik.errors.address}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                Zipcode <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  className="form-control"
                                  type="text"
                                  id="zipcode"
                                  name="zipcode"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.zipcode}
                                  maxLength={6}
                                  onKeyPress={restrictAlpha}
                                />
                              </div>
                              {formik.touched.zipcode &&
                              formik.errors.zipcode ? (
                                <div className="text-danger">
                                  {formik.errors.zipcode}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-3 col-md-6">
                              <label className="form-label" htmlFor="country">
                                Country <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                isSearchable
                                options={Country.getAllCountries()}
                                getOptionLabel={(options) => {
                                  return options["name"];
                                }}
                                getOptionValue={(options) => {
                                  return options["isoCode"];
                                }}
                                value={formik?.values?.country}
                                onChange={(item) => {
                                  formik?.setFieldValue("country", item);
                                }}
                              />
                              {formik.touched.country &&
                              formik.errors.country ? (
                                <div className="text-danger">
                                  {formik.errors.country}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                State <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                isSearchable
                                options={State?.getStatesOfCountry(
                                  formik?.values?.country?.isoCode
                                )}
                                getOptionLabel={(options) => {
                                  return options["name"];
                                }}
                                getOptionValue={(options) => {
                                  return options["name"];
                                }}
                                value={formik?.values?.state}
                                onChange={(item) => {
                                  formik?.setFieldValue("state", item);
                                }}
                              />
                              {formik.touched.state && formik.errors.state ? (
                                <div className="text-danger">
                                  {formik.errors.state}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                City <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                isSearchable
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 1,
                                  }),
                                }}
                                options={City.getCitiesOfState(
                                  formik?.values?.country?.isoCode,
                                  formik?.values?.state?.isoCode
                                )}
                                getOptionLabel={(options) => {
                                  return options["name"];
                                }}
                                getOptionValue={(options) => {
                                  return options["name"];
                                }}
                                value={formik?.values?.city}
                                onChange={(item) => {
                                  formik?.setFieldValue("city", item);
                                }}
                              />
                              {formik.touched.city && formik.errors.city ? (
                                <div className="text-danger">
                                  {formik.errors.city}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mt-4 d-flex align-items-center gap-2 ">
                              {/* {location?.state?.gst_uploaded && (
                              <div className="mb-3 col-md-3 select-show">
                                <img
                                  src={gstIcon}
                                  style={{ width: "30px" }}
                                  onClick={() => handleDownload()}
                                />
                               
                                GST
                              </div>
                            )} */}
                              {!location?.state?.gst_uploaded &&
                                formik?.values?.gst_certificate && (
                                  <>
                                    <div
                                      onClick={() => setShow(true)}
                                      className="mb-3 cursor-pointer col-md-3 d-flex gap-1 align-items-center border p-2 rounded select-show"
                                    >
                                      {/* {formik?.values?.gst_certificate?.name} */}

                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="30"
                                          height="30"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M12.5001 18.9582H7.50008C2.97508 18.9582 1.04175 17.0248 1.04175 12.4998V7.49984C1.04175 2.97484 2.97508 1.0415 7.50008 1.0415H11.6667C12.0084 1.0415 12.2917 1.32484 12.2917 1.6665C12.2917 2.00817 12.0084 2.2915 11.6667 2.2915H7.50008C3.65841 2.2915 2.29175 3.65817 2.29175 7.49984V12.4998C2.29175 16.3415 3.65841 17.7082 7.50008 17.7082H12.5001C16.3417 17.7082 17.7084 16.3415 17.7084 12.4998V8.33317C17.7084 7.9915 17.9917 7.70817 18.3334 7.70817C18.6751 7.70817 18.9584 7.9915 18.9584 8.33317V12.4998C18.9584 17.0248 17.0251 18.9582 12.5001 18.9582Z"
                                            fill="#353535"
                                          />
                                          <path
                                            d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z"
                                            fill="#353535"
                                          />
                                          <path
                                            d="M10.8333 11.4585H5.83325C5.49159 11.4585 5.20825 11.1752 5.20825 10.8335C5.20825 10.4918 5.49159 10.2085 5.83325 10.2085H10.8333C11.1749 10.2085 11.4583 10.4918 11.4583 10.8335C11.4583 11.1752 11.1749 11.4585 10.8333 11.4585Z"
                                            fill="#353535"
                                          />
                                          <path
                                            d="M9.16659 14.7915H5.83325C5.49159 14.7915 5.20825 14.5082 5.20825 14.1665C5.20825 13.8248 5.49159 13.5415 5.83325 13.5415H9.16659C9.50825 13.5415 9.79159 13.8248 9.79159 14.1665C9.79159 14.5082 9.50825 14.7915 9.16659 14.7915Z"
                                            fill="#353535"
                                          />
                                        </svg>{" "}
                                        GST{" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M9.99984 1.6665C5.40817 1.6665 1.6665 5.40817 1.6665 9.99984C1.6665 14.5915 5.40817 18.3332 9.99984 18.3332C14.5915 18.3332 18.3332 14.5915 18.3332 9.99984C18.3332 5.40817 14.5915 1.6665 9.99984 1.6665ZM13.9832 8.08317L9.25817 12.8082C9.1415 12.9248 8.98317 12.9915 8.8165 12.9915C8.64984 12.9915 8.4915 12.9248 8.37484 12.8082L6.0165 10.4498C5.77484 10.2082 5.77484 9.80817 6.0165 9.5665C6.25817 9.32484 6.65817 9.32484 6.89984 9.5665L8.8165 11.4832L13.0998 7.19984C13.3415 6.95817 13.7415 6.95817 13.9832 7.19984C14.2248 7.4415 14.2248 7.83317 13.9832 8.08317Z"
                                            fill="#50C878"
                                          />
                                        </svg>
                                        <br />
                                        {formatFileSize(
                                          formik?.values?.gst_certificate?.size
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              {formik?.values?.cin_certificate && (
                                <div
                                  onClick={() => setShow(true)}
                                  className="mb-3 col-md-2 gap-1 d-flex align-items-center border p-2 rounded select-show"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M12.5001 18.9582H7.50008C2.97508 18.9582 1.04175 17.0248 1.04175 12.4998V7.49984C1.04175 2.97484 2.97508 1.0415 7.50008 1.0415H11.6667C12.0084 1.0415 12.2917 1.32484 12.2917 1.6665C12.2917 2.00817 12.0084 2.2915 11.6667 2.2915H7.50008C3.65841 2.2915 2.29175 3.65817 2.29175 7.49984V12.4998C2.29175 16.3415 3.65841 17.7082 7.50008 17.7082H12.5001C16.3417 17.7082 17.7084 16.3415 17.7084 12.4998V8.33317C17.7084 7.9915 17.9917 7.70817 18.3334 7.70817C18.6751 7.70817 18.9584 7.9915 18.9584 8.33317V12.4998C18.9584 17.0248 17.0251 18.9582 12.5001 18.9582Z"
                                      fill="#353535"
                                    />
                                    <path
                                      d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z"
                                      fill="#353535"
                                    />
                                    <path
                                      d="M10.8333 11.4585H5.83325C5.49159 11.4585 5.20825 11.1752 5.20825 10.8335C5.20825 10.4918 5.49159 10.2085 5.83325 10.2085H10.8333C11.1749 10.2085 11.4583 10.4918 11.4583 10.8335C11.4583 11.1752 11.1749 11.4585 10.8333 11.4585Z"
                                      fill="#353535"
                                    />
                                    <path
                                      d="M9.16659 14.7915H5.83325C5.49159 14.7915 5.20825 14.5082 5.20825 14.1665C5.20825 13.8248 5.49159 13.5415 5.83325 13.5415H9.16659C9.50825 13.5415 9.79159 13.8248 9.79159 14.1665C9.79159 14.5082 9.50825 14.7915 9.16659 14.7915Z"
                                      fill="#353535"
                                    />
                                  </svg>
                                  {/* {formik?.values?.cin_certificate?.name} */}
                                  CIN{" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.99984 1.6665C5.40817 1.6665 1.6665 5.40817 1.6665 9.99984C1.6665 14.5915 5.40817 18.3332 9.99984 18.3332C14.5915 18.3332 18.3332 14.5915 18.3332 9.99984C18.3332 5.40817 14.5915 1.6665 9.99984 1.6665ZM13.9832 8.08317L9.25817 12.8082C9.1415 12.9248 8.98317 12.9915 8.8165 12.9915C8.64984 12.9915 8.4915 12.9248 8.37484 12.8082L6.0165 10.4498C5.77484 10.2082 5.77484 9.80817 6.0165 9.5665C6.25817 9.32484 6.65817 9.32484 6.89984 9.5665L8.8165 11.4832L13.0998 7.19984C13.3415 6.95817 13.7415 6.95817 13.9832 7.19984C14.2248 7.4415 14.2248 7.83317 13.9832 8.08317Z"
                                      fill="#50C878"
                                    />
                                  </svg>
                                </div>
                              )}
                              {formik?.values?.pan_card && (
                                <div
                                  onClick={() => setShow(true)}
                                  className="mb-3  gap-1 d-flex align-items-center border p-2 rounded select-show"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M12.5001 18.9582H7.50008C2.97508 18.9582 1.04175 17.0248 1.04175 12.4998V7.49984C1.04175 2.97484 2.97508 1.0415 7.50008 1.0415H11.6667C12.0084 1.0415 12.2917 1.32484 12.2917 1.6665C12.2917 2.00817 12.0084 2.2915 11.6667 2.2915H7.50008C3.65841 2.2915 2.29175 3.65817 2.29175 7.49984V12.4998C2.29175 16.3415 3.65841 17.7082 7.50008 17.7082H12.5001C16.3417 17.7082 17.7084 16.3415 17.7084 12.4998V8.33317C17.7084 7.9915 17.9917 7.70817 18.3334 7.70817C18.6751 7.70817 18.9584 7.9915 18.9584 8.33317V12.4998C18.9584 17.0248 17.0251 18.9582 12.5001 18.9582Z"
                                      fill="#353535"
                                    />
                                    <path
                                      d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z"
                                      fill="#353535"
                                    />
                                    <path
                                      d="M10.8333 11.4585H5.83325C5.49159 11.4585 5.20825 11.1752 5.20825 10.8335C5.20825 10.4918 5.49159 10.2085 5.83325 10.2085H10.8333C11.1749 10.2085 11.4583 10.4918 11.4583 10.8335C11.4583 11.1752 11.1749 11.4585 10.8333 11.4585Z"
                                      fill="#353535"
                                    />
                                    <path
                                      d="M9.16659 14.7915H5.83325C5.49159 14.7915 5.20825 14.5082 5.20825 14.1665C5.20825 13.8248 5.49159 13.5415 5.83325 13.5415H9.16659C9.50825 13.5415 9.79159 13.8248 9.79159 14.1665C9.79159 14.5082 9.50825 14.7915 9.16659 14.7915Z"
                                      fill="#353535"
                                    />
                                  </svg>
                                  {/* {formik?.values?.pan_card.name} */}
                                  PAN
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.99984 1.6665C5.40817 1.6665 1.6665 5.40817 1.6665 9.99984C1.6665 14.5915 5.40817 18.3332 9.99984 18.3332C14.5915 18.3332 18.3332 14.5915 18.3332 9.99984C18.3332 5.40817 14.5915 1.6665 9.99984 1.6665ZM13.9832 8.08317L9.25817 12.8082C9.1415 12.9248 8.98317 12.9915 8.8165 12.9915C8.64984 12.9915 8.4915 12.9248 8.37484 12.8082L6.0165 10.4498C5.77484 10.2082 5.77484 9.80817 6.0165 9.5665C6.25817 9.32484 6.65817 9.32484 6.89984 9.5665L8.8165 11.4832L13.0998 7.19984C13.3415 6.95817 13.7415 6.95817 13.9832 7.19984C14.2248 7.4415 14.2248 7.83317 13.9832 8.08317Z"
                                      fill="#50C878"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mt-2 d-flex  flex-row-reverse justify-content-between align-items-center select-show">
                            <div>
                              {imagePreview ? (
                                <div
                                  style={{ marginLeft: "40px", padding: "1px" }}
                                  className="d-flex justify-content-end align-items-center select-show"
                                >
                                  <div className="button-wrapper d-flex align-items-center gap-2">
                                    <span
                                      className=""
                                      title="Upload file max 5MB in ,PDF format."
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="18px"
                                        height="18px"
                                      >
                                        {" "}
                                        <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                                      </svg>
                                    </span>
                                    <label
                                      className="btn btn-primary "
                                      tabIndex={0}
                                      htmlFor="file-upload"
                                    >
                                      <span
                                        className="d-none d-sm-block position-relative"
                                        style={{ zIndex: 200 }}
                                      >
                                        {formik?.values?.gst_certificate
                                          ? " Update Document"
                                          : " Upload Document"}
                                        <input
                                          id="file-upload"
                                          type="file"
                                          className="display-hidden "
                                          style={{
                                            position: "absolute",
                                            width: "1px",
                                            height: "1px",
                                            overflow: "hidden",
                                            clip: "rect(1px, 1px, 1px, 1px)",
                                            whiteSpace: "nowrap",
                                          }}
                                          name="gst_certificate"
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              "gst_certificate",
                                              e.target.files[0]
                                            )
                                          }
                                          onBlur={formik.handleBlur}
                                          accept="application/pdf"
                                        />
                                      </span>
                                      <i className="bx bx-upload d-block d-sm-none" />
                                    </label>

                                    {formik.errors.gst_certificate ? (
                                      <div className="text-danger">
                                        {formik.errors.gst_certificate}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <button
                              type="button"
                              className="btn btn-primary me-2"
                              onClick={() => formik?.handleSubmit()}
                              disabled={loading || hasErrors}
                            >
                              Submit for Verification
                            </button>
                          </div>
                        </form>
                      </div>
                      {/* /Account */}
                    </div>
                  </div>
                </div>
              </div>
              {/* / Content */}
              {/* Footer */}

              {/* / Footer */}
              <div className="content-backdrop fade" />
            </div>

            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
      <UploadDocModal show={show} setShow={setShow} formik={formik} />
    </>
  );
}

export default CompanyDetails;
