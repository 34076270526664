import { useState, React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "./OTPInput";
import {
  resendOTP,
  resetPassowrd,
  verifyOTP,
} from "../../services/Apiservices";
import { setUser } from "../../redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/reducers/authSlice";
import { setSession } from "../../session";
import { getProfile } from "../../redux/actions/profileAction";
import logo from "../../CredLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import showNotification from "../../services/NotificationService";
import LoginScreen from "../../forgot.png";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState();
  const [loading, setisLoading] = useState(false);
  const mobileNumber = useSelector((state) => state.mobileNumber);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    // Disable scroll when the component mounts
    document.body.style.overflow = "hidden";
    document.body.style.paddingTop = "0"; // Reset padding-top
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  

    // Re-enable scroll when the component unmounts
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      otp: "",
      password: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
        .required("OTP is required"),

      password: Yup.string()
        .min(8, "Must be at least 8 characters")
        .required("Password is Required"),
    }),

    onSubmit: (values) => {
      // You can handle the form submission here
      handleSubmit(values);
      // navigate("/admin/dashboard")
    },
  });

  const handleSubmit = async (values) => {
    setisLoading(true);
    let body = {
      mobile_no: mobileNumber,
      otp: values?.otp,
      password: values?.password,
    };
    try {
      let response = await resetPassowrd(body);
      if (response?.status == 200) {
        setisLoading(false);
        setSession(response?.data?.access);
        showNotification("success", response?.data?.message);
        navigate("/");

        // navigate("/verify-otp")
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  const handleResend = async () => {
    setisLoading(true);

    let body = {
      mobile: mobileNumber,
    };
    try {
      let response = await resendOTP(body);
      if (response?.status == 200) {
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  return (
    <div className="container-xxl px-0 mx-0 ">
      <div className="row">
        {/* Left part with form UI */}
     {/* Left part with form UI */}
     <div className="col-md-6 d-none d-md-block">
            <div
              className="d-flex flex-column align-items-center justify-content-center h-100"
              style={{ position: "relative" }}
            >
              {/* Logo at the top with margin */}
              <img
                src={logo} // Replace with your logo URL
                alt="Logo"
                className="img-fluid " // Bootstrap class for responsive images
                style={{
                  width: "208px",
                  height: "70px",
                  marginBottom: "30px", // Adjust the margin to create space between logo and image
                }}
              />

              {/* Image centered below the logo */}
              <img
                src={LoginScreen} // Replace with your image URL
                alt="Image"
                className="img-fluid" // Bootstrap class for responsive images
                style={{
                  objectFit: "contain",
                  width: "100%", // Use 100% width for responsiveness
                  maxHeight: "437px", 
                  borderRadius:"32px"
                }}
              />
            </div>
          </div>
        {/* Right part with image */}
        <div className="col-md-6">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="card p-5 rounded-4   authentication-inner">
              {/* Register */}

              <div>
           
                {/* /Logo */}
                <div className="text-center">
                  <h5 className="mb-3 fw-semibold">OTP Verification</h5>
                  <p className="mb-4 fw-normal">
                    Please enter the OTP sent to your mobile number
                  </p>
                </div>

                <form id="formAuthentication" className="mb-3">
                  <div>
                    {" "}
                    <OTPInput
                      autoFocus
                      length={6}
                      className="otpContainer"
                      inputClassName="otpInput"
                      onChangeOTP={(otp) => formik.setFieldValue("otp", otp)}
                    />{" "}
                    {formik.touched.otp && formik.errors.otp ? (
                      <div className="text-danger">{formik.errors.otp}</div>
                    ) : null}
                  </div>

                  <div className="mb-3 form-password-toggle mt-2">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">
                        New Password
                      </label>
                    </div>
                    <div className="input-group input-group-merge  input-bottom-line">
                      <input
                        type={showPass ? "text" : "password"}
                        id="password"
                        className="form-control  input-bottom-line"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        aria-describedby="password"
                        maxLength={20}
                      />
                      {showPass ? (
                        <span
                          className="input-group-text cursor-pointer  input-bottom-line"
                          onClick={() => setShowPass(false)}
                        >
                          <i className="bx bx-hide" />
                        </span>
                      ) : (
                        <span
                          className="input-group-text cursor-pointer  input-bottom-line"
                          onClick={() => setShowPass(true)}
                        >
                          <i className="bx bx-show" />
                        </span>
                      )}
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-danger fs-7">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>

                  <p className="text-center">
                  <span  className="fs-7" style={{ color: "#4D4D4D" }}>Didn't you receive any code? </span>
                    <Link onClick={handleResend}>
                      <span  className="fs-7" style={{ color: "#4D4D4D" }}>Resend OTP</span>
                    </Link>
                  </p>
                  <button
                    className="btn btn-primary d-grid w-100"
                    onClick={() => formik.handleSubmit()}
                    type="button"
                  >
                    Submit
                  </button>
                </form>
              </div>

              {/* /Register */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
