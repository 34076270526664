import React, { useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import RewardsApiList from "./RewardsApiList";
import Payments from "./Payments";
import ComingSoon from "./ComingSoon";
import InvoiceTab from "./InvoiceTab";
import { useLocation } from "react-router-dom";
import PITab from "./PITab";

function Reports() {
  const location = useLocation();
  const [activeTab, setAciveTab] = useState(
    location?.state ? location?.state : "payments"
  );

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}
              <div className="container-xl flex-grow-1 container-p-y">
                <div className="row d-flex justify-content-end">
                  <div className="col ">
                    <h4 className="fw-semibold text-primary">
                      <span className="text-muted fw-light"></span>Reports
                    </h4>
                    <p>
                      View and download Reports related to Transactions, Voucher
                      Orders, Proforma Invoice and Invoices
                    </p>
                  </div>
                </div>
                <div className="nav-align-top">
                  <div className="tab-content">
                    <ul
                      className="nav nav-tabs mb-4"
                      role="tablist"
                      style={{ position: "absolute" }}
                    >
                      <li className="nav-item">
                        <button
                          type="button"
                          style={{
                            backgroundColor:
                              activeTab == "payments" ? "#B2AEFF" : "#D0CFFD",
                            color: "black",
                            padding: "8px",
                            borderTopLeftRadius: "10px",
                            borderRight: "2px solid black",
                            border: "none",
                          }}
                          className={activeTab == "payments" ? "active" : ""}
                          role="tab"
                          data-bs-toggle="tab"
                          data-bs-target="#navs-top-home"
                          aria-controls="navs-top-home"
                          aria-selected="true"
                          onClick={() => setAciveTab("payments")}
                        >
                          Transactions
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          type="button"
                          style={{
                            backgroundColor:
                              activeTab == "api" ? "#B2AEFF" : "#D0CFFD",
                            color: "black",
                            padding: "8px",
                            borderRight: "2px solid black",
                            border: "none",
                          }}
                          className={activeTab == "api" ? " active" : ""}
                          role="tab"
                          data-bs-toggle="tab"
                          data-bs-target="#navs-top-profile"
                          aria-controls="navs-top-profile"
                          aria-selected="false"
                          onClick={() => setAciveTab("api")}
                        >
                          Voucher Orders
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          type="button"
                          style={{
                            backgroundColor:
                              activeTab == "PI" ? "#B2AEFF" : "#D0CFFD",
                            color: "black",
                            padding: "8px",
                            borderRight: "2px solid black",

                            border: "none",
                          }}
                          className={activeTab == "PI" ? " active" : ""}
                          role="tab"
                          data-bs-toggle="tab"
                          data-bs-target="#navs-top-profile"
                          aria-controls="navs-top-profile"
                          aria-selected="false"
                          onClick={() => setAciveTab("PI")}
                        >
                          Proforma Invoice
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          type="button"
                          style={{
                            backgroundColor:
                              activeTab == "invoice" ? "#B2AEFF" : "#D0CFFD",
                            color: "black",
                            padding: "8px",
                            borderTopRightRadius: "10px",
                            border: "none",
                          }}
                          className={activeTab == "invoice" ? " active" : ""}
                          role="tab"
                          data-bs-toggle="tab"
                          data-bs-target="#navs-top-profile"
                          aria-controls="navs-top-profile"
                          aria-selected="false"
                          onClick={() => setAciveTab("invoice")}
                        >
                          Invoice
                        </button>
                      </li>
                    </ul>
                    <div
                      className={
                        activeTab == "payments"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="navs-top-home"
                      role="tabpanel"
                    >
                      <Payments />
                    </div>
                    <div
                      className={
                        activeTab == "api"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="navs-top-profile"
                      role="tabpanel"
                    >
                      {activeTab == "api" && <RewardsApiList />}
                    </div>
                    <div
                      className={
                        activeTab == "PI"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="navs-top-profile"
                      role="tabpanel"
                    >
                      {activeTab == "PI" && <PITab />}
                    </div>
                    <div
                      className={
                        activeTab == "invoice"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="navs-top-profile"
                      role="tabpanel"
                    >
                      {activeTab == "invoice" && <InvoiceTab />}
                    </div>
                  </div>
                </div>
              </div>
              {/* Content wrapper */}
            </div>
            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}

export default Reports;