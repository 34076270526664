import React, { useEffect, useState } from "react";
import bg from "../../Images/login-bg.webp";
import logo from "../../Images/whiteLogo.webp";
import logo1 from "../../CredLogo.png";
import hero from "../../Images/hero.gif";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import hero from "../../Images/New-file.json";

import { base64Image } from "../../constant";
import {
  loginWithOTP,
  loginWithPass,
  resendLoginOTP,
  resendOTP,
} from "../../services/Apiservices";
import { register } from "../../services/Apiservices";
import Signup from "./Signup";
import "../../App.css";
import { verifyOTP } from "../../services/Apiservices";
import { useDispatch, useSelector } from "react-redux";
import { setMobileNumber } from "../../redux/reducers/mobileNumberSlice";
import { login } from "../../redux/reducers/authSlice";
import { getToken, setSession } from "../../session";
import { setUser } from "../../redux/reducers/userSlice";
import showNotification from "../../services/NotificationService";
import { getProfile } from "../../redux/actions/profileAction";
import { restrictAlpha } from "../../constant";
import Slider from "./Slider";
import OTPInput from "./OTPInput";
import VerifyOtp from "./VerifyOtp";
const NewLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setisLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const profile = useSelector((state) => state?.profile?.data?.data);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginOTP, setloginOTP] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showSignup, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5 * 60);
  const [errMsg, setErroMsg] = useState("");

  useEffect(() => {
    let interval;

    if (otpSent) {
      interval = setInterval(() => {
        setTimeLeft((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setOTPSent(false);
            return 5 * 60; // Reset the timer to initial value when it reaches 0
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [otpSent]);

  const handleShowLogin = (value) => {
    setShowLogin(value);
  };
  const handleShowSignup = (value) => {
    setShowSignUp(value);
  };
  useEffect(() => {
    // Disable scroll when the component mounts
    document.body.style.overflow = "hidden";
    document.body.style.paddingTop = "0"; // Reset padding-top
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    if (getToken()) {
      navigate("/admin/dashboard");
    }

    // Re-enable scroll when the component unmounts
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      mobile: "",
      otp: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .test(
          "is-email-or-mobile",
          "Invalid email or mobile number",
          function (value) {
            if (!value) return false; // if the field is empty, it's invalid
            if (!isNaN(value)) return /^\d{10}$/.test(value); // valid mobile number if the value is a number
            return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
              value
            );
          }
        )
        .required("Email or mobile is required"),
    }),

    onSubmit: (values) => {
      // You can handle the form submission here
      getOTP(values);
      // navigate("/admin/dashboard")
    },
  });
  const handleLogin = async (values) => {
    try {
      setisLoading(true);
      let body = {
        password: values?.password,
        mobile_no: values?.mobile,
      };
      let response = await loginWithPass(body);
      if (response?.status == 200) {
        setisLoading(false);
        setSession(response?.data?.access);
        dispatch(login());

        dispatch(
          setUser({
            full_name: response?.data?.full_name,
            profile_picture: response?.data?.profile_picture,
            mobile_no: response?.data?.mobile_no,
            email: response?.data?.email,
          })
        );
        localStorage.setItem(
          "userDetails",
          JSON.stringify(response?.data?.details)
        );
        dispatch(getProfile())
          .then((resultAction) => {
            if (
              resultAction?.payload?.data?.company_added &&
              resultAction?.payload?.data?.company_verified
            ) {
              navigate("/admin/dashboard");
            } else {
              if (resultAction?.payload?.data?.company_added) {
                navigate("/admin/accounts");
              } else {
                navigate("/admin/company-details");
              }
            }
          })
          .catch((error) => {
            // Handle any error if required
            console.error("Error updating experience:", error);
          });
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const handleLoginOTP = async (OTP) => {
    if (OTP.length == 6) {
      try {
        setisLoading(true);
        let body = {
          otp: OTP,
          mobile_no: formik?.values?.mobile,
        };

        let response = await loginWithOTP(body);
        if (response?.status == 200) {
          // showNotification("success", "Login Successfully");
          setisLoading(false);
          setOTPSent(false);
          setSession(response?.data?.access);
          dispatch(login());
          dispatch(getProfile());
          dispatch(
            setUser({
              full_name: response?.data?.full_name,
              profile_picture: response?.data?.profile_picture,
              mobile_no: response?.data?.mobile_no,
              email: response?.data?.email,
            })
          );
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response?.data?.details)
          );
          dispatch(getProfile()).then((resultAction) => {
            if (resultAction?.payload?.data?.company_added) {
              navigate("/admin/dashboard");
            } else {
              navigate("/admin/company-details");
            }
          });
        } else {
          setErroMsg(response?.response?.data?.message);

          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    }
  };

  const getOTP = async (values) => {
    if (formik?.values?.mobile) {
      setisLoading(true);
      let body = {
        mobile_no: values?.mobile,
      };
      try {
        let response = await resendLoginOTP(body);

        if (response?.status == 200) {
          // showNotification("success", response?.data?.message);

          setloginOTP(true);
          setOTPSent(true);
          setTimeLeft(5 * 60);
          setShowOTP(true);
          setErroMsg("");
          setisLoading(false);
        } else {
          if (response?.response) {
            setErroMsg(response?.response?.data?.message);
            setisLoading(false);

            setShowOTP(false);
          } else {
            setErroMsg("Network Error: Please check your internet connection.");
            setisLoading(false);

            setShowOTP(false);
          }
        }
      } catch (error) {
        setisLoading(false);
      }
    } else {
      formik?.handleSubmit();
    }
  };

  const handleResend = async () => {
    let body = {
      mobile_no: formik?.values?.mobile,
    };
    try {
      let response = await resendLoginOTP(body);
      if (response?.status == 200) {
        setOTPSent(true);
        setTimeLeft(5 * 60);

        // showNotification("sucesss", response?.data?.message);
      } else {
      }
    } catch (error) {}
  };
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  return (
    <div className="d-flex flex-warp">
      <div
        className="d-none d-md-block"
        style={{ width: "50%", position: "relative" }}
      >
        <img
          src={logo}
          alt=""
          style={{
            position: "absolute",
            width: "25%",
            left: "25px",
            top: "40px",
          }}
        />

        <div
          className="text-white"
          style={{
            position: "absolute",
            textAlign: "center",
            left: "0%",
            right: "0%",
            fontSize: "38px",
            fontWeight: "700",
            top: "150px",
          }}
        >
          Perks - Above and Beyond
          <p
            style={{
              position: "absolute",
              textAlign: "center",
              left: "0%",
              right: "0%",
              fontSize: "18px",
              fontWeight: "700",
              top: "60px",
            }}
          >
            Discover the Perfect Gift from 200+ Brands
          </p>
        </div>

        <div
          style={{
            position: "absolute",
            textAlign: "center",
            margin: "auto",
            left: "0px",
            right: "0px",
            fontSize: "38px",
            bottom: "0px",
            top: "175px",
          }}
        >
          <img className="d-none d-lg-block" src={hero} width={"550px"} />
        </div>
        <img src={bg} style={{ width: "100%" }} alt="" />
      </div>
      {showLogin && (
        <div
          className="col-12 col-lg-6 custom-padding"
          style={{
            // paddingTop: "190px",
            backgroundColor: "#fff",
            height: "100vh",
          }}
        >
          <div className="d-flex justify-content-center py-5 d-lg-none">
            <img
              src={logo1}
              alt=""
              style={{
                width: "130px",
              }}
            />
          </div>
          <h4 className="text-center fw-semibold" style={{ fontSize: "24px" }}>
            Welcome to Credence👋
          </h4>
          {!showOTP && (
            <p className=" text-center" style={{ color: "GrayText" }}>
              Login using Mobile/Email OTP!
            </p>
          )}
          <form
            id="formAuthentication"
            className="mb-5 mx-auto col-8 col-md-6"
            // style={{ width: "50%" }}
            onSubmit={formik?.handleSubmit}
          >
            {!showOTP && (
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="form-label text-black fw-semibold"
                >
                  Email/Mobile No
                </label>
                <input
                  type="text"
                  className="form-control "
                  id="mobile"
                  style={{ padding: "12px" }}
                  name="mobile"
                  onChange={(e) => {
                    formik?.setFieldValue("mobile", e.target.value);
                    setErroMsg("");
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  placeholder={"Enter your email or mobile no "}
                  maxLength={50}
                  readOnly={loginOTP}
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="text-danger fs-7">{formik.errors.mobile}</div>
                ) : (
                  <>
                    {errMsg && <div className="text-danger fs-7">{errMsg}</div>}
                  </>
                )}
              </div>
            )}
            {showOTP && (
              <div className="text-center">
                {""}
                {formik.values.mobile}{" "}
                <i
                  className="bx bx-edit-alt me-2 cursor-pointer"
                  onClick={() => {
                    setShowOTP(false);
                    setloginOTP(false);
                  }}
                />
              </div>
            )}
            {showOTP && (
              <>
                <div
                  style={{
                    marginTop: "1.5rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <OTPInput
                    autoFocus
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={(otp) => {
                      formik?.setFieldValue("otp", otp);
                      handleLoginOTP(otp);
                      setErroMsg("");
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1.5rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {formik.touched.otp && formik.errors.otp ? (
                    <div className="text-danger fs-7">{formik.errors.otp}</div>
                  ) : (
                    <>
                      {errMsg && (
                        <div className="text-danger fs-7">{errMsg}</div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}

            <div className=" d-flex justify-content-center">
              {showOTP ? (
                <></>
              ) : (
                <>
                  {" "}
                  {loading ? (
                    <button
                      style={{
                        backgroundColor: "#594DF1",
                        outline: "none",
                        border: "none",
                        width: "100%",
                        color: "#fff",
                        padding: "5px",
                      }}
                      disabled
                    >
                      <div
                        class="spinner-grow me-2"
                        role="status"
                        style={{
                          fontSize: "10px",
                          height: "0.5rem",
                          width: "0.5rem",
                        }}
                      ></div>
                      <div
                        class="spinner-grow me-2"
                        role="status"
                        style={{
                          fontSize: "10px",
                          height: "0.5rem",
                          width: "0.5rem",
                        }}
                      ></div>
                      <div
                        class="spinner-grow"
                        role="status"
                        style={{
                          fontSize: "10px",
                          height: "0.5rem",
                          width: "0.5rem",
                        }}
                      ></div>
                    </button>
                  ) : (
                    <button
                      className="rounded"
                      type="submit"
                      style={{
                        backgroundColor: "#594DF1",
                        outline: "none",
                        border: "none",
                        width: "100%",
                        color: "#fff",
                        padding: "5px",
                      }}
                    >
                      Log in
                      {/* {loginOTP && !showOTP ? "Get OTP" : "Login"} */}
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
          {showOTP ? (
            <>
              <div className="d-flex justify-content-center mb-4">
                <hr />
                <div style={{ color: "gray" }}>
                  Did not get OTP?{" "}
                  {otpSent ? (
                    <span style={{ fontWeight: "600", color: "black" }}>
                      Resend OTP {formatTime()}
                    </span>
                  ) : (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        handleResend();
                        setErroMsg("");
                      }}
                      style={{ color: "#594DF1", fontWeight: "600" }}
                    >
                      Resend OTP
                    </span>
                  )}
                </div>
                <hr />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center mb-4">
                <hr />
                <div style={{ color: "gray" }}>
                  Don’t have an Account?{" "}
                  <span
                    className="cursor-pointer"
                    style={{ color: "#594DF1", fontWeight: "600" }}
                  >
                    <Link to="/signup">Sign up</Link>
                  </span>
                </div>
                <hr />
              </div>
              <div className="text-center">
                By logging in, you are accepting our <br />
                <a
                  href="https://squid.credencerewards.com/terms-&-Conditions"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
                and {" "}
                <a
                  href="https://squid.credencerewards.com/Policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NewLogin;
