import { createSlice } from "@reduxjs/toolkit";
import { getProfile } from "../actions/profileAction";



const initialState = {
  data: "", // for template
};

const  fullProfileSlice= createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfileData: (state) => {
      state.data = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getProfile.rejected, (state, { payload }) => {
        state.loading = false;
        // handle rejection if needed
      });
  },
});
export const { resetProfileData } = fullProfileSlice.actions;
export default fullProfileSlice.reducer;
