import React from "react";
import { Link } from "react-router-dom";
import logo from "../CredLogo.png";
const ThanksPage = () => {
  return (
    <div
      className=" bg-white p-5 mx-auto"
      style={{ width: "700px", marginTop: "14rem" }}
    >
      <img width={"11%"} className="mx-auto py-2" src={logo} alt="" />
      <h2 className="text-center fs-12" style={{ lineHeight: "35.5px" }}>
        Thanks! you Mobile no. has been verified.{" "}
      </h2>
      <p className="text-center mt-2">
        Please click on the link to continue with{" "}
        <Link to="/" className="text-center mx-auto fw-bold">
          Squid Login.
        </Link>
      </p>
    </div>
  );
};

export default ThanksPage;
