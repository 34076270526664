import React, { useEffect, useState } from "react";
import { DataGrid, useGridApiRef, GridPagination } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  exportRewardReport,
  getAllVoucher,
  getRewardsReport,
  sendEmailForRewards,
} from "../services/Apiservices";
import MuiPagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  columnIcon,
  crossIcon,
  exportIcon,
  filterIcon,
  restrictAlpha,
  showStatus,
} from "../constant";
import {
  Box,
  Button,
  Modal,
  Toolbar,
  FormControlLabel,
  Grid,
  Checkbox,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import "../App.css";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import showNotification from "../services/NotificationService";
const buttonStyle = {
  // Change this to your desired color
  color: "black",

  borderRadius: "6px",
  border: "0.5px solid #A0A0A0",
  padding: "8px",
  margin: "0 5px",
  minWidth: "auto",
  height: "28px",
  fontSize: "12px",
};
const ActionCell = ({ id, handleSend }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleSend(id);
            handleClose();
          }}
        >
          Resend
        </MenuItem>
      </Menu>
    </>
  );
};

function RewardsApiList() {
  const columns = [
    // Define your columns based on the API data structure
    {
      field: "txnId",
      headerName: "Txn Id",
      flex: 1,

      minWidth: 20,
      maxWidth: 70,
    },

    {
      field: "id",
      headerName: "Order Id",
      flex: 1,
      minWidth: 20,
      maxWidth: 60,
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      maxWidth: 190,
      renderCell: (params) => {
        const date = params.value;

        return moment(date).format("ll");
      },
    },
    {
      field: "email",
      headerName: "Recipient Email",
      flex: 1.6,
      width: 125,
      minWidth: 250,
      maxWidth: 350,
    },
    { field: "provider_name", headerName: "Brand", flex: 1.5, maxWidth: 360 },

    {
      field: "quantity",
      headerName: "Qty",
      flex: 1,
      hide: true,
      minWidth: 20,
      maxWidth: 100,
    },

    {
      field: "value_of_voucher",
      headerName: "Denomination",
      flex: 1,
      maxWidth: 130,
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      flex: 1,

      hide: true,
      renderCell: (params) => {
        return (
          <div>
            <i className="bx bx-rupee" />

            {params?.row?.total_amount}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 90,
      renderCell: (params) => {
        const status = params.value;
        let cellStyle = {};

        // Customize styles based on the status
        switch (status) {
          case 3:
            cellStyle = {
              backgroundColor: "#F0FDF4",

              borderRadius: "20px",
              fontSize: "14px",
            };
            break;
          case 4:
            cellStyle = { color: "orange" };
            break;
          case 2:
            cellStyle = { color: "orange" };
            break;
          default:
            break;
        }

        return (
          <div className="p-1 px-1 " style={cellStyle}>
            {showStatus(status)}
          </div>
        );
      },
    },
    {
      field: "payment_id",
      headerName: "Action",
      flex: 1,
      maxWidth: 70,
      // renderCell: (params) => {
      //   return (
      //     <span
      //       style={{
      //         cursor: "pointer",
      //         backgroundColor: "white",
      //         padding: "9px",
      //       }}
      //       onClick={() => handleSend(params?.row?.id)}
      //     >
      //       Resend
      //     </span>
      //   );
      // },
      renderCell: (params) => (
        <ActionCell id={params?.row?.id} handleSend={handleSend} />
      ),
    },

    // Add more columns as needed
  ];
  const apiRef = useGridApiRef();
  const [loading, setisLoading] = useState(false);
  const userData = useSelector((state) => state?.profile?.data?.data);
  const [list, setList] = useState([]);
  const [showResend, setShowResend] = useState(false);
  const [sendID, setSendID] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const handleFilterOpen = () => setFilterOpen(true);
  const handleFilterClose = () => setFilterOpen(false);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    columns.reduce((acc, column) => {
      if (column.field == "total_amount") {
        acc[column.field] = false;
      } else {
        acc[column.field] = true;
      }
      return acc;
    }, {})
  );

  const [filters, setFilters] = React.useState({
    txn_id: "",
    order_id: "",
    name: "",
    email: "",
    mobile_no: "",
    brand_id: "",
    status: "",
    created_from: "",
    created_to: "",
  });
  const [options, setOptions] = useState([]);

  const handleColumnVisibilityChange = (field) => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      [field]: !prevModel[field],
    }));
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 13,
    maxColumns: 10,
  });

  useEffect(() => {
    if (userData?.company_added) {
      getReports();
      getVouhcers();
    }
  }, [page, pageSize]);

  const getReports = async (filterData) => {
    setisLoading(true);
    try {
      const offset = page * pageSize;
      const limit = pageSize;
      let response = await getRewardsReport(limit, offset, filterData);
      if (response?.status == 200) {
        setList(response?.data?.results ||[]);
        setisLoading(false);
        setRowCount(response?.data?.total_count);
      } else {
        setFilters({
          txn_id: "",
          order_id: "",
          name: "",
          email: "",
          mobile_no: "",
          brand_id: "",
          status: "",
          created_from: "",
          created_to: "",
        });
        setisLoading(false);
      }
    } catch (error) {
      setFilters({
        txn_id: "",
        order_id: "",
        name: "",
        email: "",
        mobile_no: "",
        brand_id: "",
        status: "",
        created_from: "",
        created_to: "",
      });
      setisLoading(false);
    }
  };
  const getVouhcers = async () => {
    try {
      let response = await getAllVoucher();
      if (response?.status == 200) {
        setOptions(
          response?.data?.data?.map((item) => {
            return userData?.company_verified
              ? {
                  label: item?.product_name,
                  value: item?.id,
                  data: { ...item },
                }
              : {
                  label: item?.product_name,
                  value: item?.id,
                  data: { ...item },
                  isdisabled:
                    item?.id === "61400014" || item?.id === "61400015"
                      ? true
                      : false,
                };
          })
        );
      } else {
      }
    } catch (error) {}
  };

  const handleSend = async (id) => {
    try {
      let response = await sendEmailForRewards(id);
      if (response?.status == 200) {
        // Assuming response.data contains the Excel file data

        showNotification("success", response?.data?.message);
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      // Handle errors if any
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "80%",
    width: "450px",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 1,
  };
  const filterstyle = {
    position: "absolute",
    top: "60%",
    left: "65%",
    width: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 3,
  };
  const handlePaginationChange = (newModel) => {
    setPaginationModel(newModel);
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  };
  const applyFilters = () => {
    getReports(filters);
    handleFilterClose();
  };

  function Pagination({ page, onPageChange, className }) {
    const pagecount = Math.ceil(rowCount / pageSize);
    return (
      rowCount > pageSize && (
        <MuiPagination
          color="primary"
          className={className}
          count={pagecount}
          page={page + 1}
          onChange={(event, newPage) => {
            onPageChange(event, newPage - 1);
          }}
        />
      )
    );
  }

  function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }
  const handleExportReport = async () => {
    try {
      let response = await exportRewardReport(filters);
      if (response?.status == 200) {
        // Assuming response.data contains the Excel file data
        const blob = new Blob([response?.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }); // Mime type for Excel (XLSX) files

        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to initiate download
        const link = document.createElement("a");
        link.href = url;
        link.download = `voucher_orders_report.xlsx`; // Change file extension to .xlsx
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        // showNotification("success",response?.data)
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      // Handle errors if any
    }
  };
  const handleCrossIconClick = (event) => {
    event.stopPropagation(); // Prevent the click from propagating to the Button
    setFilters({
      txn_id: "",
      order_id: "",
      name: "",
      email: "",
      mobile_no: "",
      brand_id: "",
      status: "",
      created_from: "",
      created_to: "",
    });
    getReports();
  };
  const isFilter = Object.values(filters).some((value) => value !== "");
  return (
    <div style={{ width: "100%" }}>
      {list?.length !== 0 ? (
        <>
          {" "}
          <div
            className="d-flex justify-content-end"
            style={{ padding: "5px", backgroundColor: "#f5f7fa" }}
          >
            <Button onClick={handleOpen} style={buttonStyle}>
              {columnIcon} Columns
            </Button>
            <Button style={buttonStyle} onClick={handleFilterOpen}>
              <div>{filterIcon} Filters</div>
              {isFilter && (
                <div onClick={handleCrossIconClick}>{crossIcon}</div>
              )}
            </Button>
            <Button style={buttonStyle} onClick={handleExportReport}>
              {exportIcon} Export
            </Button>
          </div>
          <Modal open={filterOpen} onClose={handleFilterClose}>
            <Box sx={filterstyle}>
              <Toolbar>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Transaction ID"
                      variant="outlined"
                      value={filters.txn_id}
                      onChange={(e) =>
                        handleFilterChange("txn_id", e.target.value)
                      }
                      fullWidth
                      onKeyPress={restrictAlpha}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Order ID"
                      variant="outlined"
                      value={filters.order_id}
                      onChange={(e) =>
                        handleFilterChange("order_id", e.target.value)
                      }
                      fullWidth
                      onKeyPress={restrictAlpha}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      value={filters.name}
                      onChange={(e) =>
                        handleFilterChange("name", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      value={filters.email}
                      onChange={(e) =>
                        handleFilterChange("email", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <TextField
                      label="Mobile No"
                      variant="outlined"
                      value={filters.mobile_no}
                      onChange={(e) =>
                        handleFilterChange("mobile_no", e.target.value)
                      }
                      fullWidth
                      onKeyPress={restrictAlpha}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Brand ID</InputLabel>
                      <Select
                        value={filters.brand_id}
                        onChange={(e) =>
                          handleFilterChange("brand_id", e.target.value)
                        }
                        label="Brand ID"
                      >
                        {options?.map((data) => {
                          return (
                            <MenuItem value={data?.value} key={data?.value}>
                              {data?.label}
                            </MenuItem>
                          );
                        })}

                        {/* Add more statuses as needed */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={filters.status}
                        onChange={(e) =>
                          handleFilterChange("status", e.target.value)
                        }
                        label="Status"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="failed">Failed</MenuItem>
                        {/* Add more statuses as needed */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Created From"
                      variant="outlined"
                      type="date"
                      value={filters.created_from}
                      onChange={(e) =>
                        handleFilterChange("created_from", e.target.value)
                      }
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Created To"
                      variant="outlined"
                      type="date"
                      value={filters.created_to}
                      onChange={(e) =>
                        handleFilterChange("created_to", e.target.value)
                      }
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="btn btn-secondary rounded me-2"
                  onClick={() => {
                    setFilters({
                      txn_id: "",
                      order_id: "",
                      name: "",
                      email: "",
                      mobile_no: "",
                      brand_id: "",
                      status: "",
                      created_from: "",
                      created_to: "",
                    });
                    getReports();
                  }}
                >
                  Reset
                </button>

                <button
                  className="btn btn-primary rounded"
                  onClick={applyFilters}
                >
                  Apply
                </button>
              </div>
            </Box>
          </Modal>
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Toolbar>
                <Grid container spacing={2}>
                  {columns.map((column) => (
                    <Grid item xs={12} sm={6} md={6} key={column.field}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={columnVisibilityModel[column.field]}
                            onChange={() =>
                              handleColumnVisibilityChange(column.field)
                            }
                          />
                        }
                        label={column.headerName}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Toolbar>
            </Box>
          </Modal>
          <DataGrid
            apiRef={apiRef}
            columns={columns}
            rows={list}
            slots={{
              pagination: CustomPagination,
            }}
            rowCount={rowCount}
            loading={loading}
            pageSizeOptions={[5, 10, 25, 50]}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            initialState={{
              ...data.initialState,
              pagination: { rowCount: -1 },
            }}
            disableRowSelectionOnClick={true}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        </>
      ) : (
        <>
          {loading ? (
            <div
              className="d-flex justify-content-center bg-white align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center bg-white align-items-center"
              style={{ height: "70vh" }}
            >
              No Order Found!
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RewardsApiList;
