import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import adminImg from "../../Images/helpImg/adminList.png";
import AddadminImg from "../../Images/helpImg/addAdmin.png";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";

function AddAdminSteps() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Add Admin User{" "}
                    </h5>
                    <p>
                      This article will guide you on how to invite other members to co-manage Squid
                    </p>
                    <hr className="mt-4" />
                    <p>
                      You can invite your colleagues or members to co-manage your Squid account by adding them as either super admins or admins. Super admins possess full control and authority, while admins have more limited permissions.
                    </p>
                    <div className="mt-4">
                      <h5>Adding Members</h5>

                      <p>
                      Adding a Super Admin or an Admin member is simple. Just follow these steps:
                      </p>
                      <ol>
                        <li>
                        Go to the "Settings" section on the sidebar and select "Manage Admins."
                        </li>
                        <li>Click Add Admin button.</li>
                     

                      <img className="" width={"80%"} src={adminImg} alt="" />
                    
                        <li>
                        Enter Name, Email ID, Role and Department details of the member to be invited.
                        </li>
                        <li>Select the Access and Submit the details.</li>
                    
                    
                      <img
                        className=""
                        width={"80%"}
                        src={AddadminImg}
                        alt=""
                      />
                      
                        <li>Invited members will be displayed under Invitations.</li>
                        <li>Invited Members who have completed their email and phone verification will be displayed under Admin List.</li>
                      </ol>
                    </div>
                    <hr />
                    <div className="mt-4">
                    <BackButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default AddAdminSteps;
