import React from "react";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import slider1 from "../Images/Code.png";
import slider4 from "../Images/Partner.png";
import nsdl from "../Images/nsdl.png";



function DashboardSlider() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Autoplay]}
        loop={true}
        slidesOffsetAfter={0}
        autoplay={{
          delay: 7000, // Autoplay delay in milliseconds
          disableOnInteraction: false, // Allow manual navigation while autoplay is active
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          {" "}
          <img
            src={slider1} // Replace with your image URL
            alt="Image"
            // Bootstrap class for responsive images
            className="img-fluid rounded"
          />
        </SwiperSlide>
    
        {/* <SwiperSlide>
          {" "}
          <img
            src={slider3} // Replace with your image URL
            alt="Image"
            // Bootstrap class for responsive images
            className="img-fluid rounded"
          />
        </SwiperSlide> */}
        <SwiperSlide>
          {" "}
          <img
            src={slider4} // Replace with your image URL
            alt="Image"
            // Bootstrap class for responsive images
            className="img-fluid rounded"
          />
        </SwiperSlide>
    
        <SwiperSlide>
          {" "}
          <img
            src={nsdl} // Replace with your image URL
            alt="Image"
            // Bootstrap class for responsive images
            className="img-fluid rounded"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default DashboardSlider;
