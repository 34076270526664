import React, { useState } from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import { Link } from "react-router-dom";
import voucherImg from "../../Images/helpImg/vouhcerRepo.png";
import invoiceImg from "../../Images/helpImg/invoice.png";

import paymentImg from "../../Images/helpImg/paymentRepo.png";
import BackButton from "./BackButton";

function ReportManage() {
  const [activeReport, setActiveReport] = useState("Transaction Report");

  const handleReportClick = (reportName) => {
    setActiveReport(reportName);
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light"></span>
                      Report Management
                    </h5>
                    <p>
                      Our Reports feature enables you to effortlessly generate, customize, and analyze detailed reports on voucher orders and delivery. Users can also generate invoices and Proforma Invoice (PI) reports.
                    </p>
                    <hr className="mt-4" />
                    <ol>
                      <li   onClick={() => handleReportClick("Transaction Report")} style={{cursor:"pointer"}}>
                        Transaction Report
                      </li>
                      <li onClick={() => handleReportClick("Voucher Report")}  style={{cursor:"pointer"}}>
                        Vouchers Report
                      </li>
                      <li onClick={() => handleReportClick("Proforma Invoice Report")}  style={{cursor:"pointer"}}>
                        Proforma Invoice
                      </li>
                      <li onClick={() => handleReportClick("Invoice Report")}  style={{cursor:"pointer"}}>
                        Invoice Report
                      </li>
                    </ol>

                    {activeReport === "Transaction Report" && (
                      <div>
                        <h5>Transaction Report</h5>
                        <p>
                          This report details voucher orders, including brand, denomination, quantity, recipient, issuer, and creation date. Custom Filters can be applied to customize details and reports can be downloaded.
                        </p>
                        <img
                          className="mt-4 mb-2"
                          width={"80%"}
                          src={paymentImg}
                          alt="Transaction Report"
                        />
                      </div>
                    )}

                    {activeReport === "Voucher Report" && (
                      <div>
                        <h5 className="mt-3">Voucher Report</h5>
                        <p>
                          The voucher reports provide detailed insights into brand, denomination, quantity, recipient, and order status. These reports can be customized and downloaded for tailored analysis and insights.
                        </p>
                        <img
                          className="mt-4 mb-2"
                          width={"80%"}
                          src={voucherImg}
                          alt="Voucher Report"
                        />
                      </div>
                    )}

                    {activeReport === "Invoice Report" && (
                      <div>
                        <h5 className="mt-3">Invoice Report</h5>
                        <p>
                          The Invoice Report summarizes all transactions that have been invoiced, providing details such as amounts and dates for added Squid funds.
                        </p>
                        <img
                          className="mt-4 mb-2"
                          width={"80%"}
                          src={invoiceImg}
                          alt="Voucher Report"
                        />
                      </div>
                    )}

                    {activeReport === "Proforma Invoice Report" && (
                      <div>
                        <h5 className="mt-3">Proforma Invoice (PI) Report</h5>
                        <p>
                          Access and download detailed summaries of all generated Proforma invoices, enhancing your ability to efficiently manage and oversee financial operations.
                        </p>
                      </div>
                    )}

                    <hr />
                    <div className="mt-4">
                     <BackButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default ReportManage;
