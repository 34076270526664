import React, { useEffect, useState, useRef } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";
import {
  getAllVoucher,
  getAllVoucherCat,
  getCategories,
} from "../services/Apiservices";
import Pagination from "./Pagination/Pagination";
import PlaceholderImage from "../Images/placeholderImg.webp";
import { defaultOptions } from "../constant";
import { Tilt } from "react-tilt";
import LazyLoad from "react-lazyload";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

let PageSize = 15;
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function VoucherCatalog() {
  const [isHovered, setIsHovered] = useState(false);
  const [vouchers, setvoucher] = useState([]);
  const [count, setCount] = useState(0);
  const [filteredVouchers, setFilteredVouchers] = useState([]);
  const userData = useSelector((state) => state?.profile?.data?.data);
  const [loading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVouhcer, setSearchVoucher] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const itemsPerSlide = 5;

  const cardContainerRef = useRef(null);

  useEffect(() => {
    if (userData?.company_added) {
      getVouhcers();
    }
  }, [categoryId]);
  useEffect(() => {
    if (userData?.company_added) {
      getAllCategories();
    }
  }, []);
  const getAllCategories = async () => {
    try {
      let response = await getCategories();
      if (response?.status == 200) {
        setCategory(response?.data);

        console.log(category);
      } else {
        setCategory([]);
      }
    } catch (error) {}
  };
  const renderArrows = () => {
    return (
      <div className="slider-arrow">
        <button
          className="arrow-btn prev"
          onClick={() => this.slider.slickPrev()}
        >
          {"<"}
        </button>
        <button
          className="arrow-btn next"
          onClick={() => this.slider.slickNext()}
        >
          {">"}
        </button>
      </div>
    );
  };
  const NextArrow = ({ className, style, onClick, isHovered }) => (
    <div
      className={`${className} `}
      style={{
        ...style,
        color: isHovered ? "red" : "blue", // Conditional color change
      }}
      onClick={onClick}
    ></div>
  );

  const PrevArrow = ({ className, style, onClick, isHovered }) => (
    <div
      className={`${className} `}
      style={{
        ...style,
        color: isHovered ? "red" : "blue",
        // boxShadow: "0px,3px,3px #000",
        // Conditional color change
      }}
      onClick={onClick}
    ></div>
  );
  const settings = {
    dots: false,
    infinite: false,
    arrow: false,
    speed: 700,
    fade: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    // nextArrow: <NextArrow isHovered={isHovered} />,
    // prevArrow: <PrevArrow isHovered={isHovered} />,
  };
  const getVouhcers = async (page, search) => {
    setisLoading(true);
    try {
      let response = await getAllVoucherCat(
        PageSize,
        PageSize * (page ? page - 1 : currentPage - 1),
        search ? search : "",
        categoryId
      );
      if (response?.status == 200) {
        setisLoading(false);
        setCount(response?.data?.total_count);
        setvoucher(
          response?.data?.results?.map((item) => {
            return {
              name: item?.product_name,
              id: item?.id,
              min: item?.checkout?.min,
              max: item?.checkout?.max,
              other: item?.checkout?.other ? "Yes" : "No",
              demoination: item?.checkout?.denominations
                ? item?.checkout?.denominations.toString()
                : item?.checkout?.min + "-" + item?.checkout?.max,
              discount: item?.discount,
              img: item?.logo_url,
              in_stock: item?.in_stock,
            };
          })
        );
      } else {
        setisLoading(false);
        setvoucher([]);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  useEffect(() => {
    if (cardContainerRef.current) {
      const cardItems = cardContainerRef.current.querySelectorAll(".card-item");
      if (cardItems.length < 5) {
        cardContainerRef.current.classList.add("fewer-than-five");
      } else {
        cardContainerRef.current.classList.remove("fewer-than-five");
      }
    }
  }, [vouchers]);
  const applyPagination = (page) => {
    getVouhcers(page, searchVouhcer);
  };

  const handleSearch = (searchText) => {
    const searchTextLower = searchText?.trim()?.toLowerCase();
    setSearchVoucher(searchText);
    if (searchText) {
      getVouhcers(currentPage, searchTextLower);
    } else {
      // If searchText is empty, revert back to the original list of vouchers
      getVouhcers(currentPage, searchVouhcer);
    }
  };

  function copycode(text) {
    try {
      navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  }
  const handlePrev = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleNext = () => {
    if (currentSlide < category.length - itemsPerSlide) {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div
        className="custom-button-group d-flex justify-content-between"
        style={{ position: "relative" }}
      >
        <div
          onClick={() => previous()}
          className="p-1 px-2 pointer-cursor"
          style={{
            position: "absolute",
            top: "-26px",
            left: "0px",
            color: "white",
            fontSize: "13px",
            fontWeight: "bold",
            cursor: "pointer",
            backgroundColor: "#594DF1",
            borderRadius: "50%",
            zIndex: "2000",
          }}
        >
          {"<"}
        </div>
        <div
          onClick={() => next()}
          className="p-1 px-2 "
          style={{
            position: "absolute",
            top: "-26px",
            right: "0px",
            color: "white",
            fontSize: "13px",
            fontWeight: "bold",
            backgroundColor: "#594DF1",
            cursor: "pointer",
            borderRadius: "50%",
            zIndex: "2000",
          }}
        >
          {">"}
        </div>
      </div>
    );
  };
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row d-flex justify-content-end">
                <div className="col-md-8 col-sm-12">
                  <div>
                    <h4 className="fw-semibold text-primary">
                      <span className="text-muted fw-light"></span>Voucher
                      Catalogue
                    </h4>
                    <p>
                      Explore a wide range of brand vouchers across different
                      categories
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                  <div className="d-flex justify-content-end">
                    {/* Your search bar component goes here */}
                    <div className="input-group rounded-4">
                      <span className="input-group-text  input-border-right">
                        <i className="bx bx-search"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control input-border-left"
                        placeholder="Search Vouchers"
                        onKeyUp={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                    {/* Additional styling as needed */}
                  </div>
                </div>
              </div>
              {/* {category && category?.length !== 0 && (
                <div
                  className="mb-2"
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    boxShadow:
                      "inset 0 4px 8px #696cff ,inset 0 -4px 8px #696cff",
                    borderRadius: "30px",
                  }}
                >
                  <div className="p-3 scroll-hidden-x">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "max-content" }}
                    >
                      <div className="d-flex col-12 col-md-auto justify-content-center mb-md-0">
                        <div>
                          <span className="text-black fw-bold px-4 link-secondary">
                            Categories
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#edeef1",
                            width: "2px",
                            height: "30px",
                          }}
                        ></div>
                        <div onClick={() => setCategoryId("")}>
                          <span
                            className="fw-semibold  fw-bold px-4 link-secondary cursor-pointer"
                            style={
                              categoryId == ""
                                ? { color: "#696cff" }
                                : { color: "black" }
                            }
                          >
                            All
                          </span>
                        </div>
                        {category?.map((item) => {
                          return (
                            <div
                              key={item?.id}
                              onClick={() => setCategoryId(item?.id)}
                            >
                              <span
                                className="fw-semibold cursor-pointer px-4 link-secondary"
                                style={
                                  categoryId == item?.id
                                    ? { color: "#696cff" }
                                    : { color: "black" }
                                }
                              >
                                {item?.title}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              {category && category?.length !== 0 && (
                <div className="mb-4">
                  {/* <div>
                    <button onClick={() => previous()}>{"<"}</button>
                  </div> */}
                  <div
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      padding: "15px",
                      // border:"2px",
                      boxShadow:
                        "inset 0 4px 8px #696cff ,inset 0 -4px 8px #696cff",
                      borderRadius: "30px",
                    }}
                  >
                    <Carousel
                      responsive={responsive}
                      arrows={false}
                      customButtonGroup={<CustomButtonGroup />}
                      renderButtonGroupOutside={true}
                      className="mx-auto mx-5 text-center"
                    >
                      <div
                        className="px-0 fw-semibold"
                        onClick={() => setCategoryId("")}
                      >
                        All Categories
                      </div>
                      {category?.map((item) => {
                        return (
                          <div
                            key={item?.id}
                            onClick={() => setCategoryId(item?.id)}
                            // style={{ width: "fit-content" }}
                            // className="text-center"
                          >
                            <span
                              className="fw-semibold cursor-pointer  link-secondary "
                              style={
                                categoryId == item?.id
                                  ? { color: "#696cff" }
                                  : { color: "black" }
                              }
                            >
                              {item?.title}
                            </span>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                  {/* <div>
                    <button onClick={() => next()}>{">"}</button>
                  </div> */}
                </div>
              )}

              <span></span>
              {loading ? (
                <div className="container">
                  <div className="row">
                    {Array(12)
                      ?.fill()
                      ?.map((_, index) => {
                        return (
                          <div className="col-md-3 mb-3" key={index}>
                            <div
                              className="card placeholder-glow"
                              aria-hidden="true"
                            >
                              <svg
                                className="bd-placeholder-img card-img-top"
                                width="100%"
                                height={180}
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                aria-label="Placeholder"
                                preserveAspectRatio="xMidYMid slice"
                                focusable="false"
                              >
                                <title>Placeholder</title>
                                <rect
                                  width="100%"
                                  height="100%"
                                  fill="#d9dee3"
                                />
                              </svg>
                              <div className=" text-center">
                                <div className="h5 mt-2 card-title placeholder-glow">
                                  <span className="placeholder col-6" />
                                </div>
                                {/* <div className=" placeholder-glow d-flex justify-content-between mt-2">
                                  <span className="placeholder col-4" />
                                  <span className="placeholder col-4" />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {/* Add more cards here */}
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center
                 "
                >
                  {vouchers?.length !== 0 ? (
                    <>
                      <div className="  ">
                        <div
                          className="card-container"
                          ref={cardContainerRef}
                          style={{ width: "fit-content" }}
                        >
                          {vouchers?.map((data, index) => {
                            return (
                              <div className="card-item ">
                                <div
                                  className="card"
                                  style={{
                                    width: "194px",
                                    backgroundColor: "#F5F7FA",
                                    boxShadow: "none",
                                  }}
                                >
                                  <LazyLoad
                                    key={data?.id}
                                    height={200}
                                    offset={100}
                                    once={true}
                                    placeholder={
                                      <img
                                        src={PlaceholderImage}
                                        alt="Placeholder"
                                        style={{
                                          width: "194px",
                                          height: "200px",
                                          borderRadius: "22px",
                                        }}
                                      />
                                    }
                                  >
                                    <img
                                      src={
                                        data?.img ? data?.img : PlaceholderImage
                                      }
                                      alt="Image Alt"
                                      className="card-img-top"
                                      style={{
                                        width: "193px",
                                        height: "200px",
                                        borderRadius: "22px",
                                      }}
                                    />
                                  </LazyLoad>
                                  {data?.in_stock ? (
                                    data?.discount > 0 && (
                                      <div className="card-img-overlay">
                                        <span className="discount">
                                          {data?.discount}% OFF
                                        </span>
                                      </div>
                                    )
                                  ) : (
                                    <span className="pr_flash">
                                      Out of Stock
                                    </span>
                                  )}
                                  {/* <img
                                    src={data?.img}
                                    className="card-img-top"
                                    alt="Product Image"
                                  /> */}

                                  <div className="text-center fs-12 p-2 text-purple">
                                    {data?.name}
                                  </div>
                                  {/* <div className="card-body text-center">
                                    <span>
                                      ID:{data?.id}{" "}
                                      <a
                                        onClick={() => copycode(data?.id)}
                                        title="Copy Code"
                                        className="p-2"
                                      >
                                        <i
                                          className="bx bx-copy sm"
                                          aria-hidden="true"
                                          style={{ color: "red" }}
                                        />
                                      </a>
                                    </span>

                                    <div className="d-flex justify-content-between mt-2">
                                      <span className="fs-7 float-left">
                                        Denomination: {data?.other}
                                      </span>
                                      <span className="fs-7 float-right">
                                        Range: {data?.min}-{data?.max}
                                      </span>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            );
                          })}

                          {/* Add more cards here */}
                        </div>
                      </div>
                    </>
                  ) : (
                    // <DataGrid
                    // rowHeight={70}
                    //   columns={columns.map((col) => ({
                    //     ...col,
                    //     headerClassName: "custom-header",
                    //     renderHeader: (params) => <HeaderCell {...params} />,
                    //   }))}
                    //   rows={filteredVouchers?.length!==0?filteredVouchers:vouchers}
                    //   initialState={{
                    //     pagination: { paginationModel: { pageSize: 5 } },
                    //   }}
                    //   pageSizeOptions={[5, 10, 25]}
                    //   style={{ background: "white" }}
                    //   components={{
                    //     Toolbar: CustomToolbar,
                    //   }}
                    // />
                    <>
                      <div className="d-flex justify-content-center">
                        No Vouchers Found!
                      </div>{" "}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={count}
                pageSize={PageSize}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  applyPagination(page);
                }}
              />
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default VoucherCatalog;
