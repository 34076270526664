import React from 'react'
import { useNavigate } from 'react-router-dom'

function PageNotFound() {
  const navigate=useNavigate()
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      {/* Menu */}
      
      {/* / Menu */}
      {/* Layout container */}
      <div className="layout-page">
        {/* Navbar */}
    
        {/* / Navbar */}
        {/* Content wrapper */}
        <div className="container-xxl container-p-y">
          <div className="misc-wrapper d-flex align-items-center justify-content-center">
            <div className="text-center">
              <h2 className="mb-2 mx-2">Page Not Found :(</h2>
              <p className="mb-4 mx-2">
                Oops! 😖 The requested URL was not found on this server.
              </p>
              <button  type ="button" className="btn btn-primary" onClick={()=>navigate(-1)}>
                Back to home
              </button>
              <div className="mt-3">
                <img
                  src="../..//assets/img/illustrations/page-misc-error-light.png"
                  alt="page-misc-error-light"
                  width={500}
                  className="img-fluid"
                  data-app-dark-img="illustrations/page-misc-error-dark.png"
                  data-app-light-img="illustrations/page-misc-error-light.png"
                />
              </div>
            </div>
          </div>
        </div>
        {/* / Layout page */}
      </div>
      {/* Overlay */}
    </div>
    <div className="layout-overlay layout-menu-toggle" />
  </div>

  )
}

export default PageNotFound