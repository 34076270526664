import React, { useState } from 'react'
import {useRef,useEffect} from "react"
import { Link } from 'react-router-dom';

function EmailNavbar(props) {
const [showDrop,setShowDrop]=useState(false)
  const handleAddClass=()=>{
    document.documentElement.classList.add('layout-menu-expanded');
  }
  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDrop(false)
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);
  return (
    <>   <nav
    className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
    id="layout-navbar"
  >
    <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
      <a
        className="nav-item nav-link px-0 me-xl-4"
        href="#"
        onClick={handleAddClass}
      >
        <i className="bx bx-menu bx-sm" />
      </a>
    </div>
    <div
      className="navbar-nav-right d-flex justify-content-end"
      id="navbar-collapse"
    >
      {/* Search */}
     
      {/* /Search */}
     <button className='btn btn-primary' onClick={()=>props.setShowExplore(false)}>
       Done
     </button>
    </div>
  </nav></>
  )
}

export default EmailNavbar