import React, { useEffect, useState } from "react";
import SIdeBar from "./CommonComponents/SIdeBar";
import Navbar from "./CommonComponents/Navbar";
import { Link, useNavigate } from "react-router-dom";
import PaymentMsgModal from "./PaymentMsgModal";
import {
  getWalletBalance,
  addFunds,
  checkRefrence,
  getFundInvoice,
  addFundsOffline,
} from "../services/Apiservices";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  arrowRight,
  compass,
  copyIcon,
  pencilIcon,
  restrictAlpha,
  walletIcon,
} from "../constant";
import Pagination from "./Pagination/Pagination";
import bell from "../Images/bell.png";

import PaymentModal from "./PaymentModal";
let PageSize = 5;

function AddFunds() {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [balance, setBalance] = useState(0.0);
  const [details, setDetails] = useState("");
  const [history, setHistory] = useState([]);
  const [filterHistory, setFilterHistory] = useState([]);

  const [isValidRefNo, setIsValidRefNo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMsg, setShowMsg] = useState(false);
  const userData = useSelector((state) => state?.profile?.data?.data);
  useEffect(() => {
    applyPagination(history, currentPage, PageSize);
  }, [currentPage, PageSize, history]);

  const formik = useFormik({
    initialValues: {
      amount: "",
      refNo: "",
      email: userData?.email,
      mode: "Online",
      utr: "",
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(/^[0-9]+$/, "amount must contain only digits")
        .test(
          "min-value",
          "Please enter an amount greater than or equal to 10",
          function (value) {
            return parseInt(value, 10) >= 10;
          }
        )
        .required("Amount is required"),
      // refNo: Yup.string()
      //   .min(6, "Must be at least 15 characters")
      //   .required("RefNo is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )

        .required("Email is Required"),
    }),
    validateOnChange: true,

    onSubmit: (values) => {
      // You can handle the form submission here
      if (values?.mode == "bank") {
        handlePaymentClick(values);
      } else {
        addFundWithrazor(values);
      }
      // navigate("/admin/dashboard")
    },
  });

  useEffect(() => {
    if (userData?.company_added) {
      getBalance();
    }
  }, []);

  useEffect(() => {
    // Check refNo validity using your API
    // You can replace the following mock API call with your actual API call
    const checkRefNoValidity = async () => {
      try {
        // Make an API call to check if refNo is valid
        const response = await checkRefrence(formik?.values?.refNo);
        if (response?.status == 200) {
          if (response?.data?.message == "Not Available") {
            setIsValidRefNo(false); // Set the validity based on the API response
          } else {
            setIsValidRefNo(true);
          }
        }
      } catch (error) {
        setIsValidRefNo(false); // Set validity to false in case of an error
      }
    };
    if (formik.values.refNo?.length >= 6) {
      checkRefNoValidity();
    }
  }, [formik.values.refNo]);

  const getBalance = async () => {
    try {
      let response = await getWalletBalance();
      if (response?.status == 200) {
        setBalance(response?.data?.balance);
        setDetails(response?.data);
        setHistory(response?.data?.funds_data);
      } else {
      }
    } catch (error) {}
  };

  const handlePaymentClick = async (values) => {
    if (formik?.values?.utr !== "") {
      setLoading(true);
      let body = {
        amount: values?.amount,
        ref_no: values?.refNo,
        email: values?.email,
        utr_no: values?.utr,
      };

      try {
        let response = await addFundsOffline(body);
        if (response?.status == 200) {
          setLoading(false);
          Swal.fire(
            `₹ ${response?.data?.data?.amount} added successfully`,
            "",
            "success"
          );
          formik?.handleReset();
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(data) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // const result = await axios.post("http://localhost:5000/payment/orders");

    // if (!result) {
    //     alert("Server error. Are you online?");
    //     return;
    // }

    // const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_SEC_KEY, // Enter the Key ID generated from the Dashboard
      amount: data?.amount * 100,
      currency: "INR",
      name: "Credence Rewards.",
      description: data?.description,
      // image: { logo },
      order_id: data?.order_id,
      handler: async function (response) {
        if (response) {
          getBalance();

          setShowModal(true);
          // formik.handleReset();
        } else {
          formik?.handleReset();
        }
      },
      modal: {
        ondismiss: function () {
          formik?.handleReset();
        },
      },

      prefill: {
        email: data?.email,
      },

      theme: {
        color: "#ff324d",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const addFundWithrazor = async (values) => {
    setLoading(true);
    let body = {
      amount: values?.amount,
      ref_no: values?.refNo,
      email: values?.email,
    };
    try {
      let response = await addFunds(body);
      if (response?.status == 200) {
        setLoading(false);
        displayRazorpay(response?.data?.data);
        setShowMsg(false);
      } else {
        setLoading(false);
      }
      if (response?.response?.status == 402) {
        setShowMsg(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  function copycode(text) {
    try {
      navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  }
  const applyPagination = (data, page, size) => {
    const startIndex = (page - 1) * size;
    const endIndex = startIndex + size;
    const paginatedData = data.slice(startIndex, endIndex);
    setFilterHistory(paginatedData);
  };
  const hasErrors =
    Object.keys(formik.errors).length > 0 &&
    Object.keys(formik.touched).length > 0;

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* Menu */}
          <SIdeBar />
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}
            <Navbar />
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Content */}

              {userData && !userData?.company_verified && (
                <div
                  style={{
                    backgroundColor: "#FFF5D9",
                    padding: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderRadius: "50px",
                    margin: "auto",
                    color: "red",
                    fontWeight: "font-semibold",
                    width: "fit-content",
                  }}
                  className="mt-1"
                >
                  <span>
                    <img src={bell} width={"24px"} alt="" />
                  </span>
                  Account under verification, you can add funds up to Rs. 50,000
                </div>
              )}
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row  ">
                  <div className="col-12 col-lg-12 col-sm-12 col-md-12 ">
                    <div className="card h-80">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mt-3">
                          <h5 className="fw-semibold text-primary">
                            <span
                              className="text-muted fw-light "
                              style={{ lineHeight: "34px" }}
                            ></span>
                            Add Funds to Wallet{" "}
                          </h5>
                          <div>
                            <span className="fw-normal">
                              {" "}
                              {walletIcon}Balance
                            </span>
                            <div className="fw-bold">
                              <i className="bx bx-rupee" />

                              {balance}
                            </div>
                          </div>
                        </div>
                        <hr className="my-24" />
                        <form id="formAccountSettings">
                          <div className="row">
                            <div className="mb-3 col-md-4">
                              <label htmlFor="firstName" className="form-label">
                                Amount<span>*</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">₹</span>
                                </div>
                                <input
                                  className="form-control "
                                  type="text"
                                  id="amount"
                                  onChange={(e) => {
                                    formik?.setFieldValue(
                                      "amount",
                                      e.target.value
                                    );
                                    setShowMsg(false);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.amount}
                                  name="amount"
                                  autoFocus=""
                                  onKeyPress={restrictAlpha}
                                />
                              </div>

                              {formik.touched.amount && formik.errors.amount ? (
                                <div className="text-danger">
                                  {formik.errors.amount}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 col-md-4">
                              <label htmlFor="lastName" className="form-label">
                                PO Number (Optional)
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="refNo"
                                id="refNo"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.refNo}
                                maxLength={15}
                              />
                              {formik.touched.refNo && formik.errors.refNo ? (
                                <div className="text-danger">
                                  {formik.errors.refNo}
                                </div>
                              ) : null}
                              {!isValidRefNo && !formik.errors.refNo && (
                                <div className="text-danger">Not Available</div>
                              )}
                            </div>
                            <div className="mb-3 col-md-4">
                              <label htmlFor="emailId" className="form-label">
                                Email Id<span>*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control withoutborder"
                                  type="text"
                                  name="email"
                                  id="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                />
                                <span className="input-group-text noleft ">
                                  {pencilIcon}
                                </span>
                              </div>
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div>
                            {showMsg && (
                              <div className="text-danger">
                                KYB verification should be completed to add
                                funds more than Rs.50,000. Please contact{" "}
                                <a href="">support@credencerewards.com</a> for
                                any queries.
                              </div>
                            )}
                          </div>

                          <div className="mt-4 d-flex flex-wrap justify-content-between">
                            <p className="fs-7">
                              Note: Additional 2.5% will be charged on Credit
                              Card Payments
                            </p>
                            <button
                              type="button"
                              className="btn btn-primary rounded-md  "
                              disabled={loading || hasErrors}
                              onClick={() => {
                                formik?.handleSubmit();
                              }}
                              style={{ borderRadius: "12px" }}
                            >
                              {arrowRight} Proceed to pay
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-12 col-sm-12 col-md-12 mt-4">
                    <div className="card h-100 ">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h5
                            className="card-title text-primary fw-semibold"
                            style={{ lineHeight: "34px" }}
                          >
                            {compass} Transaction History{" "}
                          </h5>
                          <Link to="/admin/report">View More</Link>
                        </div>

                        <div className="row" style={{ overflowX: "auto" }}>
                          <div className="col-12">
                            {filterHistory && filterHistory?.length !== 0 ? (
                              <table className="table">
                                <thead
                                  style={{
                                    backgroundColor: "#B2AEFF",
                                    color: "#111827",
                                    fontWeight: "700",
                                  }}
                                >
                                  <tr>
                                    <th scope="col">Id</th>

                                    <th scope="col">Date</th>

                                    <th scope="col">PO Number</th>
                                    <th scope="col">Remark</th>
                                    <th scope="col">Amount Cr/Dr</th>
                                    <th scope="col">Closing Balance</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filterHistory?.map((data, index) => {
                                    return (
                                      <tr>
                                        <td>{data?.id}</td>

                                        <th
                                          className="fw-normal"
                                          scope="row"
                                          style={{ letterSpacing: "0px" }}
                                        >
                                          {" "}
                                          {moment(data?.created_at)?.format(
                                            "ll"
                                          )}
                                        </th>

                                        <td>
                                          {data?.ref_no ? (
                                            <>
                                              {data?.ref_no}{" "}
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  copycode(data?.ref_no)
                                                }
                                              >
                                                {copyIcon}
                                              </span>
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </td>
                                        <td>
                                          {data?.transaction_type == "debit"
                                            ? "Vouchers issued"
                                            : "Fund Added"}
                                        </td>
                                        <td>
                                          {" "}
                                          <span>
                                            {data?.transaction_type ==
                                            "credit" ? (
                                              <span>
                                                {/* <i class="bx bx-chevron-up bx-flip"></i> */}
                                                + ₹ {data?.amount}
                                              </span>
                                            ) : (
                                              <span className="fw-normal">
                                                {/* <i class="bx bx-chevron-down bx-flip"></i> */}
                                                - ₹ {data?.amount}
                                              </span>
                                            )}
                                          </span>
                                        </td>
                                        <td>{data?.balance}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <>
                                <li className="d-flex justify-content-center align-items-center mt-4">
                                  <strong>No Recent Activity Found!</strong>
                                </li>
                              </>
                            )}
                            {/* <div className="d-flex justify-content-center mt-4">
                              <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={history?.length}
                                pageSize={PageSize}
                                onPageChange={(page) => {
                                  setCurrentPage(page)
                                }}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Content wrapper */}
            </div>
            {/* / Layout page */}
          </div>
          {/* Overlay */}
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
      {/* <PaymentMsgModal show={showModal} onHide={handleCloseModal} /> */}
      <div className="d-flex justify-content-center align-items-center">
        <PaymentModal
          show={showModal}
          setShowModal={setShowModal}
          amount={formik?.values?.amount}
          handleReset={formik?.handleReset}
        />
      </div>
    </>
  );
}

export default AddFunds;
