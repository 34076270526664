import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Navigate } from "react-router-dom";
import { Route, Routes } from "react-router";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import ForgetPassword from "./Components/Auth/ForgetPassword";
import PlumAdminRoutes from "./PlumAdminRoutes";
import VerifyOtp from "./Components/Auth/VerifyOtp";
import BankDetails from "./Components/Auth/BankDetails";
import { ToastContainer } from "react-toastify";
import EmailVerified from "./Components/Auth/EmailVerified";
import EmailFailed from "./Components/Auth/EmailFailed";
import { getToken } from "./session";
import PageNotFound from "./Components/PageNotFound";
import ResetPassword from "./Components/Auth/ResetPassword";
import "react-toastify/dist/ReactToastify.css";
import NewLogin from "./Components/Auth/NewLogin";
import OverView from "./Components/HelpCenter/OverView";
import Terms from "./Components/Terms";
import Policy from "./Components/Policy";
import Invitation from "./Components/Invitation";
import ThanksPage from "./Components/ThanksPage";
import { useEffect } from "react";

function App() {
  
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Disable right-click context menu
      const disableContextMenu = (event) => {
        event.preventDefault();
      };

      // Disable specific keyboard shortcuts
      const disableDevToolsShortcut = (event) => {
        if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')) {
          event.preventDefault();
        }
      };

      const devToolsListener = () => {
        if (
          window.outerWidth - window.innerWidth > 100 ||
          window.outerHeight - window.innerHeight > 100
        ) {
          console.log('Dev tools detected');
          // Optionally, you can take action here, such as closing the window or displaying a message
          // Example: window.close();
        }
      };
    
      window.addEventListener('resize', devToolsListener);
      window.addEventListener('devtoolschange', devToolsListener);

      // Add event listeners for production only
      document.addEventListener('contextmenu', disableContextMenu);
      document.addEventListener('keydown', disableDevToolsShortcut);
    

   

      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener('resize', devToolsListener);
        window.removeEventListener('devtoolschange', devToolsListener);
        document.removeEventListener('contextmenu', disableContextMenu);
        document.removeEventListener('keydown', disableDevToolsShortcut);
     
      };
    }
  }, []);
  return (
    <>
      <ToastContainer
        autoClose={1200}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* <TawkMessengerReact
        propertyId="65a8bad88d261e1b5f54ac43"
        widgetId="1hkdhjnoj"
      /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/verified" element={<ThanksPage />} />
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/terms-&-Conditions" element={<Terms />} />
          <Route path="/Policy" element={<Policy />} />
          <Route path="/" element={<NewLogin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/bank-details" element={<BankDetails />} />
          <Route path="/verification-success" element={<EmailVerified />} />
          <Route path="/verification-failure" element={<EmailFailed />} />

          <Route path="/admin/*" element={<PlumAdminRoutes />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
