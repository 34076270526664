import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.scss";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const handleChange = (e, pageNumber) => {
    e.preventDefault();
    onPageChange(pageNumber);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      {
         currentPage !== 1 &&  <li
         className={classnames("pagination-item", {
           disabled: currentPage === 1,
         })}
         onClick={onPrevious}
       >
         <div>prev</div>
       </li>
      }
     
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={(e) => handleChange(e, pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {
        currentPage !== lastPage  &&   <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div>next</div>
      </li>
      }
    
    </ul>
  );
};

export default Pagination;
