import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTemplate } from "../redux/actions/templateActions";
import { BASE_URL } from "../constant";
import logo from "../CredLogo.png";
import footer from "../Images/emailfooter.png";
import multi from "../Images/Multibrand.svg";
function EmailPreview(props) {
  const {
    setShowExplore,
    setTemplateId,
    templateId,
    emailSub,
    setEmailSub,
    setTempImg,
    tempImg,
    setCustomTemp,
    list,
    customTemp,
  } = props;
  const [previewMobile, setPreviewMobile] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const dispatch = useDispatch();
  const templateList = useSelector((state) => state?.template?.data?.data);
  const firstElement = templateList?.filter((_, index) => index === 0)?.shift();

  useEffect(() => {
    dispatch(getTemplate());
    if (templateId == "") {
      setTemplateId(firstElement?.id);
    }
    // if (tempImg == "") {
    //   setTempImg(firstElement?.image);
    // }
  }, []);

  let previewImg = customTemp && URL?.createObjectURL(customTemp);
  console.log(list);
  return (
    <>
      {" "}
      <div className="tab-content abc">
        <div className="tab-pane active">
          <div className="row email-preview  justify-content-center">
            <div className="col-12 mail-sub">
              <label htmlFor="lastName" className="form-label">
                Email Subject*
              </label>
              <input
                className="form-control"
                type="text"
                name="lastName"
                id="lastName"
                onChange={(e) => setEmailSub(e.target.value)}
                value={emailSub}
              />

              <div className="d-flex align-items-center mt-2 justify-content-end">
                <div className="text-right inkLight">44/100</div>
              </div>
              <div className="col-12 pl-0 mb-3">
                <div
                  className="sc-qZqnM kuhHaH fs-3 text-left mb-2 fw-semibold"
                  color="dark"
                >
                  Choose Email Template
                </div>
                <p className="sc-csKIKE cQjtJk text-left mb-4" color="dark">
                  Choose from an email template in which the reward will be
                  sent.
                </p>

                <div className="  mb-4 d-flex flex-wrap justify-content-around ">
                  {templateList?.slice(0, 4)?.map((item, index) => {
                    return (
                      <div className="" key={index}>
                        <div>
                          <input
                            type="radio"
                            value={item?.name}
                            checked={templateId === item?.id}
                            onClick={() => {
                              setTemplateId(item?.id);
                              setTempImg(item?.image);
                              setCustomTemp("");
                            }}
                          />
                          <label className="px-2" htmlFor="">
                            {item?.name}
                          </label>
                        </div>

                        <div
                          className="relative shadow-lg "
                          style={{
                            borderRadius: "4px",

                            padding: "4px",
                          }}
                        >
                          <img
                            className=" "
                            src={BASE_URL + item?.image}
                            alt=""
                            style={{
                              width: "7.5rem",
                              height: "4rem",
                              borderRadius: "4px",
                            }}
                            onClick={() => {
                              setTemplateId(item?.id);
                              setTempImg(item?.image);
                              setCustomTemp("");
                            }}
                          />
                        </div>

                        <div className="mt-2">
                          <p color="dark" className="">
                            {templateId == item?.id ? "" : ""}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                  <div className="">
                    <div>
                      <label className="" htmlFor="">
                        Upload Template{" "}
                        <span title="Upload a custom image (600x250 pixels, max 5MB) in JPEG or PNG format.">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24px"
                            height="24px"
                          >
                            {" "}
                            <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                          </svg>
                        </span>
                      </label>
                    </div>

                    <div
                      className="relative shadow-lg  text-center dashed-border mt-1"
                      style={{
                        borderRadius: "4px",
                        marginTop: "2px",
                        padding: "4px",
                      }}
                    >
                      <div className="custom-file text-center ">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="fileInput"
                          aria-describedby="fileHelp"
                          accept="image/png,  image/jpeg"
                          onChange={(e) => setCustomTemp(e.target.files[0])}
                          onClick={(event) => {
                            const { target = {} } = event || {};
                            target.value = "";
                          }}
                        />
                        <label
                          className="custom-file-label text-center"
                          htmlFor="fileInput"
                        >
                          {customTemp ? (
                            <>
                              <img
                                className=" "
                                src={previewImg}
                                alt=""
                                style={{
                                  width: "7.5rem",
                                  height: "4rem",
                                  borderRadius: "4px",
                                }}
                              />
                            </>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="50"
                              height="50"
                              viewBox="0 0 107 93"
                              fill="none"
                            >
                              <path
                                d="M55.0912 21.2135C54.8881 21.8943 55.2759 22.6098 55.9564 22.8128L56.049 22.8404L56.0518 22.8373C56.7051 22.9797 57.3639 22.5946 57.5558 21.9465C59.3852 15.7997 65.1479 11.5062 71.5688 11.5062C72.2789 11.5062 72.8548 10.9303 72.8548 10.2202C72.8548 9.51011 72.2789 8.9342 71.5688 8.9342C63.7603 8.9342 57.1982 14.1331 55.0912 21.2135ZM55.0912 21.2135L55.2349 21.2564M55.0912 21.2135C55.0912 21.2135 55.0912 21.2136 55.0911 21.2136L55.2349 21.2564M55.2349 21.2564C55.0556 21.8576 55.398 22.4897 55.9993 22.6691L55.2349 21.2564Z"
                                fill="#483EA8"
                                stroke="#F9FFF9"
                                stroke-width="0.3"
                              />
                              <path
                                d="M87.4716 65.8841H80.6558C80.0285 65.8841 79.5198 65.3753 79.5198 64.7481C79.5198 64.1209 80.0285 63.6121 80.6558 63.6121H87.4716C96.8669 63.6121 104.511 55.9677 104.511 46.5723C104.511 37.177 96.8669 29.5326 87.4716 29.5326H87.3077C86.9782 29.5326 86.665 29.3898 86.4492 29.1407C86.2334 28.8916 86.136 28.5613 86.1831 28.2351C86.2845 27.5276 86.3356 26.8168 86.3356 26.1246C86.3356 17.9821 79.7103 11.3568 71.5677 11.3568C68.3999 11.3568 65.379 12.3467 62.8311 14.2202C62.2712 14.6316 61.476 14.449 61.1523 13.8332C53.9364 0.0926809 35.0892 -1.75253 25.3179 10.2005C21.2017 15.2361 19.5843 21.7867 20.8803 28.1711C21.0231 28.8762 20.4835 29.5334 19.767 29.5334H19.3118C9.91651 29.5334 2.27208 37.1779 2.27208 46.5732C2.27208 55.9685 9.91651 63.613 19.3118 63.613H26.1277C26.7549 63.613 27.2637 64.1217 27.2637 64.7489C27.2637 65.3762 26.7549 65.8849 26.1277 65.8849H19.3118C8.66352 65.8849 0 57.2214 0 46.5731C0 36.2235 8.18389 27.749 18.4201 27.2816C17.4585 20.658 19.2972 13.9767 23.5587 8.76268C34.0203 -4.03578 54.0691 -2.60124 62.5443 11.67C65.248 9.97491 68.3418 9.08569 71.5673 9.08569C81.4325 9.08569 89.2498 17.4823 88.5658 27.2922C98.7077 27.861 106.783 36.2908 106.783 46.5723C106.783 57.2214 98.1194 65.8841 87.4711 65.8841L87.4716 65.8841Z"
                                fill="#483EA8"
                              />
                              <path
                                d="M24.6896 64.1067C24.6896 79.8494 37.4971 92.6567 53.2395 92.6567C68.9822 92.6567 81.7895 79.8492 81.7895 64.1067C81.7895 48.364 68.9822 35.5568 53.2395 35.5568C37.4968 35.5568 24.6896 48.3642 24.6896 64.1067ZM27.2621 64.1067C27.2621 49.7831 38.9157 38.1293 53.2395 38.1293C67.5631 38.1293 79.217 49.7829 79.217 64.1067C79.217 78.4303 67.5631 90.0842 53.2395 90.0842C38.9159 90.0842 27.2621 78.4305 27.2621 64.1067Z"
                                fill="#483EA8"
                                stroke="#F9FFF9"
                                stroke-width="0.3"
                              />
                              <path
                                d="M52.777 75.5392C52.777 76.0813 53.2166 76.5209 53.7587 76.5209C54.3007 76.5209 54.7403 76.0819 54.7403 75.5392V53.9156C54.7403 53.3736 54.3007 52.934 53.7587 52.934C53.2166 52.934 52.777 53.3736 52.777 53.9156V75.5392Z"
                                fill="#483EA8"
                                stroke="#483EA8"
                                stroke-width="0.3"
                              />
                              <path
                                d="M53.7586 55.3064L47.7995 61.2655C47.4165 61.6492 46.7945 61.6491 46.4111 61.2656C46.0276 60.8821 46.0276 60.2608 46.4111 59.8772L53.0643 53.224C53.0643 53.224 53.0643 53.224 53.0644 53.2239C53.4474 52.8403 54.0693 52.8405 54.4527 53.2239L61.106 59.8772C61.4895 60.2607 61.4895 60.8819 61.1062 61.2654C60.9145 61.4578 60.6625 61.5531 60.4119 61.5531L53.7586 55.3064ZM53.7586 55.3064L59.7177 61.2656C59.9092 61.457 60.1611 61.5531 60.4119 61.5531L53.7586 55.3064Z"
                                fill="#483EA8"
                                stroke="#483EA8"
                                stroke-width="0.3"
                              />
                            </svg>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className=" border cursor-pointer border-primary"
                    style={{
                      height: "fit-content",
                      padding: "5px",
                      color: "#594DF1",
                      borderColor: "purple",
                      borderRadius: "15px",
                      marginTop: "35px",
                    }}
                    onClick={() => {
                      {
                        setShowExplore(true);
                        setCustomTemp("");
                      }
                    }}
                  >
                    Explore All
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-48" />
            <div className="col-12 " style={{ marginTop: "20px !important" }}>
              <div className="emailTempBckground">
                <div className="previewMode">
                  {/* <div className="d-flex align-items-center justify-content-center previewOptions">
                    <div
                      className={
                        previewMobile
                          ? "pointer systemLayout"
                          : "pointer systemLayout active"
                      }
                      onClick={() => setPreviewMobile(false)}
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.4286 3.35693H2.57143C2.25536 3.35693 2 3.61229 2 3.92836V12.7855C2 13.1016 2.25536 13.3569 2.57143 13.3569H9.35714V15.3569H6.28571C6.12857 15.3569 6 15.4855 6 15.6427V16.4998C6 16.5784 6.06429 16.6427 6.14286 16.6427H13.8571C13.9357 16.6427 14 16.5784 14 16.4998V15.6427C14 15.4855 13.8714 15.3569 13.7143 15.3569H10.6429V13.3569H17.4286C17.7446 13.3569 18 13.1016 18 12.7855V3.92836C18 3.61229 17.7446 3.35693 17.4286 3.35693Z"
                          fill="#252A31"
                        />
                      </svg>
                      <span className="ml-2">Desktop</span>
                    </div>
                    <div
                      className={
                        previewMobile
                          ? "pointer systemLayout active"
                          : "pointer systemLayout"
                      }
                      onClick={() => setPreviewMobile(true)}
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.143 2H5.85724C5.22689 2 4.71439 2.5125 4.71439 3.14286V16.8571C4.71439 17.4875 5.22689 18 5.85724 18H14.143C14.7733 18 15.2858 17.4875 15.2858 16.8571V3.14286C15.2858 2.5125 14.7733 2 14.143 2ZM14.0001 16.7143H6.0001V3.28571H14.0001V16.7143ZM9.28581 14.8929C9.28581 15.0823 9.36107 15.264 9.49502 15.3979C9.62898 15.5319 9.81066 15.6071 10.0001 15.6071C10.1895 15.6071 10.3712 15.5319 10.5052 15.3979C10.6391 15.264 10.7144 15.0823 10.7144 14.8929C10.7144 14.7034 10.6391 14.5217 10.5052 14.3878C10.3712 14.2538 10.1895 14.1786 10.0001 14.1786C9.81066 14.1786 9.62898 14.2538 9.49502 14.3878C9.36107 14.5217 9.28581 14.7034 9.28581 14.8929Z"
                          fill="#5F738C"
                        />
                      </svg>
                      <span className="ml-2">Mobile</span>
                    </div>
                  </div> */}
                </div>
                <div
                  className={
                    previewMobile
                      ? "emailTemplateSection border1 p-2 my-0 mx-auto bg-white mobile"
                      : "emailTemplateSection border1 p-2 my-0 mx-auto bg-white"
                  }
                >
                  <div className="justify-content-center row">
                    <div className="col-12">
                      <div className="logoSection position-relative">
                        <div className="logoPreview">
                          <div className="d-flex justify-content-center">
                            <img
                              className="img logo-template-image"
                              src={logo}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="justify-content-center row">
                    <div className="col-12">
                      <div className="position-relative mailBannerImage">
                        <div className="showMask h-100">
                          <div className="editBtn d-flex align-items-center justify-content-end pr-4">
                            <span className="cursor-pointer">
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.5325 16.2791H8.85382L15.1332 9.99969L15.1336 9.99936L15.1339 9.99899L17.1425 7.9904C17.2558 7.87711 17.3457 7.74259 17.4071 7.59455C17.4684 7.4465 17.5 7.28783 17.5 7.12758C17.5 6.96733 17.4684 6.80865 17.4071 6.6606C17.3458 6.51255 17.2559 6.37803 17.1426 6.26472L13.7346 2.85669C13.5056 2.62826 13.1953 2.49999 12.8718 2.5C12.5483 2.50001 12.238 2.62832 12.009 2.85677L2.85734 12.0084C2.73885 12.1269 2.6461 12.2686 2.58492 12.4246C2.57865 12.4398 2.57336 12.4553 2.56837 12.4709C2.52336 12.5996 2.50025 12.7349 2.5 12.8712V16.2791C2.50037 16.6027 2.62905 16.9128 2.8578 17.1416C3.08656 17.3703 3.39671 17.499 3.72022 17.4994H16.5325C16.6943 17.4994 16.8495 17.4351 16.9639 17.3207C17.0783 17.2062 17.1426 17.0511 17.1426 16.8893C17.1426 16.7274 17.0783 16.5723 16.9639 16.4578C16.8495 16.3434 16.6943 16.2791 16.5325 16.2791ZM12.8718 3.71957L16.2797 7.12752L14.7022 8.70508L11.2942 5.2972L12.8718 3.71957Z"
                                  fill="#28A138"
                                />
                              </svg>
                              <span
                                className="ml-2 fw-500 text-white"
                                onClick={() => setShowExplore(true)}
                              >
                                Edit Banner
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="voucher-image-preview text-center">
                          <div className="">
                            {customTemp ? (
                              <img
                                width={600}
                                height={150}
                                className="img img-fluid  voucher-template-image"
                                style={{
                                  borderRadius: "20px",
                                  height: "150px",
                                }}
                                src={previewImg}
                              />
                            ) : (
                              <img
                                width={600}
                                height={150}
                                className="img img-fluid voucher-template-image"
                                style={{
                                  borderRadius: "20px",
                                  height: "261px",
                                }}
                                src={
                                  tempImg != ""
                                    ? BASE_URL + tempImg
                                    : BASE_URL + firstElement?.image
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="position-relative  ">
                        <div style={{ marginBottom: 12, textAlign: "center" }}>
                          <span
                            className="inkNormal fw-bold"
                            style={{ color: "#5A4EF1" }}
                          >
                            Congratulations 🥳
                          </span>
                        </div>
                        <div className="inkNormal emailBody">
                          <div className="plumProDistributionEmailBody text-center">
                            {list?.length > 1 ? (
                              <div>Recipent Name</div>
                            ) : (
                              <div>{list[0]?.name}</div>
                            )}
                            {list?.length > 1 ? (
                              <div>You've got a E-gift Card Voucher.</div>
                            ) : (
                              <div>You've got a {list[0]?.voucher?.label}.</div>
                            )}

                            <div
                              className="overlayBanner align-items-center justify-content-center px-3"
                              style={{ top: 40 }}
                            >
                              <div className="mr-2">
                                <svg
                                  width={20}
                                  height={20}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 2C8.41775 2 6.87103 2.46919 5.55544 3.34824C4.23985 4.22729 3.21447 5.47672 2.60897 6.93853C2.00347 8.40034 1.84504 10.0089 2.15372 11.5607C2.4624 13.1126 3.22433 14.538 4.34315 15.6569C5.46197 16.7757 6.88743 17.5376 8.43928 17.8463C9.99113 18.155 11.5997 17.9965 13.0615 17.391C14.5233 16.7855 15.7727 15.7602 16.6518 14.4446C17.5308 13.129 18 11.5822 18 10C17.9975 7.87902 17.1539 5.84561 15.6541 4.34585C14.1544 2.84609 12.121 2.00245 10 2ZM9.99992 5.69231C10.1825 5.69231 10.361 5.74644 10.5128 5.84787C10.6646 5.9493 10.7829 6.09347 10.8527 6.26214C10.9226 6.43081 10.9409 6.61641 10.9053 6.79547C10.8696 6.97453 10.7817 7.139 10.6526 7.2681C10.5235 7.39719 10.3591 7.48511 10.18 7.52072C10.0009 7.55634 9.81535 7.53806 9.64668 7.46819C9.47801 7.39833 9.33384 7.28002 9.23241 7.12822C9.13099 6.97642 9.07685 6.79795 9.07685 6.61538C9.07685 6.49416 9.10072 6.37413 9.14711 6.26214C9.1935 6.15014 9.2615 6.04838 9.34721 5.96267C9.43293 5.87695 9.53469 5.80896 9.64668 5.76257C9.75867 5.71618 9.87871 5.69231 9.99993 5.69231H9.99992ZM10.6154 14.3077H10C9.91918 14.3077 9.83913 14.2918 9.76445 14.2609C9.68977 14.23 9.62191 14.1847 9.56476 14.1276C9.5076 14.0704 9.46228 14.0025 9.43137 13.9279C9.40046 13.8532 9.38457 13.7731 9.38462 13.6923V10C9.22141 10 9.06488 9.93516 8.94947 9.81976C8.83407 9.70435 8.76923 9.54782 8.76923 9.38461C8.76923 9.2214 8.83407 9.06488 8.94947 8.94947C9.06488 8.83406 9.22141 8.76923 9.38462 8.76923H10C10.0808 8.76918 10.1609 8.78507 10.2356 8.81598C10.3102 8.84689 10.3781 8.89221 10.4352 8.94937C10.4924 9.00652 10.5377 9.07438 10.5686 9.14906C10.5995 9.22374 10.6154 9.30379 10.6154 9.38461V13.0769C10.7786 13.0769 10.9351 13.1418 11.0505 13.2572C11.1659 13.3726 11.2308 13.5291 11.2308 13.6923C11.2308 13.8555 11.1659 14.012 11.0505 14.1274C10.9351 14.2429 10.7786 14.3077 10.6154 14.3077Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              <div>
                                Message to the recipient will overwrite the
                                default message here.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="justify-content-center row mt-2">
                    {/* Order Details: */}
                    <div className="col-12">
                      <div className=" m-1 text-center inkNormal">
                        <div className="rewardData mt-3 ">
                          <div className="fw-bold">Order Details</div>
                          <hr />
                          {/* {list?.length > 1 && list (
                            <div className="d-flex align-items-center justify-content-center gap-5">
                              <div className="my-2">
                                <img width={120} src={multi} />
                              </div>
                              <div className=" d-flex justify-content-evenly gap-5">
                                <div>
                                  <h6>Brands</h6>
                                  <p>Brand Name</p>
                                </div>
                                <div>
                                  <h6>Qty</h6>
                                  <p>******</p>
                                </div>
                                <div>
                                  <h6>Validity</h6>
                                  <p>******</p>
                                </div> */}
                                {/* <table className="table ">
                                  <thead
                                    className=""
                                    style={{ border: "none" }}
                                  >
                                    <tr style={{ border: "none" }}>
                                      <th scope="col">Brand</th>
                                      <th scope="col">Qty</th>
                                      <th scope="col">Validity</th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ border: "none" }}>
                                    <tr style={{ border: "none" }}>
                                      <td>********</td>
                                      <td>********</td>
                                      <td>********</td>
                                    </tr>

                                   
                                  </tbody>
                                </table> */}
                              {/* </div>
                            </div>
                          )} */}
                          {list?.length > 1 && (
                            <div className="d-flex align-items-center justify-content-center gap-5">
                              <div className="my-2">
                                <img
                                //  width={120} 
                                 src={multi} />
                              </div>
                              <div className=" d-flex justify-content-evenly gap-5">
                                <div>
                                  <h6>Qty</h6>
                                  <p>******</p>
                                </div>
                                <div>
                                  <h6>Total Amount</h6>
                                  <p>******</p>
                                </div>
                                <div>
                                  <h6>Validity</h6>
                                  <p>******</p>
                                </div>
                                {/* <table className="table ">
                                  <thead
                                    className=""
                                    style={{ border: "none" }}
                                  >
                                    <tr style={{ border: "none" }}>
                                      <th scope="col">Brand</th>
                                      <th scope="col">Qty</th>
                                      <th scope="col">Validity</th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ border: "none" }}>
                                    <tr style={{ border: "none" }}>
                                      <td>********</td>
                                      <td>********</td>
                                      <td>********</td>
                                    </tr>

                                   
                                  </tbody>
                                </table> */}
                              </div>
                            </div>
                          )}

                          {list?.length === 1 &&
                            list?.map((item) => (
                              <div className="d-flex align-items-center justify-content-around ">
                                <div className="my-2">
                                  <img
                                    width={120}
                                    src={item?.voucher?.data?.logo_url}
                                  />
                                </div>
                                <div
                                  className="d-flex justify-content-between gap-5"
                                  style={{ border: "none" }}
                                >
                                  <div>
                                    <h6>Qty</h6>
                                    <p>{item?.numberOfVouchers}</p>
                                  </div>
                                  <div>
                                    <h6>Value</h6>
                                    <p>
                                      {" "}
                                      {item?.voucherValue?.value
                                        ? item?.voucherValue?.value
                                        : item?.voucherValue}
                                    </p>
                                  </div>
                                  <div>
                                    <h6>Validity</h6>
                                    <p>******</p>
                                  </div>
                                  {/* <table
                                    className="table "
                                    style={{ border: "none" }}
                                  >
                                    <thead
                                      className=""
                                      style={{ border: "none" }}
                                    >
                                      <tr style={{ border: "none" }}>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Validity</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ border: "none" }}>
                                      <tr style={{ border: "none" }}>
                                        <td>{item?.numberOfVouchers}</td>
                                        <td>
                                          {" "}
                                          {item?.voucherValue?.value
                                            ? item?.voucherValue?.value
                                            : item?.voucherValue}
                                        </td>
                                        <td style={{ fontSize: "11px" }}>
                                          {" "}
                                          *******
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table> */}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="text-center">Transaction ID:*********</div>
                  <div className="  row  ">
                    <div className="text-center mt-2   ">
                      <div
                        className="d-flex align-items-center justify-content-center py-3 "
                        style={{ columnGap: 20 }}
                      >
                        <img width={"100%"} src={footer} alt="" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="rewardEmailFooter text-center ">
                <div className="content">
                  To raise any concern, email us at{" "}
                  <span
                    style={{
                      textDecoration: "underline"
                    }}
                  >
                    cs@xoxoday.com
                  </span>{" "}
                  <br /> or Whatsapp at{" "}
                  <span
                    style={{
                      textDecoration: "underline"
                    }}
                  >
                    (+91) 8061915050.
                  </span>
                </div>
              </div> */}
                </div>
                {/* <div
              className="text-center fw-500 emailTemplateSection"
              style={{ margin: "42px auto 0px" }}
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.75 2C9.82164 2 7.93657 2.57183 6.33319 3.64317C4.72982 4.71451 3.48013 6.23726 2.74218 8.01884C2.00422 9.80042 1.81114 11.7608 2.18735 13.6521C2.56355 15.5434 3.49215 17.2807 4.85571 18.6443C6.21928 20.0079 7.95656 20.9365 9.84787 21.3127C11.7392 21.6889 13.6996 21.4958 15.4812 20.7578C17.2627 20.0199 18.7855 18.7702 19.8568 17.1668C20.9282 15.5634 21.5 13.6784 21.5 11.75C21.497 9.16506 20.4688 6.68684 18.641 4.85901C16.8132 3.03118 14.3349 2.00299 11.75 2ZM11.7499 6.5C11.9724 6.5 12.1899 6.56598 12.3749 6.6896C12.5599 6.81321 12.7041 6.98891 12.7893 7.19448C12.8744 7.40005 12.8967 7.62625 12.8533 7.84448C12.8099 8.06271 12.7027 8.26316 12.5454 8.4205C12.3881 8.57783 12.1876 8.68498 11.9694 8.72838C11.7512 8.77179 11.525 8.74951 11.3194 8.66436C11.1138 8.57922 10.9381 8.43502 10.8145 8.25002C10.6909 8.06501 10.6249 7.8475 10.6249 7.625C10.6249 7.47726 10.654 7.33097 10.7105 7.19448C10.7671 7.05799 10.85 6.93397 10.9544 6.8295C11.0589 6.72504 11.1829 6.64217 11.3194 6.58563C11.4559 6.5291 11.6022 6.5 11.7499 6.5H11.7499ZM12.5 17H11.75C11.6515 17.0001 11.5539 16.9807 11.4629 16.943C11.3719 16.9054 11.2892 16.8501 11.2195 16.7805C11.1499 16.7108 11.0947 16.6281 11.057 16.5371C11.0193 16.4461 10.9999 16.3485 11 16.25V11.75C10.8011 11.75 10.6103 11.671 10.4697 11.5303C10.329 11.3897 10.25 11.1989 10.25 11C10.25 10.8011 10.329 10.6103 10.4697 10.4697C10.6103 10.329 10.8011 10.25 11 10.25H11.75C11.8485 10.2499 11.9461 10.2693 12.0371 10.307C12.1281 10.3446 12.2108 10.3999 12.2805 10.4695C12.3501 10.5392 12.4054 10.6219 12.443 10.7129C12.4807 10.8039 12.5001 10.9015 12.5 11V15.5C12.6989 15.5 12.8897 15.579 13.0303 15.7197C13.171 15.8603 13.25 16.0511 13.25 16.25C13.25 16.4489 13.171 16.6397 13.0303 16.7803C12.8897 16.921 12.6989 17 12.5 17Z"
                  fill="#0172CB"
                />
              </svg>
              <span className="ml-2">
                Codes are sent as email attachment if
                'quantity' is more than 5
              </span>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for edit logo */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="sc-fSurYz izGpuT py-28 mt-12 logoPlaceholder text-center">
              <img src="https://xoxoday-dropbox.s3.ap-southeast-1.amazonaws.com/image/clients/20473400000000000/platform_setting/jobspri_logo.png?versionId=T30gVTqIPal.1lxNuJZUgp8CGdxIS80P" />
            </div>
            <div className="" />
            <div>
              <div className="sc-dmyDGi dLxfwr">
                <span color="dark" className="sc-dmyDGi kjliPN">
                  Maximum dimension:{" "}
                </span>
                <span color="dark" className="sc-dmyDGi jpYxZF">
                  240x80{" "}
                </span>
                <span color="dark" className="sc-dmyDGi kjliPN">
                  Maximum size:{" "}
                </span>
                <span color="dark" className="sc-dmyDGi jpYxZF">
                  100KB{" "}
                </span>
              </div>
              <div className="sc-dmyDGi dLxfwr">
                <span color="dark" className="sc-dmyDGi kjliPN">
                  Accepted formats:{" "}
                </span>
                <span color="dark" className="sc-dmyDGi jpYxZF">
                  PNG{" "}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-32">
              <div>
                <label htmlFor="newlogoupload">
                  <span
                    color="primary"
                    radius="primary"
                    className="sc-eTNep gsdsii"
                  >
                    Upload New
                  </span>
                </label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  id="newlogoupload"
                />
              </div>
              <Button disabled className="primary" radius="primary">
                <span className="sc-brSaZW kJTDHo btn-label">Save</span>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Button Text
            <p className="sc-dmyDGi kjliPN mb-20" color="dark">
              The button text will be the same for all recipients receiving the
              reward.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              color="base"
              className="sc-ifyqMZ fmwujZ sc-bhvInt cuwEgH mr-20 mb-20 me-3"
              radius={40}
            >
              <span className="eui-chip-label">Redeem Now</span>
              <span className="eui-chip-custom-tag">
                <span className="sc-beySbM jtLHXa">
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7291 38.625C19.3696 38.6148 19.0243 38.4826 18.7499 38.25L8.58327 29.6458C8.42151 29.5198 8.28651 29.3629 8.18617 29.1841C8.08582 29.0053 8.02214 28.8083 7.99886 28.6046C7.97558 28.4009 7.99317 28.1946 8.05059 27.9978C8.10801 27.8009 8.20411 27.6176 8.33327 27.4583C8.59335 27.1709 8.95218 26.992 9.33827 26.9572C9.72436 26.9225 10.1094 27.0344 10.4166 27.2708L19.3541 34.8542L39.0208 11.9375C39.2887 11.5866 39.6851 11.3566 40.1227 11.298C40.5603 11.2394 41.0032 11.357 41.3541 11.625C41.705 11.893 41.935 12.2894 41.9936 12.7269C42.0522 13.1645 41.9346 13.6075 41.6666 13.9583L20.8333 38.0625C20.6981 38.2275 20.5302 38.3627 20.3402 38.4596C20.1501 38.5564 19.9421 38.6127 19.7291 38.625Z"
                      fill="blue"
                    />
                  </svg>
                </span>
              </span>
            </div>
            <div
              color="base"
              className="sc-ifyqMZ fmwujZ mr-20 mb-20 me-3"
              radius={40}
            >
              <span className="eui-chip-label">Claim Now</span>
            </div>
            <div
              color="base"
              className="sc-ifyqMZ fmwujZ mr-20 mb-20 me-3"
              radius={40}
            >
              <span className="eui-chip-label">Collect Reward</span>
            </div>
          </div>
          <div>
            <div className="sc-AnhJK bjqAbh"></div>
            <div color="dark" className="sc-qZqnM kuhHaH mb-4">
              Or Enter your own
            </div>
            <div className="sc-cyZbSi kdXapD">
              <div className="sc-lnskGP iQwzfm">
                <div className="sc-jIBmdK kPDPQQ">
                  <input
                    maxLength={20}
                    className="sc-iKOlBE jMKfMb"
                    defaultValue="Redeem now"
                  />
                </div>
              </div>
              <div className="sc-eAKupa dQiYMK">
                <div className="sc-bZHTEL cjRDmS" />
                <div className="sc-kMzDYw eOLHfX">10/20</div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-20">
            <Button className="me-3" color="primary" radius="primary">
              <span className="sc-brSaZW kJTDHo btn-label">Cancel</span>
            </Button>
            <Button color="primary" radius="primary" className="primary me-3">
              <span className="sc-brSaZW kJTDHo btn-label">Save</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EmailPreview;
