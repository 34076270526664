import { createAsyncThunk } from "@reduxjs/toolkit"
import { getUserProfile } from "../../services/Apiservices"




export const getProfile = createAsyncThunk(
    'profile/fullProfile',
    async (arg, { rejectWithValue }) => {
      try {
        const response = await getUserProfile()
        return response?.data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )