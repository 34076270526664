// reducers/index.js
import { combineReducers } from 'redux';
import mobileNumberSlice from './mobileNumberSlice';
import authSlice from './authSlice';
import userSlice from './userSlice';
import profileSlice from './profileSlice';
import templateSlice from './templateSlice';


const rootReducer = combineReducers({
    mobileNumber: mobileNumberSlice,
    auth: authSlice,
    user: userSlice,
    profile:profileSlice,
    template:templateSlice
   
    
    // Add other reducers here
  });

export default rootReducer;
