import React from "react";
import SIdeBar from "../CommonComponents/SIdeBar";
import Navbar from "../CommonComponents/Navbar";
import banner from "../../Images/helpcenter.webp";
import { Link } from "react-router-dom";

function OverView() {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* Menu */}
        <SIdeBar />
        {/* / Menu */}
        {/* Layout container */}
        <div className="layout-page">
          {/* Navbar */}
          <Navbar />
          {/* / Navbar */}
          {/* Content wrapper */}
          <div className="content-wrapper">
            {/* Content */}
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="card h-80">
                <div className="card-body">
                  <div className="mt-3">
                    <h5 className="fw-semibold text-primary">
                      <span className="text-muted fw-light "></span>
                      Overview{" "}
                    </h5>
                    <p>
                      Welcome to the Squid Help Center! Your one-stop
                      destination for sending Rewards, Incentives, and Payouts
                      effortlessly!
                    </p>
                    <hr className="mt-4" />

                    <img className="" width={"100%"} src={banner} alt="" />
                    <div>
                      <h5 className="fw-semibold text-primary mb-4">
                        Account Setup{" "}
                      </h5>
                      <p style={{ fontSize: "16px", marginBottom:"0.05rem" }}>
                        Start your journey with us by setting up your account
                        effortlessly. Quickly become acquainted with Squid's
                        powerful features and capabilities."
                      </p>
                      <p className="fs-12">
                        Here is a collection of articles to help you set up your
                        Squid account.
                      </p>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-semibold text-primary mb-4">
                        Account Verification{" "}
                      </h5>
                      <p className="fs-12">
                        To meet regulatory requirements, customers must complete
                        a quick verification step as part of the KYB process for
                        brands.
                      </p>

                      <blockquote
                        className="callout callout_default"
                        theme="➡️"
                      >
                        <h4 className="callout-heading">
                          <p>KYB Verification</p>
                        </h4>
                        <p className="fs-12">
                          Visit the article{" "}
                          <Link to="/admin/submitting-verification-details">
                            Submitting Verification Details
                          </Link>{" "}
                          to learn all about the KYB verification process.
                        </p>
                      </blockquote>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-semibold text-primary mb-4">
                        Add Funds{" "}
                      </h5>
                      <p className="fs-12">
                        Squid operates on a pre-funded wallet system, offering
                        you complete oversight and control over your spending
                        and budget allocation for rewards.
                      </p>

                      <blockquote
                        className="callout callout_default"
                        theme="➡️"
                      >
                        <h4 className="callout-heading">
                          <p>Wallet Management</p>
                        </h4>
                        <p className="fs-12">
                          Visit the{" "}
                          <Link to="/admin/wallet-management">
                            Wallet Management
                          </Link>{" "}
                          section to know everything about managing your Squid
                          wallet.{" "}
                        </p>
                      </blockquote>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-semibold text-primary mb-4">
                        Send Rewards{" "}
                      </h5>
                      <p className="fs-12">
                        Distribute rewards and incentives seamlessly and
                        swiftly. Let's start by exploring the different reward
                        modes available in Squid.
                      </p>

                      <blockquote
                        className="callout callout_default"
                        theme="➡️"
                      >
                        <ol>
                          <li>
                            {" "}
                            <Link
                              className="fw-semibold text-primary"
                              to="/admin/send-reward-bulk"
                              style={{ color: "#696cff", cursor: "pointer" }}
                            >
                              Send Rewards to Many
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              className="fw-semibold text-primary"
                              to="/admin/send-reward-individual"
                              style={{ color: "#696cff", cursor: "pointer" }}
                            >
                              Send Rewards to Individuals
                            </Link>
                          </li>

                          <li>
                            {" "}
                            <Link
                              className="fw-semibold text-primary"
                              to="/admin/send-reward-self"
                              style={{ color: "#696cff", cursor: "pointer" }}
                            >
                              Send Rewards to Self
                            </Link>
                          </li>
                        </ol>
                      </blockquote>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-semibold text-primary mb-4">
                        Profile Management{" "}
                      </h5>
                      <p className="fs-12">
                        Learn more about how to{" "}
                        <Link
                          className="fw-semibold text-primary"
                          to="/admin/profile-management"
                          style={{ color: "#696cff", cursor: "pointer" }}
                        >
                          Manage Profile
                        </Link>{" "}
                      </p>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-semibold text-primary mb-4">
                        Manage Admin{" "}
                      </h5>
                      <p className="fs-12">
                        Explore{" "}
                        <Link
                          className="fw-semibold text-primary"
                          to="/admin/add-admin-user"
                          style={{ color: "#696cff", cursor: "pointer" }}
                        >
                          Manage Admin
                        </Link>{" "}
                        Feature to simplify role invitations, removals, and
                        access controls for efficient team administration.
                      </p>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-semibold text-primary mb-4">
                        Report Management{" "}
                      </h5>
                      <p className="fs-12">
                        Gain valuable insights from comprehensive order and
                        invoice{" "}
                        <Link
                          className="fw-semibold text-primary"
                          to="/admin/report-management"
                          style={{ color: "#696cff", cursor: "pointer" }}
                        >
                          Reports
                        </Link>{" "}
                        . Efficiently review, analyze, and download data to make
                        informed decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
}

export default OverView;
