import { useState, React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "./OTPInput";
import { resendOTP, verifyOTP } from "../../services/Apiservices";
import { setUser } from "../../redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/reducers/authSlice";
import { setSession } from "../../session";
import { getProfile } from "../../redux/actions/profileAction";
import otpScreen from "../../OtpScreen.png";
import bg from "../../Images/login-bg.webp";
import logo from "../../Images/whiteLogo.webp";
import hero from "../../Images/hero.gif";

function VerifyOtp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState();
  const [emailotp, setEmailOtp] = useState();

  const [loading, setisLoading] = useState(false);
  const mobileNumber = useSelector((state) => state.mobileNumber);
  const [otpSent, setOTPSent] = useState(true);
  const [timeLeft, setTimeLeft] = useState(5 * 60);

  useEffect(() => {
    let interval;

    if (otpSent) {
      interval = setInterval(() => {
        setTimeLeft((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setOTPSent(false);
            return 5 * 60; // Reset the timer to initial value when it reaches 0
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [otpSent]);

  useEffect(() => {
    // Disable scroll when the component mounts
    document.body.style.overflow = "hidden";
    document.body.style.paddingTop = "0"; // Reset padding-top
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    // Re-enable scroll when the component unmounts
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    };
  }, []);

  const handleSubmit = async (mobileOTP,OTP) => {
   
    if ((OTP && OTP?.length == 6) && (mobileOTP&& mobileOTP?.length==6)) {
      setisLoading(true);
      let body = {
        mobile: mobileNumber,
        otp: mobileOTP,
        email_otp: OTP,
      };
      try {
        let response = await verifyOTP(body);
        if (response?.status == 200) {
          setisLoading(false);
          setSession(response?.data?.access);
          dispatch(login());
          dispatch(setUser(response?.data?.details));
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response?.data?.details)
          );
          navigate("/admin/company-details");
          dispatch(getProfile());

          // navigate("/verify-otp")
        } else {
          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    }
  };
  const handleResend = async () => {
    setisLoading(true);

    let body = {
      mobile_no: mobileNumber,
    };
    try {
      let response = await resendOTP(body);
      if (response?.status == 200) {
        setOTPSent(true);
        setisLoading(false);
      } else {
        setOTPSent(false);

        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  return (
    <div className="d-flex flex-warp">
      <div style={{ width: "50%", position: "relative" }}>
        <img
          src={logo}
          alt=""
          style={{
            position: "absolute",
            width: "25%",
            left: "25px",
            top: "40px",
          }}
        />

        <div
          className="text-white"
          style={{
            position: "absolute",
            textAlign: "center",
            left: "0%",
            right: "0%",
            fontSize: "38px",
            fontWeight: "700",
            top: "150px",
          }}
        >
          Perks - Above and Beyond
          <p
            style={{
              position: "absolute",
              textAlign: "center",
              left: "0%",
              right: "0%",
              fontSize: "18px",
              fontWeight: "700",
              top: "60px",
            }}
          >
            Discover the Perfect Gift from 200+ Brands
          </p>
        </div>

        <div
          style={{
            position: "absolute",
            textAlign: "center",
            left: "0%",
            right: "0%",
            fontSize: "38px",
            bottom: "0px",
            top: "175px",
          }}
        >
          <img src={hero} width={"550px"} />
        </div>
        <img src={bg} style={{ width: "100%" }} alt="" />
      </div>
      <div
        style={{
          width: "50%",
          paddingTop: "190px",
          backgroundColor: "#fff",
        }}
      >
        {" "}
        <div className="text-center mt-4">
          <h5 className="mb-3 fw-semibold">OTP Verification</h5>
          <p className="mb-4 fw-normal mt-4">
            Please enter the OTP sent to your mobile no
          </p>

          <form id="formAuthentication" className="mb-3">
            <OTPInput
              autoFocus
              length={6}
              className="otpContainer"
              inputClassName="otpInput"
              onChangeOTP={(otp) => {
                setOtp(otp);
                handleSubmit(otp,emailotp)
              }}
            />

            <p className="mb-4 fw-normal mt-5">
              Please enter the OTP sent to your email
            </p>
            <OTPInput
              autoFocus
              length={6}
              className="otpContainer"
              inputClassName="otpInput"
              onChangeOTP={(OTP) => {
                setEmailOtp(OTP);
                handleSubmit(otp,OTP);
              }}
           
            />
            <p className="text-center">
              <span className="fs-7" style={{ color: "#4D4D4D" }}>
                Didn't you receive any code?{" "}
              </span>
              {otpSent ? (
                <Link>
                  <span className="fs-7" style={{ color: "black" }}>
                    Resend OTP {formatTime()}
                  </span>
                </Link>
              ) : (
                <Link onClick={handleResend}>
                  <span className="fs-7" style={{ color: "#594DF1" }}>
                    Resend OTP
                  </span>
                </Link>
              )}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VerifyOtp;
