import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { setMobileNumber } from "../../redux/reducers/mobileNumberSlice";
import { register } from "../../services/Apiservices";

import { restrictAlpha, restrictNumeric } from "../../constant";

import bg from "../../Images/login-bg.webp";
import logo from "../../Images/whiteLogo.webp";
import logo2 from "../../CredLogo.png";
import hero from "../../Images/hero.gif";

function Signup({ showVerifyOTP, setShowLogin }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setisLoading] = useState(false);
  const [errMsg, setErroMsg] = useState("");

  useEffect(() => {
    // Disable scroll when the component mounts
    document.body.style.overflow = "hidden";
    document.body.style.paddingTop = "0"; // Reset padding-top
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    // Re-enable scroll when the component unmounts
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingTop = "0"; // Reset padding-top
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      mobile: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile number must contain only digits")
        .min(10, "Mobile number must be at least 10 digits")
        .required("Mobile number is required"),

      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )
        .required("Email is Required"),
    }),

    onSubmit: (values) => {
      // You can handle the form submission here
      handleRegister(values);
    },
  });

  const handleRegister = async (values) => {
    setisLoading(true);
    let body = {
      email: values?.email,
      password: "Abhi@123",
      full_name: values?.name,
      mobile_no: values?.mobile,
    };
    try {
      let response = await register(body);
      if (response?.status == 200) {
        setisLoading(false);
        dispatch(setMobileNumber(values?.mobile));
        navigate("/verify-otp");
        setErroMsg(" ");
        // navigate("/verify-otp")
      } else {
        setErroMsg(response?.response?.data?.message);

        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex flex-warp">
        <div
          className="d-none d-md-block"
          style={{ width: "50%", position: "relative" }}
        >
          <img
            src={logo}
            alt=""
            style={{
              position: "absolute",
              width: "25%",
              left: "25px",
              top: "40px",
            }}
          />

          <div
            className="text-white"
            style={{
              position: "absolute",
              textAlign: "center",
              left: "0%",
              right: "0%",
              fontSize: "38px",
              fontWeight: "700",
              top: "150px",
            }}
          >
            Perks - Above and Beyond
            <p
              style={{
                position: "absolute",
                textAlign: "center",
                left: "0%",
                right: "0%",
                fontSize: "18px",
                fontWeight: "700",
                top: "60px",
              }}
            >
              Discover the Perfect Gift from 200+ Brands
            </p>
          </div>

          <div
            style={{
              position: "absolute",
              textAlign: "center",
              margin: "auto",
              left: "0px",
              right: "0px",
              fontSize: "38px",
              bottom: "0px",
              top: "175px",
            }}
          >
            <img className="d-none d-lg-block" src={hero} width={"550px"} />
          </div>
          <img src={bg} style={{ width: "100%" }} alt="" />
        </div>
        <div
          style={{
            // paddingTop: "190px",
            height: "100vh",
            backgroundColor: "#fff",
          }}
          className="col-12 col-md-6 custom-padding"
        >
          <form
            id="formAuthentication"
            className="mb-5 mx-auto col-8 col-md-6"
            // style={{ width: "50%" }}

            onSubmit={formik.handleSubmit}
          >
            <div className="d-flex justify-content-center py-5 d-lg-none">
              <img
                src={logo2}
                alt=""
                style={{
                  width: "130px",
                }}
              />
            </div>
            <h4
              className="text-center fw-semibold"
              style={{ fontSize: "24px", lineHeight: "20px" }}
            >
              Welcome to Credence👋
            </h4>
            <div className="mb-1">
              <label htmlFor="username" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                className="form-control "
                id="name"
                name="name"
                onChange={(e) => {
                  formik.setFieldValue("name", e?.target?.value);
                  setErroMsg("");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder="Enter your full name"
                autofocus
                maxLength={25}
                onKeyPress={restrictNumeric}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger fs-7 ">{formik.errors.name}</div>
              ) : null}
            </div>

            <div className="mb-1">
              <label htmlFor="username" className="form-label">
                Mobile No
              </label>
              <input
                type="text"
                className="form-control "
                id="mobile"
                name="mobile"
                onChange={(e) => {
                  formik.setFieldValue("mobile", e?.target?.value);
                  setErroMsg("");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                placeholder="Enter your mobile no"
                autofocus
                maxLength={10}
                onKeyPress={restrictAlpha}
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className="text-danger fs-7">{formik.errors.mobile}</div>
              ) : null}
            </div>
            <div className="mb-1">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control "
                id="email"
                name="email"
                onChange={(e) => {
                  formik.setFieldValue("email", e?.target?.value);
                  setErroMsg("");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Enter your email"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger fs-7">{formik.errors.email}</div>
              ) : (
                <>
                  {errMsg && (
                    <div className="text-danger fs-12 text-center mt-4">
                      {errMsg}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className=" d-flex justify-content-center">
              <button
                className="btn mt-2 "
                type="submit"
                style={{
                  backgroundColor: "#594DF1",
                  outline: "none",
                  border: "none",
                  width: "100%",
                  color: "#fff",
                  padding: "5px",
                }}
                disabled={loading}
              >
                Register
              </button>
            </div>
            <hr />
            <div className="text-center mt-4 " style={{ color: "gray" }}>
              Already have an Account?{" "}
              <span
                className="cursor-pointer"
                style={{ color: "#594DF1", fontWeight: "600" }}
              >
                <Link to="/">Log in</Link>
              </span>
            </div>
            <div className="text-center py-3">
              By Signing up, you are accepting our <br />
              <a
                href="https://squid.credencerewards.com/terms-&-Conditions"
                target="_blank"
              >
                Terms & Conditions
              </a>
               and {" "}
              <a
                href="https://squid.credencerewards.com/Policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Signup;
